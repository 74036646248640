import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";

import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { GradientOverlay } from "@components/item/Overlays";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { SecondaryButton } from "@components/buttons";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  FrameOneInformation,
  FrameOneInformationWrapper,
  FrameOneWrapper,
} from "./FrameStyling";

const FrameOne = ({ data, linkTo }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <CommonContainer>
      <FrameOneWrapper>
        <GradientOverlay opacity="0.8" />

        <ImageWrapper ratio={isMobile ? "1 / 1" : "2 / 1"}>
          <Image
            src={data?.field_thumbnail[0]?.src}
            alt={data?.field_thumbnail[0]?.alt}
          />

          <FrameOneInformation>
            <FrameOneInformationWrapper>
              <CommonTitleOne>
                <DangerouslyHtml value={data?.field_mice_name} />
              </CommonTitleOne>

              <CommonDescOne className="ellipsis-3">
                <DangerouslyHtml value={data?.field_full_description} />
              </CommonDescOne>
            </FrameOneInformationWrapper>

            <SecondaryButton linkTo={linkTo}>
              <ViewAllText />
            </SecondaryButton>
          </FrameOneInformation>
        </ImageWrapper>
      </FrameOneWrapper>
    </CommonContainer>
  );
};

export default memo(FrameOne);
