import React, { useMemo } from "react";
import { SwiperSlide } from "swiper/react";

import { image } from "src/theme";
import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import { SliderTemplate } from "@components/sliders";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  BoxListEightDesc,
  BoxListEightInformation,
  BoxListEightItem,
  BoxListSixDetailLink,
} from "./BoxListStyling";

const BoxListEight = ({ data, linkTo }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    data?.length > 0 && (
      <CommonContainer>
        <SliderTemplate length={data?.length}>
          {data?.map((item) => {
            return (
              <SwiperSlide key={item?.field_name}>
                <BoxListEightItem>
                  <ImageWrapper>
                    <Image
                      src={item?.field_thumbnail[0]?.src}
                      alt={item?.field_thumbnail[0]?.alt}
                    />
                  </ImageWrapper>

                  <BoxListEightInformation>
                    <CommonTitleTwo className="ellipsis-3">
                      <DangerouslyHtml value={item?.field_name} />
                    </CommonTitleTwo>

                    <BoxListEightDesc className="ellipsis-2">
                      <DangerouslyHtml value={item?.field_short_description} />
                    </BoxListEightDesc>

                    <BoxListSixDetailLink
                      to={`${baseUrl}${linkTo}${item.url_alias}`}
                    >
                      <ViewDetailsText />

                      <img
                        src={image.linkArrowPurple.default}
                        alt="right arrow"
                        width={22}
                      />
                    </BoxListSixDetailLink>
                  </BoxListEightInformation>
                </BoxListEightItem>
              </SwiperSlide>
            );
          })}
        </SliderTemplate>
      </CommonContainer>
    )
  );
};

export default BoxListEight;
