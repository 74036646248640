import React from "react";

import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  OurCompanyDesc,
  OurCompanyInformation,
} from "@pages/about-us/AboutUsStyling";

const OurCulture = ({ data }) => {
  return (
    <OurCompanyInformation>
      <OurCompanyDesc>
        <DangerouslyHtml value={data?.field_our_culture_description} />
      </OurCompanyDesc>

      <ImageWrapper ratio="auto" height="auto" radius="2.5rem">
        <Image
          src={data?.field_image[0]?.src}
          alt={data?.field_image[0]?.alt}
        />
      </ImageWrapper>
    </OurCompanyInformation>
  );
};

export default OurCulture;
