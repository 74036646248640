import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { SeeTheMenuText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { downloadPdf } from "@utils/modify-data/modifyData";

const SplashBayServicesList = ({ data }) => {
  return (
    data?.length > 0 && (
      <BoxListThreeContainer>
        {data?.map((feature, idx) => {
          return (
            <BoxListThreeWrapper key={feature.name} reverse={idx % 2 > 0}>
              <ImageWrapper radius="2.5rem" ratio="3 / 2">
                <Image
                  src={feature.thumbnail?.src}
                  alt={feature.thumbnail?.alt}
                />
              </ImageWrapper>

              <BoxListThreeInformation>
                <CommonTitleOne>
                  <DangerouslyHtml value={feature.name} />
                </CommonTitleOne>

                <CommonDescTwo>
                  <DangerouslyHtml value={feature.description} />
                </CommonDescTwo>

                {feature?.pdf?.src && (
                  <PrimaryButton
                    buttonEvent={() =>
                      downloadPdf(feature.name, feature.pdf?.src)
                    }
                  >
                    <SeeTheMenuText />
                  </PrimaryButton>
                )}
              </BoxListThreeInformation>
            </BoxListThreeWrapper>
          );
        })}
      </BoxListThreeContainer>
    )
  );
};

export default SplashBayServicesList;
