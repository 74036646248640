import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { ErrorManage } from "@screens/index";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetHomeTowerSection } from "@utils/api-requests/HomeRequests";
import { IsDesktop } from "@utils/media-query/mediaQuery";

import {
  HomeTowerContainer,
  HomeTowerDesc,
  HomeTowerDetails,
  HomeTowerDetailsTitle,
  HomeTowerImageInfo,
  HomeTowerInformation,
  HomeTowerWrapper,
} from "./HomeStyling";

const HomeTower = () => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const {
    data: homeTowerSection,
    isLoading,
    isError,
    error,
  } = useGetHomeTowerSection();
  const [active, setActive] = useState(0);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CommonContainer>
        <HomeTowerContainer>
          {homeTowerSection?.map((tower, index) => {
            return (
              <HomeTowerWrapper
                key={tower.field_tower_name}
                active={active === index}
                onClick={() => setActive(index)}
              >
                <HomeTowerImageInfo>
                  <ImageWrapper
                    custom={{ flex: 2 }}
                    ratio="auto"
                    radius={
                      active === index && isDesktop
                        ? "2.5rem 0 0 2.5rem"
                        : "1.5rem"
                    }
                  >
                    <Image
                      custom={{
                        transformOrigin: "bottom right",
                        transform:
                          !isDesktop && tower.field_tower_category === "78"
                            ? "scale(2)"
                            : "",
                      }}
                      src={tower.field_thumbnail[0]?.src}
                      alt={tower.field_thumbnail[0]?.alt}
                    />

                    <HomeTowerInformation active={active === index}>
                      <DangerouslyHtml value={tower.field_tower_name} />
                    </HomeTowerInformation>
                  </ImageWrapper>

                  {active === index && (
                    <HomeTowerDetails>
                      <div style={{ width: "100%" }}>
                        <IsDesktop>
                          <HomeTowerDetailsTitle>
                            <DangerouslyHtml value={tower.field_tower_name} />
                          </HomeTowerDetailsTitle>
                        </IsDesktop>

                        <HomeTowerDesc>
                          <DangerouslyHtml
                            className="ellipsis-5"
                            value={tower.field_tower_description}
                          />
                        </HomeTowerDesc>
                      </div>

                      <PrimaryButton
                        linkTo={`/stay?category=${tower.field_tower_name}`}
                      >
                        <ViewDetailsText />
                      </PrimaryButton>
                    </HomeTowerDetails>
                  )}
                </HomeTowerImageInfo>
              </HomeTowerWrapper>
            );
          })}
        </HomeTowerContainer>
      </CommonContainer>
    </ErrorManage>
  );
};

export default HomeTower;
