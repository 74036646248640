import React, { useState } from "react";

import { SliderFive } from "@components/sliders";
import { CommonMobileContainer } from "@components/layout/ContainerStyle";
import { useGetEntertainmentPerformances } from "@utils/api-requests/EntertainmentRequests";

const PopularPerformances = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const { data: entertainmentPerformances } = useGetEntertainmentPerformances();

  return (
    <CommonMobileContainer>
      <SliderFive
        data={entertainmentPerformances}
        nameKey="field_artist_name"
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        linkEach="/entertainment/performance"
        linkTo={`/entertainment/performance${entertainmentPerformances?.[slideIndex]?.url_alias}`}
        descKey="field_date"
        desc2Key="field_to_"
        performance
      />
    </CommonMobileContainer>
  );
};

export default PopularPerformances;
