import React from "react";

import { FrameOne } from "@components/frame";
import { useGetHomeMiceSection } from "@utils/api-requests/HomeRequests";

const HomeMice = () => {
  const { data: homeMiceSection } = useGetHomeMiceSection();

  return <FrameOne data={homeMiceSection} linkTo="/meet" />;
};

export default HomeMice;
