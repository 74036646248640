import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import he from "he";

import { DangerouslyHtml } from "@components/item";
import { TableOne } from "@components/table";
import { ListWithTabs } from "@components/list";
import { PrimaryButton } from "@components/buttons";
import {
  HowToReserveContainer,
  HowToReserveFloorGuideButton,
  HowToReserveSectionDesc,
  HowToReserveSectionTitle,
} from "@pages/splash-bay/SplashBayStyling";
import { useGetHowToReserveCategory } from "@utils/api-requests/SplashBayRequests";
import {
  BookSplashBayText,
  FloorGuideText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  offlineReservationPackageTbody,
  offlineReservationTbody,
  onlineReservationPackageTbody,
  onlineReservationPackageThead,
  onlineReservationTbody,
  onlineReservationThead,
} from "@utils/table-data/tableData";

import { color, image } from "src/theme";
import {
  checkIncludes,
  downloadPdf,
  getSearchValue,
  replaceAmpersand,
} from "@utils/modify-data/modifyData";

const HowToReserve = ({ data }) => {
  const search = useLocation().search;

  const { data: howToReserveCategory } = useGetHowToReserveCategory();

  const [filterType, setFilterType] = useState(
    search !== "" ? getSearchValue(search) : 0
  );

  const howToReserveCategories = useMemo(() => {
    const result = howToReserveCategory?.map((item) => {
      return {
        title: he.decode(item.name),
        category_id: item.tid,
      };
    });

    return result;
  }, [howToReserveCategory]);

  useEffect(() => {
    if (search !== "") {
      setFilterType(getSearchValue(search));
    } else {
      setFilterType(howToReserveCategory?.[0]?.tid);
    }
  }, [howToReserveCategory, search]);

  const currentData = useMemo(() => {
    return data?.filter((el) =>
      checkIncludes(el?.field_reservation_category?.tid, filterType)
    )[0];
  }, [data, filterType]);

  return (
    <ListWithTabs
      tabArr={howToReserveCategories}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      {data?.filter(
        (el) =>
          checkIncludes(el.field_reservation_category.tid, filterType)[0]
            ?.field_online_title
      )}

      <HowToReserveContainer>
        <section>
          <HowToReserveSectionTitle>
            <DangerouslyHtml value={currentData?.field_online_title} />
          </HowToReserveSectionTitle>

          <HowToReserveSectionDesc>
            <DangerouslyHtml value={currentData?.field_online_description} />
          </HowToReserveSectionDesc>
        </section>

        <PrimaryButton
          outerLink={replaceAmpersand(currentData?.field_book_splash_bay_link)}
        >
          <BookSplashBayText />
        </PrimaryButton>

        <section>
          <TableOne
            thead={onlineReservationThead}
            tbody={onlineReservationTbody}
          />

          <TableOne
            thead={onlineReservationPackageThead}
            tbody={onlineReservationPackageTbody}
          />
        </section>

        <section>
          <HowToReserveSectionTitle>
            <DangerouslyHtml value={currentData?.field_offline_title} />
          </HowToReserveSectionTitle>

          <HowToReserveSectionDesc>
            <DangerouslyHtml value={currentData?.field_offline_description} />
          </HowToReserveSectionDesc>
        </section>

        <PrimaryButton
          buttonColor={color.tertiary_color}
          noArrow
          buttonEvent={() =>
            downloadPdf(
              currentData.field_reservation_category,
              currentData.field_floor_guide?.src
            )
          }
        >
          <HowToReserveFloorGuideButton>
            <p>
              <FloorGuideText />
            </p>

            <img
              src={image.downloadIcon.default}
              alt="download Icon"
              width={25}
              height={25}
              loading="lazy"
            />
          </HowToReserveFloorGuideButton>
        </PrimaryButton>

        <section>
          <TableOne
            thead={onlineReservationThead}
            tbody={offlineReservationTbody}
          />

          <TableOne
            thead={onlineReservationPackageThead}
            tbody={offlineReservationPackageTbody}
          />
        </section>
      </HowToReserveContainer>
    </ListWithTabs>
  );
};

export default HowToReserve;
