import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { AttractionVenuesCurrentExhibitionContainer } from "@pages/entertainment/EntertainmentStyling";

const AttractionVenuesCurrentExhibition = ({ data }) => {
  return (
    <AttractionVenuesCurrentExhibitionContainer>
      {data.slice(0, 4).map((image, index) => {
        return (
          <ImageWrapper radius="2.5rem" ratio="3 / 4" key={index}>
            <Image src={image.image?.src} alt={image.image?.alt} />
          </ImageWrapper>
        );
      })}
    </AttractionVenuesCurrentExhibitionContainer>
  );
};

export default AttractionVenuesCurrentExhibition;
