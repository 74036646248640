import React from "react";
import { useMediaQuery } from "react-responsive";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListFiveContainer,
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
} from "@components/box-list/BoxListStyling";
import { GradientOverlay } from "@components/item/Overlays";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";

const MeetingKeyPoints = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <CommonContainer>
      <BoxListFiveContainer length={data?.length}>
        {data?.map((item, idx) => {
          return (
            <BoxListFiveGridArea key={item.title}>
              <ImageWrapper
                radius="1.5rem"
                ratio={
                  (idx === 0 && isDesktop) || (idx === 1 && isDesktop)
                    ? "3 / 2"
                    : "1 / 1"
                }
              >
                <Image src={item.image?.src} alt={item.image?.alt} />

                <BoxListFiveInformation>
                  <BoxListFiveDesc className="ellipsis-3">
                    <DangerouslyHtml value={item.title} />
                  </BoxListFiveDesc>
                </BoxListFiveInformation>

                <GradientOverlay opacity={0.15} />
              </ImageWrapper>
            </BoxListFiveGridArea>
          );
        })}
      </BoxListFiveContainer>
    </CommonContainer>
  );
};

export default MeetingKeyPoints;
