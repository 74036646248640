import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { CommonTitleThree } from "@assets/styles/CommonStyles";
import {
  GettingHereMapAddress,
  GettingHereMapInformation,
} from "@pages/about-us/AboutUsStyling";
import { useMediaQuery } from "react-responsive";
import { DangerouslyHtml } from "@components/item";

const GettingHereMap = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    data?.field_image && (
      <ImageWrapper
        ratio={isDesktop ? "5 / 2" : "16 / 9"}
        radius={isDesktop ? "2.5rem" : "1.5rem"}
      >
        <Image
          src={data?.field_image[0]?.src}
          alt={data?.field_image[0]?.alt}
        />

        <GradientOverlay opacity="0.6" />

        <GettingHereMapInformation>
          <GettingHereMapAddress>
            <DangerouslyHtml value={data?.field_main_address} />
          </GettingHereMapAddress>

          <CommonTitleThree>
            <DangerouslyHtml value={data?.field_sub_address} />
          </CommonTitleThree>
        </GettingHereMapInformation>
      </ImageWrapper>
    )
  );
};

export default GettingHereMap;
