import React from "react";
import { useParams } from "react-router-dom";

import {
  DetailPageContainer,
  DetailPageDescription,
  DetailPageHero,
} from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { SliderSix } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import { useGetEntertainmentEventDetail } from "@utils/api-requests/EntertainmentRequests";

const EntertainmentEventItemDetail = () => {
  const alias = useParams().eventAlias;

  const {
    data: entertainmentEventDetail,
    isLoading,
    isError,
    error,
  } = useGetEntertainmentEventDetail(alias);

  const sectionArray = [
    {
      id: 1,
      contents: <DetailPageDescription data={entertainmentEventDetail} />,
    },
    {
      id: 2,
      contents: (
        <SliderSix imageArr={entertainmentEventDetail?.field_multiple_images} />
      ),
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <DetailPageHero data={entertainmentEventDetail} />

      <CommonContainer>
        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>
    </ErrorManage>
  );
};

export default EntertainmentEventItemDetail;
