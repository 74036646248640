import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: InspireRouter.jsx
export const InspireRouterContainer = styled.main(() => ({
  position: "relative",
  overflow: "hidden",
}));

export const InspireRouterPageAll = styled.main(
  ({ noMargin, headerFooterHide, theme }) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: headerFooterHide ? "100vh" : "calc(100% + 12rem)",
    minHeight: headerFooterHide ? "100vh" : "60vh",
    maxWidth: "100vw",
    overflow: "hidden",
    background: headerFooterHide ? "#405B7D" : theme.color.white,

    [mq("desktop")]: {
      marginTop: noMargin || headerFooterHide ? 0 : "17.4rem",
    },
  })
);
