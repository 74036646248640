import React from "react";
import { CenteredModal } from "..";
import { Alert } from "@mui/material";

const ErrorModal = ({ openModal, setOpenModal, message }) => {
  return (
    <CenteredModal
      modalName="Error"
      openModal={openModal}
      setOpenModal={setOpenModal}
      uppercase
    >
      <Alert
        severity="error"
        sx={{ width: "100%", marginBottom: "16px" }}
      >{`Something went wrong please contact admin. ${Object.keys(message).length > 2 ? message : " "}`}</Alert>
    </CenteredModal>
  );
};

export default ErrorModal;
