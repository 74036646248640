import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { MuiBreadcrumbs, NotificationToggle } from "@components/item";
import { CommonVideo } from "@pages/shop";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsInspireResort } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import { aboutUsRouteList } from "../aboutUsRouteList";

import {
  BreadthOfferings,
  DevelopementIncludes,
  OwnerDeveloper,
  TravelRedefined,
} from ".";

const InspireResortDetail = ({ pageInfo }) => {
  const intl = useIntl();
  const [currentIndex, setCurrentIndex] = useState(0);

  const currentUrl = useLocation().pathname.split("/")[3];

  const {
    data: aboutUsInspireResort,
    isLoading,
    isError,
    error,
  } = useGetAboutUsInspireResort();

  const roomDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-about-us",
        }),
        href: "/about-us",
      },
      {
        id: 3,
        current: true,
        name: aboutUsInspireResort?.field_inspire_resort_name,
      },
    ];

    return roomDetails;
  }, [aboutUsInspireResort?.field_inspire_resort_name, intl]);

  const sectionArray = [
    {
      id: 1,
      disabled: !aboutUsInspireResort?.field_video,
      contents: <CommonVideo data={aboutUsInspireResort?.field_video} />,
    },
    {
      id: 2,
      contents: <TravelRedefined data={aboutUsInspireResort} />,
    },
    {
      id: 3,
      contents: (
        <BreadthOfferings data={aboutUsInspireResort?.field_multiple_images} />
      ),
    },
    {
      id: 4,
      contents: (
        <DevelopementIncludes
          data={aboutUsInspireResort?.field_development_includes}
        />
      ),
    },
    { id: 5, contents: <OwnerDeveloper data={aboutUsInspireResort} /> },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsInspireResort} />

      <DetailPageHero data={aboutUsInspireResort} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsInspireResort} />

          <MuiBreadcrumbs
            breadcrumbArr={roomDetailBC}
            pageTitle={aboutUsInspireResort?.field_inspire_resort_name}
            value={aboutUsInspireResort?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsInspireResort} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default InspireResortDetail;
