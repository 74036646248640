import React from "react";

import { image } from "src/theme";
import { DangerouslyHtml } from "@components/item";
import {
  FeaturesContainer,
  FeaturesExtra,
  FeaturesIn,
  FeaturesItem,
  FeaturesItemIn,
} from "@pages/stay/StayStyling";

import {
  OffersIncludesContainer,
  OffersTermsCondtions,
} from "../OffersStyling";

const OffersIncludes = ({ data }) => {
  return (
    <OffersIncludesContainer>
      {data?.field_offer_includes?.length > 0 &&
        data?.field_offer_includes[0]?.field_name !== null && (
          <FeaturesContainer>
            <FeaturesIn>
              {data?.field_offer_includes?.map((feature, idx) => {
                return (
                  <FeaturesItem key={`${feature.field_name}+${idx}`}>
                    <img
                      src={image.checkIcon.default}
                      alt="check-icon"
                      width={22}
                      height={22}
                      loading="lazy"
                    />

                    <FeaturesItemIn>
                      <span>
                        <DangerouslyHtml value={feature.field_name} />
                      </span>

                      <FeaturesExtra>
                        <DangerouslyHtml value={feature.field_detail} />
                      </FeaturesExtra>
                    </FeaturesItemIn>
                  </FeaturesItem>
                );
              })}
            </FeaturesIn>
          </FeaturesContainer>
        )}

      <OffersTermsCondtions>
        <DangerouslyHtml value={data?.field_terms_condition} />
      </OffersTermsCondtions>
    </OffersIncludesContainer>
  );
};

export default OffersIncludes;
