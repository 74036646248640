import { useCallback, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import CryptoJS from "crypto-js";
import { parsePhoneNumber } from "libphonenumber-js";

import { convertDate } from "@utils/modify-data/modifyData";

import {
  activateProfile,
  drupalWebFormUrl,
  forgotPass,
  getAPIToken,
  getUserProfile,
  loginUser,
  resetPass,
  sendVerificationCode,
  userRegistration,
  drupalWebFormGetUser,
  drupalWebFormUpdateUser,
} from "./apiUrls";
import {
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_USER,
  QUERY_KEY_USER_LOGGED_IN,
} from "./static-data";

// AgilisysSignUpForm.jsx
export const userRegistrationRequest = async (requestData) => {
  try {
    let token = await tokenAPI();
    token = JSON.parse(token?.data)?.access_token;

    const response = await axios.post(
      userRegistration,
      { ...requestData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const useLogoutUser = ({ queryClient, useMemo, navigate }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  navigate(`${baseUrl}/momentum/sign-in`);

  queryClient.setQueryData(QUERY_KEY_USER_LOGGED_IN, false);

  queryClient.removeQueries("userProfile");

  localStorage.removeItem(LOCAL_STORAGE_USER_ID);

  localStorage.removeItem(LOCAL_STORAGE_USER);
};

export const userProfileActiveVerificationCodeSendRequest = async (
  requestData,
) => {
  try {
    let token = await tokenAPI();
    token = JSON.parse(token?.data)?.access_token;

    let data = {
      corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
      emailID: requestData.email,
      siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
      systemCode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
      verificationType: requestData.type,
    };

    const response = await axios.post(
      sendVerificationCode,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const userProfileActiveRequest = async (requestData) => {
  try {
    let token = await tokenAPI();
    token = JSON.parse(token?.data)?.access_token;
    let data = {
      corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
      emailID: requestData.email,
      siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
      systemCode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
      VerificationCode: requestData.code,
    };

    const response = await axios.post(
      activateProfile,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const userSignUpDrupal = async (data, fromAgylysis) => {
  try {
    const encryptedPassword = CryptoJS.AES.encrypt(
      data.password,
      process.env.REACT_APP_HASH_SECRET_SALT,
    ).toString();

    let webformObj = {
      webform_id: "register_user",
      systemcode: "PMS",
      corpcode: "Inspire",
      sitecode: "Inspire",
    };

    let obj = {};

    if (fromAgylysis) {
      // unique profile id is coming successfully from Agylysis & no data from drupal.
      const parsedPhoneNumber = parsePhoneNumber(
        `+${data?.Phones[0].PhoneCode} ${data?.Phones[0].PhoneNumber}`,
      );

      obj = {
        firstname: data.FirstName,
        lastname: data.LastName,
        emailaddresstype: data?.Emails[0]?.EmailAddressType,
        emailaddress: data?.Emails[0]?.EmailAddress,
        phonetype: data?.Phones[0]?.PhoneType,
        phonenumber: parsedPhoneNumber.nationalNumber,
        phonecountrycode: parsedPhoneNumber.country,
        phonecode: parsedPhoneNumber.countryCallingCode,
        password: encryptedPassword,
        dateofbirth: data?.DateofBirth,
        addresstype: data?.Addresses[0]?.AddressType,
        addressline1: data?.Addresses[0]?.AddressLine1,
        addressline2: data?.Addresses[0]?.AddressLine2,
        countrycode: data?.Addresses[0]?.CountryCode,
        statecode: data?.Addresses[0]?.StateCode,
        citycode: data?.Addresses[0]?.CityCode,
        postalcode: data?.Addresses[0]?.ZipCode,
      };
    } else {
      const parsedPhoneNumber = parsePhoneNumber(data.phoneNumber);

      obj = {
        firstname: data.firstName,
        lastname: data.lastName,
        emailaddresstype: data.emailAddressType,
        emailaddress: data.emailAddress,
        phonetype: data.phoneType,
        phonenumber: parsedPhoneNumber.nationalNumber,
        phonecountrycode: parsedPhoneNumber.country,
        phonecode: parsedPhoneNumber.countryCallingCode,
        password: encryptedPassword,
        dateofbirth: convertDate(data.dateOfBirth),
        mailoptin: data.mailoptin,
        personal_use: data.personal_use,
        addresstype: data.addressType || undefined,
        addressline1: data.addressLine1 || undefined,
        addressline2: data.addressLine2 || undefined,
        countrycode: data.countryCode || undefined,
        statecode: data.stateCode || undefined,
        citycode: data.cityCode || undefined,
        postalcode: data.zipCode || undefined,
      };
    }

    webformObj = { ...webformObj, ...obj };

    const res = await axios.post(drupalWebFormUrl, webformObj, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.error("Error saving Webform data:", error);
  }
};

export const userForgotPass = async (requestData) => {
  try {
    let token = await tokenAPI();
    token = JSON.parse(token?.data)?.access_token;

    let data = {
      corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
      siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
      systemCode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
      emailID: requestData.email,
      VerificationCode: requestData.code,
      newPassword: requestData.newPassword,
    };

    const response = await axios.post(
      forgotPass,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const useUserLoginRequest = async (requestData) => {
  try {
    // const decryptPassword = CryptoJS.AES.decrypt(encryptedPassword, process.env.REACT_APP_HASH_SECRET_SALT);
    // const byte=decryptPassword?.toString(CryptoJS.enc.Utf8);

    let token = await tokenAPI();
    token = JSON.parse(token?.data)?.access_token;

    let data = {
      corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
      uniqueUserId: requestData.email,
      password: requestData.password,
      siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
      systemCode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
      rememberMe: requestData.rememberMe,
    };

    const response = await axios.post(
      loginUser,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    //Save Update on Drupal side- LastLogin and ProfileID update : GETDATA
    // &pw=${requestData.password}
    const drupalResponse = await axios.get(
      `${drupalWebFormGetUser}?email=${requestData.email}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (
      drupalResponse?.data?.length <= 0 &&
      response?.data?.UniqueProfileId === null
    ) {
      // if there is no user data in drupal & agylysis gives null for unique profile id
      return "null and no data in drupal";
    }

    if (drupalResponse?.data?.length <= 0) {
      // if there is no user data in drupal
      return response;
    }

    const drupalUserUuid = drupalResponse.data[0].uuid;

    //Save Update on Drupal side- LastLogin and ProfileID update :UPDATE DATA
    let patchData = {
      lastlogindatetime: new Date().toISOString(),
      uniqueprofileid: response.data.UniqueProfileId,
    };

    await axios.patch(
      `${drupalWebFormUpdateUser}/${drupalUserUuid}`,
      patchData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const patchMarketingSwitch = async (patchData) => {
  try {
    const drupalResponse = await axios.get(
      `${drupalWebFormGetUser}?email=${patchData.email}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    // avoiding unwanted edits to email
    delete patchData.email;

    const drupalUserUuid = drupalResponse.data[0].uuid;

    const response = await axios.patch(
      `${drupalWebFormUpdateUser}/${drupalUserUuid}`,
      { ...patchData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const checkEmailExistInDrupal = async (email) => {
  const drupalResponse = await axios.get(
    `${drupalWebFormGetUser}?email=${email}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  if (drupalResponse?.data?.length <= 0) {
    return false;
  }

  return true;
};

export const useUserGetProfileRequest = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  const userInfo = getUserToken();

  const getUserProfileReqeust = useCallback(async () => {
    try {
      const storedUserData = localStorage.getItem(LOCAL_STORAGE_USER);
      const getEmail = JSON.parse(storedUserData).email;

      // &pw=${getPassword}
      // const getPassword = JSON.parse(storedUserData).password;

      const responseDrupal = await axios.get(
        `${drupalWebFormGetUser}?email=${getEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      let token = userInfo?.Token;
      let profileId = userInfo?.UniqueProfileId;

      if (
        userInfo.UniqueProfileId !== null &&
        userInfo.UniqueProfileId !== undefined
      ) {
        const response = await axios.get(
          `${getUserProfile}/${userInfo?.UniqueProfileId}?Fields=3&corpCode=${process.env.REACT_APP_AGILISYS_API_CORPCODE}&siteCode=${process.env.REACT_APP_AGILISYS_API_SITECODE}&systemCode=${process.env.REACT_APP_AGILISYS_API_SYSTEMCODE}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        let responseData = response?.data?.ProfileData;

        response.data.ProfileData.mailoptin =
          responseDrupal?.data[0]?.mailoptin;

        response.data.ProfileData.personal_use =
          responseDrupal?.data[0]?.personal_use;

        responseData.FullName = `${responseData?.FirstName} ${responseData?.LastName}`;
        responseData.UniqueProfileId = profileId;

        const isExist = await checkEmailExistInDrupal(
          response?.data?.ProfileData?.Emails[0]?.EmailAddress,
        );

        if (!isExist) {
          await userSignUpDrupal(response?.data?.ProfileData, true);
        }

        return response.data;
      } else {
        // if the agylysis send null for the unique Profile id
        const storedUserData = localStorage.getItem(LOCAL_STORAGE_USER);
        const getEmail = JSON.parse(storedUserData).email;

        // &pw=${getPassword}
        // const getPassword = JSON.parse(storedUserData).password;

        const response = await axios.get(
          `${drupalWebFormGetUser}?email=${getEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        const userData = response.data[0];

        const formattedData = {
          ProfileData: {
            FirstName: userData.firstname,
            LastName: userData.lastname,
            FullName: `${userData.firstname} ${userData.lastname}`,
            Emails: [
              {
                EmailAddressType: "Primary",
                EmailAddress: userData.emailAddress,
              },
            ],
            Phones: [
              {
                PhoneType: "Primary",
                PhoneNumber: parseInt(userData.phoneNumber),
                PhoneCode: userData.phoneCode,
              },
            ],
            DateofBirth: userData.dateofBirth,
            AnniversaryDate: "",
            Addresses: [
              {
                AddressType: 0,
                AddressLine1: userData.addressLine1,
                AddressLine2: userData.addressLine2,
                CountryCode: userData.countryCode,
                StateCode: userData.stateCode,
                CityCode: userData.cityCode,
                ZipCode: userData.postalCode,
              },
            ],
            uniqueProfileId: userData.uniqueProfileId,
            mailoptin: userData.mailoptin,
            personal_use: userData.personal_use,
          },
          BucketBalances: [
            {
              BucketName: "Loyalty Points",
              BucketBalanceInPoints: 100.0,
              BucketBalanceInCash: 0.0,
            },
            {
              BucketName: "GiftCard",
              BucketBalanceInPoints: 0.0,
              BucketBalanceInCash: 0.0,
            },
            {
              BucketName: "Voucher",
              BucketBalanceInPoints: 0.0,
              BucketBalanceInCash: 0.0,
            },
          ],
          Warnings: null,
          CorpCode: null,
          SiteCode: null,
          Message: null,
        };

        return formattedData;
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        queryClient.setQueryData(QUERY_KEY_USER_LOGGED_IN, false);

        queryClient.removeQueries("userProfile");

        localStorage.removeItem(LOCAL_STORAGE_USER_ID);

        localStorage.removeItem(LOCAL_STORAGE_USER);

        navigate(`${baseUrl}/momentum/sign-in`);

        throw error;
      }
    }
  }, [baseUrl, navigate, queryClient, userInfo]);

  return useQuery(["userProfile"], getUserProfileReqeust, {
    notifyOnChangeProps: "tracked",
  });
};

export const userResetPass = async (requestData) => {
  try {
    let userInfo = getUserToken();
    let token = userInfo?.Token;

    let data = {
      corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
      siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
      systemCode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
      emailID: requestData.email,
      VerificationCode: requestData.code,
      newPassword: requestData.newPassword,
      oldPassword: requestData.oldPassword,
    };

    const response = await axios.post(
      resetPass,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

const tokenAPI = async () => {
  const response = await axios.post(
    getAPIToken,
    process.env.REACT_APP_AGILISYS_API_TOKEN_CONN_STR,
    {
      responseType: "text",
      headers: {
        "Content-Type": "text/plain",
      },
    },
  );

  return response;
};

const getUserToken = () => {
  let token = localStorage.getItem(LOCAL_STORAGE_USER_ID);

  var bytes = CryptoJS.AES.decrypt(
    token,
    process.env.REACT_APP_AGILISYS_API_X_GATEWAY_KEY,
  );

  var decryptedData = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};
