import React, { memo, useMemo } from "react";
import { useParams } from "react-router-dom";
import {DangerouslyHtml, MuiBreadcrumbs} from "@components/item";
import { ErrorManage } from "@screens/index";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { CustomMetatag } from "@utils/metatags";
import { useGetBasicPage } from "@utils/api-requests/PageBasicRequests";
import {PrivacyPolicyContentsContainer} from "@pages/about-us/AboutUsStyling";

const BasicPage = ({ pageInfo }) => {
  const alias = useParams().pageAlias;

  const { data: getBasicPageInformation, isLoading,
    isError,
    error, } = useGetBasicPage(alias);
  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={pageData} />

      <CommonContainer>
        <>
          <MuiBreadcrumbs
            pageTitle={getBasicPageInformation?.title}
            value={getBasicPageInformation?.body}
            featuredImage={getBasicPageInformation?.field_featured_image?.[0]}
            url="/page"
          />
        </>

        {getBasicPageInformation?.field_content_bottom && (
          <div style={{ width: "100%" }}>
            <PrivacyPolicyContentsContainer disableTopBorder={true}>
              <div>
                <DangerouslyHtml value={getBasicPageInformation?.field_content_bottom} />
              </div>
            </PrivacyPolicyContentsContainer>
          </div>
        )}

      </CommonContainer>

    </ErrorManage>

  );
};

export default memo(BasicPage);
