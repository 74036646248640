import React from "react";

import { PageTabButtonsContainer, PageTabButtonsItem } from "./ItemStyling";
import { DangerouslyHtml } from ".";

const PageTabButtons = ({ tabArr, currentTab, setCurrentTab }) => {
  return (
    <PageTabButtonsContainer>
      {tabArr.map((tab, idx) => {
        return (
          <PageTabButtonsItem
            active={currentTab === idx}
            key={tab.category_id}
            onClick={() => setCurrentTab(idx)}
          >
            <DangerouslyHtml value={tab.title} />
          </PageTabButtonsItem>
        );
      })}
    </PageTabButtonsContainer>
  );
};

export default PageTabButtons;
