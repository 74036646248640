import React, { memo, useMemo, useRef, useState } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { OfferRecommendation } from "@components/item";
import { CustomMetatag } from "@utils/metatags";
import {
  useGetShopFeatures,
  useGetShopOfferRecommendation,
} from "@utils/api-requests/ShopRequests";

import { SelectedShops, ShopFeatures, CommonVideo, StoresCategory } from ".";

const Shop = ({ pageInfo }) => {
  const storeRef = useRef(null);

  const { data: shopOfferRecommendation } = useGetShopOfferRecommendation();

  const [disableSelected, setDisableSelected] = useState(false);

  const { data: shopFeatures } = useGetShopFeatures();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <CommonContainer>
          <ShopFeatures data={shopFeatures?.field_shop_features} />
        </CommonContainer>
      ),
    },
    {
      id: 2,
      disabled: !shopFeatures?.field_shop_v,
      contents: (
        <CommonContainer>
          <CommonVideo data={shopFeatures?.field_shop_v} />
        </CommonContainer>
      ),
    },
    {
      id: 3,
      disabled: disableSelected,
      contents: <SelectedShops setDisableSelected={setDisableSelected} />,
    },
    {
      id: 4,
      contents: (
        <CommonContainer>
          <StoresCategory ref={storeRef} />
        </CommonContainer>
      ),
    },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={shopOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </div>
  );
};

export default memo(Shop);
