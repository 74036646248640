import React from "react";

import { useGetAboutUsInspireResort } from "@utils/api-requests/AboutUsRequests";
import { FrameTwo } from "@components/frame";
import { SecondaryButton } from "@components/buttons";
import { ErrorManage } from "@screens/index";
import {
  AboutUsText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const AboutUsInspireResort = () => {
  const {
    data: aboutUsInspireResort,
    isError,
    error,
  } = useGetAboutUsInspireResort();

  return (
    <ErrorManage isError={isError} error={error}>
      <FrameTwo
        direction="right"
        subTitle={<AboutUsText />}
        title={aboutUsInspireResort?.field_inspire_resort_name}
        desc={aboutUsInspireResort?.field_short_description}
        image={aboutUsInspireResort?.field_thumbnail?.[0]}
        button={
          <SecondaryButton
            buttonColor="white"
            linkTo={`/about-us/inspire-resort`}
          >
            <ViewDetailsText />
          </SecondaryButton>
        }
      />
    </ErrorManage>
  );
};

export default AboutUsInspireResort;
