import React, { useState } from "react";
import { useIntl } from "react-intl";

import { CenteredModal } from "@components/modal";
import { ForgotPasswordText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import ForgotPasswordForm from "./ForgotPasswordForm";
import VerificationCodeForm from "./VerificationCodeForm";
import PasswordDonePage from "./PasswordDonePage";

const ForgotPasswordModal = ({ openModal, setOpenModal }) => {
  const intl = useIntl();

  const [warning, setWarning] = useState({
    previous: false,
  });

  const [passwordStep, setPasswordStep] = useState(0);
  const [email, setEmail] = useState("");

  const forgetPasswordStep = [
    {
      name: <ForgotPasswordText />,
      contents: (
        <ForgotPasswordForm
          setPasswordStep={setPasswordStep}
          setEmail={setEmail}
          setWarning={setWarning}
        />
      ),
    },
    {
      name: "Verification",
      contents: (
        <VerificationCodeForm
          setPasswordStep={setPasswordStep}
          email={email}
          warning={warning}
        />
      ),
    },
    {
      name: intl.formatMessage({
        id: "lang-password-changed-success",
      }),
      contents: (
        <PasswordDonePage
          setOpenModal={setOpenModal}
          setPasswordStep={setPasswordStep}
        />
      ),
    },
  ];

  return (
    <CenteredModal
      modalName={forgetPasswordStep[passwordStep].name}
      openModal={openModal}
      setOpenModal={setOpenModal}
      uppercase
      onCloseEvent={setPasswordStep}
    >
      {forgetPasswordStep[passwordStep].contents}
    </CenteredModal>
  );
};

export default ForgotPasswordModal;
