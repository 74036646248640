import React, { useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { CommonDescFive } from "@assets/styles/CommonStyles";
import {
  ChangePasswordModal,
  ChangeMarketingModal,
} from "@components/modal/modal-list";
import { IsDesktop } from "@utils/media-query/mediaQuery";
import { EditText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { userProfileActiveVerificationCodeSendRequest } from "@utils/api-requests/userRequests";
import { SubmitLoadingText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  MyProfileAccountContainer,
  MyProfileAccountEditPassword,
  MyProfileAccountImage,
  MyProfileAccountInformation,
  MyProfileAccountInformationWrapper,
  MyProfileAccountValue,
} from "./MyProfileStyling";
import {
  NoText,
  YesText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

const MyProfileAccount = ({
  data,
  userData,
  complete,
  setComplete,
  changeMarketingModalOpen,
  setChangeMarketingModalOpen,
}) => {
  const intl = useIntl();

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  // const [changeMarketingModalOpen, setChangeMarketingModalOpen] =
  //   useState(false);

  const [warning, setWarning] = useState({
    previous: false,
  });

  const currentAddress = userData?.Addresses[0]
    ? `${userData?.Addresses[0]?.AddressLine1} ${userData?.Addresses[0]?.AddressLine2}, ${userData?.Addresses[0]?.CityCode}, ${userData?.Addresses[0]?.StateCode}, ${userData?.Addresses[0]?.CountryCode}, ${userData?.Addresses[0]?.ZipCode}`
    : "-";

  let marketingNumberValue;
  let personalNumberValue;

  if (!isNaN(parseFloat(userData?.mailoptin))) {
    marketingNumberValue =
      userData?.mailoptin === "1"
        ? "Yes"
        : userData.mailoptin === "0"
          ? "No"
          : "-";

    personalNumberValue =
      userData?.personal_use === "1"
        ? "Yes"
        : userData.personal_use === "0"
          ? "No"
          : "-";
  } else {
    marketingNumberValue = userData.mailoptin || "-";
    personalNumberValue = userData.personal_use || "-";
  }

  const preferenceArr = [
    {
      id: 1,
      label: intl.formatMessage({
        id: "lang-name",
      }),
      value: `${userData?.FirstName} ${userData?.LastName}`,
    },
    {
      id: 2,
      label: intl.formatMessage({
        id: "lang-date-of-birth-label",
      }),
      value: `${userData?.DateofBirth}`,
    },
    {
      id: 3,
      label: intl.formatMessage({
        id: "lang-email-label",
      }),
      value: `${userData?.Emails[0].EmailAddress}`,
    },
    {
      id: 4,
      label: intl.formatMessage({
        id: "lang-password-label",
      }),
      dialogName: "openChangePass",
      value: "*********",
      edit: true,
    },
    {
      id: 5,
      label: `${intl.formatMessage({
        id: "lang-phone",
      })} (${userData?.Phones[0].PhoneType})`,
      value: `+${userData?.Phones[0].PhoneCode} ${userData?.Phones[0].PhoneNumber}`,
    },
    {
      id: 6,
      label: intl.formatMessage({
        id: "lang-address",
      }),
      value: `${currentAddress}`,
    },
    {
      id: 7,
      label: intl.formatMessage({
        id: "lang-mail-opt-label",
      }),
      value:
        marketingNumberValue === "Yes" ? (
          <YesText />
        ) : marketingNumberValue === "No" ? (
          <NoText />
        ) : (
          "-"
        ),
      dialogName: "openChangeMarketing",
      edit: true,
    },
    {
      id: 7,
      label: intl.formatMessage({
        id: "lang-mail-use-label",
      }),
      value:
        personalNumberValue === "Yes" ? (
          <YesText />
        ) : personalNumberValue === "No" ? (
          <NoText />
        ) : (
          "-"
        ),
      dialogName: "openChangeMarketing",
      edit: true,
    },
  ];

  const { mutate: verificationMutate, isLoading: verificationIsLoading } =
    useMutation(userProfileActiveVerificationCodeSendRequest, {
      onSuccess: (data) => {
        if (data.status === 201) {
          setChangePasswordModalOpen(true);

          if (data?.data?.Warnings?.[0]?.Code === "PW0001") {
            // Previous Verification Code is not expired.
            setWarning({
              previous: true,
            });
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const openChangePass = useCallback(() => {
    verificationMutate({
      email: userData?.Emails[0].EmailAddress,
      type: 3,
    });
  }, [userData, verificationMutate]);

  const openChangeMarketing = useCallback(() => {
    setChangeMarketingModalOpen(true);
  }, [userData]);

  // Map of dialog names to actual functions
  const functionMap = {
    openChangePass,
    openChangeMarketing,
    // Add other functions as needed
  };

  const handleDialogName = (dialogName) => {
    if (typeof functionMap[dialogName] === "function") {
      functionMap[dialogName]();
    } else {
      console.error(`${dialogName} is not a valid function`);
    }
  };

  useEffect(() => {}, [complete]);

  return (
    <div>
      <ChangeMarketingModal
        email={userData?.Emails[0].EmailAddress}
        openModal={changeMarketingModalOpen}
        setOpenModal={setChangeMarketingModalOpen}
        previous={warning.previous}
        userData={userData}
        complete={complete}
        setComplete={setComplete}
      />

      <ChangePasswordModal
        email={userData?.Emails[0].EmailAddress}
        openModal={changePasswordModalOpen}
        setOpenModal={setChangePasswordModalOpen}
        previous={warning.previous}
      />

      <MyProfileAccountContainer>
        <MyProfileAccountInformation>
          <MyProfileAccountInformationWrapper>
            {preferenceArr.map((preference) => {
              return (
                <div key={preference.label}>
                  <CommonDescFive>{preference.label}</CommonDescFive>

                  <MyProfileAccountValue>
                    <p>{preference.value}</p>

                    {preference.edit && (
                      <MyProfileAccountEditPassword
                        onClick={() => handleDialogName(preference.dialogName)}
                        disabled={verificationIsLoading}
                      >
                        {verificationIsLoading ? (
                          <SubmitLoadingText />
                        ) : (
                          <EditText />
                        )}
                      </MyProfileAccountEditPassword>
                    )}
                  </MyProfileAccountValue>
                </div>
              );
            })}
          </MyProfileAccountInformationWrapper>
        </MyProfileAccountInformation>

        <IsDesktop>
          <MyProfileAccountImage
            src={`${process.env.REACT_APP_SERVER_URL}${data?.field_image[0].src}`}
            alt={data?.field_image[0].alt}
          />
        </IsDesktop>
      </MyProfileAccountContainer>

      {/*
      As user don't have access to delete account
      <SecondaryButton customstyle={{ background: color.light_grey }} noArrow>
        <DeleteAccountText />
      </SecondaryButton> */}
    </div>
  );
};

export default MyProfileAccount;
