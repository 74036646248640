import React from "react";

import SliderThree from "@components/sliders/SliderThree";
import { ErrorManage } from "@screens/index";
import { useGetOfferRecommendation } from "@utils/api-requests/GlobalRequests";

import { OfferRecommendationContainer } from "./ItemStyling";

const OfferRecommendation = ({ data }) => {
  const {
    data: offerRecommendation,
    isError,
    error,
  } = useGetOfferRecommendation(
    JSON.stringify(data?.field_recommendation?.join())
      ?.replaceAll('"', " ")
      .replaceAll(" ", "")
  );

  return data?.field_recommendation?.length > 0 ? (
    <ErrorManage isError={isError} error={error}>
      <OfferRecommendationContainer>
        <SliderThree data={offerRecommendation} recommendation/>
      </OfferRecommendationContainer>
    </ErrorManage>
  ) : null;
};

export default OfferRecommendation;
