import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

import {
  isChineseLanguage,
  isJapanese,
  isKorean,
} from "@utils/lanugauge-settings/LanguageOptions";
import { mq } from "@utils/media-query/mediaQuery";

// NOTE: zIndex
export const headerMenuModalZIndex = 1200;
export const loadingScreenZIndex = 1900;
export const maintenanceScreenZIndex = 1800;
export const modalOverHeaderZIndex = 1800;
export const headerLanguageModalZIndex = 1300;
export const calendarModalZIndex = 1300;
export const headerContainerZIndex = 1300;
export const footerLogoZIndex = 600;
export const backdropZIndex = 799;
export const sliderButtonZIndex = 600;
export const overlayZIndex = 1;
export const infoZIndex = 2;
export const shareButtonZIndex = 200;

export const CommonModalButtonWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "3rem",
  marginTop: "2rem",
}));

export const CommonModalButton = styled.button(({ theme }) => ({
  padding: "1rem 4rem",
  fontSize: "1.4rem",
  fontWeight: 600,
  border: `2px solid ${theme.color.primary_color}`,
  color: theme.color.primary_color,
  textTransform: "uppercase",
}));

export const CommonTitleOne = styled.h2(({ theme, lowercase }) => ({
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.extraBold,
  textTransform: lowercase ? "initial" : "uppercase",
  fontSize: isJapanese ? "2.5rem" : "2.4rem",

  [mq("tablet")]: {
    fontSize: "3.6rem",
  },

  [mq("desktop")]: {
    fontSize: "4.8rem",
  },
}));

export const CommonTitleTwo = styled.h3(({ theme }) => ({
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.extraBold,
  fontSize: isJapanese ? "2.1rem" : "2.4rem",
  paddingBottom: "0.25rem",

  [mq("desktop")]: {
    fontSize: "3.6rem",
  },
}));

export const CommonTitleThree = styled.div(({ theme, Black }) => ({
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: Black ? theme.fontWeight.Black : theme.fontWeight.bold,
  textTransform: "uppercase",
  fontSize: isJapanese ? "1.8rem" : "2rem",

  [mq("desktop")]: {
    fontSize: "3.2rem",
  },
}));

export const CommonTitleFour = styled.div(({ theme, uppercase, Black }) => ({
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: Black ? theme.fontWeight.Black : theme.fontWeight.bold,
  fontSize: isJapanese ? "1.6rem" : "2rem",
  textTransform: uppercase ? "uppercase" : "initial",
  overflowWrap: isJapanese || isChineseLanguage ? "anywhere" : "normal",

  [mq("desktop")]: {
    fontSize: "2.4rem",
  },
}));

export const CommonTitleFive = styled.div(({ theme, Black }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: Black ? theme.fontWeight.Black : theme.fontWeight.bold,
  fontSize: "1.6rem",

  [mq("desktop")]: {
    fontSize: isJapanese ? "1.8rem" : "2rem",
  },
}));

export const CommonTitleSix = styled.div(({ theme }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.6rem",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

export const CommonTitleSeven = styled.div(({ theme }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.6rem",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

export const CommonDescOne = styled.div(({ theme, custom }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.secondaryFont,
  fontWeight: theme.fontWeight.regular,
  fontSize: "1.6rem",
  overflowWrap: isJapanese || isChineseLanguage ? "anywhere" : "normal",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },

  ...custom,
}));

export const CommonDescTwo = styled.div(({ theme }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.secondaryFont,
  fontWeight: theme.fontWeight.regular,
  fontSize: "1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  whiteSpace: "break-spaces",
  overflowWrap: isJapanese || isChineseLanguage ? "anywhere" : "normal",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

export const CommonDescThree = styled.div(({ theme }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontWeight: theme.fontWeight.extraBold,
  fontSize: "1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  whiteSpace: "break-spaces",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));

export const CommonDescFour = styled.div(({ theme, uppercase }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontSize: "1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  fontFamily: theme.fontFamily.secondaryFont,
  textTransform: uppercase ? "uppercase" : "initial",
  fontWeight: theme.fontWeight.regular,
}));

export const CommonDescFive = styled.div(({ theme, uppercase }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontSize: "1.4rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  fontFamily: theme.fontFamily.secondaryFont,
  textTransform: uppercase ? "uppercase" : "initial",
}));

export const CommonErrorMessage = styled.div(({ theme }) => ({
  display: "block",
  color: theme.color.red_color,
  fontSize: "1.2rem",
  fontWeight: theme.fontWeight.regular,
  width: "fit-content",
}));

export const CommonViewDetailsToggle = styled.button(({ theme, up }) => ({
  display: "flex",
  color: theme.color.point_primary_color,
  fontSize: "1.8rem",
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.medium,
  alignItems: "center",
  gap: "0 1.6rem",

  "&>img": {
    transform: up ? "rotate(0)" : "rotate(180deg)",
  },

  [mq("desktop")]: {
    fontSize: "2.4rem",
  },
}));

export const CommonUlList = styled.ul(() => ({
  paddingLeft: "3rem",

  "& li": {
    listStyle: "initial",
    marginBottom: "1rem",
  },
}));

export const QRWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  flexFlow: "column",
  gap: "16px",

  svg: {
    border: "4px solid #FFF",
  },

  "&.full-screen": {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 9999,
    backdropFilter: "blur(2px)",

    svg: {
      border: "16px solid #FFF",
      borderRadius: "16px",
    },

    img: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "50px",
      width: "50px",
      background: "#FFF",
      padding: "8px",
      marginTop: "-20px",
      opacity: 1,
      borderRadius: "8px",
    },
  },
}));

export const QRCodeData = styled.div(() => ({
  fontSize: "16px",
  letterSpacing: "0.15em",
}));

const elipsisStyle = css`
  // ellipsis when the contents text is over than nth line.
  .ellipsis-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .ellipsis-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .ellipsis-3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .ellipsis-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .ellipsis-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .ellipsis-6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }

  .ellipsis-8 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }
`;

function CustomStyle() {
  return <Global styles={elipsisStyle} />;
}

export default CustomStyle;
