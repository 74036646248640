import React from "react";
import { color } from "src/theme";

const MapPingImage = ({ active, width = "68", height = "86" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 68 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1452_15648)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.7948 38.3002C62.5789 35.6705 63 32.8845 63 30C63 13.9837 50.0163 1 34 1C17.9837 1 5 13.9837 5 30C5 38.3106 8.49573 45.8046 14.097 51.092L28.7392 74.2012C31.0631 77.8689 36.3927 77.9303 38.8005 74.3171L55.0166 49.9826C57.3123 47.5689 59.1964 44.7603 60.5567 41.6691L60.7845 41.3272C61.4253 40.3656 61.7453 39.3253 61.7948 38.3002Z"
          fill={active ? color.secondary_color : color.white}
        />
        <path
          d="M61.7948 38.3002L61.3156 38.1573L61.2983 38.2154L61.2954 38.2761L61.7948 38.3002ZM14.097 51.092L14.5193 50.8244L14.4858 50.7715L14.4402 50.7284L14.097 51.092ZM28.7392 74.2012L28.3169 74.4688L28.7392 74.2012ZM38.8005 74.3171L39.2165 74.5944L38.8005 74.3171ZM55.0166 49.9826L54.6543 49.638L54.6245 49.6693L54.6006 49.7053L55.0166 49.9826ZM60.5567 41.6691L60.1406 41.3918L60.1165 41.4279L60.099 41.4677L60.5567 41.6691ZM60.7845 41.3272L61.2005 41.6045L60.7845 41.3272ZM62.5 30C62.5 32.8356 62.0861 35.5735 61.3156 38.1573L62.2739 38.443C63.0717 35.7675 63.5 32.9333 63.5 30H62.5ZM34 1.5C49.7401 1.5 62.5 14.2599 62.5 30H63.5C63.5 13.7076 50.2924 0.5 34 0.5V1.5ZM5.5 30C5.5 14.2599 18.2599 1.5 34 1.5V0.5C17.7076 0.5 4.5 13.7076 4.5 30H5.5ZM14.4402 50.7284C8.93484 45.5316 5.5 38.1673 5.5 30H4.5C4.5 38.4538 8.05661 46.0777 13.7537 51.4556L14.4402 50.7284ZM29.1616 73.9336L14.5193 50.8244L13.6746 51.3596L28.3169 74.4688L29.1616 73.9336ZM38.3844 74.0398C36.1773 77.3519 31.2918 77.2956 29.1616 73.9336L28.3169 74.4688C30.8344 78.4421 36.6081 78.5086 39.2165 74.5944L38.3844 74.0398ZM54.6006 49.7053L38.3844 74.0398L39.2165 74.5944L55.4327 50.2599L54.6006 49.7053ZM60.099 41.4677C58.7623 44.5053 56.9107 47.2657 54.6543 49.638L55.3789 50.3272C57.7138 47.8722 59.6305 45.0153 61.0143 41.8705L60.099 41.4677ZM60.3684 41.05L60.1406 41.3918L60.9727 41.9464L61.2005 41.6045L60.3684 41.05ZM61.2954 38.2761C61.2502 39.2121 60.9583 40.1647 60.3684 41.05L61.2005 41.6045C61.8923 40.5665 62.2404 39.4384 62.2942 38.3242L61.2954 38.2761Z"
          fill={active ? color.secondary_color : color.white}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1452_15648"
          x="0.5"
          y="0.5"
          width="67"
          height="84.9883"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1452_15648"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1452_15648"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MapPingImage;
