import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { useGetCasinoEventsList } from "@utils/api-requests/CasinoRequests";
import { CustomMetatag } from "@utils/metatags";

import { CasinoEventItems } from ".";
import {CasinoMemebershipTiers} from "@pages/casino";

const CasinoEventList = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: casinoEventsList,
    isLoading,
    isError,
    error,
  } = useGetCasinoEventsList();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
      },
      {
        id: 3,
        current: true,
        name: casinoEventsList?.field_page_name,
      },
    ];

    return roomDetails;
  }, [intl, casinoEventsList?.field_page_name]);

  const sectionArray = [
    {
      id: 1,
      contents: <CasinoEventItems />,
    },
    {
      id: 2,
      contents: <CasinoMemebershipTiers />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={casinoEventsList} />

      <DetailPageHero data={casinoEventsList} />

      <CommonContainer>
        <>
          <NotificationToggle data={casinoEventsList} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={casinoEventsList?.field_page_name}
            value={casinoEventsList?.field_full_description}
            url="/casino"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={casinoEventsList} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default CasinoEventList;
