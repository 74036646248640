import React from 'react';
import {Link} from "react-router-dom";

const ErrorBoundaryFallback = ({error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>

      <pre style={{color: "red"}}>{error.message}</pre>

      <Link to="/" onClick={resetErrorBoundary}>홈으로</Link>
    </div>
  );
};

export default ErrorBoundaryFallback;