import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  casinoAboutInspireUrl,
  casinoCasinoEventsListUrl,
  casinoCasinoEventsUrl,
  casinoDiningCategoryUrl,
  casinoDiningUrl,
  casinoGamesCategoryUrl, casinoGamesUrl,
  casinoIntroCategoryUrl,
  casinoMembershipUrl,
  casinoOfferRecommendationUrl,
  casinoResponsibleGamingUrl,
  casinoServiceCategoryUrl,
  casinoServieceUrl,
  casinoVerySpecialUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Casino.jsx
export function useGetCasinoOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["casinoOfferRecommendation", language, localStorage],
    getCasinoOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// Casino.jsx
export function useGetCasinoVerySpecial() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoVerySpecial = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoVerySpecialUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["casinoVerySpecial", language, localStorage],
    getCasinoVerySpecial,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoDining.jsx
export function useGetCasinoDining() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoDining = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoDiningUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["casinoDining", language, localStorage], getCasinoDining, {
    notifyOnChangeProps: "tracked",
  });
}

// CasinoDining.jsx
export function useGetCasinoDiningCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoDiningCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoDiningCategoryUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["casinoDiningCategory", language, localStorage],
    getCasinoDiningCategory,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoMemebershipTiers.jsx
export function useGetCasinoTiers() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoTiers = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoMembershipUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["casinoTiers", language, localStorage], getCasinoTiers, {
    notifyOnChangeProps: "tracked",
  });
}

// CasinoServiceFacilities.jsx
export function useGetCasinoServiceCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoServiceCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoServiceCategoryUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["casinoServiceCategory", language, localStorage],
    getCasinoServiceCategory,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoServiceFacilities.jsx
export function useGetCasinoService() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoService = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoServieceUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["casinoService", language, localStorage], getCasinoService, {
    notifyOnChangeProps: "tracked",
  });
}

// CasinoResponsibleGaming.jsx
export function useGetCasinoResponsibleGaming() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoResponsibleGaming = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoResponsibleGamingUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["casinoResponsibleGaming", language, localStorage],
    getCasinoResponsibleGaming,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoAboutInpsire.jsx
export function useGetCasinoAboutInspire() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoAboutInspire = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoAboutInspireUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["casinoAboutInspire", language, localStorage],
    getCasinoAboutInspire,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoIntro.jsx
export function useGetCasinoIntroCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoIntroCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoIntroCategoryUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["casinoIntroCategory", language, localStorage],
    getCasinoIntroCategory,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoGames.jsx
export function useGetCasinoGamesCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoGamesCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoGamesCategoryUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["casinoGamesCategory", language, localStorage],
    getCasinoGamesCategory,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

export function useGetCasinoGames(filterType) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoGames = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoGamesUrl}?language=${language}&category=${filterType}`
    );

    if (status === 200) {
      return data;
    }
  }, [language, filterType]);

  return useQuery(
    ["casinoGames", language, localStorage, filterType],
    getCasinoGames,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!filterType
    }
  );
}

export function useGetCasinoGameDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoGameDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoGamesUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["casinoGameDetail", language, localStorage, alias],
    getCasinoGameDetail,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!alias
    }
  );
}

export function useGetCasinoEventsDetail(eventAlias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoEventsDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoCasinoEventsUrl}?language=${language}${
        eventAlias ? `&url_alias=/${eventAlias}` : ""
      }`
    );

    if (status === 200) {
      if (eventAlias) {
        return data[0];
      }
      return data;
    }
  }, [language, eventAlias]);

  return useQuery(
    ["casinoEventsDetail", language, localStorage, eventAlias],
    getCasinoEventsDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// CasinoEventList.jsx
export function useGetCasinoEventsList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getCasinoEventsList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${casinoCasinoEventsListUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["casinoEventsList", language, localStorage],
    getCasinoEventsList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}
