import React from "react";

import { DangerouslyHtml } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetMomentumTermsConditions } from "@utils/api-requests/MomentumRequests";
import { PrivacyPolicyContentsContainer } from "@pages/about-us/AboutUsStyling";

import { CommonModal } from "..";
import { UnauthorizedEmailModalContainer } from "../ModalStyling";

const MarketingLearnMoreModal = ({ openModal, setOpenModal }) => {
  const {
    data: momentumTermsConditions,
    isLoading,
    isError,
    error,
  } = useGetMomentumTermsConditions();

  return (
    <div>
      <ErrorManage isLoading={isLoading} isError={isError} error={error}>
        <CommonModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          fixCloseButton
          modalName={momentumTermsConditions?.field_page_title}
        >
          <UnauthorizedEmailModalContainer>
            <div style={{ width: "100%", textAlign: "left" }}>
              <PrivacyPolicyContentsContainer disableTopBorder={true}>
                <div>
                  <DangerouslyHtml
                    value={momentumTermsConditions?.field_page_full_description}
                  />
                </div>
              </PrivacyPolicyContentsContainer>
            </div>
          </UnauthorizedEmailModalContainer>
        </CommonModal>
      </ErrorManage>
    </div>
  );
};

export default MarketingLearnMoreModal;
