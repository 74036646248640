import React from "react";

import PageTabButtons from "@components/item/PageTabButtons";

const PageWithTabs = ({ children, tabArr, currentTab, setCurrentTab }) => {
  return (
    // <ErrorManage isLoading={isLoading} isError={isError} error={error}>
    <>
      <PageTabButtons
        tabArr={tabArr}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />

      {children}
    </>
    // </ErrorManage>
  );
};

export default PageWithTabs;
