import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import { useGetSplashBayReserveNow } from "@utils/api-requests/SplashBayRequests";

import { SplashBayDomeReserveNow } from "../splash-dome";
import { HowToReserve } from ".";

const SplashBayReserveDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: splashBayReserveNow,
    isLoading,
    isError,
    error,
  } = useGetSplashBayReserveNow();

  const serviceDetailBC = useMemo(() => {
    const serviceDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-splash-bay",
        }),
        href: "/splash-bay",
      },
      {
        id: 3,
        current: true,
        name: splashBayReserveNow?.field_ree,
      },
    ];

    return serviceDetails;
  }, [intl, splashBayReserveNow?.field_ree]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <HowToReserve data={splashBayReserveNow?.field_how_to_reserve} />
      ),
    },
    {
      id: 2,
      contents: <SplashBayDomeReserveNow data={splashBayReserveNow} noButton />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={splashBayReserveNow} />

      <DetailPageHero data={splashBayReserveNow} />

      <CommonContainer>
        <>
          <NotificationToggle data={splashBayReserveNow} />

          <MuiBreadcrumbs
            breadcrumbArr={serviceDetailBC}
            pageTitle={splashBayReserveNow?.field_ree}
            value={splashBayReserveNow?.field_full_description}
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={splashBayReserveNow} />

        <MomentumLayout data={splashBayReserveNow} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default SplashBayReserveDetail;
