import React, { memo, useMemo } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import {
  CommonContainer,
  CommonDescktopContainer,
} from "@components/layout/ContainerStyle";
import { PageBottomContents } from "@components/layout";
import { OfferRecommendation } from "@components/item";
import { CustomMetatag } from "@utils/metatags";
import { useGetCasinoOfferRecommendation } from "@utils/api-requests/CasinoRequests";

import {
  CasinoDining,
  CasinoEvents,
  CasinoMemebershipTiers,
  CasinoResponsibleGaming,
  CasinoServiceFacilities,
  CasinoVerySpecial,
} from ".";

const Casino = ({ pageInfo }) => {
  const { data: casinoOfferRecommendation } = useGetCasinoOfferRecommendation();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <CommonContainer>
          <CasinoVerySpecial />
        </CommonContainer>
      ),
    },
    {
      id: 2,
      contents: (
        <CommonDescktopContainer>
          <CasinoServiceFacilities />
        </CommonDescktopContainer>
      ),
    },
    {
      id: 3,
      contents: (
        <CommonContainer>
          <CasinoEvents />
        </CommonContainer>
      ),
    },
    {
      id: 4,
      contents: (
        <CommonContainer>
          <CasinoMemebershipTiers />
        </CommonContainer>
      ),
    },
    {
      id: 5,
      contents: (
        <CommonContainer>
          <CasinoDining />
        </CommonContainer>
      ),
    },
    {
      id: 6,
      contents: (
        <CommonContainer>
          <CasinoResponsibleGaming />
        </CommonContainer>
      ),
    },
  ];

  return (
    <>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={casinoOfferRecommendation} />

      </PageBottomContents>
    </>
  );
};

export default memo(Casino);
