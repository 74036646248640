import {
  CommonDescOne,
  CommonTitleFive,
  infoZIndex,
} from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: TableOne.jsx
export const TableOneContainer = styled(CommonDescOne)(({ theme }) => ({
  marginBottom: "5rem",
  border: `1px solid ${theme.color.light_grey}`,
  boxShadow: theme.shadow.secondary,
  fontFamily: theme.fontFamily.secondaryFont,
  borderRadius: "2.5rem",
  overflowX: "auto",

  background: theme.color.white,

  [mq("desktop")]: {
    width: "100%",
  },
}));

export const TableOneWrapper = styled.table(({ theme }) => ({
  width: "100%",
  minWidth: "60rem",
  height: "100%",
  borderCollapse: "separate",
  borderSpacing: 0,

  "& thead": {
    background: theme.color.primary_color,
    color: theme.color.white,

    th: {
      borderTop: `1px solid ${theme.color.primary_light_color}`,
      fontFamily: theme.fontFamily.primaryFont,
      fontWeight: theme.fontWeight.medium,

      "&:not(:last-of-type)": {
        padding: "2rem",
        borderRight: `1px solid ${theme.color.primary_light_color}`,
      },
    },
  },

  "& .fixed": {
    position: "sticky",
    left: 0,
    zIndex: infoZIndex,
  },

  td: {
    padding: "2rem 0.8rem",
    textAlign: "center",
    borderRight: `1px solid ${theme.color.light_grey}`,
    borderTop: `1px solid ${theme.color.light_grey}`,
    background: theme.color.white,
  },
}));

// STYLE: TableTwo.jsx
export const TableTwoContainer = styled(CommonDescOne)(({ theme }) => ({
  marginBottom: "5rem",
  borderRadius: "2.5rem",
  boxShadow: theme.shadow.secondary,
  overflowX: "auto",

  [mq("desktop")]: {
    width: "100%",
  },
}));

export const TableTwoWrapper = styled.table(({ theme }) => ({
  minWidth: "60rem",
  width: "100%",
  borderCollapse: "separate",
  borderRadius: "2.5rem",
  borderSpacing: 0,

  "& thead": {
    background: theme.color.primary_color,
    color: theme.color.white,
    textAlign: "center",

    th: {
      borderBottom: `4px solid ${theme.color.white}`,
      fontWeight: theme.fontWeight.regular,

      "&:not(:last-of-type)": {
        padding: "2rem",
        borderRight: `4px solid ${theme.color.white}`,
      },
    },
  },

  "& .fixed": {
    position: "sticky",
    left: 0,
    zIndex: infoZIndex,
  },

  td: {
    padding: "2rem 0.8rem",
    textAlign: "center",
    borderRight: `4px solid ${theme.color.white}`,
    background: theme.color.white,
    fontSize: "1.6rem",
    fontWeight: theme.fontWeight.medium,
  },
}));

export const TableTwoTbodyTr = styled.tr(({ theme }) => ({
  "&:nth-of-type(2n)>td": {
    background: theme.color.light_white,
  },
}));

// STYLE: TableThree.jsx
export const TableThreeContainer = styled.div(({ theme, viewDetails }) => ({
  maxHeight: viewDetails ? "100%" : 0,
  borderRadius: "2.5rem",
  overflow: "hidden",
  boxShadow: theme.shadow.secondary,
  border: viewDetails ? `1px solid ${theme.color.tertiary_grey}` : "none",
  transition: "maxHeight 0.3s",
  width: "100%",
  marginBottom: "2rem",
}));

export const TableThreeHeader = styled(CommonTitleFive)(({ theme }) => ({
  background: theme.color.primary_color,
  color: theme.color.white,
  textAlign: "center",
  padding: "1.2rem 0",
  fontWeight: theme.fontWeight.medium,
  textTransform: "uppercase",
}));

export const TableThreeContents = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "0 1.6rem",

  [mq("desktop")]: {
    flexDirection: "row",
    padding: "0 3rem",
  },
}));

export const TableThreeBenefitList = styled.ul(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  padding: "1.6rem 1.6rem 1.6rem 3rem",
  color: theme.color.secondary_grey,

  "&:not(:last-of-type)": {
    borderBottom: `1px solid ${theme.color.tertiary_grey}`,
  },

  "& > li": {
    listStyle: "initial",
  },

  [mq("desktop")]: {
    padding: "2rem 3rem 2rem 5rem",

    "&:not(:last-of-type)": {
      borderBottom: "none",
      borderRight: `1px solid ${theme.color.tertiary_grey}`,
    },
  },
}));

// STYLE: TableFour.jsx
export const TableFourContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "2.5rem",
  overflow: "hidden",
  border: `1px solid ${theme.color.grey_white}`,

  [(mq("desktop"), mq("tablet"))]: {
    flexDirection: "row",
  },
}));

export const TableFourItem = styled.div(({ theme }) => ({
  flex: 1,

  "&:not(:last-of-type)": {
    borderRight: `1px solid ${theme.color.grey_white}`,
  },
}));

export const TableFourHeader = styled(CommonTitleFive)(({ theme }) => ({
  background: theme.color.primary_color,
  color: theme.color.white,
  padding: "1.6rem",
  fontWeight: theme.fontWeight.medium,
  textTransform: "uppercase",

  [(mq("desktop"), mq("tablet"))]: {
    padding: "1.2rem 3.2rem",
  },
}));

export const TableFourContents = styled.div(() => ({
  padding: "1.6rem",

  [(mq("desktop"), mq("tablet"))]: {
    padding: "1.6rem 3.2rem",
  },
}));

// STYLE: TableFive.jsx
export const TableFiveContainer = styled.div(
  ({ theme, firstColumnSticky }) => ({
    borderRadius: "2.5rem",
    width: "100%",
    overflow: !firstColumnSticky ? "hidden" : "auto",
    border: `1px solid ${theme.color.light_grey}`,

    [mq("desktop")]: {
      minWidth: 0,
    },
  })
);

export const TableFiveGroup = styled.div(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  gridGap: "3.2rem",

  "> *": {
    flex: "1 1 600px",
  },

  ".table-scroll": {
    overflow: "auto",
  },

  table: {
    whiteSpace: "nowrap",
  },
}));

export const TableFiveWrapper = styled.table(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderCollapse: "separate",
  borderSpacing: 0,

  thead: {
    color: theme.color.white,

    th: {
      background: "#593C72",
      textTransform: "uppercase",
      borderTop: `1px solid #4D2B6C`,
      padding: "1.6rem 0.8rem",

      "&:not(:last-of-type)": {
        borderRight: `1px solid #4D2B6C`,
      },

      strong: {
        display: "block",
      },
    },
  },

  "& thead:first-of-type": {
    th: {
      padding: "1.6rem 0.8rem",
      border: "none",
      background: theme.color.primary_color,
    },
  },

  "& .fixed": {
    position: "sticky",
    left: 0,
    zIndex: infoZIndex,
  },

  "& .capitalize": {
    textTransform: "capitalize",
  },

  tbody: {
    tr: {
      ":nth-of-type(2n) td": {
        background: theme.color.light_white,
      },
    },
  },

  td: {
    padding: "2rem 0.8rem",
    textAlign: "center",
    borderTop: `1px solid ${theme.color.light_grey}`,
    background: theme.color.white,
    fontFamily: theme.fontFamily.secondaryFont,

    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.color.light_grey}`,
    },
  },
}));

// STYLE: TableSix.jsx
export const TableSixContainer = styled.div(({theme}) => ({
  ".table-description" : {
    marginBottom: "6rem",
    fontFamily: theme.fontFamily.secondaryFont,
    fontSize: "1.8rem",
    lineHeight: "1.8",
  },

  ".table": {
    width: "100%",
    borderSpacing: 0,

    marginBottom: "5rem",
    border: `1px solid ${theme.color.light_grey}`,
    boxShadow: theme.shadow.secondary,
    fontFamily: theme.fontFamily.secondaryFont,
    borderRadius: "2.5rem",
    overflow: "hidden"
  },

  ".td-title": {
    color: theme.color.white,

    width: "25%",
    wordBreak: "word-wrap",

    background: "#593C72",
    textTransform: "uppercase",
    borderTop: `1px solid #4D2B6C`,
    padding: "1.6rem 0.8rem",

    "&:not(:last-of-type)": {
      borderRight: `1px solid #4D2B6C`,
    },

    strong: {
      display: "block",
    },
  },

  tbody: {
    tr: {
      ":nth-of-type(2n) td:not(.td-title)": {
        background: theme.color.light_white,
      },
    },
  },

  td: {
    padding: "2rem 0.8rem",
    textAlign: "center",
    borderTop: `1px solid ${theme.color.light_grey}`,
    background: theme.color.white,
    fontFamily: theme.fontFamily.secondaryFont,

    "&:not(:last-of-type)": {
      borderRight: `1px solid ${theme.color.light_grey}`,
    },

    small: {
      display: "block",
    },

    strong: {
      color: "#68507C",
    }
  },

  ".title": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    margin: "0 auto",
    color: "white",
    fontSize: "1.8rem",
    fontWeight: theme.fontWeight.bold,
    textAlign: "center",
  },

  ".title-help": {
    fontSize: "1.8rem",
    textAlign: "center",
    paddingTop: "3rem",

    strong: {
      fontWeight: theme.fontWeight.bold
    }
  },

  ".td-content": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    textAlign: "left",
    padding: "2rem",
    fontWeight: "400",
    fontSize: "1.8rem",
    lineHeight: "1.4",
  },

  ".td-border": {
    borderTop: "1px solid #68507C",
    borderBottom: "1px solid #68507C",
  },

  ".text-md": { fontWeight: "400" },

  ".text-in": {
    display: "block",
    marginLeft: "2rem",
    fontWeight: "400",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
  },

  ".text-in-flex": {
    display: "flex",
    flexDirection: "column",
    fontWeight: "400",
    marginLeft: "1.6rem",
    lineHeight: "2rem",
  },
}));
