import React, { memo, useEffect, useState } from "react";

import { PageContainer } from "@components/sub-page";

import { CustomMetatag } from "@utils/metatags";
import { useGetHomeInformation } from "@utils/api-requests/GlobalRequests";
import { useGetHomeOffersSection } from "@utils/api-requests/HomeRequests";

import {
  HomeEntertainment,
  HomeMice,
  HomeOffers,
  HomeRestaurant,
  HomeRoom,
  HomeShop,
  HomeSignUp,
  HomeSplashBay,
  HomeTower,
  MainBanner,
} from ".";

const Home = () => {
  const [homeSectionInformation, setHomeSectionInformation] = useState();

  const { data: homeOffersSection } = useGetHomeOffersSection();

  const {
    data: homeInformation,
    isLoading,
    isError,
    error,
  } = useGetHomeInformation();

  const sectionArray = [
    {
      id: 1,
      contents: <HomeOffers data={homeOffersSection} />,
    },
    {
      id: 2,
      contents: <HomeTower />,
    },
    {
      id: 3,
      titleDirection: "left",
      contents: <HomeRoom />,
    },
    {
      id: 4,
      contents: <HomeShop />,
    },
    {
      id: 5,
      titleDirection: "left",
      contents: <HomeRestaurant />,
    },
    {
      id: 6,
      titleDirection: "left",
      contents: <HomeEntertainment />,
    },
    {
      id: 7,
      contents: <HomeSplashBay />,
    },
    {
      id: 8,
      titleDirection: "left",
      contents: <HomeMice />,
    },
    {
      id: 9,
      contents: <HomeSignUp />,
    },
  ];

  useEffect(() => {
    if (homeOffersSection?.field_home_section_offers?.length > 0) {
      setHomeSectionInformation({ 0: homeInformation?.[0] });
    }

    if (homeOffersSection?.field_home_section_offers?.length === 0 || homeOffersSection === undefined) {
      setHomeSectionInformation(() => ({
        0: {
          ...homeInformation?.[0],
          field_enable_section_1: "Off", // Change 'On' to 'Off' or vice versa based on your requirement
        },
      }));
    }
  }, [homeInformation, homeOffersSection, homeOffersSection?.length]);

  return (
    homeSectionInformation && (
      <div>
        <CustomMetatag metaData={homeInformation?.[0]} />

        <MainBanner
          data={homeInformation}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />

        <PageContainer
          data={homeSectionInformation?.[0]}
          sectionArray={sectionArray}
        />
      </div>
    )
  );
};

export default memo(Home);
