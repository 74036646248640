import React, { useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { FrameTwo } from "@components/frame";
import { FacilityServicesText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const FacilityServices = ({ data }) => {
  const [filterType, setFilterType] = useState("");

  const facilityNames = useMemo(() => {
    const result = data?.map((item) => {
      return {
        title: item.name,
        category_id: item.name,
      };
    });

    setFilterType(data?.[0]?.name);

    return result;
  }, [data]);

  const currentData = useMemo(() => {
    return data?.filter((el) => el.name === filterType)?.[0];
  }, [data, filterType]);

  return (
    <div>
      <ListWithTabs
        tabArr={facilityNames}
        filterType={filterType}
        setFilterType={setFilterType}
      >
        <FrameTwo
          subTitle={<FacilityServicesText />}
          title={currentData?.name}
          desc={currentData?.description}
          image={currentData?.thumbnail}
          ratio="9 / 5"
        />
      </ListWithTabs>
    </div>
  );
};

export default FacilityServices;
