import React from "react";

import { TableOne } from "@components/table";
import { splashTbody, splashTableThead } from "@utils/table-data/tableData";

const SplashBayOperationHours = () => {
  return <TableOne thead={splashTableThead} tbody={splashTbody} />;
};

export default SplashBayOperationHours;
