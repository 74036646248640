import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { BoxListFour } from "@components/box-list";
import { MuiPagination } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsMediaCenterList } from "@utils/api-requests/AboutUsRequests";

const MediaCenterList = () => {
  const list = useRef();
  const search = useLocation().search;

  const pageQuery = useMemo(() => {
    const params = new URLSearchParams(search);

    const page = params.get("page");

    return page;
  }, [search]);

  const [page, setPage] = useState(1);

  const {
    data: aboutUsMediaCenterList,
    isFetching,
    isError,
    error,
  } = useGetAboutUsMediaCenterList(page);

  useEffect(() => {
    if (pageQuery !== null) {
      setPage(Number(pageQuery));
    }
  }, [pageQuery]);

  return (
    <ErrorManage isFetching={isFetching} isError={isError} error={error}>
      <div ref={list}>
        <BoxListFour
          data={aboutUsMediaCenterList}
          imageKey="field_thumbnail"
          nameKey="field_name"
          descKey="field_short_description"
          typeKey=""
          dateKey="field_date"
          linkTo="/about-us/media-center"
          noExpire
          centered
          query={`?page=${page}`}
        />

        {aboutUsMediaCenterList?.length > 0 && (
          <MuiPagination
            listRef={list}
            count={aboutUsMediaCenterList?.[0]?.pager?.total_pages}
            defaultPage={1}
            page={page}
            setPage={setPage}
          />
        )}
      </div>
    </ErrorManage>
  );
};

export default MediaCenterList;
