import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Backdrop } from "@mui/material";

import { modalOverHeaderZIndex } from "@assets/styles/CommonStyles";
import {
  CommonContainer,
  CommonDescktopContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MainBannerIFrame } from "@pages/home/HomeStyling";
import { ErrorManage } from "@screens/index";
import {
  useGetMeetMeetingSuccessDetail,
  useGetMeetingSuccessAliasGroup,
} from "@utils/api-requests/MeetRequests";
import { CustomMetatag } from "@utils/metatags";

import {
  MeetingCapacityStructure,
  MeetingKeyPoints,
  MeetingRooms,
  MeetingSuccessInformation,
  ServicesTechnology,
} from ".";

const MeetingSuccessDetail = ({ pageInfo }) => {
  const intl = useIntl();
  const alias = useParams().meetingSuccessAlias;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [sectionArray, setSectionArray] = useState();

  const [openIFrame, setOpenIFrame] = useState(false);

  const {
    data: meetingSuccessDetail,
    isFetching,
    isError,
    error,
  } = useGetMeetMeetingSuccessDetail(alias);

  const { data: meetingSuccessAliasGroup } = useGetMeetingSuccessAliasGroup();

  const roomDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-meet",
        }),
        href: "/meet",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-meeting",
        }),
        href: "/meet",
        tab: meetingSuccessDetail?.field_meeting_category,
      },
      { id: 4, current: true, name: meetingSuccessDetail?.field_meeting_name },
    ];

    return roomDetails;
  }, [
    intl,
    meetingSuccessDetail?.field_meeting_category,
    meetingSuccessDetail?.field_meeting_name,
  ]);

  useEffect(() => {
    setSectionArray([
      {
        id: 1,
        contents: (
          <CommonDescktopContainer>
            <MeetingRooms data={meetingSuccessDetail?.field_room_themes} />
          </CommonDescktopContainer>
        ),
      },
      {
        id: 2,
        disabled:
          meetingSuccessDetail?.field_enable_information_section === "Off",
        contents: (
          <CommonContainer>
            <MeetingSuccessInformation data={meetingSuccessDetail} />
          </CommonContainer>
        ),
      },
      {
        id: 3,
        disabled:
          meetingSuccessDetail?.field_enable_capacities_structur === "Off",
        contents: (
          <CommonContainer>
            <MeetingCapacityStructure
              data={meetingSuccessDetail?.field_capacity_and_structure_lis}
            />
          </CommonContainer>
        ),
      },
      {
        id: 4,
        title: meetingSuccessDetail?.field_meeting_name,
        disabled:
          meetingSuccessDetail?.field_enable_key_point_section === "Off",
        contents: (
          <MeetingKeyPoints data={meetingSuccessDetail?.field_key_points} />
        ),
      },
      {
        id: 5,
        disabled: meetingSuccessDetail?.field_enable_service_section === "Off",
        contents: (
          <ServicesTechnology
            data={meetingSuccessDetail?.field_meeting_services}
          />
        ),
      },
    ]);
  }, [meetingSuccessDetail, alias]);

  useEffect(() => {
    if (meetingSuccessAliasGroup) {
      setCurrentIndex(
        meetingSuccessAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [meetingSuccessAliasGroup, alias]);

  const handleClose = () => {
    setOpenIFrame(false);
  };
  const handleOpen = () => {
    setOpenIFrame(true);
  };

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <Backdrop
        sx={{ zIndex: modalOverHeaderZIndex }}
        open={openIFrame}
        onClick={handleClose}
      >
        <MainBannerIFrame
          title="Meet-Booking"
          src={meetingSuccessDetail?.field_widget?.[0]?.field_link.uri}
          frameborder="0"
          allowfullscreen
        />
      </Backdrop>

      <CustomMetatag metaData={meetingSuccessDetail} />

      <DetailPageHero data={meetingSuccessDetail} />

      <CommonContainer>
        <NotificationToggle data={meetingSuccessDetail} />

        <MuiBreadcrumbs
          breadcrumbArr={roomDetailBC}
          pageTitle={meetingSuccessDetail?.field_meeting_name}
          value={meetingSuccessDetail?.field_full_description}
          prevUUid={meetingSuccessAliasGroup?.[currentIndex - 1]}
          nextUUid={meetingSuccessAliasGroup?.[currentIndex + 1]}
          url={`/meet/meeting-success`}
          // buttonName={<BookYourEventText />}
          // buttonEvent={handleOpen}
        />
      </CommonContainer>

      <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire
            data={meetingSuccessDetail}
            buttonEvent={handleOpen}
            noButton
          />
        </CommonMobileContainer>

        <OfferRecommendation data={meetingSuccessDetail} />

        <MomentumLayout data={meetingSuccessDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MeetingSuccessDetail;
