import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import {DetailPageHero} from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { MuiBreadcrumbs, NotificationToggle } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsPrivacyPolicy } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import { aboutUsRouteList } from "../aboutUsRouteList";
import {
  InformationHandling,
  PrivacyPolicyContents,
} from ".";

const PrivacyPolicyDetail = ({pageInfo}) => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsPrivacyPolicy,
    isLoading,
    isError,
    error,
  } = useGetAboutUsPrivacyPolicy();

  const restaurantDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-about-us",
      }),
      href: "/about-us",
    },

    { id: 3, current: true, name: aboutUsPrivacyPolicy?.field_policy_name },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsPrivacyPolicy} />

      <DetailPageHero data={aboutUsPrivacyPolicy} small />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsPrivacyPolicy} />

          <MuiBreadcrumbs
            breadcrumbArr={restaurantDetailBC}
            pageTitle={aboutUsPrivacyPolicy?.field_policy_name}
            value={aboutUsPrivacyPolicy?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

         <InformationHandling data={aboutUsPrivacyPolicy} />

         <PrivacyPolicyContents data={aboutUsPrivacyPolicy} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsPrivacyPolicy} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default PrivacyPolicyDetail;
