import React, { memo, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

import { ScreenPortal } from ".";
import {
  LoadingLogo,
  LoadingPhasePurple,
  LoadingScreenContainer,
  LoadingSpinnerSvg,
} from "./ScreensStyling";

const LoadingScreen = () => {
  const theme = useTheme();

  const [animationRight, setAnimationRight] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (animationRight) {
        setAnimationRight(false);
      } else {
        setAnimationRight(true);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, [animationRight]);

  useEffect(() => {
    document.body.style.cssText = `
      overflow-y: hidden;
      width: 100%;`;

    return () => {
      document.body.style.cssText = "";
    };
  }, []);

  return (
    <ScreenPortal>
      <LoadingScreenContainer>
        <LoadingPhasePurple animationRight={animationRight}>
          <LoadingLogo
            data={theme.image.logoWhiteFull.default}
            alt="Mohegan Inspire Entertainment Resort"
            aria-label="Mohegan Inspire Entertainment Resort"
            height={32}
          />

          <LoadingSpinnerSvg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#fff"
          >
            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
          </LoadingSpinnerSvg>
        </LoadingPhasePurple>

        {/* <LoadingPhaseBrown animationRight={animationRight} />

        <LoadingPhaseYellow animationRight={animationRight} />

        <LoadingPhaseGrey animationRight={animationRight} /> */}
      </LoadingScreenContainer>
    </ScreenPortal>
  );
};

export default memo(LoadingScreen);
