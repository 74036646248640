import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import {
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
  BoxListFiveTexts,
} from "@components/box-list/BoxListStyling";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { GalleryOneImages } from "@components/layout/LayoutStyling";
import {
  BoxListFiveDetailButton,
  BoxListFiveOverlays,
} from "@components/box-list/BoxListFive";
import { ErrorManage } from "@screens/index";
import { useGetEntertainmentVenueList } from "@utils/api-requests/EntertainmentRequests";
import {
  changeRatioDependingDataLength,
  getSearchValue,
} from "@utils/modify-data/modifyData";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { mq } from "@utils/media-query/mediaQuery";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const EntertainmentAttractionVenue = () => {
  const search = useLocation().search;

  const attractionVenueRef = useRef();

  const {
    data: entertainmentVenueList,
    isLoading,
    isError,
    error,
  } = useGetEntertainmentVenueList();

  const [onHover, setOnHover] = useState(-1);

  const onMouseEnter = (i) => {
    setOnHover(i);
  };

  const onMouseLeave = () => {
    setOnHover(-1);
  };

  const length = useMemo(() => {
    return entertainmentVenueList?.length % 6;
  }, [entertainmentVenueList?.length]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "attraction-venues") {
        scrollToTargetAdjusted(attractionVenueRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  return (
    <CommonContainer>
      <div ref={attractionVenueRef}>
        <GalleryOneImages length={length} isMobileSingle>
          <ErrorManage
            isLoading={isLoading}
            isError={isError}
            error={error}
            noLoading
          >
            {entertainmentVenueList?.map((item, idx) => {
              return (
                <BoxListFiveGridArea
                  key={item.field_category_id}
                  onMouseEnter={() => onMouseEnter(idx)}
                  onMouseLeave={onMouseLeave}
                >
                  <ImageWrapper
                    radius="1.5rem"
                    custom={{
                      [mq("desktop")]: {
                        aspectRatio: changeRatioDependingDataLength(
                          length,
                          idx
                        ),
                      },
                    }}
                  >
                    <Image
                      src={item?.field_thumbnail[0]?.src}
                      alt={item?.field_thumbnail[0]?.alt}
                    />

                    <BoxListFiveInformation align={"column"}>
                      <BoxListFiveTexts>
                        <CommonTitleTwo>
                          <DangerouslyHtml value={item.field_venue_name} />
                        </CommonTitleTwo>

                        <BoxListFiveDesc className="ellipsis-3">
                          <DangerouslyHtml
                            value={item.field_ent_venue_short_desc}
                          />
                        </BoxListFiveDesc>
                      </BoxListFiveTexts>

                      <BoxListFiveDetailButton
                        onHover={onHover}
                        idx={idx}
                        item={item}
                        url="/entertainment/attraction-venue"
                      />
                    </BoxListFiveInformation>

                    <BoxListFiveOverlays onHover={onHover} idx={idx} />
                  </ImageWrapper>
                </BoxListFiveGridArea>
              );
            })}
          </ErrorManage>
        </GalleryOneImages>
      </div>
    </CommonContainer>
  );
};

export default EntertainmentAttractionVenue;
