import React from "react";

import {
  CustomOverlayContainer,
  GradientOverlayContainer,
  NormalOverlayContainer,
} from "./ItemStyling";

export const NormalOverlay = ({ opacity = "0.15", rgb = "0, 0, 0" }) => {
  return <NormalOverlayContainer opacity={opacity} rgb={rgb} />;
};

export const GradientOverlay = ({ opacity = "0.4", direction = "to top" }) => {
  return <GradientOverlayContainer opacity={opacity} direction={direction} />;
};

export const CustomOverlay = ({
  opacity1 = "1",
  opacity2,
  direction = "to top",
}) => {
  return (
    <CustomOverlayContainer
      opacity1={opacity1}
      opacity2={opacity2}
      direction={direction}
    />
  );
};
