import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import {
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";

const OwnerDeveloper = ({ data }) => {
  return (
    <BoxListThreeWrapper key={data.title} reverse>
      <ImageWrapper radius="2.5rem" ratio="3 / 2">
        <Image src={data?.field_logo[0]?.src} alt={data?.field_logo[0]?.alt} />
      </ImageWrapper>

      <BoxListThreeInformation>
        <CommonTitleOne>
          <DangerouslyHtml value={data.field_owner_title} />
        </CommonTitleOne>

        <CommonDescTwo>
          <DangerouslyHtml value={data.field_owner_description} />
        </CommonDescTwo>
      </BoxListThreeInformation>
    </BoxListThreeWrapper>
  );
};

export default OwnerDeveloper;
