import React, { memo } from "react";

import { PrimaryButton } from "@components/buttons";
import { SliderSeven } from "@components/sliders";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetHomeShopSection } from "@utils/api-requests/HomeRequests";

import { HomeButtonWrapper } from "./HomeStyling";

const HomeShop = () => {
  const { data: homeShopSection } = useGetHomeShopSection();

  return (
    <CommonContentButtonWrapper>
      <SliderSeven
        data={homeShopSection}
        nameKey="field_shop_name"
        categoryKey="field_category"
        descKey="field_short_description"
        linkKey="field_linked_content_url_alias"
        categoryIdKey="field_shop_category"
      />

      <CommonContainer>
        <HomeButtonWrapper>
          <PrimaryButton linkTo="/shop">
            <ViewAllText />
          </PrimaryButton>
        </HomeButtonWrapper>
      </CommonContainer>
    </CommonContentButtonWrapper>
  );
};

export default memo(HomeShop);
