import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {useIntl} from "react-intl";

import {DetailPageContainer, DetailPageHero} from "@components/sub-page";
import {CommonContainer} from "@components/layout/ContainerStyle";
import {MuiBreadcrumbs, NotificationToggle} from "@components/item";
import {MomentumLayout, PageBottomContents} from "@components/layout";
import {ErrorManage} from "@screens/index";
import {useGetAboutUsGettingHere} from "@utils/api-requests/AboutUsRequests";
import {CustomMetatag} from "@utils/metatags";

import {GettingHereMap} from ".";
import {aboutUsRouteList} from "../aboutUsRouteList";
import GettingHereHow from "./GettingHereHow";
import GettingHereParkingFee from "./GettingHereParkingFee";

const GettingHereDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsGettingHere,
    isLoading,
    isError,
    error,
  } = useGetAboutUsGettingHere();

  const sitemapDetailBC = useMemo(() => {
    return [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-about-us",
        }),
        href: "/about-us",
      },
      {
        id: 3,
        current: true,
        name: aboutUsGettingHere?.field_getting_here_name,
      },
    ];
  }, [aboutUsGettingHere?.field_getting_here_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <GettingHereMap data={aboutUsGettingHere} />,
    },
    { id: 2, contents: <GettingHereParkingFee data={aboutUsGettingHere} /> },
    { id: 3, contents: <GettingHereHow data={aboutUsGettingHere} /> },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsGettingHere} />

      <DetailPageHero data={aboutUsGettingHere} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsGettingHere} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={aboutUsGettingHere?.field_getting_here_name}
            value={aboutUsGettingHere?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsGettingHere} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default GettingHereDetail;
