import React, { memo, useMemo } from "react";
import he from "he";
import { Link } from "@mui/material";

import { image } from "src/theme";
import { ArrowImage } from "@components/buttons/ButtonsStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { replaceAmpersand } from "@utils/modify-data/modifyData";

import {
  BreadcrumbContainer,
  BreadcrumbBefore,
  PageTitle,
  BreadcrumbInfo,
  BreadcrumbPageArrow,
  BreadcrumbDesc,
  SubTitle,
  StyledBreadcrumbs, CoverImageWrapper,
} from "./ItemStyling";
import { BreadcrumbArrow, DangerouslyHtml } from ".";
import BreadcrumbButton from "@components/buttons/BreadcrumbButton";


const MuiBreadcrumbs = ({
  breadcrumbArr,
  pageTitle,
  subTitle,
  value,
  featuredImage,
  linkTo,
  outerLink,
  buttonName,
  buttonEvent,
  prevUUid,
  nextUUid,
  url,
  category,
  textAlign = "start",
  coverImage
}) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  return (
    <BreadcrumbContainer marginBottom={buttonName}>
      {(prevUUid || nextUUid) && (
        <>
          {prevUUid && (
            <BreadcrumbPageArrow
              direction="left"
              to={
                category
                  ? `${baseUrl}${url}?category=${prevUUid}`
                  : `${baseUrl}${url}${prevUUid}`
              }
            >
              <ArrowImage
                src={image.sliderArrow.default}
                alt="left arrow icon"
                direction="left"
              />
            </BreadcrumbPageArrow>
          )}

          {nextUUid && (
            <BreadcrumbPageArrow
              direction="right"
              to={
                category
                  ? `${baseUrl}${url}?category=${nextUUid}`
                  : `${baseUrl}${url}${nextUUid}`
              }
            >
              <ArrowImage
                src={image.sliderArrow.default}
                alt="right arrow icon"
                direction="right"
              />
            </BreadcrumbPageArrow>
          )}
        </>
      )}

      <BreadcrumbInfo>
        {breadcrumbArr && (
          <StyledBreadcrumbs
            separator={<BreadcrumbArrow />}
            aria-label="breadcrumb"
          >
            {breadcrumbArr
              .filter((el) => !el.current)
              .map((breadcrumb, idx) => {
                return (
                  <Link
                    key={breadcrumb.id}
                    href={
                      idx === 0
                        ? `${baseUrl}`
                        : `${baseUrl}${breadcrumb.href}${
                            breadcrumb.tab !== undefined
                              ? `?category=${breadcrumb.tab}`
                              : ""
                          }`
                    }
                    underline="hover"
                  >
                    <BreadcrumbBefore to={
                      idx === 0
                        ? `${baseUrl}`
                        : `${baseUrl}${breadcrumb.href}${
                          breadcrumb.tab !== undefined
                            ? `?category=${breadcrumb.tab}`
                            : ""
                        }`
                    }>
                      {breadcrumb?.name && he.decode(breadcrumb?.name)}
                    </BreadcrumbBefore>
                  </Link>
                );
              })}
          </StyledBreadcrumbs>
        )}

        <PageTitle noMargin={!value}>
          {pageTitle && <DangerouslyHtml value={he.decode(pageTitle)} />}

          <SubTitle>
            <DangerouslyHtml value={subTitle} />
          </SubTitle>
        </PageTitle>

        <BreadcrumbDesc textAlign={textAlign} coverImage={coverImage}>
          {
            coverImage &&
            <CoverImageWrapper>
              <img src={`${process.env.REACT_APP_SERVER_URL}${coverImage?.src}`} alt={coverImage?.alt} width="100%" height="100%" />
            </CoverImageWrapper>
          }

          <DangerouslyHtml value={value}/>
        </BreadcrumbDesc>

        {featuredImage && (<ImageWrapper width="100%">
            <Image
              src={featuredImage.src}
              alt={featuredImage.alt}
            />
        </ImageWrapper>)}

        {buttonName && (
          <BreadcrumbButton
            linkTo={linkTo}
            outerLink={replaceAmpersand(outerLink)}
            buttonEvent={buttonEvent}
          >
            {buttonName}
          </BreadcrumbButton>
        )}
      </BreadcrumbInfo>
    </BreadcrumbContainer>
  );
};

export default memo(MuiBreadcrumbs);
