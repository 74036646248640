import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import { SwiperArrowButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { IsDesktop } from "@utils/media-query/mediaQuery";

import {
  SliderSixContainer,
  SliderSixLeftArrow,
  SliderSixRightArrow,
  SliderDescription,
} from "./SlidersStyling";
import {DangerouslyHtml} from "@components/item";

const SliderSix = ({ imageArr, currentTab, sliderDesc }) => {
  const location = useLocation();
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [swiper, setSwiper] = useState();

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    slidesPerView: 1,
    loopedSlides: 1,
    spaceBetween: 0,
    pagination: {
      clickable: true,
    },
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
  };
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [currentTab, swiper, location]);

  return (
    (imageArr?.length >= 1 && imageArr) &&
    (<SliderSixContainer>
      <Swiper
        {...custom}
        onSwiper={setSwiper}
        onResize={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        <IsDesktop>
          {swiper && (
            <>
              <SwiperArrowButton
                ref={navPrevRef}
                direction="left"
                custom={{
                  ...SliderSixLeftArrow,
                  display: imageArr?.length >= 1 ? "flex" : "none",
                }}
                design="border"
              />

              <SwiperArrowButton
                ref={navNextRef}
                direction="right"
                custom={{
                  ...SliderSixRightArrow,
                  display: imageArr?.length >= 1 ? "flex" : "none",
                }}
                design="border"
              />
            </>
          )}
        </IsDesktop>

        {imageArr?.length >= 1 && imageArr?.map((image, idx) => {
          return (
            <SwiperSlide key={idx}>
              <ImageWrapper
                ratio={isDesktop ? "5 / 2" : "16 / 9"}
                radius={isDesktop ? "2.5rem" : "1.5rem"}
              >
                {image?.src && <Image src={image?.src} alt={image?.alt} />}
              </ImageWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {sliderDesc?.length &&  (
        <SliderDescription>
          <DangerouslyHtml value={sliderDesc} />
        </SliderDescription>
      )}
    </SliderSixContainer>)
  );
};

export default SliderSix;
