import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";

import {ListWithTabs} from "@components/list";
import {useGetCasinoGames, useGetCasinoGamesCategory} from "@utils/api-requests/CasinoRequests";
import {getSearchValue} from "@utils/modify-data/modifyData";
import {ErrorManage} from "@screens/ErrorManage";
import {BoxListFour} from "@components/box-list";

const CasinoGames = () => {

  const search = useLocation().search;

  const [filterType, setFilterType] = useState("");

  const { data: casinoGamesCategory } = useGetCasinoGamesCategory();

  const {data: casinoGames, isFetching,isError, errors} = useGetCasinoGames(filterType);

  useEffect(() => {
    if (search !== "") {
      setFilterType(getSearchValue(search));
    } else {
      if (search === "") {
        setFilterType(casinoGamesCategory?.[0].tid);
      }
    }
  }, [casinoGamesCategory, search]);

  const casinoGamesCategories = useMemo(() => {
    return casinoGamesCategory?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });
  }, [casinoGamesCategory]);

  return (
    <ErrorManage isFetching={isFetching} isError={isError} errors={errors}>
      <ListWithTabs
      tabArr={casinoGamesCategories}
      filterType={filterType}
      setFilterType={setFilterType}
      >
        <BoxListFour
          data={casinoGames}
          imageKey="field_thumbnail"
          nameKey="field_game_name"
          descKey="field_full_description"
          linkTo="/casino/games"
          noDate
          casinoGame
        />
      </ListWithTabs>
    </ErrorManage>
  );
};

export default CasinoGames;
