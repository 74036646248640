import React from "react";

import { SliderNine } from "@components/sliders";
import { TiersBenefitsContainer } from "@pages/momentum/MomentumStyling";

const CasinoTiersBenefits = ({ data }) => {
  return (
    <TiersBenefitsContainer>
      <SliderNine
        data={data}
        imageKey="field_icon"
        nameKey="field_tier_name"
        pointListKey="field_event_name"
      />
    </TiersBenefitsContainer>
  );
};

export default CasinoTiersBenefits;
