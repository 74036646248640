import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import {
  TribalChiefContainer,
  TribalChiefDesc,
} from "@pages/about-us/AboutUsStyling";

const TribalChief = ({ data }) => {
  return (
    <TribalChiefContainer>
      <ImageWrapper radius="2.5rem" ratio={"16 / 9"}>
        <Image
          src={data?.field_meet_our_tribal_chief_imag[0]?.src}
          alt={data?.field_meet_our_tribal_chief_imag[0]?.alt}
        />
      </ImageWrapper>

      <TribalChiefDesc>
        <DangerouslyHtml value={data?.field_meet_our_tribal_chief_desc} />
      </TribalChiefDesc>
    </TribalChiefContainer>
  );
};

export default TribalChief;
