import React, { memo } from "react";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import { CommonTitleOne } from "@assets/styles/CommonStyles";
import { CommonContainer } from "@components/layout/ContainerStyle";

import {
  PageContainerSectionDesc,
  PageContainerSectionName,
  PageContainerWrapper,
} from "./SubPageStyling";
import { DangerouslyHtml } from "@components/item";

const PageContainer = ({ data, sectionArray }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return data ? (
    [1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => {
      const titleDirection = sectionArray?.filter((el) => el.id === num)[0]
        ?.titleDirection;

      const noMargin = sectionArray?.filter((el) => el.id === num)[0]?.noMargin;

      const noMarginBottom = sectionArray?.filter((el) => el.id === num)[0]
        ?.noMarginBottom;

      const disabled = sectionArray?.filter((el) => el.id === num)[0]?.disabled;

      return (
        !disabled &&
        data[`field_enable_section_${num}`] === "On" && (
          <PageContainerWrapper key={num}>
            {data[`field_title_${num}`] && (
              <CommonContainer noPadding={noMargin}>
                <PageContainerSectionName
                  textAlign={isDesktop ? titleDirection : "center"}
                  noMargin={noMargin}
                  noMarginBottom={noMarginBottom}
                >
                  <CommonTitleOne>
                    <DangerouslyHtml value={data[`field_title_${num}`]} />
                  </CommonTitleOne>

                  <PageContainerSectionDesc
                    direction={isDesktop ? titleDirection : "center"}
                  >
                    <DangerouslyHtml value={data[`field_description_${num}`]} />
                  </PageContainerSectionDesc>
                </PageContainerSectionName>
              </CommonContainer>
            )}

            {sectionArray?.filter((el) => el.id === num)[0]?.contents}
          </PageContainerWrapper>
        )
      );
    })
  ) : (
    <Skeleton variant="rounded" width="100%" height="60vh" />
  );
};

export default memo(PageContainer);
