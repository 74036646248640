import styled from "@emotion/styled";

import {
  CommonTitleFour,
  CommonTitleSeven,
  infoZIndex,
} from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";

// ListOne.jsx
export const ListOneContainer = styled.div(() => ({
  width: '100%',
  display: "flex",
  justifyContent: "center",
  gap: "3.2rem",
  flexWrap: "wrap",

  [mq("desktop")]: {
    gap: "3.2rem 4.8rem",
  },
}));

export const ListOneItem = styled(CommonTitleSeven)(({ length }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "1rem 0",
  maxWidth: "24rem",
  minWidth: "24rem",
  margin: length < 3 ? "0 5rem" : "0 auto",
  textAlign: "center",
  lineHeight: "3rem",

  [mq("desktop")]: {
    minWidth: "20rem",
    gap: "3.2rem 0",
  },
}));

export const ListOneDesc = styled.div(({ theme }) => ({
  fontSize: "1.6rem",
  fontWeight: theme.fontWeight.regular,
}));

// ListTwo.jsx
export const ListTwoContainer = styled.div(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "4.8rem",
}));

export const ListTwoItem = styled(CommonTitleFour)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem 0",
  margin: "0 auto",
  textAlign: "center",
  lineHeight: "3rem",

  [mq("desktop")]: {
    minWidth: "30rem",
    maxWidth: "30rem",
    gap: "3.2rem 0",
  },
}));

// STYLE: ContainerStyle.jsx
export const FullContainer = styled.div(({ noBlur, noPadding }) => ({
  position: "relative",
  margin: "0 auto",
  paddingInline: noPadding ? 0 : "1.6rem",
  maxWidth: "153.6rem",
  width: "100%",

  [mq("desktop")]: {
    paddingInline: "2.4rem",

    "@media (min-resolution: 90dpi)": {
      // when zoom level is over than 100%
      margin: "0 auto 0 auto",
      maxWidth: "151rem",
      paddingRight: 0,
      paddingInline: noPadding ? 0 : "3rem",
    },

    "&:after": {
      content: '""',
      position: "absolute",
      display: noBlur ? "none" : "block",
      width: "15rem",
      height: "100%",
      right: "24px",
      top: 0,
      zIndex: 1,
      background:
        "linear-gradient(to left,rgba(255,255,255,100%), rgba(255,255,255,60%), rgba(255,255,255,0%));",
    },
  },
}));

// STYLE: PageBottomContents.jsx
export const PageBottomContentsContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));

// STYLE: KeepInTouch.jsx
export const KeepInTouchWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "3rem 6rem",
  marginTop: "10rem",

  [mq("desktop")]: {
    flexDirection: "row",
    marginTop: "16rem",
  },
}));

export const KeepInTouchCopywrite = styled.div(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "2.4rem",
  zIndex: infoZIndex,
  transform: "translateY(-50%)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  maxWidth: "90%",
  color: theme.color.white,

  [mq("desktop")]: {
    left: "5rem",
    maxWidth: "calc(100% - 10rem)",
  },
}));

export const KeepInTouchInformation = styled.div(() => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "6rem",
  textAlign: "center",

  [mq("desktop")]: {
    alignItems: "flex-start",
    textAlign: "left",
  },
}));

export const KeepInTouchInformationWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
}));

// GalleryOne.jsx
export const GalleryOneContainer = styled.div(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "5.6rem 0",
}));

export function galleryGrid(length) {
  switch (length) {
    case 1:
      return `"a a a a a a"`;

    case 2:
      return `"a a a b b b"`;

    case 3:
      return `"a a a a a a"            "b b b c c c"`;

    case 4:
      return `"a a a a a a"            "b b c c d d"`;

    case 5:
      return `"a a a b b b"            "c c d d e e"`;

    case 0:
      return `"a a b b c c"            "d d e e f f"`;

    case 6:
      return `"a a b b c c"            "d d e e f f"`;

    default:
      break;
  }
}

export const GalleryOneImages = styled.div(({ length, isMobileSingle }) => ({
  width: "100%",
  display: "grid",
  flexFlow: "row wrap",
  gap: "2.5rem",
  gridTemplateColumns: isMobileSingle ? "repeat(1, 1fr)" : "repeat(2, 1fr)",

  "& > *": {
    flex: "1 1 158px",
  },

  [mq("tablet")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    "& > *": {
      flex: "1 1 255px",
    },
  },

  [mq("desktop")]: {
    gridTemplateAreas: galleryGrid(length),
    gridTemplateColumns: "auto",
    "& > *": {
      flex: "1 1 364px",
    },
  },
}));

export const GalleryOneImageItem = styled.div(({ noClick }) => ({
  cursor: noClick ? "default" : "pointer",

  "& img": {
    transformOrigin: "center",
  },

  "&:hover img": {
    transform: "scale(1.2)",
  },

  [mq("desktop")]: {
    "&:nth-of-type(1)": {
      gridArea: "a",
    },

    "&:nth-of-type(2)": {
      gridArea: "b",
    },

    "&:nth-of-type(3)": {
      gridArea: "c",
    },

    "&:nth-of-type(4)": {
      gridArea: "d",
    },

    "&:nth-of-type(5)": {
      gridArea: "e",
    },

    "&:nth-of-type(6)": {
      gridArea: "f",
    },
  },
}));

export const GalleryOneImagePreview = styled.div(() => ({
  width: "100%",
  minWidth: "50vw",

  [mq("desktop")]: {
    minHeight: "60vh",
    maxHeight: "80vh",
  },
}));
