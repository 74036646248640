import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { CasinoEvents, CasinoResponsibleGaming } from "@pages/casino";
import { ErrorManage } from "@screens/index";
import { useGetCasinoAboutInspire } from "@utils/api-requests/CasinoRequests";
import { CustomMetatag } from "@utils/metatags";

import CasinoIntro from "./CasinoIntro";
import CasinoGames from "./CasinoGames";

const CasinoAboutInspire = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: casinoAboutInspire,
    isFetching,
    isError,
    error,
  } = useGetCasinoAboutInspire();

  const aboutCasinoDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
        href: "/casino",
      },
      {
        id: 3,
        current: true,
        name: casinoAboutInspire?.field_page_name,
      },
    ];

    return roomDetails;
  }, [casinoAboutInspire?.field_page_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <CasinoIntro data={casinoAboutInspire?.field_casino_intro} />,
    },
    {
      id: 2,
      contents: <CasinoGames />,
    },
    {
      id: 3,
      contents: <CasinoEvents />,
    },
    { id: 4, contents: <CasinoResponsibleGaming /> },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={casinoAboutInspire} />

      <DetailPageHero data={casinoAboutInspire} />

      <CommonContainer>
        <>
          <NotificationToggle data={casinoAboutInspire} />

          <MuiBreadcrumbs
            breadcrumbArr={aboutCasinoDetailBC}
            pageTitle={casinoAboutInspire?.field_page_name}
            value={casinoAboutInspire?.field_full_description}
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={casinoAboutInspire} />

        <MomentumLayout data={casinoAboutInspire} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default CasinoAboutInspire;
