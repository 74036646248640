import React from "react";

import { CommonTitleOne } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";

import {DangerouslyHtml} from "@components/item";

import {
  LoginText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const ThirdPartyDown = () => {
  return (
    <React.Fragment>
      <CommonTitleOne>
        <DangerouslyHtml value="PAGE UNDER MAINTENANCE" />
      </CommonTitleOne>

      <div style={{textAlign: "center"}}>

        <p>
          <DangerouslyHtml
            value="We are currently enhancing our application. Please check back later."
          />
        </p>

        <p style={{marginTop: "1.6rem"}}>
          <DangerouslyHtml
            value="If you already have an account, please use the button below to access your benefits."
          />
        </p>

        <div style={{display: "flex", justifyContent: "center", marginTop: "3.2rem"}}>

          <PrimaryButton linkTo={`/momentum/sign-in`}>
            <LoginText />
          </PrimaryButton>
        </div>

      </div>
    </React.Fragment>
  );
};

export default ThirdPartyDown;
