import React, { useMemo, useState } from "react";
import he from "he";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import { TabButtonBlacked } from "@components/item/ItemStyling";
import { SliderEight } from "@components/sliders";
import { DangerouslyHtml } from "@components/item";
import {
  DetailPageContainerInformation,
  DetailPageDesc,
} from "@components/sub-page/SubPageStyling";
import {
  EntertainmentPerformancesContainer,
  EntertainmentPerformancesPage,
} from "@pages/entertainment/EntertainmentStyling";
import {
  useGetEntertainmentPerformances,
  useGetPerformanceCategoryList,
} from "@utils/api-requests/EntertainmentRequests";
import {
  FilterDetailPageData,
  concatAllValue,
} from "@utils/modify-data/modifyData";

const EntertainmentPerformances = ({ data }) => {
  const [filterType, setFilterType] = useState("all");

  const pageInfo = FilterDetailPageData(data?.field_detail_page_information);

  const { data: entertainmentPerformances } =
    useGetEntertainmentPerformances(filterType);
  const { data: performanceCategoryList } = useGetPerformanceCategoryList();

  const performanceCategories = useMemo(() => {
    const result = performanceCategoryList?.map((item) => {
      return {
        title: he.decode(item.name),
        category_id: item.tid,
      };
    });

    return result;
  }, [performanceCategoryList]);

  return (
    <EntertainmentPerformancesContainer>
      <DetailPageContainerInformation>
        <EntertainmentPerformancesPage>
          <DangerouslyHtml value={pageInfo?.field_title_2} />
        </EntertainmentPerformancesPage>

        <DetailPageDesc>
          <CommonDescOne>
            <DangerouslyHtml value={pageInfo?.field_description_2} />
          </CommonDescOne>
        </DetailPageDesc>
      </DetailPageContainerInformation>

      <ListWithTabs
        color={TabButtonBlacked}
        tabArr={concatAllValue(performanceCategories)}
        filterType={filterType}
        setFilterType={setFilterType}
      >
        <SliderEight data={entertainmentPerformances} />
      </ListWithTabs>
    </EntertainmentPerformancesContainer>
  );
};

export default EntertainmentPerformances;
