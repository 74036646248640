import React, { useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";

import {
  CommonModalTitleWrapper,
  MovableModalContainer,
  MovableModalTitle,
  StyeldDialog,
} from "./ModalStyling";

const MovableModal = ({
  noHeader,
  modalName,
  open,
  setOpen,
  children,
  modalRef,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.keyCode === 27) {
        // close modal when press Esc button.
        e.preventDefault();

        setOpen(false);
      }
    };

    document.addEventListener("keyup", escKeyModalClose);

    return () => document.removeEventListener("keyup", escKeyModalClose);
  }, [setOpen]);

  return (
    <StyeldDialog
      disablePortal
      open={open}
      hideBackdrop
      aria-labelledby={modalName}
      container={modalRef}
    >
      <MovableModalContainer>
        {!noHeader && (
          <CommonModalTitleWrapper>
            <MovableModalTitle id={modalName}>{modalName}</MovableModalTitle>

            <button type="button" onClick={handleClose}>
              <img
                src={image.modalCloseIcon.default}
                alt="Close button"
                width={isDesktop ? 40 : 32}
                height={isDesktop ? 40 : 32}
                loading="lazy"
              />
            </button>
          </CommonModalTitleWrapper>
        )}

        {children}
      </MovableModalContainer>
    </StyeldDialog>
  );
};

export default MovableModal;
