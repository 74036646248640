import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { FrameTwo } from "@components/frame";
import { useGetCasinoIntroCategory } from "@utils/api-requests/CasinoRequests";
import { CasinoText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const CasinoIntro = ({ data }) => {
  const [filterType, setFilterType] = useState("");
  const [currentData, setCurrentData] = useState("");

  const { data: casinoIntroCategory } = useGetCasinoIntroCategory();

  const casinoIntroCategories = useMemo(() => {
    const result = casinoIntroCategory?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [casinoIntroCategory]);

  useEffect(() => {
    setCurrentData(
      data?.filter((el) => el.field_casino_floor.id === filterType)[0]
    );
  }, [data, filterType]);

  useEffect(() => {
    setFilterType(casinoIntroCategory?.[0].tid);
  }, [casinoIntroCategory]);

  return (
    <ListWithTabs
      tabArr={casinoIntroCategories}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <FrameTwo
        subTitle={<CasinoText />}
        title={currentData?.field_name}
        desc={currentData?.field_full_description}
        image={currentData?.field_icon}
      />
    </ListWithTabs>
  );
};

export default CasinoIntro;
