import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  CommonDescOne,
  CommonDescTwo,
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleSeven,
  CommonTitleTwo,
  infoZIndex,
  overlayZIndex,
  sliderButtonZIndex,
} from "@assets/styles/CommonStyles";
import { SwiperArrowButton } from "@components/buttons";
import { mq, mqMax } from "@utils/media-query/mediaQuery";
import { BoxListSixItem } from "@components/box-list/BoxListStyling";
import {isChineseLanguage, isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";

// STYLE: SliderTemplate.jsx
export const SliderTemplateSlider = styled(Swiper)(({ length }) => ({
  paddingBottom: "0.8rem",

  [mq("desktop")]: {
    ".swiper-wrapper": {
      width: "100%",
      justifyContent: length >= 3 ? "flex-start" : "center",

      "& .swiper-slide:last-child": {
        marginRight: "0 !important",
      },
    },
  },
}));

// STYLE: SliderOne.jsx
export const SliderOneContainer = styled.div(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: "65rem",
  marginTop: "1.6rem",

  [mq("desktop")]: {
    position: "relative",
    width: "100%",
    height: "100%",
    maxWidth: "calc(100% - 3rem)",
    marginLeft: "auto",
    marginRight: "auto",
    "&:after": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "15rem",
      height: "100%",
      right: 0,
      top: 0,
      zIndex: 100,
      background:
        "linear-gradient(to left,rgba(255,255,255,100%), transparent);",
    },
  },
}));

export const SliderOneArrowWrapper = styled.div(({ noShow }) => ({
  display: noShow ? "none" : "flex",
  gap: "0 1rem",
  marginBottom: "2rem",
  justifyContent: "flex-end",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mqMax("mobileSmall")]: {
    justifyContent: "space-between",
    width: "100%",
    top: "34rem",
    pointerEvents: "none",

    "& button": {
      pointerEvents: "all",
    },
  },

  [mq("desktop")]: {
    display: noShow ? "none" : "flex",
    justifyContent: "space-between",
    position: "absolute",
    gap: "0 3rem",
    top: "35%",
    right: "-2.8rem",
    width: "calc(100% + 5.6rem)",
    marginBottom: 0,
    zIndex: 101,
  },
}));

export const SliderOnePreview = styled.div(({ theme }) => ({
  position: "absolute",
  left: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "2rem 0",
  width: "50%",
  maxHeight: "80%",
  height: "100%",
  padding: "5.6rem",
  background: theme.color.tertiary_color,
  color: "white",
  borderRadius: "2.5rem",
}));

export const SliderOneInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "80%",
  height: "100%",
  lineHeight: 1.2,
}));

export const SliderOneThumbnail = styled.div(() => ({
  position: "absolute",
  bottom: 0,
  right: "62%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "0 3rem",
  height: "6.6rem",
}));

export const SliderOneThumbnailFigure = styled.figure(() => ({
  position: "relative",
  width: "6.6rem",
  height: "6.6rem",
  aspectRatio: "1/ 1",
  borderRadius: "50%",
  overflow: "hidden",
  cursor: "pointer",
}));

export const SliderOneThumbnailImage = styled.img(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  inset: 0,
}));

export const SliderOneSliderWrapper = styled.div(() => ({
  position: "relative",

  "& .swiper-pagination": {
    top: "25rem",
    height: "fit-content",
  },

  [mq("desktop")]: {
    position: "absolute",
    right: 0,
    bottom: 0,
    width: "60%",
  },
}));

export const SliderOneSliderInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 0",
  justifyContent: "space-between",
  padding: "3.2rem",
  color: theme.color.white,
  background: theme.color.tertiary_color,
  borderRadius: "0 0 2.5rem 2.5rem",
  minHeight: "33rem",
}));

// STYLE: SliderTwo.jsx
export const SliderTwoWrapper = styled.div(({ direction, isSmall }) => ({
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",

  [(mq("desktop"), mq("tablet"))]: {
    borderRadius: "1.5rem",
    alignItems: "stretch",
    minHeight: isSmall ? "0" : "36rem",
    flexDirection: direction === "left" ? "row-reverse" : "row",
  },

  [mq("desktop")]: {
    minHeight: isSmall ? "0" : "64rem",
  },
}));

export const SliderTwoLeft = styled.div(() => ({
  width: "100%",

  [mq("tablet")]: {
    width: "40%",
  },

  [mq("desktop")]: {
    width: "60%",
  },
}));

export const SliderTwoRight = styled.div(({ theme, direction }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "3.2rem 1.6rem",
  background: theme.color.primary_color,
  borderRadius: 0,
  gap: "2.4rem 0",

  [mq("tablet")]: {
    width: "60%",
  },

  [mq("desktop")]: {
    width: "40%",
    gap: "8rem 0",
    padding: "7rem 5rem",
    borderRadius:
      direction === "left" ? "2.5rem 0 0 2.5rem" : "0 2.5rem 2.5rem 0",
  },
}));

export const SliderTwoInformation = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  color: theme.color.white,
  zIndex: 3,
  fontFamily: theme.fontFamily.secondaryFont,

  [(mq("desktop"), mq("tablet"))]: {
    gap: "3rem 0",
  },
}));

export const SliderTwoTitle = styled(CommonTitleOne)(() => ({
  minHeight: "6rem",

  [mq("desktop")]: {
    minHeight: "12rem",
  },
}));

export const SliderTwoDesc = styled(CommonDescOne)(() => ({
  minHeight: "10rem",

  [mq("desktop")]: {
    minHeight: "20rem",
  },
}));

export const SliderTwoSubTitle = styled(CommonDescTwo)(() => ({
  textTransform: "uppercase",
}));

export const SliderTwoHours = styled.div(() => ({
  display: "flex",
  gap: "0 1.6rem",
  alignItems: "flex-start",

  "& >img": {
    marginTop: "0.4rem",
  },
}));

export const SliderTwoButtonWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0 1rem",
}));

export const SliderTwoArrowWrapper = styled.div(({ noShow }) => ({
  display: noShow ? "none" : "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0 1rem",
}));

// STYLE: SliderThree.jsx
export const SliderThreeArrowWrapper = styled.div(() => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",

  [mq("desktop")]: {
    marginBottom: "7.2rem",
  },
}));

export const SliderThreeButtons = styled.div(() => ({
  display: "flex",
  gap: "0 1.6rem",
  justifyContent: "flex-end",
  marginBottom: "2rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mqMax("mobileSmall")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    pointerEvents: "none",

    "& > button": {
      pointerEvents: "all",
    },
  },

  [mq("desktop")]: {
    position: "absolute",
    top: 0,
    right: 30,
    marginTop: "-140px",
  },
}));

export const SliderThreeSubTitle = styled(CommonTitleSeven)(({ theme }) => ({
  textTransform: "uppercase",
  color: theme.color.pale_grey,
  letterSpacing: "0.2rem",
  margin: "2.8rem 0 1.4rem",

  [mq("desktop")]: {
    fontSize: "1.6rem",
  },
}));

// STYLE: SliderFour.jsx
export const SliderFourSwiper = styled(Swiper)(() => ({
  "& .swiper-slide": {
    width: "fit-content",
  },
}));

export const SliderFourContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 3rem",

  [mq("desktop")]: {
    minHeight: "40rem",
  },
}));

export const SliderFourSliderIn = styled.div(({ backgroundrgb }) => ({
  flex: 1,
  position: "relative",
  aspectRatio: "2 / 3",
  borderRadius: "3rem",
  overflow: "hidden",

  "figure:after, section:after": {
    background: `linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))`,
    content: '""',
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: "1",
  },

  // information in the slider
  ":hover article": {
    bottom: "7.2rem",
  },

  ":hover section": {
    background: `linear-gradient(to top, rgba(${backgroundrgb}, 1), rgba(0, 0, 0, 0))`,
  },

  [mq("desktop")]: {
    minWidth: "36rem",
    maxWidth: "48rem",
  },
}));

export const SliderFourCustomImageWrapper = {
  position: "absolute",
};

export const SliderFourCutomOverlay = styled.section(() => ({
  // WARNING: this must be section tag for hover effect
  position: "absolute",
  inset: 0,
  width: "100%",
  height: "100%",
  zIndex: overlayZIndex,
}));

export const SliderFourInformation = styled.article(({ theme }) => ({
  position: "absolute",
  left: "50%",
  bottom: "4.5rem",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "column",
  gap: "3rem 0",
  width: "90%",
  color: theme.color.white,
  transition: "all 0.3s",
  zIndex: infoZIndex,

  [mq("desktop")]: {
    gap: "2rem 0",
    width: "80%",
  },
}));

export const SliderFourButtonWrapper = styled.div(() => ({
  display: "flex",
  gap: "0 1.8rem",
}));

export const SliderFourArrowWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "0 1.6rem",
  marginBottom: "2rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mqMax("mobileSmall")]: {
    width: "100%",
    justifyContent: "space-between",

    "& button": {
      pointerEvents: "all",
    },
  },

  [mq("desktop")]: {
    width: "90%",
    position: "absolute",
    top: "40%",
    zIndex: infoZIndex,
  },
}));

export const SliderFourLeftArrow = styled(SwiperArrowButton)(() => ({
  [mq("desktop")]: {
    position: "absolute",
    left: "-4rem",
  },
}));

export const SliderFourRightArrow = styled(SwiperArrowButton)(() => ({
  [mq("desktop")]: {
    position: "absolute",
    right: "2rem",
  },
}));

export const SliderFourTitle = styled(CommonTitleTwo)(() => ({
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  whiteSpace: "break-spaces",

  [mq("desktop")]: {
    minHeight: "9rem",
    display: "flex",
    alignItems: "flex-end",
  },
}));

export const SliderFourSubTitle = styled(CommonTitleFour)(() => ({
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  whiteSpace: "break-spaces",
}));

export const SliderFourButtonCategory = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0 1.8rem",
  padding: "0 1.2rem",
  borderRadius: "1.5rem",
  background: theme.color.white,
  color: theme.color.base_black,
  fontFamily: theme.fontFamily.secondaryFont,
  lineHeight: 1,
}));

// STYLE: SliderFive.jsx
export const SliderFiveWrapper = styled.div(({ noMargin }) => ({
  position: noMargin ? "relative" : "static",
  top: "-8rem",
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "flex-end",
  justifyContent: "space-between",
  gap: "3rem 5rem",
  width: "100%",
  marginBottom: 0,

  [mq("tablet")]: {
    flexDirection: "column",
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const SliderFiveActiveItemWrapper = styled.div(() => ({
  position: "relative",
  aspectRatio: "1 / 1",
  width: "100%",

  [mq("desktop")]: {
    width: "45%",
  },
}));

export const SliderFiveInformation = styled.div(({ theme }) => ({
  position: "absolute",
  left: "2rem",
  bottom: "2rem",
  display: "flex",
  flexDirection: "column",
  gap: "1.8rem 0",
  width: "80%",
  color: "white",
  zIndex: 3,
  fontFamily: theme.fontFamily.secondaryFont,

  [mq("desktop")]: {
    left: "5rem",
    bottom: "5rem",
  },
}));

export const SliderFiveDesc = styled(CommonDescOne)(() => ({
  lineHeight: 1.2,
  minHeight: "2.4rem",
}));

export const SliderFiveSliderList = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  gap: "3rem 0",

  [mq("desktop")]: {
    maxWidth: "50%",
  },
}));

export const SliderFiveArrowWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "0 1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mq("desktop")]: {
    gap: "0 3rem",
  },
}));

export const SliderFiveSummary = styled(CommonTitleSeven)(({ theme }) => ({
  marginTop: "2.8rem",
  textTransform: "uppercase",
  letterSpacing: "0.2rem",
  color: theme.color.white,
  minHeight: "2.4rem",

  [mq("desktop")]: {
    fontSize: "1.6rem",
    color: theme.color.pale_grey,
  },
}));

export const SliderFiveInformationWrapper = styled.div(({ theme }) => ({
  position: "absolute",
  bottom: "1.6rem",
  left: "1.6rem",
  color: theme.color.white,
  zIndex: 2,
  maxWidth: "calc(100% - 3.2rem)",

  [mq("desktop")]: {
    position: "static",
    color: theme.color.base_black,
    maxWidth: "100%",
  },
}));

export const SliderFiveName = styled.header(({ theme }) => ({
  marginTop: "1.4rem",
  marginBottom: "2rem",
  fontSize: "3.2rem",
  fontWeight: theme.fontWeight.extraBold,

  [mq("desktop")]: {
    marginBottom: 0,
  },
}));

// STYLE: SliderSix.jsx
export const SliderSixContainer = styled.div(() => ({
  width: "100%",

  "* .swiper-pagination": {
    bottom: "3rem",
  },
}));

export const SliderSixSwiperWrapper = styled.div(({ show }) => ({
  display: show ? "block" : "none",
}));

export const SliderSixLeftArrow = {
  position: "absolute",
  left: "5rem",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
};

export const SliderSixRightArrow = {
  position: "absolute",
  right: "5rem",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
};

// STYLE: SliderEight.jsx
export const SliderEightContainer = styled.div(() => ({
  position: "relative",
}));

export const SliderEightArrowWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  top: "calc(50% - 4.8rem)",
  left: "-2.4rem",
  width: "calc(100% + 4.8rem)",
  zIndex: sliderButtonZIndex,

  [mq("desktop")]: {
    width: "calc(100% + 7.2rem)",
  },
}));

export const SliderEightItem = styled(BoxListSixItem)(() => ({
  marginBottom: "3rem",
}));

// STYLE: SliderNine.jsx
export const SliderNineSwiper = styled(Swiper)(() => ({
  marginTop:'2rem',

  [mq("tablet")]: {
    "& .swiper-wrapper": {
      height: "fit-content !important",
      alignItems: "stretch",
    },
  },

  [mq("desktop")]: {
    padding: "0 0.3rem 4rem",
    marginTop: 0,

    "& .swiper-wrapper": {
      height: "fit-content !important",
      alignItems: "stretch",
    },
  },
}));

export const SliderNineSwiperSlide = styled(SwiperSlide)(() => ({
  height: "auto",
}));

export const SliderNineItem = styled.div(({ theme }) => ({
  height: "fit-content",
  padding: "3.2rem 1rem",
  background: theme.color.white,
  boxShadow: theme.shadow.primary,
  borderRadius: "2.5rem",
  margin: "0 0.4rem 0.4rem",

  [mq("tablet")]: {
    height: "100%",
  },

  [mq("desktop")]: {
    height: "100%",
    padding: "3.2rem 2rem",
  },
}));

export const SliderNineTitle = styled(CommonTitleTwo)(({ theme }) => ({
  margin: "2.8rem 0 4rem",
  color: theme.color.primary_grey,
  textAlign: "center",
}));

export const SliderNineArrowWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mqMax("mobileSmall")]: {
    position: "relative",
  },

  [mq("desktop")]: {
    justifyContent: "flex-end",
    marginBottom: "3rem",
    position: "relative",
    zIndex: 200,
  },
}));

export const SliderDescription = styled.div(({ theme, design }) => ({
  lineHeight: isJapanese ? 1.5 : 1.4,
  fontFamily: theme.fontFamily.secondaryFont,
  fontWeight: theme.fontWeight.regular,
  fontSize: "1.6rem",
  overflowWrap: isJapanese || isChineseLanguage ? "anywhere" : "normal",
  marginTop: "4rem",

  [mq("desktop")]: {
    fontSize: "1.8rem",
  },
}));
