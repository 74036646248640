import React from "react";

import {
  DetailPageInformationBoxContainer,
  DetailPageInformationBoxInformation,
  DetailPageInformationBoxItem,
  DetailPageInformationBoxLogo,
  DetailPageInformationBoxRight,
} from "./SubPageStyling";

const DetailPageInformationBox = ({ children, info, data, noBox }) => {
  return (
    <DetailPageInformationBoxContainer noBox={noBox}>
      {children}

      {!noBox && (
        <DetailPageInformationBoxRight>
          <DetailPageInformationBoxInformation>
            {info.map((item) => {
              return (
                <DetailPageInformationBoxItem key={item.header}>
                  <header>{item.header}</header>

                  <p>{item.data} </p>
                </DetailPageInformationBoxItem>
              );
            })}
          </DetailPageInformationBoxInformation>

          <DetailPageInformationBoxLogo>
            {data?.field_logo && (
                <img
                  width="100%"
                  src={`${process.env.REACT_APP_SERVER_URL}${data?.field_logo[0]?.src}`}
                  alt={data?.field_logo[0]?.alt}
                />
            )}
          </DetailPageInformationBoxLogo>
        </DetailPageInformationBoxRight>
      )}
    </DetailPageInformationBoxContainer>
  );
};

export default DetailPageInformationBox;
