import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { SliderFive } from "@components/sliders";
import { useGetOfferRecommendation } from "@utils/api-requests/GlobalRequests";
import { ErrorManage } from "@screens/index";

const HighlightsSection = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [slideIndex, setSlideIndex] = useState(0);

  const {
    data: offerRecommendation,
    isLoading,
    isError,
    error,
  } = useGetOfferRecommendation(
    JSON.stringify(data?.field_highlights?.join())
      ?.replaceAll('"', " ")
      .replaceAll(" ", "")
  );

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <SliderFive
        noMargin={!isDesktop}
        data={offerRecommendation}
        nameKey="field_offer_name"
        supTitle={offerRecommendation?.[slideIndex]?.field_offer_type?.name}
        subTitle={offerRecommendation?.[slideIndex]?.field_offer_type?.name}
        slideIndex={slideIndex}
        imageKey="field_offer_thumbnail"
        setSlideIndex={setSlideIndex}
        desc={offerRecommendation?.[slideIndex]?.field_offer_short_desc}
        linkTo={`/offers/${offerRecommendation?.[slideIndex]?.uuid}`}
      />
    </ErrorManage>
  );
};

export default HighlightsSection;
