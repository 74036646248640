import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

import {SliderTwo} from "@components/sliders";
import {ListWithTabs} from "@components/list";
import {SecondaryButton} from "@components/buttons";
import {CommonDescktopContainer} from "@components/layout/ContainerStyle";
import {useGetMeetMeetingSuccess} from "@utils/api-requests/MeetRequests";
import {ViewDetailsText} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {checkIncludes, getSearchValue} from "@utils/modify-data/modifyData";
import {scrollToTargetAdjusted} from "@utils/scroll-behavior/ScrollIntoView";
import {SCROLL_INTO_VIEW_TIMER} from "@utils/api-requests/static-data";

import {MeetingSuccessContainer} from "./MeetStyling";

const MeetingSuccess = () => {
  const searchValue = getSearchValue(useLocation().search);

  const meetingSuccessRef = useRef();

  const [filterType, setFilterType] = useState("");

  const { data: inspireMeetingSuccess } = useGetMeetMeetingSuccess();


  const meetingCategory = useMemo(() => {
    return inspireMeetingSuccess
      ?.filter((el) => !el.field_meeting_category?.includes("Business"))
      .map((item) => {
        return {
          title: item.field_meeting_name,
          category_id: item.field_meeting_category,
        };
      });
  }, [inspireMeetingSuccess]);

  const currentData = useMemo(() => {
    return inspireMeetingSuccess?.filter((el) =>
      checkIncludes(el.field_meeting_category, filterType)
    )[0];
  }, [filterType, inspireMeetingSuccess]);

  useEffect(() => {
    const checkIncluding = meetingCategory?.map((element) => {
      if (checkIncludes(searchValue, element.title)) {
        return true;
      }

      return false;
    });

    const intervalId = setTimeout(() => {
      if (checkIncluding?.includes(true)) {
        scrollToTargetAdjusted(meetingSuccessRef);

        setFilterType(searchValue);
      } else {
        setFilterType(inspireMeetingSuccess?.[0]?.field_meeting_category);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [inspireMeetingSuccess, meetingCategory, searchValue]);

  return (
    <MeetingSuccessContainer ref={meetingSuccessRef}>
      <ListWithTabs
        tabArr={meetingCategory}
        filterType={filterType}
        setFilterType={setFilterType}
      >
        <CommonDescktopContainer>
          <SliderTwo
            className="ellipsis-6"
            direction="left"
            data={currentData}
            desc={currentData?.field_short_description}
            title={currentData?.field_meeting_name}
            button={
              <SecondaryButton
                buttonColor="white"
                noArrow
                linkTo={`/meet/meeting-success${currentData?.url_alias}`}
              >
                <ViewDetailsText />
              </SecondaryButton>
            }
          />
        </CommonDescktopContainer>
      </ListWithTabs>
    </MeetingSuccessContainer>
  );
};

export default MeetingSuccess;
