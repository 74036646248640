import React from "react";

import { ListOne } from "@components/layout";
import { useGetCasinoVerySpecial } from "@utils/api-requests/CasinoRequests";

const CasinoVerySpecial = () => {
  const { data: casinoVerySpecial } = useGetCasinoVerySpecial();

  return (
    <ListOne
      data={casinoVerySpecial?.field_special_features}
      titleKey="name"
      imageKey="thumbnail"
      detailButton={casinoVerySpecial?.field_button_name}
    />
  );
};

export default CasinoVerySpecial;
