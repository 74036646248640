import React from "react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { useGetArenaInquiryModal } from "@utils/api-requests/GlobalRequests";

import { CommonModal } from "..";
import {
  ModalBorderButton,
  RoomReservationModalContainer,
  RoomReservationModalRight,
} from "../ModalStyling";
import { ErrorManage } from "@screens/index";

const ArenaInquiryModal = ({ openModal, setOpenModal }) => {
  const { data: arenaInquiryModal, isError, error } = useGetArenaInquiryModal();

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        noName
        name="Arena Inquiry"
        description="Arena Inquiry"
        openModal={openModal}
        setOpenModal={setOpenModal}
        noPadding
        noHeader
        whiteClose={isDesktop ? false : true}
      >
        <RoomReservationModalContainer>
          <ImageWrapper ratio={isDesktop ? "1 / 1" : "2 / 1"}>
            <Image
              src={arenaInquiryModal?.field_thumbnail?.[0].src}
              alt={arenaInquiryModal?.field_thumbnail?.[0].alt}
            />
          </ImageWrapper>

          <RoomReservationModalRight>
            <CommonTitleOne>
              <DangerouslyHtml value={arenaInquiryModal?.field_modal_title} />
            </CommonTitleOne>

            <CommonDescTwo>
              <DangerouslyHtml
                value={arenaInquiryModal?.field_modal_description}
              />
            </CommonDescTwo>

            <ModalBorderButton
              href={`${arenaInquiryModal?.field_email_address}`}
              target="_blank"
            >
              {arenaInquiryModal?.field_button_name}

              <img src={image.linkArrowPurple.default} alt="arrow icon" />
            </ModalBorderButton>
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default ArenaInquiryModal;
