import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { FrameThree } from "@components/frame";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import { DangerouslyHtml } from "@components/item";
import {
  FacilityInformationText,
  OpenHoursText,
  OperationInformationCautionText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const AttractionVenueInformation = ({ data }) => {
  return (
    <FrameThree noImage>
      <li>
        <FrameThreeIn>
          <header>
            <OpenHoursText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_location_venue !== ""
                  ? data?.field_location_venue
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>

        <FrameThreeIn>
          <header>
            <FacilityInformationText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_entery_policy !== ""
                  ? data?.field_entery_policy
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>
      </li>

      <li>
        <FrameThreeIn>
          <header>
            <OperationInformationCautionText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_operational_hours !== ""
                  ? data?.field_operational_hours
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>
      </li>
    </FrameThree>
  );
};

export default AttractionVenueInformation;
