import React, { useCallback, useMemo, useState } from "react";
import { AccordionSummary } from "@mui/material";

import { image } from "src/theme";
import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { DangerouslyHtml, MuiPagination } from "@components/item";
import { ListWithTabs } from "@components/list";
import {
  CustomerCenterArrow,
  CustomerCenterLeft,
  CustomerCenterListAccordion,
  CustomerCenterListAccordionDetails,
  CustomerCenterListAccordionHeader,
  CustomerCenterListCounter,
  CustomerCenterTitle,
} from "@pages/about-us/AboutUsStyling";
import { ErrorManage } from "@screens/index";
import {
  useGetFAQCategoryList,
  useGetFAQList,
} from "@utils/api-requests/MeetRequests";
import { concatAllValue } from "@utils/modify-data/modifyData";

const FAQ = () => {
  const [filterType, setFilterType] = useState("all");
  const [expanded, setExpanded] = useState(false);

  const [page, setPage] = useState(1);

  const { data: FAQCategoryList } = useGetFAQCategoryList();

  const {
    data: FAQList,
    isLoading,
    isError,
    error,
  } = useGetFAQList(page, filterType);

  const faqCategory = useMemo(() => {
    const result = FAQCategoryList?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [FAQCategoryList]);

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const categoryChangeEvent = useCallback(() => {
    setPage(1);
  }, []);

  return (
    <>
      <ListWithTabs
        tabArr={concatAllValue(faqCategory)}
        filterType={filterType}
        setFilterType={setFilterType}
        changeEvent={categoryChangeEvent}
      >
        <ErrorManage isLoading={isLoading} isError={isError} error={error}>
          {FAQList?.map((item) => {
            return (
              <CustomerCenterListAccordion
                square
                key={item.field_faq_question}
                expanded={expanded === item.field_faq_question}
                onChange={handleChange(item.field_faq_question)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <CustomerCenterListAccordionHeader>
                    <CustomerCenterLeft>
                      <CustomerCenterListCounter
                        expanded={expanded === item.field_faq_question}
                      >
                        {item.counter}
                      </CustomerCenterListCounter>

                      <CustomerCenterTitle>
                        <span>
                          <DangerouslyHtml value={item.field_faq_category} />
                        </span>

                        <CommonDescTwo>
                          <DangerouslyHtml value={item.field_faq_question} />
                        </CommonDescTwo>
                      </CustomerCenterTitle>
                    </CustomerCenterLeft>

                    <CustomerCenterArrow
                      expanded={expanded === item.field_faq_question}
                      src={image.faqAngleDown.default}
                      alt="down arrow"
                      height={12}
                    />
                  </CustomerCenterListAccordionHeader>
                </AccordionSummary>

                <CustomerCenterListAccordionDetails>
                  <DangerouslyHtml value={item.field_faq_answer} />
                </CustomerCenterListAccordionDetails>
              </CustomerCenterListAccordion>
            );
          })}
        </ErrorManage>
      </ListWithTabs>

      <MuiPagination
        count={FAQList?.[0].total_pages}
        defaultPage={1}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default FAQ;
