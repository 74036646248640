import React, { useEffect, useMemo, useRef, useState } from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import MapWithPings from "@components/item/MapWithPings";
import {
  BoxListThreeBoldTitle,
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { FloorMapPlaceList } from "@pages/about-us/AboutUsStyling";
import { useGetAboutUsFloorMapCategory } from "@utils/api-requests/AboutUsRequests";
import { scrollToElement } from "@utils/scroll-behavior/ScrollIntoView";

const FloorMap = ({ data }) => {
  const places = useRef([]);

  const [filterType, setFilterType] = useState("");
  const [currentData, setCurrentData] = useState([]);
  const [currentMap, setCurrentMap] = useState([]);

  const { data: aboutUsFloorMapCategory } = useGetAboutUsFloorMapCategory();

  const floorMapCategory = useMemo(() => {
    const result = aboutUsFloorMapCategory?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [aboutUsFloorMapCategory]);

  useEffect(() => {
    setCurrentData(
      data?.field_map_in.filter(
        (el) => el.field_floor_category.id === filterType
      )
    );
  }, [data?.field_map_in, filterType]);

  useEffect(() => {
    setCurrentMap(
      data?.field_map.filter(
        (el) => el.field_floor_category.id === filterType
      )[0]?.field_info_picture?.[0]
    );
  }, [data?.field_map, filterType]);

  useEffect(() => {
    setFilterType(aboutUsFloorMapCategory?.[0].tid);
  }, [aboutUsFloorMapCategory]);

  return (
    <ListWithTabs
      tabArr={floorMapCategory}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <MapWithPings
        image={currentMap}
        filteredData={currentData}
        titleKey="field_info_title"
        imageKey="field_info_picture"
      />

      <FloorMapPlaceList>
        {currentData?.map((item, idx) => {
          return (
            <li
              key={item.field_info_title}
              onClick={() => {
                scrollToElement(places, idx);
              }}
            >
              {item.field_info_title}
            </li>
          );
        })}
      </FloorMapPlaceList>

      <div>
        <BoxListThreeContainer>
          {currentData?.map((feature, idx) => {
            return (
              <BoxListThreeWrapper
                key={feature.field_info_title}
                reverse={idx % 2 > 0}
                ref={(el) => (places.current[idx] = el)}
              >
                <ImageWrapper radius="2.5rem" ratio="3 / 2">
                  <Image
                    src={feature.field_info_picture[0]?.src}
                    alt={feature.field_info_picture[0]?.alt}
                  />
                </ImageWrapper>

                <BoxListThreeInformation>
                  <BoxListThreeBoldTitle>
                    {feature.field_info_title}
                  </BoxListThreeBoldTitle>

                  <div>
                    <CommonDescOne>
                      <DangerouslyHtml value={feature.field_info_description} />
                    </CommonDescOne>
                  </div>
                </BoxListThreeInformation>
              </BoxListThreeWrapper>
            );
          })}
        </BoxListThreeContainer>
      </div>
    </ListWithTabs>
  );
};

export default FloorMap;
