import React, { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Container } from "@mui/material";

import { SecondaryButton } from "@components/buttons";
import {
  replaceAmpersand,
  switchLanguageToFitBooking,
} from "@utils/modify-data/modifyData";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  BookYourInpireContainer,
  BookYourInpireInformation,
  BookYourInspireMenu,
} from "./ItemStyling";
import { DangerouslyHtml } from ".";

const BookYourInspire = ({
  children,
  subTitle,
  copywrite,
  buttonName,
  outerLink,
  data,
  noMargin,
  buttonEvent,
  langCode,
  noButton,
}) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const widgetData = useMemo(() => {
    if (
      data?.field_widget?.[0]?.field_title &&
      data?.field_widget?.[0]?.field_description
    ) {
      return data?.field_widget;
    }

    return undefined;
  }, [data]);

  return (
    widgetData &&
    (copywrite || data?.field_widget.length > 0) && (
      <Container maxWidth="lg" disableGutters>
        <BookYourInpireContainer noMargin={noMargin}>
          <BookYourInpireInformation>
            <BookYourInspireMenu>
              {data ? widgetData?.[0]?.field_title : subTitle}
            </BookYourInspireMenu>

            <DangerouslyHtml
              value={data ? widgetData?.[0]?.field_description : copywrite}
            />
          </BookYourInpireInformation>

          {children && children}

          {!noButton && (buttonName || widgetData?.[0]?.field_link.text) && (
            <SecondaryButton
              buttonColor="white"
              customstyle={{ maxWidth: !isDesktop ? "100%" : "fit-content" }}
              buttonEvent={buttonEvent}
              outerLink={
                langCode
                  ? `${replaceAmpersand(widgetData?.[0]?.field_link.uri)}${
                      replaceAmpersand(
                        widgetData?.[0]?.field_link.uri
                      ).includes("?")
                        ? "&"
                        : "?"
                    }langCode=${switchLanguageToFitBooking(lang)}`
                  : widgetData
                  ? replaceAmpersand(widgetData?.[0]?.field_link.uri)
                  : replaceAmpersand(outerLink)
              }
              noArrow
            >
              {buttonName ? (
                <DangerouslyHtml value={buttonName} />
              ) : data ? (
                <DangerouslyHtml value={widgetData?.[0]?.field_link.text} />
              ) : null}
            </SecondaryButton>
          )}
        </BookYourInpireContainer>
      </Container>
    )
  );
};

export default BookYourInspire;
