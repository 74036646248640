import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import { Container } from "@mui/material";

import { mq } from "@utils/media-query/mediaQuery";

import { FullContainer } from "./LayoutStyling";

export const CommonContainer = ({ noPadding, children, custom, noMargin }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <Container
      maxWidth={noMargin && !isDesktop ? false : isDesktop ? "xl" : "lg"}
      disableGutters={noPadding}
      sx={{
        position: "relative",
        ...custom,
      }}
    >
      {children}
    </Container>
  );
};

export const CommonFullContainer = ({ children, noBlur, noPadding }) => {
  return (
    <FullContainer noBlur={noBlur} noPadding={noPadding}>
      {children}
    </FullContainer>
  );
};

export const CommonContentButtonWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "3.2rem 0",
}));

export const CommonMobileContainer = ({ noPadding, children, custom }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <Container
      disableGutters={noPadding || isDesktop}
      maxWidth={isDesktop ? false : "lg"}
      sx={{
        position: "relative",
        ...custom,
      }}
    >
      {children}
    </Container>
  );
};

export const CommonDescktopContainer = ({ noPadding, children, custom }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Container
      disableGutters={noPadding || isMobile}
      maxWidth={!isMobile ? "xl" : false}
      sx={{
        position: "relative",
        ...custom,
      }}
    >
      {children}
    </Container>
  );
};

export const CommonOnlyDesktopContainer = ({ noPadding, children, custom }) => {
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  return (
    <Container
      disableGutters={noPadding || isDefault}
      maxWidth={!isDefault ? "xl" : false}
      sx={{
        position: "relative",
        ...custom,
      }}
    >
      {children}
    </Container>
  );
};

// STYLE: MomentumLayout.jsx
export const MomentumLayoutInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  [mq("desktop")]: {
    textAlign: "left",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));

export const MomentumLayoutButtonWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [mq("desktop")]: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));
