import React from "react";

import { CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { useGetRoomReservationModal } from "@utils/api-requests/GlobalRequests";
import { IsDesktop } from "@utils/media-query/mediaQuery";

import { CommonModal } from "..";
import {
  RoomReservationModalContainer,
  RoomReservationModalRight,
} from "../ModalStyling";
import { BookingModal } from ".";
import { ErrorManage } from "@screens/index";

const RoomReservationModal = ({ openModal, setOpenModal }) => {
  const {
    data: roomReservationModal,
    isError,
    error,
  } = useGetRoomReservationModal();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        modalName={roomReservationModal?.field_modal_title}
        openModal={openModal}
        setOpenModal={setOpenModal}
        noPadding
        noHeader
      >
        <RoomReservationModalContainer>
          <IsDesktop>
            <ImageWrapper ratio="1 / 1" height="auto">
              <Image
                src={roomReservationModal?.field_image[0]?.src}
                alt={roomReservationModal?.field_image[0]?.alt}
              />
            </ImageWrapper>
          </IsDesktop>

          <RoomReservationModalRight>
            <CommonTitleOne>
              <DangerouslyHtml
                value={roomReservationModal?.field_modal_title}
              />
            </CommonTitleOne>

            {/* <CommonDescTwo>
              <DangerouslyHtml
                value={roomReservationModal?.field_modal_description}
              />
            </CommonDescTwo> */}

            {/* <ModalBorderButton
              href={`mailto:${roomReservationModal?.field_email_address}`}
            >
              {roomReservationModal?.field_button_name}
  
              <img src={image.linkArrowPurple.default} alt="arrow icon" />
            </ModalBorderButton> */}

            <BookingModal />
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default RoomReservationModal;
