import React from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetAboutUsGettingHere } from "@utils/api-requests/AboutUsRequests";

import {
  AboutUsGettingHereContainer,
  AboutUsGettingHereInformation,
  AboutUsGettingHereWrapper,
} from "./AboutUsStyling";
import { ErrorManage } from "@screens/index";

const AboutUsGettingHere = () => {
  const {
    data: aboutUsGettingHere,
    isError,
    error,
  } = useGetAboutUsGettingHere();

  return (
    <ErrorManage isError={isError} error={error}>
      <AboutUsGettingHereContainer>
        <ImageWrapper ratio="7 / 6" radius="2.5rem">
          <Image
            src={aboutUsGettingHere?.field_thumbnail[0]?.src}
            alt={aboutUsGettingHere?.field_thumbnail[0]?.alt}
          />
        </ImageWrapper>

        <AboutUsGettingHereWrapper>
          <AboutUsGettingHereInformation>
            <div>
              <DangerouslyHtml
                value={aboutUsGettingHere?.field_rm_short_description}
              />
            </div>

            <CommonDescOne>
              <DangerouslyHtml
                value={aboutUsGettingHere?.field_offer_full_description}
              />
            </CommonDescOne>
          </AboutUsGettingHereInformation>

          <PrimaryButton linkTo="/about-us/getting-here" noArrow>
            <ViewDetailsText />
          </PrimaryButton>
        </AboutUsGettingHereWrapper>
      </AboutUsGettingHereContainer>
    </ErrorManage>
  );
};

export default AboutUsGettingHere;
