import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { SecondaryButton } from "@components/buttons";
import {
  InquiryInformationContainer,
  InquiryInformationItem,
  InquiryInformationWrapper,
} from "@pages/meet/MeetStyling";
import { BookYourMeetingText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useMediaQuery } from "react-responsive";
import { DangerouslyHtml } from "@components/item";
import { replaceAmpersand } from "@utils/modify-data/modifyData";

const InquiryInformation = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    data?.field_inquire_modes.length > 0 && (
      <InquiryInformationContainer>
        {data?.field_inquire_modes?.map((item) => {
          return (
            <InquiryInformationItem key={item?.name}>
              <ImageWrapper
                width={isDesktop ? "7.2rem" : "3.2rem"}
                height={isDesktop ? "7.8rem" : "3.2rem"}
              >
                <Image
                  src={item?.imageslist?.src}
                  alt={item?.imageslist?.alt}
                />
              </ImageWrapper>

              <InquiryInformationWrapper>
                <span>
                  <DangerouslyHtml value={item?.name} />
                </span>

                <div>
                  <DangerouslyHtml value={item?.description} />
                </div>
              </InquiryInformationWrapper>
            </InquiryInformationItem>
          );
        })}

        {
          <SecondaryButton
            buttonColor="white"
            outerLink={replaceAmpersand(data?.field_book_your_meeting_link)}
          >
            <BookYourMeetingText />
          </SecondaryButton>
        }
      </InquiryInformationContainer>
    )
  );
};

export default InquiryInformation;
