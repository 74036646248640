import React from "react";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { StyledSelectInput, StyledSelectInputContainer } from "./InputsStyling";

const SelectInput = (props) => {
  return (
    <StyledSelectInputContainer>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => {
          return (
            <FormControl error={!!props.errors[props.name]}>
              <InputLabel id={props.name}>{props.label}</InputLabel>

              <StyledSelectInput
                {...field}
                label={props.label}
                placeholder={props.placeholder}
              >
                {props.arr.map((el) => {
                  return (
                    <MenuItem
                      key={props.country ? el : el.value}
                      value={props.country ? el : el.value}
                    >
                      {props.country ? el : el.text}
                    </MenuItem>
                  );
                })}
              </StyledSelectInput>

              {props.errors[props.name] && (
                <FormHelperText>
                  {props.errors[props.name].message}
                </FormHelperText>
              )}
            </FormControl>
          );
        }}
      />
    </StyledSelectInputContainer>
  );
};

export default SelectInput;
