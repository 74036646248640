import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  AgilisysSignUpModalUrl,
  momentumCategoryListUrl,
  momentumIntroductionUrl,
  momentumLifestyleEventsDetailUrl,
  momentumLifestyleEventsUrl,
  momentumOfferRecommendationUrl,
  momentumTermsConditionsUrl,
  momentumTiersBenefitsUrl,
  myProfileUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Momentum.jsx
export function useGetMomentumOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["momentumOfferRecommendation", language, localStorage],
    getMomentumOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// Momentum.jsx
export function useGetMomentumIntroduction() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumIntroduction = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumIntroductionUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["momentumIntroduction", language, localStorage],
    getMomentumIntroduction,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MomentumTiers.jsx
export function useGetMomentumTiersBenefits() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumTiersBenefits = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumTiersBenefitsUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["momentumTiersBenefits", language, localStorage],
    getMomentumTiersBenefits,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MomentumTiers.jsx
export function useGetMomentumCategoryList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumCategoryListUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["momentumCategoryList", language, localStorage],
    getMomentumCategoryList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MomentumLifestyleList.jsx
export function useGetMomentumLifestyleEvents() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumLifestyleEvents = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumLifestyleEventsUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["momentumLifestyleEvents", language, localStorage],
    getMomentumLifestyleEvents,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MomentumLifestyleItemList.jsx
export function useGetMomentumLifestyleEventsItems() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumLifestyleEventsItems = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumLifestyleEventsDetailUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["momentumLifestyleEventsDetail", language, localStorage],
    getMomentumLifestyleEventsItems,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MomentumLifestyleDetail.jsx
export function useGetMomentumLifestyleEventsDetail(uuid) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumLifestyleEventsDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumLifestyleEventsDetailUrl}?language=${language}&uuid=${uuid}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, uuid]);

  return useQuery(
    ["momentumLifestyleEventsDetail", language, localStorage, uuid],
    getMomentumLifestyleEventsDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// AgilisysSignUp.jsx
export function useGetAgilisysSignUp() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAgilisysSignUp = useCallback(async () => {
    const { status, data } = await axios.get(
      `${AgilisysSignUpModalUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["AgilisysSignUp", language], getAgilisysSignUp, {
    enabled: !!language,
  });
}

// MyProfile.jsx
export function useGetMyProfile() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMyProfile = useCallback(async () => {
    const { status, data } = await axios.get(
      `${myProfileUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["myProfile", language], getMyProfile, {
    enabled: !!language,
  });
}

export function useGetMomentumTermsConditions() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMomentumTermsConditions = useCallback(async () => {
    const { status, data } = await axios.get(
      `${momentumTermsConditionsUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["momentumTermsConditions", language, localStorage],
    getMomentumTermsConditions,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}
