import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { ListWithTabs } from "@components/list";
import { MuiPagination } from "@components/item";
import { BoxListFour } from "@components/box-list";
import { ErrorManage } from "@screens/index";
import {
  useGetOfferCategoryList,
  useGetOfferList,
} from "@utils/api-requests/OfferRequests";
import { concatAllValue, getSearchValue } from "@utils/modify-data/modifyData";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";

import { OfferEmptyBox } from ".";

const OfferList = () => {
  const [page, setPage] = useState(1);

  const currentSearch = new URLSearchParams(useLocation().search).get('category');

  // Reconstruct the query string with only the desired parameter
  const search = currentSearch ? `?category=${currentSearch}` : '';

  const offerListRef = useRef();

  const [filterType, setFilterType] = useState("all");

  const {
    data: offerList,
    isFetching,
    isError,
    error,
  } = useGetOfferList(filterType, page, search);

  const {
    data: offerCategoryList,
    isLoading: categoryIsLoading,
    isError: categoryIsError,
    error: categoryError,
  } = useGetOfferCategoryList();

  useEffect(() => {
    if (search !== "") {
      setFilterType(getSearchValue(search));

      if (offerListRef.current) {
        scrollToTargetAdjusted(offerListRef);
      }
    }
  }, [search]);

  const onTabChange = useCallback(() => {
    setPage(1);
  }, []);

  return (
    <ErrorManage isFetching={isFetching} isError={isError} error={error}>
      <div ref={offerListRef}>
        <ListWithTabs
          tabArr={concatAllValue(offerCategoryList)}
          filterType={filterType}
          setFilterType={setFilterType}
          isLoading={categoryIsLoading}
          isError={categoryIsError}
          error={categoryError}
          changeEvent={onTabChange}
        >
          {offerList?.length === 0 ? (
            <OfferEmptyBox />
          ) : (
            <BoxListFour
              data={offerList}
              typeKey="field_offer_type"
              offer
              dateKey="field_applicable_end_date"
            />
          )}
        </ListWithTabs>
      </div>

      {offerList?.length > 0 && (
        <MuiPagination
          listRef={offerListRef}
          count={offerList?.[0]?.pager?.total_pages}
          defaultPage={1}
          page={page}
          setPage={setPage}
        />
      )}
    </ErrorManage>
  );
};

export default OfferList;
