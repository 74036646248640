import { CommonDescOne, infoZIndex } from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: Meet.jsx
export const VideoPlayButton = styled.img(() => ({
  position: "absolute",
  zIndex: infoZIndex,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "6rem",

  [mq("tablet")]: {
    width: "9rem",
  },

  [mq("desktop")]: {
    width: "15rem",
  },
}));

// STYLE: StoreImageSlider
export const StoreImageSliderContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "4rem 0",
}));

export const StoreImageSliderDesc = styled(CommonDescOne)(() => ({
  maxWidth: "80%",
  textAlign: "center",
}));
