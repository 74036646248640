import React from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  OurCompanyContainer,
  OurCompanyDescOne,
  OurCompanyInformation,
} from "@pages/about-us/AboutUsStyling";

const OurCompany = ({ data }) => {
  return (
    <OurCompanyContainer>
      <OurCompanyDescOne>
        <DangerouslyHtml value={data?.field_our_company_description_1} />
      </OurCompanyDescOne>

      <OurCompanyInformation>
        <ImageWrapper ratio="auto" height="auto" radius="2.5rem">
          <Image
            src={data?.field_logo[0]?.src}
            alt={data?.field_logo[0]?.alt}
          />
        </ImageWrapper>

        <CommonDescOne>
          <DangerouslyHtml value={data?.field_our_company_description_2} />
        </CommonDescOne>
      </OurCompanyInformation>
    </OurCompanyContainer>
  );
};

export default OurCompany;
