import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";

const WeddingThemes = ({ data }) => {
  return (
    data?.length > 0 && (
      <BoxListThreeContainer>
        {data?.map((feature, idx) => {
          return (
            <BoxListThreeWrapper key={feature.name} reverse={idx % 2 > 0}>
              <ImageWrapper radius="2.5rem" ratio="3 / 2">
                <Image
                  src={feature?.thumbnail?.src}
                  alt={feature?.thumbnail?.alt}
                />
              </ImageWrapper>

              <BoxListThreeInformation>
                <CommonTitleOne>
                  <DangerouslyHtml value={feature.name} />
                </CommonTitleOne>

                <CommonDescTwo>
                  <DangerouslyHtml value={feature.description} />
                </CommonDescTwo>
              </BoxListThreeInformation>
            </BoxListThreeWrapper>
          );
        })}
      </BoxListThreeContainer>
    )
  );
};

export default WeddingThemes;
