import React, { memo, useMemo } from 'react';

import { PageContainer, SubpageHero } from '@components/sub-page';
import {
  CommonContainer,
  CommonDescktopContainer,
} from '@components/layout/ContainerStyle';
import {ListOne, MomentumLayout, PageBottomContents} from '@components/layout';
import { OfferRecommendation } from '@components/item';
import { CustomMetatag } from '@utils/metatags';
import {
  useGetMomentumIntroduction,
  useGetMomentumOfferRecommendation,
  useGetMomentumTiersBenefits,
} from '@utils/api-requests/MomentumRequests';

import { MomentumNewMember, MomentumTiers } from '.';

const Momentum = ({ pageInfo }) => {
  const { data: momentumOfferRecommendation } =
    useGetMomentumOfferRecommendation();

  const { data: momentumIntroduction } = useGetMomentumIntroduction();
  const { data: momentumTiersBenefits } = useGetMomentumTiersBenefits();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <CommonContainer>
          {/*<MomentumLifestyle data={momentumIntroduction} />*/}
          <ListOne
            data={momentumIntroduction?.field_special_features}
            titleKey="name"
            imageKey="thumbnail"
          />
        </CommonContainer>
      ),
    },
    {
      id: 2,
      contents: (
        <CommonDescktopContainer>
          <MomentumTiers data={momentumTiersBenefits?.field_tier_information} />
        </CommonDescktopContainer>
      ),
    },
    {
      id: 3,
      contents: (
        <CommonContainer>
          <MomentumNewMember data={momentumIntroduction} />
        </CommonContainer>
      ),
    },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={momentumOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </div>
  );
};

export default memo(Momentum);
