import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { SliderTwo } from "@components/sliders";
import { SecondaryButton } from "@components/buttons";
import { useGetMomentumCategoryList } from "@utils/api-requests/MomentumRequests";
import {
  MomentumLifestyleText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const MomentumTiers = ({ data }) => {
  const [filterType, setFilterType] = useState("");
  const [currentData, setCurrentData] = useState();

  const { data: momentumCategoryList } = useGetMomentumCategoryList();

  const momentumCategories = useMemo(() => {
    const result = momentumCategoryList?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [momentumCategoryList]);

  useEffect(() => {
    setFilterType(momentumCategoryList?.[0].tid);
  }, [momentumCategoryList]);

  useEffect(() => {
    setCurrentData(
      data?.filter((el) => el.field_notification_contents.id === filterType)[0]
    );
  }, [data, filterType]);

  return (
    <ListWithTabs
      tabArr={momentumCategories}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <SliderTwo
        direction="left"
        data={currentData}
        subTitle={<MomentumLifestyleText />}
        title={currentData?.field_name}
        button={
          <SecondaryButton
            buttonColor="white"
            noArrow
            linkTo="/momentum/tiers-benefits"
          >
            <ViewDetailsText />
          </SecondaryButton>
        }
      />
    </ListWithTabs>
  );
};

export default MomentumTiers;
