import React from "react";

import { MembershipTierStandard } from "@pages/momentum/detail";
import { CommonContainer } from "@components/layout/ContainerStyle";

const CasinoTiersStandard = ({ data }) => {
  return (
    <CommonContainer>
      <MembershipTierStandard data={data} />
    </CommonContainer>
  );
};

export default CasinoTiersStandard;
