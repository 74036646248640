export const aboutUsRouteList = [
  "/inspire-resort",
  "/careers",
  "/media-center",
  "/customer-center",
  "/getting-here",
  "/mohegan-resort",
  "/privacy-policy",
  "/terms-conditions",
  // "/health-safety",
  "/floor-guide",
  "/gallery",
  "/sitemap",
];
