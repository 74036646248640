import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  myPageUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Momentum.jsx
export function useGetBasicPage(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const useGetBasicPage = useCallback(async () => {
    const { status, data } = await axios.get(
      `${myPageUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language,alias]);

  return useQuery(
    ["basicPageDetails", language, localStorage,alias],
    useGetBasicPage,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}


