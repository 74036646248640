import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { FrameThree } from "@components/frame";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import {
  FacilityInformationText,
  OpenHoursText,
  OperationInformationCautionText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { DangerouslyHtml } from "@components/item";

const AmenityInformation = ({ data }) => {
  return (
    <FrameThree data={data}>
      <li>
        <FrameThreeIn>
          <header>
            <OpenHoursText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_operation_hours !== ""
                  ? data?.field_operation_hours
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>

        <FrameThreeIn>
          <header>
            <FacilityInformationText />
          </header>

          <CommonDescTwo>
            {data?.field_entry_policy !== "" ? (
              <DangerouslyHtml value={data?.field_entry_policy} />
            ) : (
              "-"
            )}
          </CommonDescTwo>
        </FrameThreeIn>
      </li>

      <li>
        <FrameThreeIn>
          <header>
            <OperationInformationCautionText />
          </header>

          <CommonDescTwo>
            {data?.field_amenity_location !== "" ? (
              <DangerouslyHtml value={data?.field_amenity_location} />
            ) : (
              "-"
            )}
          </CommonDescTwo>
        </FrameThreeIn>
      </li>
    </FrameThree>
  );
};

export default AmenityInformation;
