import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MonthlyCalendar } from "@components/calendar";
import { SliderSix } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import {
  useGetSplashBayServiceDetail,
  useGetSplashServiceAliasGroup,
} from "@utils/api-requests/SplashBayRequests";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { CustomMetatag } from "@utils/metatags";

import { SplashBayServiceInfomation, SplashBayServicesList } from ".";

const SplashBayServicesDetail = () => {
  const intl = useIntl();
  const alias = useParams().serviceAlias;

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: splashServiceDetail,
    isFetching,
    isError,
    error,
  } = useGetSplashBayServiceDetail(alias);

  const { data: splashServiceAliasGroup } = useGetSplashServiceAliasGroup();

  const serviceDetailBC = useMemo(() => {
    const serviceDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-splash-bay",
        }),
        href: "/splash-bay",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-services",
        }),
        href: "/splash-bay",
        tab: "services",
      },
      {
        id: 4,
        current: true,
        name: splashServiceDetail?.field_service_name,
      },
    ];

    return serviceDetails;
  }, [intl, splashServiceDetail?.field_service_name]);

  useEffect(() => {
    if (splashServiceAliasGroup) {
      setCurrentIndex(
        splashServiceAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [splashServiceAliasGroup, alias]);

  const secondRender = useMemo(() => {
    if (splashServiceDetail?.field_show_activity_schedule === "On") {
      return (
        <MonthlyCalendar
          data={splashServiceDetail?.field_activity_schedule_calendar}
        />
      );
    }

    if (splashServiceDetail?.field_service_list?.length > 0) {
      return (
        <SplashBayServicesList data={splashServiceDetail?.field_service_list} />
      );
    }

    return null;
  }, [
    splashServiceDetail?.field_activity_schedule_calendar,
    splashServiceDetail?.field_service_list,
    splashServiceDetail?.field_show_activity_schedule,
  ]);

  const sectionArray = [
    {
      id: 1,
      disabled:
        splashServiceDetail?.field_multiple_images === "" ||
        !splashServiceDetail?.field_multiple_images,
      contents: (
        <SliderSix imageArr={splashServiceDetail?.field_multiple_images} />
      ),
    },
    {
      id: 2,
      desc: splashServiceDetail?.field_service_list_description,
      disabled:
        splashServiceDetail?.field_service_list?.length <= 0 ||
        splashServiceDetail?.field_show_activity_schedule !== "On",
      contents: secondRender,
    },
    {
      id: 3,
      disabled:
        splashServiceDetail?.field_show_activity_schedule !== "On" &&
        splashServiceDetail?.field_splash_bay_service_categor ===
          "Pool Activities",
      contents: (
        <SplashBayServicesList data={splashServiceDetail?.field_service_list} />
      ),
    },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={splashServiceDetail} />

      <DetailPageHero data={splashServiceDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={splashServiceDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={serviceDetailBC}
            pageTitle={splashServiceDetail?.field_service_name}
            value={splashServiceDetail?.field_full_description}
            prevUUid={splashServiceAliasGroup?.[currentIndex - 1]}
            nextUUid={splashServiceAliasGroup?.[currentIndex + 1]}
            url={`/splash-bay/services`}
          />
        </>

        {splashServiceDetail?.field_show_information === "On" && (
          <SplashBayServiceInfomation data={splashServiceDetail} />
        )}

        <DetailPageContainer
          data={FilterDetailPageData(
            splashServiceDetail?.field_splash_bay_service_categor
          )}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={splashServiceDetail} />

        <MomentumLayout data={splashServiceDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default SplashBayServicesDetail;
