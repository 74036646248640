import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { CommonDescTwo, CommonTitleTwo } from "@assets/styles/CommonStyles";
import { GradientOverlay, NormalOverlay } from "@components/item/Overlays";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { SecondaryButton } from "@components/buttons";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/index";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  BoxListFiveContainer,
  BoxListFiveGridArea,
  BoxListFiveInformation,
} from "./BoxListStyling";
import { DangerouslyHtml } from "@components/item";

export const BoxListFiveDetailButton = ({ onHover, idx, item, url }) => {
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  return (
    <SecondaryButton
      customstyle={{
        display: onHover === idx || isDefault ? "flex" : "none",
        opacity: onHover === idx || isDefault ? 1 : 0,
        width: "100%",
      }}
      buttonColor="white"
      linkTo={`${url}${item.url_alias}`}
    >
      <ViewDetailsText />
    </SecondaryButton>
  );
};

export const BoxListFiveOverlays = ({ onHover, idx }) => {
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  return (
    <>
      <NormalOverlay
        rgb="104, 80, 124"
        opacity={isDefault ? 0 : onHover === idx ? 0.6 : 0}
      />

      <GradientOverlay
        opacity={isDefault ? 0.85 : onHover === idx ? 0 : 0.6}
        direction="to top"
      />
    </>
  );
};

const BoxListFive = ({ data, isLoading, isError, error, imageSrc }) => {
  const [onHover, setOnHover] = useState(-1);

  const onMouseEnter = (i) => {
    setOnHover(i);
  };

  const onMouseLeave = () => {
    setOnHover(-1);
  };

  return (
    data?.length > 0 && (
      <CommonContainer>
        <BoxListFiveContainer>
          <ErrorManage isLoading={isLoading} isError={isError} error={error}>
            {data?.map((item, idx) => {
              return (
                <BoxListFiveGridArea
                  key={item.uuid}
                  onMouseEnter={() => onMouseEnter(idx)}
                  onMouseLeave={onMouseLeave}
                >
                  <ImageWrapper
                    radius="1.5rem"
                    ratio={idx === 0 || idx === 1 ? "3 / 2" : "1 / 1"}
                  >
                    <Image
                      src={
                        imageSrc
                          ? item[imageSrc]
                          : item?.field_thumbnail[0]?.src
                      }
                      alt={item?.field_thumbnail[0]?.alt}
                    />

                    <BoxListFiveInformation>
                      <CommonTitleTwo>
                        <DangerouslyHtml value={item.field_amenity_name} />
                      </CommonTitleTwo>

                      <CommonDescTwo className="ellipsis-3">
                        <DangerouslyHtml
                          value={item.field_amenity_short_description}
                        />
                      </CommonDescTwo>

                      <SecondaryButton
                        customstyle={{
                          display: onHover === idx ? "flex" : "none",
                          opacity: onHover === idx ? 1 : 0,
                        }}
                        buttonColor="white"
                        linkTo={`/stay/amenities${item.url_alias}`}
                      >
                        <ViewDetailsText />
                      </SecondaryButton>
                    </BoxListFiveInformation>

                    <NormalOverlay
                      rgb="104, 80, 124"
                      opacity={onHover === idx ? 0.6 : 0}
                    />

                    <GradientOverlay
                      opacity={onHover === idx ? 0 : 0.6}
                      direction="to top"
                    />
                  </ImageWrapper>
                </BoxListFiveGridArea>
              );
            })}
          </ErrorManage>
        </BoxListFiveContainer>
      </CommonContainer>
    )
  );
};

export default BoxListFive;
