import React, { useCallback } from "react";

import { PrimaryButton } from "@components/buttons";
import {
  ForgotPasswordFormDesc,
  ForgotPasswordModalForm,
} from "@components/modal/ModalStyling";
import { OkText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { PasswordResetSuccessText } from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

const ResetPasswordSuccessModal = ({ setOpenModal, setPasswordStep }) => {
  const closeModal = useCallback(() => {
    setOpenModal(false);
    setPasswordStep(0);
  }, [setOpenModal, setPasswordStep]);

  return (
    <ForgotPasswordModalForm>
      <ForgotPasswordFormDesc>
        {<PasswordResetSuccessText />}
      </ForgotPasswordFormDesc>

      <PrimaryButton buttonEvent={closeModal}>
        <OkText />
      </PrimaryButton>
    </ForgotPasswordModalForm>
  );
};

export default ResetPasswordSuccessModal;
