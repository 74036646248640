import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { useGetSplashBayDomeList } from "@utils/api-requests/SplashBayRequests";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const SplashBayDome = () => {
  const search = useLocation().search;

  const splashBayDomeRef = useRef();

  const { data: splashBayDomeList } = useGetSplashBayDomeList();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "facilities") {
        scrollToTargetAdjusted(splashBayDomeRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  return (
    <div ref={splashBayDomeRef}>
      <CommonContainer>
        <CommonContentButtonWrapper>
          <BoxListThreeContainer>
            {splashBayDomeList?.field_splash_bay_pool_dome?.map(
              (feature, idx) => {
                return (
                  <BoxListThreeWrapper key={feature.name} reverse={idx % 2 > 0}>
                    <ImageWrapper radius="2.5rem" ratio="3 / 2">
                      <Image
                        src={feature.thumbnail?.src}
                        alt={feature.thumbnail?.alt}
                      />
                    </ImageWrapper>

                    <BoxListThreeInformation>
                      <CommonTitleOne>
                        <DangerouslyHtml value={feature.name} />
                      </CommonTitleOne>

                      <CommonDescTwo className="ellipsis-6">
                        <DangerouslyHtml value={feature.short_description} />
                      </CommonDescTwo>
                    </BoxListThreeInformation>
                  </BoxListThreeWrapper>
                );
              }
            )}
          </BoxListThreeContainer>

          <PrimaryButton linkTo="/splash-bay/facilities/splash-bay-dome">
            <ViewDetailsText />
          </PrimaryButton>
        </CommonContentButtonWrapper>
      </CommonContainer>
    </div>
  );
};

export default SplashBayDome;
