import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  discoveryCategoriesUrl,
  entertainmentAttractionVenueUrl,
  entertainmentEventItemsUrl,
  entertainmentEventListUrl,
  entertainmentOfferRecommendationUrl,
  entertainmentPerformancesUrl,
  entertainmentUrl,
  inspireArenaUrl,
  performancesCategoriesUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// InspireArena.jsx
export function useGetEntertainmentOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["entertainmentOfferRecommendation", language],
    getEntertainmentOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// EntertainmentEventList.jsx
export function useGetEntertainmentEventList(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentEventList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentEventListUrl}?language=${language}${
        alias ? `&url_alias=/${alias}` : ""
      }`
    );

    if (status === 200) {
      return data?.[0];
    }
  }, [language, alias]);

  return useQuery(
    ["entertainmentEventList", language, alias],
    getEntertainmentEventList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// EntertainmentEventList.jsx
export function useGetEventListAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEventListUuidGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentEventListUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["eventListAliasGroup", language, localStorage],
    getEventListUuidGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// EntertainmentEventItems.jsx
export function useGetEntertainmentEventItems(type) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentEventItems = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentEventItemsUrl}?language=${language}${
        type ? `&event_type=/${type}` : ""
      }`
    );

    if (status === 200) {
      return data;
    }
  }, [language, type]);

  return useQuery(
    ["entertainmentEventItems", language, type],
    getEntertainmentEventItems,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// EntertainmentEventItemDetail.jsx
export function useGetEntertainmentEventDetail(uuid) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentEventDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentEventItemsUrl}?language=${language}&url_alias=/${uuid}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, uuid]);

  return useQuery(
    ["entertainmentEventDetail", language, uuid],
    getEntertainmentEventDetail,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// EntertainmentDetail.jsx
export function useGetEntertainmentDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentUrl}&language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["entertainmentDetail", language, alias],
    getEntertainmentDetail,
    {
      enabled: !!language,
    }
  );
}

// Entertainment.jsx
export function useGetEntertainmentVenueList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentVenueList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentAttractionVenueUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["entertainmentVenueList", language],
    getEntertainmentVenueList,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// InspireArena.jsx
export function useGetInspireArena() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getInspireArena = useCallback(async () => {
    const { status, data } = await axios.get(
      `${inspireArenaUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["inspireArena", language], getInspireArena, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// InspireArenaDetail.jsx
export function useGetInspireArenaDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getInspireArenaDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${inspireArenaUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["inspireArenaDetail", language, alias],
    getInspireArenaDetail,
    {
      enabled: !!language,
    }
  );
}

// InspireArenaDetail.jsx
export function useGetInspireArenaAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getInspireArenaAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${inspireArenaUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["inspireArenaAliasGroup", language, localStorage],
    getInspireArenaAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// EntertainmentVenueDetail.jsx
export function useGetEntertainmentVenueDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentEventDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentAttractionVenueUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["entertainmentVenueDetail", language, alias],
    getEntertainmentEventDetail,
    {
      enabled: !!language,
    }
  );
}

// EntertainmentVenueDetail.jsx
export function useGetAttractionVenueAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getAttractionVenueUAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentAttractionVenueUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["attractionVenueAliasGroup", language, localStorage],
    getAttractionVenueUAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// AttractionVenueExplore.jsx
export function useGetDiscoveryCategoryList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getDiscoveryCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${discoveryCategoriesUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["discoveryCategoryList", language],
    getDiscoveryCategoryList,
    {
      enabled: !!language,
    }
  );
}

// EntertainmentPerformances.jsx
export function useGetEntertainmentPerformances(pid) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEntertainmentPerformances = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentPerformancesUrl}?language=${language}${
        pid ? `&pid=${pid}` : ""
      }`
    );

    if (status === 200) {
      return data;
    }
  }, [language, pid]);

  return useQuery(
    ["entertainmentPerformances", language, pid],
    getEntertainmentPerformances,
    {
      enabled: !!language,
    }
  );
}

// EntertainmentPerformances.jsx
export function useGetPerformanceDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getPerformanceDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${entertainmentPerformancesUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["performanceDetail", language, alias],
    getPerformanceDetail,
    {
      enabled: !!language,
    }
  );
}

// EntertainmentPerformances.jsx
export function useGetPerformanceCategoryList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getPerformanceCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${performancesCategoriesUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["performanceCategoryList", language],
    getPerformanceCategoryList,
    {
      enabled: !!language,
    }
  );
}
