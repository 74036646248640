import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import CryptoJS from "crypto-js";
import { image } from "src/theme";
import { CommonTitleFour, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image } from "@components/item/ImageRelated";
import { BorderButton, PrimaryButton } from "@components/buttons";
import { yupSignIn } from "@components/inputs/yupValidation";
import { TextInput } from "@components/inputs";
import { NormalOverlay } from "@components/item/Overlays";
import { useGetSignIn } from "@utils/api-requests/GlobalRequests";
import { CustomMetatag } from "@utils/metatags";
import { useUserLoginRequest } from "@utils/api-requests/userRequests";
import {
  ForgotPasswordText,
  RememberMeText,
  SignInText,
  SignUpText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  LOCAL_STORAGE_LANGUAGE,
  LOCAL_STORAGE_REMEMBER,
  LOCAL_STORAGE_USER_ID,
  LOCAL_STORAGE_USER,
  QUERY_KEY_USER_LOGGED_IN,
} from "@utils/api-requests/static-data";
import {
  EmailLabelText,
  FailLoginContactAdminText,
  PasswordLabelText,
  SignInAccountIsBlockedErrorText,
  SignInInvalidAccountErrorText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

import {
  FindMyId,
  SignInAccount,
  SignInContainer,
  SignInDesc,
  SignInForm,
  SignInFormWrapper,
  SignInInformation,
  SignInPasswordIcon,
  SignInTitle,
  SignInWrapper,
} from "./SignInStyling";
import ForgotPasswordModal from "./forget-password/ForgotPasswordModal";

const SignIn = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invalid, setInvalid] = useState("");
  const [errorState, setErrorState] = useState({
    noUser: false,
  });

  const storedRememberMe = localStorage.getItem(LOCAL_STORAGE_REMEMBER);

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(yupSignIn),
    mode: "onSubmit",
    defaultValues: storedRememberMe
      ? {
          email: JSON.parse(storedRememberMe).email,
          password: "",
          rememberMe: false,
        }
      : {
          email: "",
          password: "",
          rememberMe: false,
        },
  });

  useEffect(() => {
    if (storedRememberMe) {
      const storedData = JSON.parse(storedRememberMe);
      const storedDate = new Date(storedData.date);
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      if (storedDate > thirtyDaysAgo) {
        setValue("email", storedData.email);
      } else {
        // Clear stored data if it's older than 30 days
        localStorage.removeItem(LOCAL_STORAGE_REMEMBER);
      }
    }
  }, [storedRememberMe, setValue]);

  const { mutate: userLoginMutate, isLoading } = useMutation(
    useUserLoginRequest,
    {
      onSuccess: (data) => {
        if (data.status === 200 || data === "no data in drupal") {
          /**encrypt Data */
          var cipherText = CryptoJS.AES.encrypt(
            JSON.stringify(data.data),
            process.env.REACT_APP_AGILISYS_API_X_GATEWAY_KEY
          ).toString();

          queryClient.resetQueries(["userProfile"]);
          localStorage.setItem(LOCAL_STORAGE_USER_ID, cipherText);
          queryClient.setQueryData(QUERY_KEY_USER_LOGGED_IN, true);

          let userObj = {
            email: JSON.parse(data.config.data).uniqueUserId,
            // password: JSON.parse(data.config.data).password,
            date: new Date(),
          };

          localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userObj));

          /**remember me */
          if (JSON.parse(data.config.data).rememberMe) {
            let obj = {
              email: JSON.parse(data.config.data).uniqueUserId,
              // password: JSON.parse(data.config.data).password,
              date: new Date(),
            };
            localStorage.setItem(LOCAL_STORAGE_REMEMBER, JSON.stringify(obj));
          }

          navigate(`${baseUrl}/momentum/my-profile`);

          return;
        } else if (data === "null and no data in drupal") {
          setErrorState({
            noUser: true,
          });
        }

        // ERROR: Invalid user name or password
        else if (
          data.response.data?.Errors &&
          data.response.data?.Errors[0]?.Code === "L1003"
        ) {
          setInvalid(<SignInInvalidAccountErrorText />);
        }
        // ERROR: when account blocked
        else if (
          data.response.data?.Errors &&
          data.response.data?.Errors[0]?.Code === "L1004"
        ) {
          setInvalid(<SignInAccountIsBlockedErrorText />);
        } else if (data?.response.status === 400) {
          // ERROR: Invalid user name or password
          setInvalid(<SignInInvalidAccountErrorText />);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { data: signIn } = useGetSignIn();

  const signInFormSubmit = useCallback(
    (data) => {
      userLoginMutate(data);
    },
    [userLoginMutate]
  );

  const hanldeShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const handleOpenPasswordModal = useCallback(() => {
    setOpenPasswordModal(true);
  }, []);

  return (
    <SignInContainer>
      <NormalOverlay opacity="50%" />

      <CustomMetatag metaData={signIn} />

      <ForgotPasswordModal
        openModal={openPasswordModal}
        setOpenModal={setOpenPasswordModal}
      />

      <Image
        src={signIn?.field_image[0]?.src}
        alt={signIn?.field_image[0]?.alt}
      />

      <SignInWrapper>
        <SignInInformation>
          <CommonTitleFour uppercase Black>
            <DangerouslyHtml value={signIn?.field_sub_title} />
          </CommonTitleFour>

          <SignInTitle>
            <DangerouslyHtml value={signIn?.field_main_title} />
          </SignInTitle>

          <SignInDesc>
            <DangerouslyHtml value={signIn?.field_full_description} />
          </SignInDesc>

          <BorderButton linkTo="/momentum/join-momentum">
            <SignUpText />
          </BorderButton>
        </SignInInformation>

        <SignInFormWrapper>
          <CommonTitleOne>
            <SignInText />
          </CommonTitleOne>

          <SignInForm onSubmit={handleSubmit(signInFormSubmit)}>
            {errorState?.noUser && (
              <Alert
                severity="error"
                sx={{ width: "100%", marginBottom: "16px" }}
              >
                <FailLoginContactAdminText />
              </Alert>
            )}

            {invalid !== "" && (
              <Alert
                severity="error"
                sx={{ width: "100%", marginBottom: "16px" }}
              >
                {invalid}
              </Alert>
            )}

            <div>
              <TextInput
                control={control}
                name="email"
                label={<EmailLabelText />}
                placeholder="name@email.com"
                type="email"
              />

              <TextInput
                control={control}
                name="password"
                label={<PasswordLabelText />}
                placeholder="*******"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <SignInPasswordIcon
                    src={image.showPasswordIcon.default}
                    alt="show passowrd"
                    onClick={hanldeShowPassword}
                  />
                }
              />

              <FindMyId>
                <SignInAccount>
                  <Controller
                    control={control}
                    name="rememberMe"
                    render={({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={field.onChange}
                              name="rememberMe"
                            />
                          }
                          label={<RememberMeText />}
                        />
                      );
                    }}
                  />
                </SignInAccount>

                <button type="button" onClick={handleOpenPasswordModal}>
                  <ForgotPasswordText />
                </button>
              </FindMyId>
            </div>

            <PrimaryButton buttonType="submit" noArrow disable={isLoading}>
              {isLoading ? <SubmitLoadingText /> : <SignInText />}
            </PrimaryButton>
          </SignInForm>
        </SignInFormWrapper>
      </SignInWrapper>
    </SignInContainer>
  );
};

export default SignIn;
