import React from "react";

import { SliderNine } from "@components/sliders";

import { TiersBenefitsContainer } from "../MomentumStyling";

const TiersBenefits = ({ data, noPadding }) => {
  return (
    <TiersBenefitsContainer>
      <SliderNine
        data={data}
        momentum
        noPadding={noPadding}
        imageKey="field_thumbnail_List"
        nameKey="field_name"
        pointListKey="field_benefits_points"
      />

      {/* <TableThree data={data} category={MOMENTUM_BENEFIT_CATEGORY} /> */}
    </TiersBenefitsContainer>
  );
};

export default TiersBenefits;
