import React from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { SliderSix } from "@components/sliders";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import {
  DetailPageContainer,
  DetailPageHero,
  DetailPageInformationBox,
} from "@components/sub-page";
import { ErrorManage } from "@screens/index";
import { useGetPerformanceDetail } from "@utils/api-requests/EntertainmentRequests";
import { BookYourTicketText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { CustomMetatag } from "@utils/metatags";
import {changeEmptyValue, replaceAmpersand} from "@utils/modify-data/modifyData";

const PerformanceDetail = () => {
  const performanceAlias = useParams().performanceAlias;

  const intl = useIntl();

  const {
    data: performanceDetail,
    isLoading,
    isError,
    error,
  } = useGetPerformanceDetail(performanceAlias);

  const restaurantDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-entertainment",
      }),
      href: "/entertainment",
    },
    {
      id: 3,
      name: intl.formatMessage({
        id: "lang-inspire-arena",
      }),
      href: "/entertainment/inspire-arena/featured-performances",
    },

    { id: 4, current: true, name: performanceDetail?.field_artist_name },
  ];

  const sectionArray = [
    {
      id: 1,
      contents: (
        <CommonContentButtonWrapper>
          <SliderSix imageArr={performanceDetail?.field_multiple_images} />
        </CommonContentButtonWrapper>
      ),
    },
  ];

  const performanceDetailInfo = [
    {
      id: 1,
      header: intl.formatMessage({
        id: "lang-date",
      }),
      data: `${performanceDetail?.field_date} ${performanceDetail?.field_to_ ? `~ ${performanceDetail?.field_to_}` : ""}`,
    },
    {
      id: 2,
      header: intl.formatMessage({
        id: "lang-time",
      }),
      data: changeEmptyValue(performanceDetail?.field_time),
    },
    {
      id: 3,
      header: intl.formatMessage({
        id: "lang-admission",
      }),
      data: changeEmptyValue(performanceDetail?.field_adminssion),
    },
    {
      id: 4,
      header: intl.formatMessage({
        id: "lang-running-time",
      }),
      data: changeEmptyValue(performanceDetail?.field_runni),
    },
    {
      id: 5,
      header: intl.formatMessage({
        id: "lang-host",
      }),
      data: changeEmptyValue(performanceDetail?.field_host),
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={performanceDetail} />

      <DetailPageHero
        data={performanceDetail}
        desc={performanceDetail?.field_sub_information}
        small
      />

      <CommonContainer>
        <DetailPageInformationBox
          info={performanceDetailInfo}
          data={performanceDetail}
        >
          <NotificationToggle data={performanceDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={restaurantDetailBC}
            pageTitle={performanceDetail?.field_artist_name}
            subTitle={performanceDetail?.field_sub_title}
            value={performanceDetail?.body}
            buttonName={
              performanceDetail?.field_book_ticket_link ? (
                <BookYourTicketText />
              ) : undefined
            }
            outerLink={replaceAmpersand(
              performanceDetail?.field_book_ticket_link
            )}
            textAlign="center"
            coverImage={performanceDetail?.field_image?.[0]}
          />
        </DetailPageInformationBox>

        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={performanceDetail} />

        <MomentumLayout data={performanceDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default PerformanceDetail;
