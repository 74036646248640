import React, {memo, useEffect, useState} from "react";
import { AccordionSummary } from "@mui/material";

import { image } from "src/theme";
import { CommonTitleThree } from "@assets/styles/CommonStyles";
import { CommonDescktopContainer } from "@components/layout/ContainerStyle";
import {
  ViewMenuArrow,
  ViewMenuContainer,
  ViewMenuDesc,
  ViewMenuItem,
  ViewMenuList,
  ViewMenuTitle,
} from "@pages/eat-drink/EatDrinkStyling";
import { downloadPdf } from "@utils/modify-data/modifyData";
import { ViewMenuText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { DangerouslyHtml } from "@components/item";

const ViewMenu = ({ viewMenuList, fileKey = "file", filterType }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordion = () => {
    setExpanded((prev) => !prev);
  };
  useEffect(() => {
    if (filterType) {
      setExpanded(false);
    }
  }, [filterType]);

  return (
    <CommonDescktopContainer>
      <ViewMenuContainer expanded={expanded}>
        <AccordionSummary
          aria-controls="view-menu-content"
          id="view-menu-header"
          sx={{ margin: 0 }}
          onClick={handleAccordion}
          expandIcon={
            <ViewMenuArrow
              src={image.angleDown.default}
              alt="down arrow"
              expanded={expanded}
            />
          }
        >
          <ViewMenuTitle>
            <CommonTitleThree>
              <ViewMenuText />
            </CommonTitleThree>
          </ViewMenuTitle>
        </AccordionSummary>

        <ViewMenuDesc>
          <ViewMenuList>
            {viewMenuList?.map((menu) => {
              return (
                <ViewMenuItem
                  key={menu.title}
                  onClick={() => downloadPdf(menu.title, menu[fileKey]?.src)}
                >
                  <DangerouslyHtml value={menu.title} />
                </ViewMenuItem>
              );
            })}
          </ViewMenuList>
        </ViewMenuDesc>
      </ViewMenuContainer>
    </CommonDescktopContainer>
  );
};

export default memo(ViewMenu);
