import React, { useMemo, useState } from "react";

import { image } from "src/theme";
import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeStep,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { downloadPdf } from "@utils/modify-data/modifyData";
import { PdfDownloadText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SplashBayUseGuide = ({ data }) => {
  const [filterType, setFilterType] = useState(0);

  const useNames = useMemo(() => {
    const result = data?.field_splash_bay_use_guide?.map((item) => {
      return {
        title: item.guide,
        category_id: item.guide,
      };
    });

    // Remove duplicates based on 'title' property
    const uniqueResult = result?.filter(
      (item, index, self) =>
        index === self.findIndex((i) => i.title === item.title)
    );

    setFilterType(uniqueResult?.[0]?.title);

    return uniqueResult;
  }, [data]);

  return (
    <CommonContentButtonWrapper>
      <ListWithTabs
        tabArr={useNames}
        filterType={filterType}
        setFilterType={setFilterType}
      >
        <BoxListThreeContainer>
          {data?.field_splash_bay_use_guide
            ?.filter((el) => el.guide === filterType)
            .map((feature) => {
              return (
                <BoxListThreeWrapper
                  key={feature.title}
                  alignItems="flex-start"
                >
                  <ImageWrapper radius="2.5rem" ratio="3 / 2">
                    <Image src={feature.image?.src} alt={feature.image?.alt} />
                  </ImageWrapper>

                  <BoxListThreeInformation>
                    <BoxListThreeStep>
                      <DangerouslyHtml value={feature.step} />
                    </BoxListThreeStep>

                    <CommonTitleOne>
                      <DangerouslyHtml value={feature.title} />
                    </CommonTitleOne>

                    <CommonDescTwo>
                      <DangerouslyHtml value={feature.instruction} />
                    </CommonDescTwo>
                  </BoxListThreeInformation>
                </BoxListThreeWrapper>
              );
            })}
        </BoxListThreeContainer>
      </ListWithTabs>

      {data?.field_guide_pdf && (
        <PrimaryButton
          noArrow
          buttonEvent={() => downloadPdf("ddd", data?.field_guide_pdf)}
        >
          <PdfDownloadText />

          <img
            src={image.downloadIcon.default}
            alt="download icon"
            width={25}
            height={25}
            loading="lazy"
          />
        </PrimaryButton>
      )}
    </CommonContentButtonWrapper>
  );
};

export default SplashBayUseGuide;
