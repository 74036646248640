import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { IsDefault, IsDesktop } from "@utils/media-query/mediaQuery";

import { image } from "src/theme";

import {
  TabButton,
  TabButtonItem,
  TabButtonsContainer,
  TabButtonsMobileActive,
  TabButtonsMobileContainer,
  TabButtonsMobileWrapper,
  TabButtonsWrapper,
} from "./ItemStyling";

const TabButtons = ({
  color,
  tabArr,
  currentTab,
  setCurrentTab,
  filterType,
  setFilterType,
  tabKey,
  titleKey,
  changeEvent,
}) => {
  const tabModalRef = useRef(null);
  const tabModalButtonRef = useRef(null);

  const [tabModal, setTabModal] = useState(false);

  const openTabModal = useCallback(() => {
    setTabModal((prev) => !prev);
  }, []);

  const closeTabModal = useCallback(() => {
    setTabModal(false);
  }, []);

  const onClickTab = useCallback(
    (tab, idx) => {
      if (changeEvent) {
        changeEvent();
      }
      if (setFilterType) {
        setFilterType(tab.category_id);
      }

      if (setCurrentTab) {
        setCurrentTab(idx);
      }

      closeTabModal();
    },
    [changeEvent, closeTabModal, setCurrentTab, setFilterType]
  );

  function useOutsideModalClicked(modalRef, buttonRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setTabModal(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [buttonRef, modalRef]);
  }

  useOutsideModalClicked(tabModalRef, tabModalButtonRef);

  return (
    <>
      <IsDefault>
        <CommonContainer>
          <TabButtonsMobileContainer ref={tabModalButtonRef}>
            <TabButtonsMobileActive onClick={openTabModal}>
              {tabArr !== "" &&
                tabArr
                  ?.filter((el, idx) =>
                    filterType
                      ? tabKey
                        ? el[tabKey]
                        : el.category_id === filterType
                      : currentTab === idx
                  )
                  .map((tab, idx) => {
                    return (
                      <div
                        key={tabKey ? tab[tabKey] : tab.category_id}
                        aria-labelledby={`tab button named ${
                          titleKey ? tab[titleKey] : tab.title
                        }`}
                      >
                        {titleKey
                          ? typeof tab[titleKey] === "object"
                            ? tab[titleKey]
                            : tab[titleKey]?.replaceAll("//", "")
                          : typeof tab?.title === "object"
                          ? tab?.title
                          : tab?.title?.replaceAll("//", "")}
                      </div>
                    );
                  })}

              <img
                style={{ transform: tabModal ? "rotate(180deg)" : "rotate(0)" }}
                src={image.angleDown.default}
                alt="click for more"
                width={11}
              />
            </TabButtonsMobileActive>

            {tabModal && (
              <TabButtonsMobileWrapper ref={tabModalRef}>
                {tabArr !== "" &&
                  tabArr.map((tab, idx) => {
                    return (
                      <TabButtonItem
                        key={tabKey ? tab[tabKey] : tab.category_id}
                        aria-labelledby={`tab button named ${
                          titleKey ? tab[titleKey] : tab.title
                        }`}
                        active={
                          filterType
                            ? tabKey
                              ? tab[tabKey]
                              : tab.category_id === filterType
                            : currentTab === idx
                        }
                        onClick={() => {
                          onClickTab(tab, idx);
                        }}
                      >
                        {titleKey
                          ? typeof tab[titleKey] === "object"
                            ? tab[titleKey]
                            : tab[titleKey]?.replaceAll("//", "")
                          : typeof tab?.title === "object"
                          ? tab?.title
                          : tab?.title?.replaceAll("//", "")}
                      </TabButtonItem>
                    );
                  })}
              </TabButtonsMobileWrapper>
            )}
          </TabButtonsMobileContainer>
        </CommonContainer>
      </IsDefault>

      <IsDesktop>
        <TabButtonsContainer>
          <TabButtonsWrapper aria-label="Tab Buttons">
            {tabArr !== "" &&
              tabArr?.map((tab, idx) => {
                return (
                  <TabButton
                    color={
                      color &&
                      color(
                        filterType
                          ? tabKey
                            ? tab[tabKey]
                            : tab.category_id === filterType
                          : currentTab === idx
                      )
                    }
                    key={tabKey ? tab[tabKey] : tab.category_id}
                    aria-labelledby={`tab button named ${
                      titleKey ? tab[titleKey] : tab.title
                    }`}
                    active={
                      filterType
                        ? tabKey
                          ? tab[tabKey]
                          : tab.category_id === filterType
                        : currentTab === idx
                    }
                    onClick={() => {
                      onClickTab(tab, idx);
                    }}
                  >
                    {titleKey
                      ? typeof tab[titleKey] === "object"
                        ? tab[titleKey]
                        : tab[titleKey]?.replaceAll("//", "")
                      : typeof tab?.title === "object"
                      ? tab?.title
                      : tab?.title?.replaceAll("//", "")}
                  </TabButton>
                );
              })}
          </TabButtonsWrapper>
        </TabButtonsContainer>
      </IsDesktop>
    </>
  );
};

export default memo(TabButtons);
