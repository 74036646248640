import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import he from "he";

import {ListWithTabs} from "@components/list";
import {BoxListSix} from "@components/box-list";
import {ErrorManage} from "@screens/index";
import {useGetStoreCategoryList, useGetStoreList,} from "@utils/api-requests/ShopRequests";
import { concatAllValue, getSearchValue} from "@utils/modify-data/modifyData";
import {scrollToTargetAdjusted} from "@utils/scroll-behavior/ScrollIntoView";
import {SCROLL_INTO_VIEW_TIMER} from "@utils/api-requests/static-data";

const StoresCategory = forwardRef((_, ref) => {
  const search = useLocation().search;

  const [filterType, setFilterType] = useState("all");

  const { data: storeCategoryList } = useGetStoreCategoryList(filterType);

  const {
    data: storeList,
    isFetching,
    isError,
    error,
  } = useGetStoreList(storeCategoryList?.filter((el) => el.tid === filterType)[0]?.url_alias.replace("/", ""));

  const storeCategory = useMemo(() => {
    return storeCategoryList?.map((item) => {
      return {
        title: he.decode(item.name),
        category_id: item.tid,
      };
    });
  }, [storeCategoryList]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (search !== "") {
        setFilterType(getSearchValue(search));

        scrollToTargetAdjusted(ref);
      } else {
        setFilterType("all");
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [ref, search]);

  const resetUrl = useCallback(() => {
    window.history.replaceState({}, document.title);
  }, []);


  return (
    <div ref={ref}>
        <ListWithTabs
          tabArr={concatAllValue(storeCategory)}
          filterType={filterType}
          setFilterType={setFilterType}
          changeEvent={resetUrl}
        >
          <ErrorManage isFetching={isFetching} isError={isError} error={error}>
            <BoxListSix
              data={storeList}
              nameKey="field_shop_"
              typeKey="shop_category_name"
              locationKey="field_location"
              linkTo="/shop/store"
              categoryKey="shop_category_name"
            />
          </ErrorManage>
        </ListWithTabs>
    </div>
  );
});

export default StoresCategory;
