import React from "react";

import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { PrimaryButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";

import {
  CheckMoreOffersContainer,
  CheckMoreOffersInformation,
  CheckMoreOffersLeft,
} from "../OffersStyling";

const CheckMoreOffers = ({ data, linkTo }) => {
  return (
    <CheckMoreOffersContainer>
      <CheckMoreOffersLeft>
        <CheckMoreOffersInformation>
          <CommonTitleOne>
            <DangerouslyHtml value={data?.field_check_more_title} />
          </CommonTitleOne>

          <CommonDescOne>
            <DangerouslyHtml value={data?.field_check_more_description} />
          </CommonDescOne>
        </CheckMoreOffersInformation>

        <PrimaryButton linkTo={linkTo ? linkTo : "/offers"}>
          {data?.field_check_more_button_name}
        </PrimaryButton>
      </CheckMoreOffersLeft>

      <ImageWrapper ratio="7 / 4" radius="2.5rem">
        <Image
          src={data?.field_thumbnail[0]?.src}
          alt={data?.field_thumbnail[0]?.alt}
        />
      </ImageWrapper>
    </CheckMoreOffersContainer>
  );
};

export default CheckMoreOffers;
