import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { image } from "src/theme";

import { CommonOnlyDesktopContainer } from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/index";
import { useGetHomeTopBanner } from "@utils/api-requests/GlobalRequests";
import {
  LOCAL_STORAGE_LANGUAGE,
} from "@utils/api-requests/static-data";
import { IsDefault, IsDesktop } from "@utils/media-query/mediaQuery";
import { CloseText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  MainAdBannerButtons,
  MainAdBannerContainer,
  MainAdBannerImage,
  MainAdBannerWrapper,
} from "./ItemStyling";

const MainAdBanner = ({ mainAdBannerOpen, setMainAdBannerOpen }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();

  const {
    data: homeTopBanner,
    isLoading,
    isError,
    error,
  } = useGetHomeTopBanner();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  // const closeTodayPop = useCallback(() => {
  //   const newDate = new Date();
  //   const expires = newDate.setHours(newDate.getHours() + 24);
  //
  //   localStorage.setItem(LOCAL_STORAGE_VISTED, expires);
  //
  //   setMainAdBannerOpen(false);
  // }, [setMainAdBannerOpen]);

  const linkToPage = useCallback(() => {
    navigate(`${baseUrl}${homeTopBanner?.field_link}`);
  }, [baseUrl, homeTopBanner?.field_link, navigate]);

  return (
    homeTopBanner && (
      <ErrorManage isLoading={isLoading} isError={isError} error={error}>
        <MainAdBannerContainer open={mainAdBannerOpen}>
          <MainAdBannerImage
            onClick={linkToPage}
            open={mainAdBannerOpen}
            src={
              homeTopBanner?.field_mobile_image && isMobile
                ? homeTopBanner?.field_mobile_image?.[0].src
                : homeTopBanner?.field_image[0].src
            }
            width="100%"
            alt=""
          />

          <MainAdBannerButtons to={`${baseUrl}${homeTopBanner?.field_link}`}>
            <CommonOnlyDesktopContainer custom={{ height: "100%" }}>
              <MainAdBannerWrapper>
                <IsDesktop>
                  <img
                    src={image.modalCloseWhiteIcon.default}
                    alt="Close Modal"
                    width={40}
                    onClick={(e) => {
                      e.preventDefault();
                      setMainAdBannerOpen(false);
                    }}
                  />
                </IsDesktop>

                <IsDefault>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setMainAdBannerOpen(false);
                    }}
                  >
                    <CloseText />
                  </button>
                </IsDefault>

                {/*<MainAdBannerCloseDesc*/}
                {/*  onClick={(e) => {*/}
                {/*    e.preventDefault();*/}
                {/*    closeTodayPop();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  not to see again today*/}
                {/*</MainAdBannerCloseDesc>*/}
              </MainAdBannerWrapper>
            </CommonOnlyDesktopContainer>
          </MainAdBannerButtons>
        </MainAdBannerContainer>
      </ErrorManage>
    )
  );
};

export default MainAdBanner;
