import React, { useMemo } from "react";
import he from "he";

import { image } from "src/theme";
import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { Image } from "@components/item/ImageRelated";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  BoxListSixDetailLink,
  BoxListSixGrid,
  BoxListSixInformation,
  BoxListSixItem,
  BoxListSixName,
  BoxListSixTexts,
} from "./BoxListStyling";
import { DangerouslyHtml } from "@components/item";
import {decodeUrl} from "@utils/modify-data/modifyData";

const BoxListSix = ({ data, nameKey, typeKey, locationKey, linkTo, categoryKey, restaurant }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    data?.length > 0 && (
      <BoxListSixGrid>
        {data?.map((item, idx) => {
          const isShop = restaurant && item?.[nameKey] === null

          return (
            <BoxListSixItem key={`${item[isShop ? "field_shop_" : nameKey]}${idx}`}>
              <Image
                src={item?.field_thumbnail[0]?.src}
                alt={item?.field_thumbnail[0]?.alt}
              />

              <BoxListSixInformation>
                <BoxListSixTexts>
                  <BoxListSixName>
                    <DangerouslyHtml value={item[isShop ? "field_shop_" : nameKey]} />
                  </BoxListSixName>

                  {item[isShop ? "shop_category_name" : typeKey] && item[isShop ? "field_location" : locationKey] && (
                    <CommonDescTwo>
                      {he.decode(item[isShop ? "shop_category_name" : typeKey])}{" "}

                      {item[isShop ? "shop_category_name" : typeKey] && (
                          <DangerouslyHtml value={item[isShop ? "field_location" : locationKey]} />
                        ) && <span>| </span>}

                      {item[isShop ? "shop_category_name" : typeKey].length + item[isShop ? "field_location" : locationKey].length > 22 && (
                        <br />
                      )}
                      <DangerouslyHtml value={item[isShop ? "field_location" : locationKey]} />
                    </CommonDescTwo>
                  )}
                </BoxListSixTexts>

                <BoxListSixDetailLink
                  to={
                    isShop || categoryKey
                      ? `${baseUrl}${isShop ? "/shop/store" : linkTo}/${decodeUrl(item[isShop ? "shop_category_name" : categoryKey])}${item.url_alias}`
                      : `${baseUrl}${linkTo}/${item.field_parent_name?.replaceAll(
                        " ",
                        "-"
                      )}${item.url_alias}`
                  }
                >
                  <ViewDetailsText />

                  <img
                    src={image.linkArrowPurple.default}
                    alt="right arrow"
                    width={22}
                  />
                </BoxListSixDetailLink>
              </BoxListSixInformation>
            </BoxListSixItem>
          );
        })}
      </BoxListSixGrid>
    )
  );
};

export default BoxListSix;
