import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  MapAll,
  MapAllDesc,
  PingImage,
  PingIn,
  PingInformation,
  PingThumbnail,
  PingWrapper,
} from './ItemStyling';
import { Image, ImageWrapper } from './ImageRelated';
import MapPingImage from './MapPingImage';
import { DangerouslyHtml } from '.';

const MapWithPings = ({
  image,
  desc,
  filteredData,
  discovery,
  imageKey,
  titleKey = 'field_venu_name',
  dateKey = 'field_event_date',
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [hover, setHover] = useState('');

  const activeHover = useCallback(
    (idx) => {
      if (isDesktop) {
        setHover(idx);
      }
    },
    [isDesktop]
  );

  const inactiveHover = useCallback(() => {
    if (isDesktop) {
      setHover('');
    }
  }, [isDesktop]);

  return (
    <MapAll>
      {image && (
        <ImageWrapper ratio="5 / 2" radius={isDesktop ? '2.5rem' : '1.5rem'}>
          <Image src={image?.src} alt={image?.alt} />
        </ImageWrapper>
      )}

      {filteredData?.map((item, idx) => {
        return (
          <PingWrapper
            key={item?.[titleKey]}
            onClick={() => setHover(idx)}
            onMouseEnter={() => activeHover(idx)}
            onMouseLeave={inactiveHover}
          >
            <PingIn>
              <PingImage>
                <MapPingImage
                  active={hover === idx}
                  width={!isDesktop ? '48' : undefined}
                />

                <PingThumbnail>
                  {discovery ? (
                    <Image
                      src={item?.field_icon?.src}
                      alt={item?.field_icon?.alt}
                    />
                  ) : (
                    <Image
                      src={item?.[imageKey][0]?.src}
                      alt={item?.[imageKey][0]?.alt}
                    />
                  )}
                </PingThumbnail>
              </PingImage>

              {hover === idx && (
                <PingInformation>
                  <header>{item?.[titleKey]}</header>

                  {discovery && (
                    <>
                      <div>
                        {item.field_event_name} | {item[dateKey]}
                      </div>

                      <p>{item?.field_descr}</p>
                    </>
                  )}
                </PingInformation>
              )}
            </PingIn>
          </PingWrapper>
        );
      })}

      {desc && (
        <MapAllDesc>
          <DangerouslyHtml value={desc} />
        </MapAllDesc>
      )}
    </MapAll>
  );
};

export default MapWithPings;
