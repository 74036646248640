import React from "react";

import { CommonDescFour } from "@assets/styles/CommonStyles";
import {
  TableFourContainer,
  TableFourContents,
  TableFourHeader,
  TableFourItem,
} from "./TableStyling";

const TableFour = ({ data, category }) => {
  return (
    <TableFourContainer>
      {category?.map((category) => {
        return (
          <TableFourItem key={category.name}>
            <TableFourHeader>{category.name}</TableFourHeader>

            <TableFourContents>
              <CommonDescFour>
                {data?.[`field_${category.field}`]}
              </CommonDescFour>
            </TableFourContents>
          </TableFourItem>
        );
      })}
    </TableFourContainer>
  );
};

export default TableFour;
