import React from "react";
import { useMediaQuery } from "react-responsive";

import { color } from "src/theme";
import { PrimaryButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { GradientOverlay } from "@components/item/Overlays";
import { DangerouslyHtml } from "@components/item";
import { MoheganNewsInformation } from "@pages/about-us/AboutUsStyling";
import { replaceAmpersand } from "@utils/modify-data/modifyData";

const MoheganNews = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    <CommonContentButtonWrapper>
      <ImageWrapper ratio={isDesktop ? "5 / 2" : "1 / 1"} radius="2.5rem">
        <Image
          src={data?.field_mohegan_news_image[0]?.src}
          alt={data?.field_mohegan_news_image[0]?.alt}
        />

        <MoheganNewsInformation>
          <DangerouslyHtml value={data?.field_mohegan_news_title} />

          <PrimaryButton
            buttonColor={color.secondary_color}
            outerLink={replaceAmpersand(data?.field_mohegan_news_link)}
          >
            {data?.field_link_text}
          </PrimaryButton>
        </MoheganNewsInformation>
        <GradientOverlay />
      </ImageWrapper>
    </CommonContentButtonWrapper>
  );
};

export default MoheganNews;
