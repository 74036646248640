import React from "react";

import { PageWithTabs } from "@components/list";

import { MyProfileAccount, MyProfileDashboard } from ".";
import { DashboardAccountContainer } from "./MyProfileStyling";
import {
  AccountText,
  DashboardText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const DashboardAccount = ({
  data,
  currentTab,
  setCurrentTab,
  currentIndex,
  setCurrentIndex,
  userData,
  complete,
  setComplete,
  changeMarketingModalOpen,
  setChangeMarketingModalOpen,
}) => {
  const tabArr = [
    { category_id: 0, title: <DashboardText /> },
    { category_id: 1, title: <AccountText /> },
  ];

  const pages = [
    {
      id: 0,
      contents: (
        <MyProfileDashboard
          data={data}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          userData={userData?.BucketBalances}
        />
      ),
    },
    {
      id: 1,
      contents: (
        <MyProfileAccount
          data={data}
          userData={userData?.ProfileData}
          complete={complete}
          setComplete={setComplete}
          changeMarketingModalOpen={changeMarketingModalOpen}
          setChangeMarketingModalOpen={setChangeMarketingModalOpen}
        />
      ),
    },
  ];

  return (
    <div>
      <PageWithTabs
        tabArr={tabArr}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      >
        <DashboardAccountContainer>
          {pages[currentTab].contents}
        </DashboardAccountContainer>
      </PageWithTabs>
    </div>
  );
};

export default DashboardAccount;
