import {
  CommonDescOne,
  CommonDescTwo,
  CommonTitleFour,
  infoZIndex,
} from '@assets/styles/CommonStyles';
import { DetailPageTitle } from '@components/sub-page/SubPageStyling';
import styled from '@emotion/styled';
import { mq } from '@utils/media-query/mediaQuery';

export const EntertainmentLocationDate = styled.div(() => ({
  display: 'flex',
  gap: '0 1rem',
}));

export const EntertainmentShortDesc = styled(CommonDescTwo)(() => ({
  maxWidth: '80%',
}));

export const EntertainmentInformation = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem 0',
}));

// STYLE: AttractionVenueExplore
export const AttractionVenueExploreWrapper = styled.div(() => ({
  position: 'relative',
  borderRadius: '2.5rem',
  overflow: 'hidden',
}));

export const AttractionVenueExploreImage = {
  maxHeight: '49.8rem',
};

export const AttractionVenueExploreDesc = styled(CommonDescOne)(
  ({ theme }) => ({
    position: 'absolute',
    padding: '3.2rem',
    bottom: 0,
    zIndex: 20,
    color: theme.color.white,

    [mq('desktop')]: {
      padding: '3.2rem 6.4rem',
    },
  })
);

// STYLE: InspireArena.jsx
export const InspireArenaButtons = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1.6rem',

  '& > *': {
    flex: 1,
  },
}));

// STYLE: FacilitySpecification.jsx
export const FacilitySpecificationInformation = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3.4rem',
  padding: '3.2rem',
  boxShadow: theme.shadow.secondary,

  [mq('desktop')]: {
    padding: '6rem',
  },
}));

export const FacilitySpecificationPdfWrapper = styled.div(() => ({
  display: 'flex',
  gap: '3.2rem 7.2rem',
  flexDirection: 'column',

  [mq('desktop')]: {
    flexDirection: 'row',
  },
}));

export const FacilitySpecificationFactList = styled.ul(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '3.2rem',
  justifyContent: 'space-between',

  '&>li': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem 0',
  },

  [mq('tablet')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [mq('desktop')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

// STYLE: FacilityTechnology.jsx
export const FacilityTechnologyContainer = styled.div(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '7rem',

  [mq('tablet')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [mq('desktop')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

// STYLE: SeatsSystem.jsx
export const SeatsSystemContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.6rem 0',
  padding: '3.2rem 1.6rem',
  boxShadow: theme.shadow.secondary,
  borderRadius: '1.5rem',

  [mq('desktop')]: {
    flexDirection: 'row',
    gap: '0 6.4rem',
    padding: '8rem',
    borderRadius: '2.5rem',
  },
}));

export const SeatsSystemPdf = styled.div(() => ({
  flex: 1,

  '&>*': {
    width: '100%',
  },
}));

export const SeatsSystemWrapper = styled.ul(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const SeatsSystemInformation = styled.ul(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2.8rem 0',
  fontSize: '1.6rem',
  marginTop: '2rem',
  marginBottom: '2rem',

  [mq('desktop')]: {
    marginTop: '4rem',
    marginBottom: '4rem',
    fontSize: '2.4rem',
  },
}));

export const SeatsSystemItem = styled.li(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  '& > header': {
    color: theme.color.primary_grey,
  },

  '& > p': {
    color: theme.color.primary_color,
    fontWeight: theme.fontWeight.medium,
  },
}));

export const SeatsSystemTotal = styled(CommonTitleFour)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '3rem',
  padding: '1.6rem',
  background: theme.color.primary_color,
  color: theme.color.white,

  [mq('desktop')]: {
    padding: '3rem 4rem',
  },
}));

export const SeatsSystemNotification = styled.div(({ theme }) => ({
  color: theme.color.red_color,
}));

// STYLE: ArtistSpaces.jsx
export const ArtistSpacesInformation = styled.div(({ theme }) => ({
  position: 'absolute',
  bottom: '3.2rem',
  left: '3.2rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem 0',
  color: theme.color.white,
  zIndex: infoZIndex,

  [mq('desktop')]: {
    bottom: '7rem',
    left: '7rem',
  },
}));

// STYLE: MonthlyCalendar.jsx
export const MonthlyCalendarContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const MonthlyCalendarSliderWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0 1.6rem',
  marginBottom: '6.4rem',
  fontSize: '2.4rem',
  fontWeight: theme.fontWeight.bold,

  [mq('desktop')]: {
    gap: '0 6.4rem',
    fontSize: '3.2rem',
  },
}));

// STYLE: AttractionVenuesCurrentExhibition.jsx
export const AttractionVenuesCurrentExhibitionContainer = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2.5rem',
  justifyContent: 'center',

  figure: {
    minWidth: '36rem',
    maxWidth: '36rem',
  },
}));

// STYLE: EntertainmentPerformances.jsx
export const EntertainmentPerformancesContainer = styled.div(({ theme }) => ({
  padding: '1.6rem',
  backgroundImage: `url(${theme.image.performanceBg})`,
  color: theme.color.white,

  [mq('desktop')]: {
    padding: '6rem',
  },
}));

export const EntertainmentPerformancesPage = styled(DetailPageTitle)(
  ({ theme }) => ({
    color: theme.color.secondary_color,
  })
);

// STYLE: InspireArenaFeaturedEvents.jsx
export const InspireArenaFeaturedEventsInformation = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.8rem 0',
}));

export const InspireArenaFeaturedEventsSubTitle = styled.div(() => ({
  display: 'flex',
  gap: '0 0.5rem',
}));

export const FiveEventsAreaDescription = styled(CommonTitleFour)(() => ({
  margin: '2rem auto 0',
  textAlign: 'center',

  [mq('desktop')]: {
    maxWidth: '90%',
  },
}));
