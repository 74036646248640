import React, { memo, useCallback, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Backdrop, Fade, Modal } from "@mui/material";

import { image } from "src/theme";

import {
  CenteredModalCloseButton,
  CenteredModalSubTitle,
  CenteredModalTitle,
  CenteredModalWrapper,
  CommonModalContainer,
  CommonModalContents,
  CommonModalWrapper,
} from "./ModalStyling";
import { DangerouslyHtml } from "@components/item";

const CenteredModal = ({
  modalWidth,
  modalName,
  subTitle,
  description,
  openModal,
  setOpenModal,
  children,
  noHeader,
  noPadding,
  noName,
  noCloseButton,
  uppercase,
  modalCloseEvent,
  onCloseEvent,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const handleClose = useCallback(() => {
    setOpenModal(false);

    if (onCloseEvent) {
      onCloseEvent(0);
    }
  }, [onCloseEvent, setOpenModal]);

  useEffect(() => {
    const escKeyModalClose = (e) => {
      if (e.keyCode === 27) {
        // close modal when press Esc button.
        e.preventDefault();

        if (onCloseEvent) {
          onCloseEvent(0);
        }

        setOpenModal(false);
      }
    };

    document.addEventListener("keyup", escKeyModalClose);
    return () => document.removeEventListener("keyup", escKeyModalClose);
  }, [onCloseEvent, setOpenModal]);

  return (
    <Modal
      open={openModal}
      onClose={modalCloseEvent}
      closeAfterTransition
      slots={{ overlay: Backdrop }}
      slotProps={{
        overlay: {
          timeout: 500,
        },
      }}
      aria-labelledby={modalName}
      aria-describedby={description}
    >
      <Fade in={openModal}>
        <CommonModalContainer noPadding={noPadding} width={modalWidth}>
          <CommonModalWrapper>
            {!noCloseButton && (
              <CenteredModalCloseButton type="button" onClick={handleClose}>
                <img
                  src={image.modalCloseIcon.default}
                  alt="Close Modal"
                  width={isDesktop ? 40 : 32}
                  height={isDesktop ? 40 : 32}
                  loading="lazy"
                />
              </CenteredModalCloseButton>
            )}

            {!noHeader && (
              <CenteredModalWrapper>
                {noName ? (
                  <div></div>
                ) : (
                  <div>
                    <CenteredModalTitle uppercase={uppercase}>
                      <DangerouslyHtml value={modalName} />
                    </CenteredModalTitle>

                    {subTitle && (
                      <CenteredModalSubTitle>
                        <DangerouslyHtml value={subTitle} />
                      </CenteredModalSubTitle>
                    )}
                  </div>
                )}
              </CenteredModalWrapper>
            )}

            <CommonModalContents>{children}</CommonModalContents>
          </CommonModalWrapper>
        </CommonModalContainer>
      </Fade>
    </Modal>
  );
};

export default memo(CenteredModal);
