import { mq } from "@utils/media-query/mediaQuery";
import { AppTheme, color } from "src/theme";

const { css, Global } = require("@emotion/react");

const style = css`
  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 6rem;
  }

  .swiper-pagination-bullet-active {
    background: transparent !important;
    border: 1px solid white;
    width: 18px !important;
    height: 18px !important;

    ${mq("desktop")} {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
    width: 8px;
    height: 8px;

    ${mq("desktop")} {
      width: 10px;
      height: 10px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
  }

  .swiper-wrapper {
    width: 100vw;
  }

  // Calendar
  .rdrCalendarWrapper {
    position: relative;
    font-family: ${AppTheme.fontFamily.primaryFont};
  }

  .rdrDayToday .rdrDayNumber span:after {
    background: ${color.primary_color};
  }

  .rdrNextPrevButton:hover {
    background: ${color.secondary_color};
  }

  .rdrWeekDay {
    color: ${color.base_black};
    font-weight: 700;
  }

  .rdrDay {
    height: 4.5rem;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    font-weight: 600;
  }

  .rdrEndEdge {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    right: 2px;
  }

  .rdrStartEdge {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    left: 2px;
  }

  .rdrInRange ~ .rdrDayNumber {
    background: #f1e5fd;
    border-radius: 1rem;
    left: 2px;
    right: 2px;
  }

  .rdrSelected,
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge {
    top: 5px;
    left: 2px;
    right: 2px;
    bottom: 5px;
  }

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonthAndYearWrapper {
    position: absolute;
    top: 50%;
    display: flex;
    aling-items: center;
    padding: 0;
    justify-content: space-between;
    width: 100%;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span {
    color: white !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    color: white !important;
  }

  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
  .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
    display: none;
  }

  .rdrInRange {
    background: transparent;
    color: ${color.primary_color};
  }

  .rdrMonths {
    & > div:first-of-type {
      padding: 0 1rem 0 4rem;
    }

    & > div:last-of-type {
      padding: 0 4rem 0 1rem;
    }
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: #482a6a;
  }

  .rdrDayHovered {
    & > *.rdrDayNumber {
      border-radius: 1rem;
      font-weight: 600;
      background: ${color.primary_color};
      opacity: 1;
      left: 3px;
      right: 3px;

      & > span {
        color: ${color.white};
      }
    }

    border: 0;
  }

  .rdrDayStartPreview {
    border: none;
  }

  .rdrDayInPreview {
    border: none;
    font-weight: 600;
  }

  .rdrDayInPreview ~ .rdrDayNumber {
    border: none;
    background: #f1e5fd;
    border-radius: 1rem;
    left: 3px;
    right: 3px;
  }

  .rdrDayEndPreview {
    border: none;
  }

  .rdrMonthName {
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 800;
    color: ${color.base_black};
    word-spacing: 0.5rem;
  }

  .react-pdf__Page__canvas {
    max-width: 100%;
  }
`;

function SwiperStyle() {
  return <Global styles={style} />;
}

export default SwiperStyle;
