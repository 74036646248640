import React from "react";

import { useGetCasinoDining } from "@utils/api-requests/CasinoRequests";
import {CasinoDiningInformation} from "@pages/casino/detail/casino-dining";
import {SliderSix} from "@components/sliders";
import {CasinoDiningWrapper} from "@pages/casino/CasinoStyling";
import {CommonContainer} from "@components/layout/ContainerStyle";

const CasinoDining = () => {
  const { data: casinoDining } = useGetCasinoDining();

  return (
    <CasinoDiningWrapper>
      <SliderSix imageArr={casinoDining?.field_multiple_images} />

      <CommonContainer noPadding>
        <div>
          <CasinoDiningInformation
            casinoDining
            data={casinoDining?.field_casino_dining_information}
          />
        </div>
      </CommonContainer>
    </CasinoDiningWrapper>
  );
};

export default CasinoDining;
