import { DatePicker } from "@mui/x-date-pickers";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import React from "react";
import { Controller } from "react-hook-form";
import { image } from "src/theme";

const DateInput = ({ control, name, label, dateVal, setDateVal, errors }) => {
  const past = dayjs().subtract(100, "year");
  const yesterday = dayjs().subtract(19, "year");

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <DatePicker
            {...field}
            disableFuture
            minDate={past}
            maxDate={yesterday}
            format="YYYY-MM-DD"
            value={dateVal}
            onChange={(val) => {
              field.onChange(addDays(new Date(val?.toString()), 0));

              setDateVal(val);
            }}
            sx={{ width: "100%", minHeight: "8rem" }}
            label={label}
            slotProps={{
              textField: {
                helperText: errors[name]?.message,
                error: !!errors[name],
              },
            }}
            slots={{
              calendarIcon: () => (
                <img src={image.datepickerIcon.default} alt="calendar icon" />
              ),
            }}
          />
        );
      }}
    />
  );
};

export default DateInput;
