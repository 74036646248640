import React from "react";

import { CommonTitleFive } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";

const AboutUsTermList = ({ data }) => {
  return (
    <CommonTitleFive>
      <DangerouslyHtml value={data} />
    </CommonTitleFive>
  );
};

export default AboutUsTermList;
