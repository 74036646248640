import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Backdrop } from "@mui/material";

import { BookYourEventText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import {
  CommonContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { MainBannerIFrame } from "@pages/home/HomeStyling";
import { ErrorManage } from "@screens/index";
import {
  useGetMeetServicesDetail,
  useGetMeetServicesAliasGroup,
} from "@utils/api-requests/MeetRequests";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { CustomMetatag } from "@utils/metatags";

import { InquiryInformation, MeetServiceFeatures } from ".";

const MeetServicesDetail = () => {
  const alias = useParams().serviceAlias;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [openIFrame, setOpenIFrame] = useState(false);

  const intl = useIntl();

  const {
    data: meetServiceDetail,
    isFetching,
    isError,
    error,
  } = useGetMeetServicesDetail(alias);

  const { data: meetServicesAliasGroup } = useGetMeetServicesAliasGroup();

  const servicesDetailBC = useMemo(() => {
    return [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-meet",
        }),
        href: "/meet",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-services",
        }),
        href: "/meet",
        tab: "meet-services",
      },
      {
        id: 4,
        current: true,
        name: meetServiceDetail?.field_meet_service_title,
      },
    ];
  }, [intl, meetServiceDetail?.field_meet_service_title]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <MeetServiceFeatures data={meetServiceDetail?.field_services} />
      ),
    },
    {
      id: 2,
      disabled: meetServiceDetail?.field_connection_modes === "False",
      contents: <InquiryInformation data={meetServiceDetail} />,
    },
  ];

  useEffect(() => {
    if (meetServicesAliasGroup) {
      setCurrentIndex(
        meetServicesAliasGroup?.findIndex((item) => item === "/" + alias),
      );
    }
  }, [meetServicesAliasGroup, alias]);

  const handleClose = () => {
    setOpenIFrame(false);
  };
  const handleOpen = () => {
    setOpenIFrame(true);
  };

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openIFrame}
        onClick={handleClose}
      >
        <MainBannerIFrame
          title="Meet-Booking"
          src={meetServiceDetail?.field_widget?.[0]?.field_link.uri}
          frameborder="0"
          allowfullscreen
        />
      </Backdrop>

      <CustomMetatag metaData={meetServiceDetail} />

      <DetailPageHero data={meetServiceDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={meetServiceDetail} />
          <MuiBreadcrumbs
            breadcrumbArr={servicesDetailBC}
            pageTitle={meetServiceDetail?.field_meet_service_title}
            value={meetServiceDetail?.field_full_description}
            prevUUid={meetServicesAliasGroup?.[currentIndex - 1]}
            nextUUid={meetServicesAliasGroup?.[currentIndex + 1]}
            url={`/meet`}
            buttonName={<BookYourEventText />}
            outerLink={"https://inquiry.inspirekorea.com"}
            // buttonEvent={handleOpen}
          />
        </>

        <DetailPageContainer
          data={FilterDetailPageData(meetServiceDetail?.field_services_type)}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire
            data={meetServiceDetail}
            buttonEvent={handleOpen}
            noButton
            noMargin
          />
        </CommonMobileContainer>

        <OfferRecommendation data={meetServiceDetail} />

        <MomentumLayout data={meetServiceDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MeetServicesDetail;
