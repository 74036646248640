import React, { useRef, useState } from "react";

import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { DangerouslyHtml, MobileScrollArrow } from "@components/item";

import {
  BoxListTwoContainer,
  BoxListTwoItem,
  BoxListTwoContents,
} from "./BoxListStyling";

const BoxListTwo = ({ data }) => {
  const scrollItems = useRef([]);

  const [currentItem, setCurrentItem] = useState(0);

  return (
    data?.length > 0 && (
      <BoxListTwoContainer>
        <MobileScrollArrow
          length={data?.length}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          refs={scrollItems}
        />

        {data?.map((item, idx) => {
          return (
            <BoxListTwoItem
              key={item.title}
              ref={(el) => (scrollItems.current[idx] = el)}
            >
              <ImageWrapper radius="2.5rem" ratio="3 / 4">
                <Image src={item?.image?.src} alt={item?.image?.alt} />

                <GradientOverlay opacity="0.6" />
              </ImageWrapper>

              <BoxListTwoContents>
                <CommonTitleTwo>
                  <DangerouslyHtml value={item.title} />
                </CommonTitleTwo>
              </BoxListTwoContents>
            </BoxListTwoItem>
          );
        })}
      </BoxListTwoContainer>
    )
  );
};

export default BoxListTwo;
