import React, {useCallback, useMemo, useRef, useState} from "react";
import { useLocation } from "react-router-dom";
import { AccordionSummary } from "@mui/material";

import { image } from "src/theme";
import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import {DangerouslyHtml, MuiPagination} from "@components/item";
import {
  CustomerCenterArrow,
  CustomerCenterLeft,
  CustomerCenterListCounter,
  CustomerCenterTitle,
  CustomerCenterListAccordion,
  CustomerCenterListAccordionDetails,
  CustomerCenterListAccordionHeader,
} from "@pages/about-us/AboutUsStyling";
import { ErrorManage } from "@screens/index";
import { concatAllValue } from "@utils/modify-data/modifyData";
import {
  useGetAboutUsCustomerCenterCategory,
  useGetAboutUsCustomerCenterList,
} from "@utils/api-requests/AboutUsRequests";

const CustomerCenterList = () => {
  const list = useRef();

  const location = useLocation();

  const [page, setPage] = useState(1);

  const [filterType, setFilterType] = useState("all");
  const [expanded, setExpanded] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  // Access query parameters
  const paramValue = queryParams.get("filter");

  const {
    data: aboutUsCustomerCenterList,
    isFetching,
    isError,
    error,
  } = useGetAboutUsCustomerCenterList(filterType, page);

  const { data: aboutUsCustomerCenterCategory } =
    useGetAboutUsCustomerCenterCategory();

  const customerCenterCategories = useMemo(() => {
    const result = aboutUsCustomerCenterCategory?.map((item) => {
      paramValue === item.tid && setFilterType(item.tid);
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [aboutUsCustomerCenterCategory, paramValue]);

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const tabChange = useCallback(() => {
    setExpanded(false);
    setPage(1);
  }, []);

  return (
    <ErrorManage isFetching={isFetching} isError={isError} error={error}>
      <div ref={list}>
        <ListWithTabs
          tabArr={concatAllValue(customerCenterCategories)}
          filterType={filterType}
          setFilterType={setFilterType}
          changeEvent={tabChange}
        >
          {aboutUsCustomerCenterList?.map((item) => {
            return (
              <CustomerCenterListAccordion
                square
                key={item.field_customer_center_title}
                expanded={expanded === item.field_customer_center_title}
                onChange={handleChange(item.field_customer_center_title)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <CustomerCenterListAccordionHeader>
                    <CustomerCenterLeft>
                      <CustomerCenterListCounter
                        expanded={expanded === item.field_customer_center_title}
                      >
                        {item.counter}
                      </CustomerCenterListCounter>

                      <CustomerCenterTitle>
                        <span>{item.field_customer_center_category}</span>

                        <CommonDescTwo>
                          <DangerouslyHtml
                            className="ellipsis-1"
                            value={item.field_customer_center_title}
                          />
                        </CommonDescTwo>
                      </CustomerCenterTitle>
                    </CustomerCenterLeft>

                    <CustomerCenterArrow
                      expanded={expanded === item.field_customer_center_title}
                      src={image.faqAngleDown.default}
                      alt="down arrow"
                      height={12}
                    />
                  </CustomerCenterListAccordionHeader>
                </AccordionSummary>

                <CustomerCenterListAccordionDetails>
                  <DangerouslyHtml value={item.field_customer_center_contents}/>
                </CustomerCenterListAccordionDetails>
              </CustomerCenterListAccordion>
            );
          })}
        </ListWithTabs>
      </div>

      {aboutUsCustomerCenterList?.length > 0 && (
        <MuiPagination
          listRef={list}
          count={aboutUsCustomerCenterList?.[0]?.pager?.total_pages}
          defaultPage={1}
          page={page}
          setPage={setPage}
        />
      )}
    </ErrorManage>
  );
};

export default CustomerCenterList;
