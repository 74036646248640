import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Alert } from "@mui/material";

import { PrimaryButton } from "@components/buttons";
import VerificationCodeForm from "@pages/momentum/sign-in/forget-password/VerificationCodeForm";
import { OkText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { CenteredModal } from "..";

const ActivationCodeModal = ({
  openModal,
  setOpenModal,
  email,
  signUpData,
}) => {
  const intl = useIntl();

  const [successMessage, setSuccessMessage] = useState({});

  const setData = (data) => {
    setSuccessMessage(data);
  };

  return (
    <CenteredModal
      modalName={intl.formatMessage({
        id: "lang-activation-code",
      })}
      openModal={openModal}
      setOpenModal={setOpenModal}
      uppercase
      noCloseButton={true}
    >
      {Object.keys(successMessage).length > 0 &&
      successMessage?.message !== "" ? (
        <>
          <Alert
            severity="success"
            sx={{ width: "100%", marginBottom: "16px" }}
          >{`${successMessage?.message}`}</Alert>

          <PrimaryButton linkTo={successMessage?.path}>
            <OkText />
          </PrimaryButton>
        </>
      ) : (
        <VerificationCodeForm
          email={email}
          success={(data) => setData(data)}
          signUpData={signUpData}
        />
      )}
    </CenteredModal>
  );
};

export default ActivationCodeModal;
