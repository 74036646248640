import React from "react";

import { DangerouslyHtml } from "@components/item";
import {
  MeetSightseeingActivitiesContainer,
  MeetSightseeingActivitiesCounter,
  MeetSightseeingActivitiesImage,
  MeetSightseeingActivitiesList,
  MeetSightseeingActivitiesName,
} from "@pages/meet/MeetStyling";
import { CommonTitleSix } from "@assets/styles/CommonStyles";

const MeetSightseeingActivities = ({ data }) => {
  return (
    <MeetSightseeingActivitiesContainer>
      <MeetSightseeingActivitiesName>
        <DangerouslyHtml value={data?.field_near_the_resort_name} />
      </MeetSightseeingActivitiesName>

      <MeetSightseeingActivitiesImage
        src={`${process.env.REACT_APP_SERVER_URL}${data?.field_logo[0]?.src}`}
        alt={data?.field_logo[0]?.alt}
      />

      <MeetSightseeingActivitiesList>
        {data?.field_near_the_resort_informatio?.map((item, idx) => {
          return (
            <li key={item.field_name}>
              <MeetSightseeingActivitiesCounter>
                {idx + 1}
              </MeetSightseeingActivitiesCounter>

              <div>
                <CommonTitleSix>
                  <DangerouslyHtml value={item.field_name} />
                </CommonTitleSix>

                <p>
                  <DangerouslyHtml value={item.field_distance} />
                </p>
              </div>
            </li>
          );
        })}
      </MeetSightseeingActivitiesList>
    </MeetSightseeingActivitiesContainer>
  );
};

export default MeetSightseeingActivities;
