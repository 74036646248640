import React from "react";

import { image } from "src/theme";
import { CommonDescTwo, CommonTitleFour } from "@assets/styles/CommonStyles";
import { DangerouslyHtml, PdfPreview } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import {
  FacilitySpecificationFactList,
  FacilitySpecificationInformation,
  FacilitySpecificationPdfWrapper,
} from "@pages/entertainment/EntertainmentStyling";
import { CommonVideo } from "@pages/shop";
import { PdfDownloadText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { downloadPdf } from "@utils/modify-data/modifyData";

const FacilitySpecification = ({ data }) => {
  return (
    <CommonContentButtonWrapper>
      {data?.field_facility_specification_vid && (
        <CommonContainer>
          <CommonVideo data={data?.field_facility_specification_vid} />
        </CommonContainer>
      )}

      <CommonContainer>
        <FacilitySpecificationInformation>
          <FacilitySpecificationPdfWrapper>
            {data.field_facts_pdf &&
              data.field_facts_pdf.split(", ").map((item) => {
                return (
                  <PdfPreview
                    key={item}
                    pdfName=""
                    pdfLink={item}
                    ratio="1 / 1"
                  />
                );
              })}
          </FacilitySpecificationPdfWrapper>

          <FacilitySpecificationFactList className="list-container">
            {data?.field_fact_list.map((item) => {
              return (
                <li key={item.field_fact_description}>
                  <CommonTitleFour>
                    <DangerouslyHtml value={item.field_title} />
                  </CommonTitleFour>

                  <CommonDescTwo>
                    <DangerouslyHtml value={item.field_fact_description} />
                  </CommonDescTwo>
                </li>
              );
            })}
          </FacilitySpecificationFactList>
        </FacilitySpecificationInformation>
      </CommonContainer>

      {data?.field_pdf_download !== "" && (
        <PrimaryButton
          noArrow
          buttonEvent={() =>
            downloadPdf("About Inspire Arena", data?.field_pdf_download)
          }
        >
          <PdfDownloadText />

          <img
            src={image.downloadIcon.default}
            alt="download icon"
            width={25}
            height={25}
            loading="lazy"
          />
        </PrimaryButton>
      )}
    </CommonContentButtonWrapper>
  );
};

export default FacilitySpecification;
