import React, { memo } from "react";
import { Skeleton } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import {
  DangerouslyHtml,
  SectionDividerLogo,
  NotificationToggle,
} from "@components/item";
import { NormalOverlay } from "@components/item/Overlays";
import { CommonContainer } from "@components/layout/ContainerStyle";

import {
  SubPageHeroImage,
  SubpageContainer,
  SubpageDescription,
  SubpageTitleWrapper,
} from "./SubPageStyling";

const SubpageHero = ({ pageData, small }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return pageData ? (
    <SubpageContainer>
      <SubPageHeroImage url={pageData?.field_hero_image[0]?.src} small={small || pageData?.field_banner_size ==="True"}>
        <NormalOverlay opacity="0.4" />

        <SubpageTitleWrapper>
          <object data={image.logoWhiteMini.default} width={isMobile ? 30 : 60}>
            mini-INSPIRE-logo
          </object>

          <span />

          <h1>
            <DangerouslyHtml value={pageData?.field_hero_title} />
          </h1>

          <span />

          <object data={image.logoWhiteMini.default} width={isMobile ? 30 : 60}>
            mini-INSPIRE-logo
          </object>
        </SubpageTitleWrapper>
      </SubPageHeroImage>

      <CommonContainer>
        <NotificationToggle data={pageData} />
      </CommonContainer>

      {pageData?.field_page_information && (
        <SubpageDescription>
          <DangerouslyHtml value={pageData?.field_page_information} />
        </SubpageDescription>
      )}

      {pageData?.field_page_information && <SectionDividerLogo />}
    </SubpageContainer>
  ) : (
    <Skeleton variant="rounded" width="100%" height="60vh" />
  );
};

export default memo(SubpageHero);
