import React, { useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { CommonErrorMessage } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";
import { RangeCalendar } from "@components/calendar";
import {
  BookingModalArrow,
  BookingModalGuestsIn,
  BookingModalGuestsWrapper,
  BookingModalWrapper,
} from "@components/item/ItemStyling";
import { yupBooking } from "@components/inputs/yupValidation";
import { BookingSelectPeopleModal } from "@components/modal/modal-list/BookingModal";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  CheckAvailabilityText,
  CheckInCheckOutText,
  NumberofGuestsText,
  OfferCodeText,
  SelectDatesText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { openBookingSite } from "@utils/modify-data/modifyData";
import { IsDesktop } from "@utils/media-query/mediaQuery";
import { useGetFooterQuery } from "@utils/api-requests/GlobalRequests";

import { image } from "src/theme";
import {
  MainBannerBooking,
  MainBannerBookingButton,
  MainBannerBookingHeader,
  MainBannerBookingSection,
  MainBannerBookingWrapper,
} from "./HomeStyling";

const MainBannerBookingBanner = () => {
  const parentRef = useRef(null);

  const { footerContact } = useGetFooterQuery();

  const intl = useIntl();

  const [guests, setGuests] = useState(
    `1 ${intl.formatMessage({
      id: "lang-guests",
    })}`
  );

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [peopleModalOn, setPeopleModalOn] = useState(false);

  const [dates, setDates] = useState(<SelectDatesText />);

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupBooking),
    mode: "onChange",
    defaultValues: {
      startdate: "",
      adults: 1,
      children: 0,
      enddate: "",
      Noguest: 1,
      offercode: "",
    },
  });

  const handleOpenCalendar = useCallback(() => {
    setCalendarOpen(true);
  }, [setCalendarOpen]);

  const onSubmit = useCallback(
    (data) => {
      openBookingSite(data, footerContact?.field_offer_default_code);
    },
    [footerContact?.field_offer_default_code]
  );

  const onClickGuests = useCallback(() => {
    setPeopleModalOn((prev) => !prev);
  }, []);

  return (
    <IsDesktop>
      <CommonContainer>
        <MainBannerBooking onSubmit={handleSubmit(onSubmit)}>
          <MainBannerBookingWrapper>
            <MainBannerBookingSection>
              <MainBannerBookingHeader>
                <CheckInCheckOutText />

                <CommonErrorMessage>
                  {errors.startdate?.message}
                </CommonErrorMessage>
              </MainBannerBookingHeader>

              <MainBannerBookingButton>
                <button onClick={handleOpenCalendar} type="button">
                  {dates}
                </button>

                <RangeCalendar
                  calendarOpen={calendarOpen}
                  setCalendarOpen={setCalendarOpen}
                  setDates={setDates}
                  setValue={setValue}
                />
              </MainBannerBookingButton>
            </MainBannerBookingSection>

            <BookingModalWrapper>
              <MainBannerBookingSection>
                <MainBannerBookingHeader>
                  <NumberofGuestsText />
                </MainBannerBookingHeader>

                <MainBannerBookingButton
                  ref={parentRef}
                  onClick={onClickGuests}
                >
                  <BookingModalGuestsIn>
                    <p>{guests}</p>

                    <BookingModalGuestsWrapper>
                      <BookingModalArrow
                        src={image.toggleDownPurple.default}
                        alt="Open Modal"
                        active={peopleModalOn}
                      />
                    </BookingModalGuestsWrapper>
                  </BookingModalGuestsIn>
                </MainBannerBookingButton>

                {peopleModalOn && (
                  <BookingSelectPeopleModal
                    watch={watch}
                    setValue={setValue}
                    setGuests={setGuests}
                    setPeopleModalOn={setPeopleModalOn}
                    parentRef={parentRef}
                  />
                )}
              </MainBannerBookingSection>
            </BookingModalWrapper>

            <MainBannerBookingSection>
              <MainBannerBookingHeader>
                <OfferCodeText />
              </MainBannerBookingHeader>

              <input
                type="text"
                placeholder={intl.formatMessage({
                  id: "lang-enter-code-here",
                })}
                {...register("offercode")}
              />
            </MainBannerBookingSection>
          </MainBannerBookingWrapper>

          <PrimaryButton
            buttonType="submit"
            customstyle={{ width: "fit-content" }}
          >
            <CheckAvailabilityText />
          </PrimaryButton>
        </MainBannerBooking>
      </CommonContainer>
    </IsDesktop>
  );
};

export default MainBannerBookingBanner;
