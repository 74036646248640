import React, { memo, useEffect, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { BorderButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { CommonFullContainer } from "@components/layout/ContainerStyle";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { doubleData } from "@utils/modify-data/modifyData";

import {
  SliderFourArrowWrapper,
  SliderFourButtonCategory,
  SliderFourButtonWrapper,
  SliderFourContainer,
  SliderFourCustomImageWrapper,
  SliderFourCutomOverlay,
  SliderFourInformation,
  SliderFourLeftArrow,
  SliderFourRightArrow,
  SliderFourSliderIn,
  SliderFourSubTitle,
  SliderFourSwiper,
  SliderFourTitle,
} from "./SlidersStyling";

const SliderFourItem = ({
  item,
  idx,
  titleKey,
  descKey,
  link,
  linkKey = "url_alias",
  subTitleKey,
  iconNameKey,
  icon,
  onlyLinkKey,
}) => {
  function backgroundColor(color) {
    switch (color) {
      // purple
      case 0:
        return "104, 80, 124";

      // yellow
      case 1:
        return "225, 177, 26";

      // blue
      case 2:
        return "124, 143, 172";

      default:
        return "0, 0, 0";
    }
  }

  return (
    <SliderFourSliderIn backgroundrgb={backgroundColor(idx % 3)}>
      <ImageWrapper custom={SliderFourCustomImageWrapper}>
        <Image
          src={item?.field_thumbnail[0]?.src}
          alt={item?.field_thumbnail[0]?.alt}
        />

        <SliderFourCutomOverlay />

        <SliderFourInformation>
          <SliderFourTitle className="ellipsis-2">
            <DangerouslyHtml value={item?.[titleKey]} />
          </SliderFourTitle>

          {subTitleKey && item?.[subTitleKey] && (
            <SliderFourSubTitle>{item?.[subTitleKey]}</SliderFourSubTitle>
          )}

          <CommonDescTwo>
            <DangerouslyHtml value={item?.[descKey]} className="ellipsis-6" />
          </CommonDescTwo>

          <SliderFourButtonWrapper>
            <BorderButton
              linkTo={
                onlyLinkKey ? item[onlyLinkKey] : `${link}${item[linkKey]}`
              }
              noArrow
              small
            >
              <ViewDetailsText />
            </BorderButton>

            {iconNameKey && item[iconNameKey] !== "" && (
              <SliderFourButtonCategory>
                {item?.[icon] !== "" && (
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}${item?.[icon]?.[0]?.src}`}
                    alt={item?.[icon]?.[0]?.alt}
                    width={20}
                    height={20}
                    loading="lazy"
                  />
                )}

                <p>{item[iconNameKey]}</p>
              </SliderFourButtonCategory>
            )}
          </SliderFourButtonWrapper>
        </SliderFourInformation>
      </ImageWrapper>
    </SliderFourSliderIn>
  );
};

const SliderFour = ({
  data,
  titleKey,
  descKey,
  link,
  linkKey = "url_alias",
  subTitleKey,
  iconNameKey,
  icon,
  onlyLinkKey,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [changedData, setChangedData] = useState();
  const [slidesPerView, setSlidesPerView] = useState(
    data?.length < 4 ? data?.length : 3.4
  );

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    loopedSlides: slidesPerView,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: data?.length < 4 ? data?.length : 3.4,
      },
    },
  };

  useEffect(() => {
    doubleData(
      slidesPerView,
      data,
      setChangedData,
      false,
      data?.length <= 2 && isDesktop ? true : false
    );
  }, [data, slidesPerView, isDesktop]);

  useEffect(() => {
    if (data?.length < 4 || isDesktop) {
      setSlidesPerView(data?.length);
    }
  }, [data?.length, isDesktop]);

  return (
    changedData?.length > 0 && (
      <CommonFullContainer noBlur={data?.length < 4}>
        {(data?.length >= 4 || !isDesktop) && (
          <SliderFourArrowWrapper>
            <SliderFourLeftArrow ref={navPrevRef} direction="left" />

            <MobileSlideText />

            <SliderFourRightArrow ref={navNextRef} direction="right" />
          </SliderFourArrowWrapper>
        )}

        {data?.length >= 4 || !isDesktop ? (
          <SliderFourSwiper
            {...custom}
            length={data?.length}
            onResize={(swiper) => {
              swiper.params.navigation.prevEl = navPrevRef.current;
              swiper.params.navigation.nextEl = navNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
              setSlidesPerView(swiper.params.slidesPerView);
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = navPrevRef.current;
              swiper.params.navigation.nextEl = navNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {changedData?.map((item, idx) => {
              return (
                <SwiperSlide key={`${item.uuid}${idx}`}>
                  <SliderFourItem
                    item={item}
                    idx={idx}
                    titleKey={titleKey}
                    descKey={descKey}
                    link={link}
                    linkKey={linkKey}
                    subTitleKey={subTitleKey}
                    iconNameKey={iconNameKey}
                    icon={icon}
                    onlyLinkKey={onlyLinkKey}
                  />
                </SwiperSlide>
              );
            })}
          </SliderFourSwiper>
        ) : (
          <SliderFourContainer>
            {data?.map((item, idx) => {
              return (
                <SliderFourItem
                  key={`${item.uuid}${idx}`}
                  item={item}
                  idx={idx}
                  titleKey={titleKey}
                  descKey={descKey}
                  link={link}
                  linkKey={linkKey}
                  subTitleKey={subTitleKey}
                  iconNameKey={iconNameKey}
                  icon={icon}
                  onlyLinkKey={onlyLinkKey}
                />
              );
            })}
          </SliderFourContainer>
        )}
      </CommonFullContainer>
    )
  );
};

export default memo(SliderFour);
