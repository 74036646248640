import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { MuiBreadcrumbs, NotificationToggle } from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsTermsConditions } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import { aboutUsRouteList } from "../aboutUsRouteList";
import { AboutUsTermList } from ".";

const AboutUsTermsConditionsDetail = () => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsTermsConditions,
    isLoading,
    isError,
    error,
  } = useGetAboutUsTermsConditions();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-about-us",
        }),
        href: "/about-us",
      },
      {
        id: 3,
        current: true,
        name: aboutUsTermsConditions?.field_page_name,
      },
    ];

    return roomDetails;
  }, [aboutUsTermsConditions?.field_page_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <AboutUsTermList data={aboutUsTermsConditions?.field_terms_condition} />
      ),
    },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsTermsConditions} />

      <DetailPageHero data={aboutUsTermsConditions} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsTermsConditions} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={aboutUsTermsConditions?.field_page_name}
            value={aboutUsTermsConditions?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsTermsConditions} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default AboutUsTermsConditionsDetail;
