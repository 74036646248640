import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { DangerouslyHtml } from "@components/item";

const AmenityFeatures = ({ data }) => {
  return (
    data?.[0]?.title !== null && (
      <BoxListThreeContainer>
        {data?.map((feature, idx) => {
          return (
            <BoxListThreeWrapper key={feature.title} reverse={idx % 2 > 0}>
              <ImageWrapper radius="2.5rem" ratio="3 / 2">
                <Image src={feature.image?.src} alt={feature.image?.alt} />
              </ImageWrapper>

              <BoxListThreeInformation>
                <CommonTitleOne>
                  <DangerouslyHtml value={feature.title} />
                </CommonTitleOne>

                <CommonDescTwo>
                  <DangerouslyHtml value={feature.description} />
                </CommonDescTwo>
              </BoxListThreeInformation>
            </BoxListThreeWrapper>
          );
        })}
      </BoxListThreeContainer>
    )
  );
};

export default AmenityFeatures;
