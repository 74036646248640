import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import { CommonContainer } from "@components/layout/ContainerStyle";

import {
  MaintenanceScreenContainer,
  MaintenanceScreenDesc,
  MaintenanceScreenTitle,
  MaintenanceScreenWrapper,
} from "./ScreensStyling";
import {useNavigate} from "react-router-dom";

const MaintenanceScreen = () => {
  const navigate = useNavigate()
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  useEffect(() => {
    document.body.style.cssText = `
      overflow-y: hidden;
      max-height: 100vh;
      width: 100%;
      `;
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      // NOTE: redirect to home every 30seconds

      navigate("/")
    }, 30000);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <MaintenanceScreenContainer>
      <CommonContainer>
        <MaintenanceScreenWrapper>
          <object
            data={image.logoWhiteFull.default}
            width={isDesktop ? 410 : 300}
          >
            INSPIRE Entertainment Resort
          </object>

          <div>
            <MaintenanceScreenTitle>
              <p>we are performing maintenance at the moment</p>
            </MaintenanceScreenTitle>

            <MaintenanceScreenDesc>
              PLEASE CHECK BACK LATER
            </MaintenanceScreenDesc>
          </div>
        </MaintenanceScreenWrapper>
      </CommonContainer>
    </MaintenanceScreenContainer>
  );
};

export default MaintenanceScreen;
