import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const AutoCompleteSelect = ({
  arr,
  label,
  control,
  name,
  errors,
  setValue,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={arr?.[0]}
      render={({ field }) => {
        return (
          <FormControl error={!!errors[name]}>
            <Autocomplete
              {...field}
              disablePortal
              onChange={(event, newValue) => {
                setValue(name, newValue);
              }}
              isOptionEqualToValue={(option) => option === ""}
              options={arr.map((option) => option)}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} label={label} />}
            />

            {errors[name] && (
              <FormHelperText>{errors[name].message}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default AutoCompleteSelect;
