import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import {
  GalleryOne,
  MomentumLayout,
  PageBottomContents,
} from "@components/layout";
import {
  CommonContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { FrameFive } from "@components/frame";
import { ErrorManage } from "@screens/index";
import { useGetMeetWhyUsDetail } from "@utils/api-requests/MeetRequests";
import {
  FilterDetailPageData,
  checkIncludes,
} from "@utils/modify-data/modifyData";

import FAQ from "./FAQ";
import { Backdrop } from "@mui/material";
import { MainBannerIFrame } from "@pages/home/HomeStyling";
import { CustomMetatag } from "@utils/metatags";

const WhyUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openIFrame, setOpenIFrame] = useState(false);

  const intl = useIntl();
  const whyUsName = useParams().whyUsName.replaceAll("-", " ");

  const { data: whyUsList } = useGetMeetWhyUsDetail();

  const {
    data: whyUsDetail,
    isLoading,
    isError,
    error,
  } = useGetMeetWhyUsDetail(whyUsName);

  const sectionArray = [
    {
      id: 1,
      contents:
        whyUsDetail?.field_display_gallery_info === "On" ? (
          <GalleryOne data={whyUsDetail?.field_multiple_images} />
        ) : whyUsDetail?.field_display_ourteam_info === "On" ? (
          <FrameFive data={whyUsDetail?.field_our_teams} />
        ) : whyUsDetail?.field_display_faq_information === "On" ? (
          <FAQ />
        ) : null,
    },
  ];

  const meetWhyUsCategoryGroup = useMemo(() => {
    return whyUsList?.map((item) => {
      return item.field_faq_category;
    });
  }, [whyUsList]);

  useEffect(() => {
    if (meetWhyUsCategoryGroup) {
      setCurrentIndex(
        meetWhyUsCategoryGroup?.findIndex((el) => checkIncludes(el, whyUsName))
      );
    }
  }, [meetWhyUsCategoryGroup, whyUsName]);

  const whyUsDetailBC = useMemo(() => {
    const serviceDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-meet",
        }),
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-why-us",
        }),
      },
      {
        id: 4,
        current: true,
        name: whyUsDetail?.field_why_us_name,
      },
    ];

    return serviceDetails;
  }, [intl, whyUsDetail?.field_why_us_name]);

  const handleClose = () => {
    setOpenIFrame(false);
  };
  const handleOpen = () => {
    setOpenIFrame(true);
  };

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={whyUsDetail} />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openIFrame}
        onClick={handleClose}
      >
        <MainBannerIFrame
          title="Meet-Booking"
          src={whyUsDetail?.field_widget?.[0]?.field_link.uri}
          frameborder="0"
          allowfullscreen
        />
      </Backdrop>

      <DetailPageHero data={whyUsDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={whyUsDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={whyUsDetailBC}
            pageTitle={whyUsDetail?.field_why_us_name}
            value={whyUsDetail?.field_full_description}
            prevUUid={meetWhyUsCategoryGroup?.[currentIndex - 1]}
            nextUUid={meetWhyUsCategoryGroup?.[currentIndex + 1]}
            url={`/meet/why-us`}
          />
        </>

        <DetailPageContainer
          data={FilterDetailPageData(whyUsName)}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire data={whyUsDetail} buttonEvent={handleOpen} />
        </CommonMobileContainer>

        <OfferRecommendation data={whyUsDetail} />

        <MomentumLayout data={whyUsDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default WhyUs;
