import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ListWithTabs } from "@components/list";
import { useGetDiscoveryCategoryList } from "@utils/api-requests/EntertainmentRequests";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { CustomOverlay, GradientOverlay } from "@components/item/Overlays";
import {
  AttractionVenueExploreDesc,
  AttractionVenueExploreImage,
  AttractionVenueExploreWrapper,
} from "@pages/entertainment/EntertainmentStyling";
import { IsDefault } from "@utils/media-query/mediaQuery";

const AttractionVenueExplore = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [filterType, setFilterType] = useState("");
  const [currentMap, setCurrentMap] = useState();

  const { data: discoveryCategoryList } = useGetDiscoveryCategoryList();

  const aboutSpiritAquaiCategories = useMemo(() => {
    const result = discoveryCategoryList?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [discoveryCategoryList]);

  useEffect(() => {
    setCurrentMap(
      data?.field_discovery_map_information?.filter(
        (el) => el.field_map_category.id === filterType
      )[0]
    );
  }, [data?.field_discovery_map_information, filterType]);

  useEffect(() => {
    setFilterType(discoveryCategoryList?.[0]?.tid);
  }, [discoveryCategoryList]);

  return (
    <div>
      <ListWithTabs
        tabArr={aboutSpiritAquaiCategories}
        filterType={filterType}
        setFilterType={setFilterType}
      >
        <AttractionVenueExploreWrapper>
          <ImageWrapper
            ratio={isDesktop ? "5 / 2" : "1 / 2"}
            custom={AttractionVenueExploreImage}
          >
            <Image
              src={currentMap?.field_icon?.src}
              alt={currentMap?.field_icon?.src}
            />

            <AttractionVenueExploreDesc>
              <DangerouslyHtml value={currentMap?.field_full_description} />
            </AttractionVenueExploreDesc>
          </ImageWrapper>

          {filterType === "250" && (
            <IsDefault>
              <CustomOverlay opacity1={1} opacity2={0.4} />
            </IsDefault>
          )}

          <GradientOverlay opacity="0.86" />
        </AttractionVenueExploreWrapper>

        {/* <MapWithPings
          discovery
          image={currentMap?.field_icon}
          desc={currentMap?.field_full_description}
          filteredData={data?.field_explore_discove?.filter(
            (el) => el.field_spirit_of_aquai_categorys.id === filterType
          )}
        /> */}
      </ListWithTabs>
    </div>
  );
};

export default AttractionVenueExplore;
