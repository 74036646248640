import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { SecondaryButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsKnowMore } from "@utils/api-requests/AboutUsRequests";

import {
  AboutUsMoreButtons,
  AboutUsMoreContainer,
  AboutUsMoreTitle,
} from "./AboutUsStyling";

const AboutUsMore = () => {
  const { data: aboutUsKnowMore, isError, error } = useGetAboutUsKnowMore();

  return (
    <ErrorManage isError={isError} error={error}>
      <AboutUsMoreContainer>
        <div style={{ width: "100%" }}>
          <AboutUsMoreTitle>
            <DangerouslyHtml value={aboutUsKnowMore?.field_copywrite} />
          </AboutUsMoreTitle>

          <CommonDescTwo>
            <DangerouslyHtml value={aboutUsKnowMore?.field_description} />
          </CommonDescTwo>
        </div>

        <AboutUsMoreButtons>
          <SecondaryButton
            buttonColor="white"
            noArrow
            linkTo="/about-us/sitemap"
          >
            {aboutUsKnowMore?.field_sitemap_button_name}
          </SecondaryButton>

          <SecondaryButton
            buttonColor="white"
            noArrow
            linkTo="/about-us/floor-guide"
          >
            {aboutUsKnowMore?.field_floor_guide_button_name}
          </SecondaryButton>
        </AboutUsMoreButtons>
      </AboutUsMoreContainer>
    </ErrorManage>
  );
};

export default AboutUsMore;
