import React from "react";

import QRCodeComponent from "./MyAccountQRCode";

import { CommonDescFive, CommonTitleOne } from "@assets/styles/CommonStyles";

import {
  WelcomeMessageCard,
  WelcomeMessageContainer,
  WelcomeMessageInformation,
  WelcomeMessageItem,
} from "./MyProfileStyling";
import { useMediaQuery } from "react-responsive";

const WelcomeMessage = ({ data, userData, buckets, currentIndex }) => {
  // Find the bucket where BucketName is "Points"

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const pointsBucket = buckets?.find(
    (bucket) => bucket.BucketName.trim() === "Momentum Points",
  );

  const accountInformation = [
    {
      id: 1,
      label: data?.field_information_labels[0],
      value: userData?.Emails[0]?.EmailAddress,
    },
    {
      id: 2,
      label: data?.field_information_labels[1],
      value: userData?.Addresses[0] ? userData?.Addresses[0]?.CountryCode : "-",
    },
    {
      id: 3,
      label: data?.field_information_labels[2],
      value: userData?.DateofBirth,
    },
    {
      id: 4,
      label: data?.field_information_labels[3],
      value: pointsBucket?.BucketBalanceInPoints?.toLocaleString(),
    },
  ];

  return (
    <WelcomeMessageContainer>
      <div>
        <CommonTitleOne lowercase>
          {data?.field_welcome_message.replace("{{name}}", userData?.FirstName)}
        </CommonTitleOne>

        <WelcomeMessageInformation>
          {accountInformation.map((item) => {
            return (
              <WelcomeMessageItem key={item.id}>
                <CommonDescFive>{item.label}</CommonDescFive>

                <p>{item.value}</p>
              </WelcomeMessageItem>
            );
          })}
        </WelcomeMessageInformation>
      </div>

      {data && (
        <WelcomeMessageCard
          url={`${process.env.REACT_APP_SERVER_URL}${
            data?.field_tier[currentIndex]?.field_icon.src
          }`}
        >
          {isMobile && (
            <QRCodeComponent currentData={userData?.UniqueProfileId.replace(/(\d{4})(?=\d)/g, '$1 ')} />
          )}

          <div>
            {userData?.FullName} <br />
            {userData?.UniqueProfileId.replace(/(\d{4})(?=\d)/g, '$1 ')}
          </div>
        </WelcomeMessageCard>
      )}
    </WelcomeMessageContainer>
  );
};

export default WelcomeMessage;
