import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import {
  CommonContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import EatDrinkReservationModal from "@components/modal/modal-list/EatDrinkReservationModal";
import { SliderSix } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import {
  useGetRestaurantDetail,
  useGetRestaurantAliasGroup,
} from "@utils/api-requests/EatDrinkRequests";

import { BookYourTableText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  checkIncludes,
  textFormat,
  replaceAmpersand,
  switchLanguageToFitBooking
} from "@utils/modify-data/modifyData";

import {
  InteriorVibe,
  MeetOurChefs,
  RestaurantInformation,
  SignatureMenu,
  ViewMenu,
} from ".";
import {LOCAL_STORAGE_LANGUAGE} from "@utils/api-requests/static-data";

const RestaurantDetail = ({ pageInfo }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const intl = useIntl();
  const alias = useParams().restaurantAlias;
  const categoryId = useParams().restaurantCategoryId;

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: restaurantAliasGroup } = useGetRestaurantAliasGroup(categoryId);

  const {
    data: restaurantDetail,
    isFetching,
    isError,
    error,
    isFetched,
  } = useGetRestaurantDetail(alias);

  const restaurantDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-eat-and-drink",
      }),
      href: "/eat-and-drink",
    },
    {
      id: 3,
      name: textFormat(restaurantDetail?.field_parent_name),
      href: "/eat-and-drink",
      tab: restaurantDetail?.field_parent_name,
    },

    { id: 4, current: true, name: restaurantDetail?.field_restaurant_name },
  ];

  useEffect(() => {
    if (restaurantAliasGroup) {
      setCurrentIndex(
        restaurantAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [restaurantAliasGroup, alias]);

  useEffect(() => {
    if (
      isFetched &&
      restaurantDetail &&
      !checkIncludes(restaurantDetail?.field_parent_name_en, categoryId)
    ) {
      navigate("/not-found");
    }
  }, [categoryId, isFetched, navigate, restaurantDetail]);

  const sectionArray = [
    {
      id: 1,
      disabled:
        restaurantDetail?.field_multiple_images === "" ||
        !restaurantDetail?.field_multiple_images,
      contents: (
        <CommonMobileContainer>
          <SliderSix imageArr={restaurantDetail?.field_multiple_images}
                     sliderDesc={restaurantDetail?.field_gallery_description} />
        </CommonMobileContainer>
      ),
    },
    {
      id: 2,
      contents: (
        <>
          <SignatureMenu menuType={restaurantDetail?.field_service_entity_id} />

          {restaurantDetail?.field_view_menu_list.length > 0 && (
            <CommonMobileContainer>
              <ViewMenu viewMenuList={restaurantDetail?.field_view_menu_list} />
            </CommonMobileContainer>
          )}
        </>
      ),
    },
    {
      id: 3,
      contents: (
        <CommonMobileContainer>
          <InteriorVibe
            data={restaurantDetail?.field_restaurant_interior_vibe}
          />
        </CommonMobileContainer>
      ),
    },
    {
      id: 4,
      titleDirection: "left",
      contents: (
        <CommonMobileContainer>
          <MeetOurChefs data={restaurantDetail?.field_meet_our_chefs} />
        </CommonMobileContainer>
      ),
    },
  ];

  const handleOpen = useCallback(() => {
    setOpenModal(true);
  }, [setOpenModal]);

  return (
    <div>
      <EatDrinkReservationModal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <CustomMetatag metaData={restaurantDetail} />

      <ErrorManage isLoading={isFetching} isError={isError} error={error}>
        <DetailPageHero data={restaurantDetail} />
      </ErrorManage>

      <CommonContainer noMargin noPadding={isMobile}>
        <CommonMobileContainer>
          <>
            <NotificationToggle data={restaurantDetail} />

            <MuiBreadcrumbs
              breadcrumbArr={restaurantDetailBC}
              pageTitle={restaurantDetail?.field_restaurant_name}
              value={restaurantDetail?.body}
              prevUUid={restaurantAliasGroup?.[currentIndex - 1]}
              nextUUid={restaurantAliasGroup?.[currentIndex + 1]}
              url={`/eat-and-drink/${categoryId}`}
              buttonName={<BookYourTableText />}
              buttonEvent={handleOpen}
              outerLink={`${
                replaceAmpersand(restaurantDetail?.field_widget?.[0]?.field_link.uri)
              }${
                replaceAmpersand(restaurantDetail?.field_widget?.[0]?.field_link.uri)?.includes(
                  "?"
                )
                  ? "&"
                  : "?"
              }langCode=${switchLanguageToFitBooking(lang)}`}
            />
          </>

          <RestaurantInformation data={restaurantDetail} />
        </CommonMobileContainer>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire
            data={restaurantDetail}
            langCode
            buttonEvent={handleOpen}
            noButton
          />
        </CommonMobileContainer>

        <OfferRecommendation data={restaurantDetail} />

        <MomentumLayout data={restaurantDetail} />
      </PageBottomContents>
    </div>
  );
};

export default RestaurantDetail;
