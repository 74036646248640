import React from "react";

import { image } from "src/theme";

import { SectionDividerLogoContainer } from "./ItemStyling";

const SectionDividerLogo = () => {
  return (
    <SectionDividerLogoContainer>
      <hr />

      <img src={image.dividerLogo.default} alt="" />

      <hr />
    </SectionDividerLogoContainer>
  );
};

export default SectionDividerLogo;
