import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { CommonDescTwo } from "@assets/styles/CommonStyles";

import {
  BoxListSevenContainer,
  BoxListSevenInforamtion,
  BoxListSevenTitle,
} from "./BoxListStyling";
import { DangerouslyHtml } from "@components/item";

const BoxListSeven = ({ data }) => {
  return (
    data?.length > 0 && (
      <BoxListSevenContainer>
        {data?.map((item) => {
          return (
            <ImageWrapper ratio="2 / 3" radius="1.5rem">
              <Image src={item.thumbnail?.src} alt={item.thumbnail?.alt} />

              <BoxListSevenInforamtion>
                <BoxListSevenTitle>
                  <DangerouslyHtml value={item.name} />
                </BoxListSevenTitle>

                <CommonDescTwo className="ellipsis-3">
                  <DangerouslyHtml value={item.description} />
                </CommonDescTwo>
              </BoxListSevenInforamtion>

              <GradientOverlay opacity="0.6" />
            </ImageWrapper>
          );
        })}
      </BoxListSevenContainer>
    )
  );
};

export default BoxListSeven;
