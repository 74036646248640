import React, { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { PrimaryButton } from "@components/buttons";
import { CommonModal } from "@components/modal";
import { chunkData } from "@utils/modify-data/modifyData";
import { LoadMoreText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  GalleryOneContainer,
  GalleryOneImageItem,
  GalleryOneImagePreview,
  GalleryOneImages,
} from "./LayoutStyling";

const GalleryOne = ({ data, noClick, gallery }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [currentIndex, setCurrentIndex] = useState(1);
  const [slicedData, setSlicedData] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [imageInfo, setImageInfo] = useState([]);

  const handleLoadMore = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const handleModalOpen = useCallback((imageSrc, imageAlt) => {
    setOpenModal(true);
    setImageInfo([imageSrc, imageAlt]);
  }, []);

  useEffect(() => {
    setSlicedData(chunkData(data));
  }, [data]);

  return (
    <GalleryOneContainer>
      <CommonModal
        noName
        name="gallery-modal"
        description="gallery modal"
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <GalleryOneImagePreview>
          <img
            src={`${process.env.REACT_APP_SERVER_URL}${imageInfo[0]}`}
            alt={imageInfo[1]}
            width="100%"
            height="100%"
            loading="lazy"
          />
        </GalleryOneImagePreview>
      </CommonModal>

      {slicedData.slice(0, currentIndex)?.map((slidedImages, idx) => {
        const length = slidedImages?.length % 6;

        return (
          <GalleryOneImages length={length} key={idx}>
            {slidedImages?.map((image, idx) => {
              return (
                <GalleryOneImageItem
                  key={`${image?.src}${idx}`}
                  noClick
                  onClick={() => {
                    if (!noClick) {
                      handleModalOpen(image?.src, image?.alt);
                    }
                  }}
                >
                  <ImageWrapper
                    ratio={
                      (length === 1 && isDesktop) ||
                      (length === 3 && idx === 0 && isDesktop) ||
                      (length === 4 && idx === 0 && isDesktop)
                        ? "3 / 1"
                        : "1 / 1"
                    }
                    radius="1.5rem"
                  >
                    <Image src={image?.src} alt={image?.alt} />

                    <GradientOverlay opacity={0.6} />
                  </ImageWrapper>
                </GalleryOneImageItem>
              );
            })}
          </GalleryOneImages>
        );
      })}

      {slicedData?.length > currentIndex && (
        <PrimaryButton buttonEvent={handleLoadMore}>
          <LoadMoreText />
        </PrimaryButton>
      )}
    </GalleryOneContainer>
  );
};

export default GalleryOne;
