import { keyframes } from "@emotion/react";
import { mq, mqMax } from "@utils/media-query/mediaQuery";

const { default: styled } = require("@emotion/styled");
const {
  loadingScreenZIndex,
  maintenanceScreenZIndex,
} = require("@assets/styles/CommonStyles");

// STYLE: LoadingScreen.jsx
export const LoadingScreenContainer = styled.div(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.color.white,
  width: "100%",
  minWidth: "100vw",
  height: "100vh",
  maxHeight: "100vh",
  zIndex: loadingScreenZIndex,
  color: "white",
  overflow: "hidden",

  "& > div": {
    opacity: 1,
    position: "absolute",
    transition: "all 0.1s",
    width: "100%",
    height: "100%",
    animationTimingFunction: "ease",
    animationFillMode: "both",
    animationDuration: "1s",
  },
}));

export const LoadingImage = styled.img(() => ({
  width: "100%",
  height: "100%",
}));

const moveRight = (left) => keyframes`
  0 {
    opacity: 0;
    left: ${left};
  }

  100% {
    left: 100%;
    opacity: 1;
  }
`;

const moveLeft = (left) => keyframes`
  0 {
    left: 100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: ${left};
  }
`;

const moveBottom = (height) => keyframes`
  0 {
    opacity: 0;
    top: ${height};
  }

  100% {
    top: 100%;
    opacity: 1;
  }
`;

const moveTop = (height) => keyframes`
  0 {
    top: 100%;
    opacity: 0;
  }

  100% {
    opacity: 1;
    top: ${height};
  }
`;

export const LoadingLogo = styled.object(() => ({
  width: "24rem",
  height: "24rem",

  [mq("desktop")]: {
    width: "35rem",
  },
}));

export const LoadingPhasePurple = styled.div(({ theme }) => ({
  left: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "5rem 0",
  background: theme.color.primary_color,
}));

export const LoadingPhaseBrown = styled.div(({ theme, animationRight }) => ({
  background: theme.color.brown_color,
  animationDelay: animationRight ? "2s" : 0,

  [mqMax("desktop")]: {
    // when mobile
    top: animationRight ? "55%" : "100%",
    animationName: `${animationRight ? moveBottom("55%") : moveTop("55%")}`,
  },

  [mq("desktop")]: {
    left: animationRight ? "55%" : "100%",
    animationName: `${animationRight ? moveRight("55%") : moveLeft("55%")}`,
  },
}));

export const LoadingPhaseYellow = styled.div(({ theme, animationRight }) => ({
  background: theme.color.secondary_color,
  animationDelay: "1s",

  [mqMax("desktop")]: {
    // when mobile
    top: animationRight ? "60%" : "100%",
    animationName: `${animationRight ? moveBottom("60%") : moveTop("60%")}`,
  },

  [mq("desktop")]: {
    left: animationRight ? "60%" : "100%",
    animationName: `${animationRight ? moveRight("60%") : moveLeft("60%")} `,
  },
}));

export const LoadingPhaseGrey = styled.div(({ theme, animationRight }) => ({
  background: theme.color.tertiary_color,
  animationDelay: animationRight ? 0 : "2s",

  [mqMax("desktop")]: {
    // when mobile
    top: animationRight ? "80%" : "100%",
    animationName: `${animationRight ? moveBottom("80%") : moveTop("80%")}`,
  },

  [mq("desktop")]: {
    left: animationRight ? "80%" : "100%",
    animationName: `${animationRight ? moveRight("80%") : moveLeft("80%")}`,
  },
}));

// STYLE: NotFound.jsx
export const NotFoundContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

export const NotFoundBanner = styled.div(({ url }) => ({
  width: "100%",
  height: "50vh",
  overflow: "hidden",

  backgroundImage: `url("${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  zIndex: 1,

  [mq("desktop")]: {
    aspectRatio: "3 / 1",
    height: "100%",
  },
}));

export const NotFoundInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "10rem 0",
  gap: "5rem 0",
  textAlign: "center",
}));

const spinning = (height) => keyframes`
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const LoadingSpinnerSvg = styled.svg`
  width: 6.4rem;
  animation: ${spinning} 1s ease infinite;
`;

// STYLE: MaintenanceScreen.jsx
export const MaintenanceScreenContainer = styled.div(({ theme }) => ({
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#405B7D",
  width: "100vw",
  inset: 0,
  maxHeight: "100vh",
  height: "100vh",
  fontFamily: theme.fontFamily.primary,
  textTransform: "uppercase",
  fontSize: "1.6rem",
  color: theme.color.white,
  fontWeight: theme.fontWeight.medium,
  zIndex: maintenanceScreenZIndex,
  overflow: "hidden",
}));

export const MaintenanceScreenWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "6rem 0",
}));

export const MaintenanceScreenTitle = styled.div(() => ({
  background: "#1B3757",
  padding: "2rem",
  textAlign: "center",
  lineHeight: 1.8,
}));

export const MaintenanceScreenDesc = styled.p(() => ({
  fontSize: "1.2rem",
  marginTop: "2rem",
  textAlign: "center",
}));
