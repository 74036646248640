import React from "react";

import { TableOneContainer, TableOneWrapper } from "./TableStyling";

const TableOne = ({ thead, tbody }) => {
  return (
    <div
      style={{
        overflowX: "auto",
      }}
    >
      <TableOneContainer id="table-scroll">
        <TableOneWrapper className="table">
          <thead>
            {thead.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map((th) => {
                    return (
                      <th {...th} key={th.id}>
                        {th.name}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>

          <tbody>
            {tbody.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map((td) => {
                    return (
                      <td
                        {...td}
                        key={td.id}
                        className={td.fixed === "true" ? "fixed" : ""}
                      >
                        {td.name}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </TableOneWrapper>
      </TableOneContainer>
    </div>
  );
};

export default TableOne;
