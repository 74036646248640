import React, { memo } from "react";

import { PrimaryButton } from "@components/buttons";

import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  OffersText,
  ViewDetailsText, ViewRulesText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { TranslateDayMonth, textFormat } from "@utils/modify-data/modifyData";
import { IsDesktop } from "@utils/media-query/mediaQuery";

import {
  BoxListFourItemContainer,
  BoxListFourItemIn,
  BoxListFourItemInformation,
  BoxListFourItemTitle,
  BoxListFourItemType,
  BoxListFourContainer,
  BoxListFourDesc,
  BoxListFourDate,
} from "./BoxListStyling";

export const BoxListFourItem = ({
  idx,
  item,
  imageKey,
  centered,
  nameKey,
  descKey,
  linkTo,
  query,
  children,
  casinoGame
}) => {
  return (
    <BoxListFourItemContainer key={idx}>
      <ImageWrapper radius="1.5rem 1.5rem 0 0" ratio="3 / 2">
        <Image src={item[imageKey][0]?.src} alt={item[imageKey][0]?.alt} />
      </ImageWrapper>

      <BoxListFourItemInformation>
        <BoxListFourItemIn centered={centered}>
          <BoxListFourItemTitle className="ellipsis-2">
            <DangerouslyHtml value={item[nameKey]} />
          </BoxListFourItemTitle>

          {/* <BoxListFourItemType>
            <ComingSoonText />
          </BoxListFourItemType> */}

          {children}

          <BoxListFourDesc className="ellipsis-2">
            <DangerouslyHtml value={item[descKey]} />
          </BoxListFourDesc>
        </BoxListFourItemIn>

        <PrimaryButton
          noArrow
          linkTo={`${linkTo}${item.url_alias}${query ? query : ""}`}
          detailButton
        >
          {casinoGame ? <ViewRulesText /> : <ViewDetailsText />}
        </PrimaryButton>
      </BoxListFourItemInformation>
    </BoxListFourItemContainer>
  );
};

const BoxListFour = ({
  data,
  imageKey = "field_offer_thumbnail",
  nameKey = "field_offer_name",
  descKey = "field_offer_short_desc",
  linkTo = "/offers/detail",
  centered,
  query,
  singleTypeKey,
  typeKey,
  offer,
  dateKey,
  html,
  casinoGame
}) => {
  return (
    data?.length > 0 && (
      <BoxListFourContainer>
        {data?.map((item, idx) => {
          return (
            <BoxListFourItem
              key={idx}
              idx={idx}
              item={item}
              imageKey={imageKey}
              centered={centered}
              nameKey={nameKey}
              descKey={descKey}
              linkTo={linkTo}
              query={query}
              offer={offer}
              casinoGame={casinoGame}
            >
              <div>
                <BoxListFourItemType>
                  {singleTypeKey !== "" && (
                    <span>
                      <DangerouslyHtml value={item[singleTypeKey]} />
                    </span>
                  )}

                  {!casinoGame && <div>
                    {offer ? (
                      <div>
                        <span>
                          <DangerouslyHtml
                            value={textFormat(item[typeKey]?.name)}
                          />

                          <OffersText/>
                        </span>
                      </div>
                    ) : (
                      <IsDesktop>
                        <div>{item[typeKey] !== "" && item[typeKey]}</div>
                      </IsDesktop>
                    )}

                    {item[typeKey]?.id !== "45" ? null : (
                      <BoxListFourDate>
                        {offer ? (
                          <>Expires: {TranslateDayMonth(item[dateKey])}</>
                        ) : html ? (
                          <DangerouslyHtml value={item[dateKey]}/>
                        ) : (
                          TranslateDayMonth(item[dateKey])
                        )}
                      </BoxListFourDate>
                    )}

                    {item[typeKey]?.id === undefined &&
                      TranslateDayMonth(item[dateKey])}
                  </div>}
                </BoxListFourItemType>

                {/* <BoxListFourItemType>
                  {offer && (
                    <div>
                      <StartsFromText />: {item.field_bookable_end_date}
                    </div>
                  )}
                </BoxListFourItemType> */}
              </div>
            </BoxListFourItem>
          );
        })}
      </BoxListFourContainer>
    )
  );
};

export default memo(BoxListFour);
