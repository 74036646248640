import React, { useEffect, useRef, useState } from "react";

import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import {
  BoxListFiveContainer,
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
} from "@components/box-list/BoxListStyling";
import {
  BoxListFiveDetailButton,
  BoxListFiveOverlays,
} from "@components/box-list/BoxListFive";
import { ErrorManage } from "@screens/index";
import { useGetMeetSightSeeing } from "@utils/api-requests/MeetRequests";
import { useLocation } from "react-router-dom";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const MeetSightseeing = () => {
  const search = useLocation().search;

  const meetSightseeingRef = useRef();

  const {
    data: meetSightseeing,
    isLoading,
    isError,
    error,
  } = useGetMeetSightSeeing();

  const [onHover, setOnHover] = useState(-1);

  const onMouseEnter = (i) => {
    setOnHover(i);
  };

  const onMouseLeave = () => {
    setOnHover(-1);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "meet-sightseeing") {
        scrollToTargetAdjusted(meetSightseeingRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  return (
    <CommonContainer>
      <div ref={meetSightseeingRef}>
        <BoxListFiveContainer length={2}>
          <ErrorManage isLoading={isLoading} isError={isError} error={error}>
            {meetSightseeing?.map((item, idx) => {
              return (
                <BoxListFiveGridArea
                  key={item.uuid}
                  onMouseEnter={() => onMouseEnter(idx)}
                  onMouseLeave={onMouseLeave}
                >
                  <ImageWrapper
                    radius="1.5rem"
                    ratio={idx === 0 || idx === 1 ? "3 / 2" : "1 / 1"}
                  >
                    <Image
                      src={item?.field_thumbnail[0]?.src}
                      alt={item?.field_thumbnail[0]?.alt}
                    />

                    <BoxListFiveInformation>
                      <CommonTitleTwo>
                        <DangerouslyHtml value={item.field_sightseeing_title} />
                      </CommonTitleTwo>

                      <BoxListFiveDesc className="ellipsis-3">
                        <DangerouslyHtml value={item.field_sightseeing} />
                      </BoxListFiveDesc>

                      <BoxListFiveDetailButton
                        onHover={onHover}
                        idx={idx}
                        item={item}
                        url="/meet/sightseeing"
                      />
                    </BoxListFiveInformation>

                    <BoxListFiveOverlays onHover={onHover} idx={idx} />
                  </ImageWrapper>
                </BoxListFiveGridArea>
              );
            })}
          </ErrorManage>
        </BoxListFiveContainer>
      </div>
    </CommonContainer>
  );
};

export default MeetSightseeing;
