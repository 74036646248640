import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import {
  homeEntertainmentUrl,
  homeMiceUrl,
  homeOffersUrl,
  homeRoomUrl,
  homeShopUrl,
  homeSignUpUrl,
  homeSplashBayUrl,
  homeTowerUrl,
  homefandbUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// HomeOffers.jsx
export function useGetHomeOffersSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeOffersSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeOffersUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["homeOffersSection", language, localStorage],
    getHomeOffersSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeTower.jsx
export function useGetHomeTowerSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeTowerSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeTowerUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeTowerSection", language, localStorage],
    getHomeTowerSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeRoom.jsx
export function useGetHomeRoomSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeRoomSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeRoomUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeRoomSection", language, localStorage],
    getHomeRoomSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeShop.jsx
export function useGetHomeShopSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeShopSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeShopUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeShopSection", language, localStorage],
    getHomeShopSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeRestaurant.jsx
export function useGetHomeFandBSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeFandBSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homefandbUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeFandBSection", language, localStorage],
    getHomeFandBSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeEntertainment.jsx
export function useGetHomeEntertainmentSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeEntertainmentSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeEntertainmentUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeEntertainmentSection", language, localStorage],
    getHomeEntertainmentSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeSplashBay.jsx
export function useGetHomeSplashBaySection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeSplashBaySection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeSplashBayUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["homeSplashBaySection", language, localStorage],
    getHomeSplashBaySection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeMice.jsx
export function useGetHomeMiceSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeMiceSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeMiceUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["homeMiceSection", language, localStorage],
    getHomeMiceSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// HomeSignUp.jsx
export function useGetHomeSignUpSection() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHomeSignUpSection = useCallback(async () => {
    const { status, data } = await axios.get(
      `${homeSignUpUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["homeSignUpSection", language, localStorage],
    getHomeSignUpSection,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}
