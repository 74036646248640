import React from "react";

import { ListOneDesc, ListOneItem } from "@components/layout/LayoutStyling";
import { FacilityTechnologyContainer } from "@pages/entertainment/EntertainmentStyling";
import { DangerouslyHtml } from "@components/item";

const FacilityTechnology = ({ data }) => {
  return (
    <FacilityTechnologyContainer className="list-container">
      {data?.map((item) => {
        return (
          <ListOneItem key={item.title}>
            <img
              src={`${process.env.REACT_APP_SERVER_URL}${item.image?.src}`}
              alt={item.image.alt}
              loading="lazy"
            />

            <header>
              <DangerouslyHtml value={item.title} />
            </header>

            {item.description && (
              <ListOneDesc>
                <DangerouslyHtml value={item.description} />
              </ListOneDesc>
            )}
          </ListOneItem>
        );
      })}
    </FacilityTechnologyContainer>
  );
};

export default FacilityTechnology;
