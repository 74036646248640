import { FormattedMessage } from "react-intl";

export const variousSeatsSystemInfo = [
  [
    {
      id: 1,
      header: <FormattedMessage id="lang-floor" />,
      data: "1,600",
    },
    {
      id: 2,
      header: <FormattedMessage id="lang-lowel-bowl-retractable" />,
      data: "4,150",
    },
    {
      id: 3,
      header: <FormattedMessage id="lang-lowel-bowl-fixed" />,
      data: "5,070",
    },
    {
      id: 4,
      header: <FormattedMessage id="lang-upper-bowl-fixed" />,
      data: "3,430",
    },
    { id: 5, header: <FormattedMessage id="lang-sky-box" />, data: "380" },
    { id: 6, header: <FormattedMessage id="lang-wheelchair" />, data: "400" },
    {
      id: "total",
      header: <FormattedMessage id="lang-total" />,
      data: "15,030",
    },
  ],
  [
    { id: 1, header: <FormattedMessage id="lang-floor" />, data: "1,600" },
    {
      id: 2,
      header: <FormattedMessage id="lang-lowel-bowl-retractable" />,
      data: "3,000",
    },
    {
      id: 3,
      header: <FormattedMessage id="lang-lowel-bowl-fixed" />,
      data: "4,100",
    },
    {
      id: 4,
      header: <FormattedMessage id="lang-upper-bowl-fixed" />,
      data: "2,400",
    },
    { id: 5, header: <FormattedMessage id="lang-sky-box" />, data: "300" },
    { id: 6, header: <FormattedMessage id="lang-wheelchair" />, data: "400" },
    {
      id: "total",
      header: <FormattedMessage id="lang-total" />,
      data: "11,800",
    },
  ],
  [
    { id: 1, header: <FormattedMessage id="lang-floor" />, data: "400" },
    {
      id: 2,
      header: <FormattedMessage id="lang-lowel-bowl-retractable" />,
      data: "4,150",
    },
    {
      id: 3,
      header: <FormattedMessage id="lang-lowel-bowl-fixed" />,
      data: "5,070",
    },
    {
      id: 4,
      header: <FormattedMessage id="lang-upper-bowl-fixed" />,
      data: "3,430",
    },
    { id: 5, header: <FormattedMessage id="lang-sky-box" />, data: "300" },
    { id: 6, header: <FormattedMessage id="lang-wheelchair" />, data: "400" },
    {
      id: "total",
      header: <FormattedMessage id="lang-total" />,
      data: "13,750",
    },
  ],
  [
    { id: 1, header: <FormattedMessage id="lang-floor" />, data: "4,000" },
    {
      id: 2,
      header: <FormattedMessage id="lang-lowel-bowl-retractable" />,
      data: "3,000",
    },
    {
      id: 3,
      header: <FormattedMessage id="lang-lowel-bowl-fixed" />,
      data: "4,100",
    },
    {
      id: 4,
      header: <FormattedMessage id="lang-upper-bowl-fixed" />,
      data: "2,400",
    },
    { id: 5, header: <FormattedMessage id="lang-sky-box" />, data: "300" },
    { id: 6, header: <FormattedMessage id="lang-wheelchair" />, data: "400" },
    {
      id: "total",
      header: <FormattedMessage id="lang-total" />,
      data: "14,200",
    },
  ],
];
