import React from "react";
import { image } from "src/theme";
import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { ImageWrapper } from "@components/item/ImageRelated";
import { useGetMomentumTiersBenefits } from "@utils/api-requests/MomentumRequests";
import { IsDesktop } from "@utils/media-query/mediaQuery";

import {
  RoomReservationModalContainer,
  RoomReservationModalRight,
  SingleMomentumBenefitImageWrapper,
  SingleMomentumBenefitWrapper,
} from "../ModalStyling";
import { CommonModal } from "..";
import { ErrorManage } from "@screens/index";

const SingleMomentumBenefitModal = ({
  openModal,
  setOpenModal,
  currentIndex,
}) => {
  const {
    data: momentumTiersBenefits,
    isError,
    error,
  } = useGetMomentumTiersBenefits();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        noName
        name="Arena Inquiry"
        description="Arena Inquiry"
        openModal={openModal}
        setOpenModal={setOpenModal}
        noPadding
        noHeader
        purpleClose
      >
        <RoomReservationModalContainer>
          <IsDesktop>
            <SingleMomentumBenefitImageWrapper>
              <ImageWrapper ratio="5 / 9" custom={{ height: "fit-content" }}>
                <img
                  src={image.singleMomentumBenefit}
                  alt="single momentum modal"
                />
              </ImageWrapper>
            </SingleMomentumBenefitImageWrapper>
          </IsDesktop>

          <RoomReservationModalRight>
            <SingleMomentumBenefitWrapper>
              <CommonTitleOne>
                <DangerouslyHtml
                  value={
                    momentumTiersBenefits?.field_tier_information[currentIndex]
                      ?.field_name
                  }
                />
              </CommonTitleOne>

              <CommonDescTwo>
                <DangerouslyHtml
                  value={
                    momentumTiersBenefits?.field_tier_information[currentIndex]
                      ?.field_benefits_points[0]
                  }
                />
              </CommonDescTwo>

              {/* <SingleMomentumBenefitList>
                {MOMENTUM_BENEFIT_CATEGORY.map((item) => {
                  return (
                    <div key={item.name}>
                      <SingleMomentumBenefitTitle Black>
                        <DangerouslyHtml value={item.name} />
                      </SingleMomentumBenefitTitle>
  
                      <SingleMomentumBenefitItem>
                        {momentumTiersBenefits?.field_tier_information[
                          currentIndex
                        ]?.[`field_${item.field}`].map((benefit) => {
                          return (
                            <li key={benefit}>
                              <CommonDescOne>
                                <DangerouslyHtml value={benefit} />
                              </CommonDescOne>
                            </li>
                          );
                        })}
                      </SingleMomentumBenefitItem>
                    </div>
                  );
                })}
              </SingleMomentumBenefitList> */}
            </SingleMomentumBenefitWrapper>
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default SingleMomentumBenefitModal;
