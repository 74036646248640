import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import he from "he";

import { CommonTitleOne, CommonTitleTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { SwiperArrowButton } from "@components/buttons";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { DangerouslyHtml } from "@components/item";
import { CommonFullContainer } from "@components/layout/ContainerStyle";
import { doubleData } from "@utils/modify-data/modifyData";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { RecommendationText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  SliderThreeArrowWrapper,
  SliderThreeButtons,
  SliderThreeSubTitle,
} from "./SlidersStyling";

const SliderThree = ({ data, recommendation }) => {
  const [changedData, setChangedData] = useState();
  const [slidesPerView, setSlidesPerView] = useState(3.4);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    loopedSlides: slidesPerView,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3.4,
      },
    },
  };

  useEffect(() => {
    doubleData(slidesPerView, data, setChangedData, "repeat");
  }, [data, slidesPerView]);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    data?.length > 0 && (
      <>
        {recommendation && (
          <CommonFullContainer noBlur>
            <SliderThreeArrowWrapper>
              <CommonTitleOne>
                <RecommendationText />
              </CommonTitleOne>
            </SliderThreeArrowWrapper>
          </CommonFullContainer>
        )}

        <CommonFullContainer>
          <SliderThreeButtons>
            <SwiperArrowButton ref={navPrevRef} direction="left" />

            <MobileSlideText />

            <SwiperArrowButton ref={navNextRef} direction="right" />
          </SliderThreeButtons>

          {changedData && (
            <Swiper
              {...custom}
              onResize={(swiper) => {
                setSlidesPerView(swiper.params.slidesPerView);
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {changedData?.map((item, index) => {
                return (
                  <SwiperSlide key={index} radius={30}>
                    <Link
                      to={
                        recommendation
                          ? `${baseUrl}/offers/detail${item?.url_alias}`
                          : `${baseUrl}/eat-and-drink/${item?.field_parent_id}${item?.field_linked_content_url_alias}`
                      }
                    >
                      <ImageWrapper ratio="1 / 1">
                        <Image
                          src={
                            item?.[
                              recommendation
                                ? "field_offer_thumbnail"
                                : "field_thumbnail"
                            ][0]?.src
                          }
                          alt={
                            item?.[
                              recommendation
                                ? "field_offer_thumbnail"
                                : "field_thumbnail"
                            ][0]?.alt
                          }
                          radius={30}
                        />
                      </ImageWrapper>

                      <SliderThreeSubTitle>
                        <DangerouslyHtml
                          value={he.decode(
                            recommendation
                              ? item.field_offer_type.name
                              : item.field_restaurant_type
                          )}
                        />
                      </SliderThreeSubTitle>

                      <CommonTitleTwo>
                        <DangerouslyHtml
                          value={
                            recommendation
                              ? item.field_offer_name
                              : item.field_restaurant_name
                          }
                        />
                      </CommonTitleTwo>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </CommonFullContainer>
      </>
    )
  );
};

export default memo(SliderThree);
