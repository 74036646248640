import React, { memo } from "react";

import { image } from "src/theme";
import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";
import {
  GoBackToHomepageText,
  NotFoundInstruction1,
  NotFoundInstruction2,
  NotFoundInstruction3,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  NotFoundBanner,
  NotFoundContainer,
  NotFoundInformation,
} from "./ScreensStyling";

const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundBanner url={image.notFoundHero} />

      <NotFoundInformation>
        <CommonTitleOne>
          <NotFoundInstruction1 />
        </CommonTitleOne>

        <CommonDescOne>
          <p>
            <NotFoundInstruction2 />
          </p>

          <p>
            <NotFoundInstruction3 />
          </p>
        </CommonDescOne>
      </NotFoundInformation>

      <PrimaryButton linkTo="/">
        <GoBackToHomepageText />
      </PrimaryButton>
    </NotFoundContainer>
  );
};

export default memo(NotFound);
