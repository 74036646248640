import React, { memo, useMemo } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { OfferRecommendation } from "@components/item";
import { CustomMetatag } from "@utils/metatags";
import { useGetEntertainmentOfferRecommendation } from "@utils/api-requests/EntertainmentRequests";

import {
  EntertainmentAttractionVenue,
  EventsArea,
  InspireArena,
} from ".";
import PopularPerformances from "./detail/inspire-arena/PopularPerformances";
import {CommonContainer} from "@components/layout/ContainerStyle";

const Entertainment = ({ pageInfo }) => {
  const { data: entertainmentOfferRecommendation } =
    useGetEntertainmentOfferRecommendation();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    { id: 1, contents: (
        <CommonContainer>
          <PopularPerformances/>
        </CommonContainer>
      )},
    {
      id: 2,
      contents: <InspireArena />,
    },
    {
      id: 3,
      contents: <EntertainmentAttractionVenue />,
    },
    { id: 4, contents: <EventsArea /> },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={entertainmentOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </div>
  );
};

export default memo(Entertainment);
