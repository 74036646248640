import React from "react";

import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { BoxListEight } from "@components/box-list";
import { PrimaryButton } from "@components/buttons";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetAboutUsMediaCenterList } from "@utils/api-requests/AboutUsRequests";
import { ErrorManage } from "@screens/index";

const AboutUsMediaCenter = () => {
  const {
    data: aboutUsMediaCenterList,
    isError,
    error,
  } = useGetAboutUsMediaCenterList();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonContentButtonWrapper>
        <BoxListEight
          data={aboutUsMediaCenterList?.slice(0, 3)}
          linkTo="/about-us/media-center"
        />

        <PrimaryButton linkTo="/about-us/media-center">
          <ViewAllText />
        </PrimaryButton>
      </CommonContentButtonWrapper>
    </ErrorManage>
  );
};

export default AboutUsMediaCenter;
