import React, { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { CommonDescOne, CommonDescTwo } from "@assets/styles/CommonStyles";
import { SwiperArrowButton } from "@components/buttons";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import {
  CommonContainer,
  CommonFullContainer,
} from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import { TermsConditionsApplyText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  SliderNineArrowWrapper,
  SliderNineItem,
  SliderNineSwiper,
  SliderNineSwiperSlide,
  SliderNineTitle,
} from "./SlidersStyling";

const SliderNine = ({
  data,
  momentum,
  imageKey,
  nameKey,
  pointListKey,
  noPadding,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [slidesPerView, setSlidesPerView] = useState(momentum ? 3 : 3.4);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    loop: false,
    autoHeight: true,
    spaceBetween: 30,
    loopedSlides: slidesPerView,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: momentum ? 3 : 3.4,
      },
    },
  };

  return (
    data?.length > 0 && (
      <>
        <CommonFullContainer noBlur={momentum} noPadding={noPadding}>
          {isDesktop && momentum ? null : (
            <CommonContainer>
              <SliderNineArrowWrapper>
                <SwiperArrowButton ref={navPrevRef} direction="left" />

                <MobileSlideText />

                <SwiperArrowButton ref={navNextRef} direction="right" />
              </SliderNineArrowWrapper>
            </CommonContainer>
          )}

          <SliderNineSwiper
            {...custom}
            onResize={(swiper) => {
              setSlidesPerView(swiper.params.slidesPerView);
              swiper.params.navigation.prevEl = navPrevRef.current;
              swiper.params.navigation.nextEl = navNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = navPrevRef.current;
              swiper.params.navigation.nextEl = navNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {data?.map((item, idx) => {
              return (
                <SliderNineSwiperSlide key={`${item.uuid}${idx}`}>
                  <SliderNineItem>
                    {imageKey === "field_icon" ? (
                      <img
                        width="100%"
                        src={`${process.env.REACT_APP_SERVER_URL}${item[imageKey]?.src}`}
                        alt={item[imageKey]?.alt}
                        loading="lazy"
                      />
                    ) : (
                      <img
                        width="100%"
                        src={`${process.env.REACT_APP_SERVER_URL}${item[imageKey]?.[0]?.src}`}
                        alt={item[imageKey]?.[0]?.alt}
                        loading="lazy"
                      />
                    )}

                    <SliderNineTitle>
                      <DangerouslyHtml value={item[nameKey]} />
                    </SliderNineTitle>

                    <CommonDescOne>
                      <DangerouslyHtml value={item[pointListKey][0]} />
                    </CommonDescOne>
                  </SliderNineItem>
                </SliderNineSwiperSlide>
              );
            })}
          </SliderNineSwiper>

          {momentum && (
            <CommonDescTwo>
              <TermsConditionsApplyText />
            </CommonDescTwo>
          )}
        </CommonFullContainer>
      </>
    )
  );
};

export default SliderNine;
