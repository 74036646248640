import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MuiBreadcrumbs, NotificationToggle } from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsMohegan } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import {
  MoheganNews,
  OurCompany,
  OurCulture,
  SpiritOfAquai,
  TribalChief,
} from ".";
import { aboutUsRouteList } from "../aboutUsRouteList";

const MoheganResortDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsMohegan,
    isLoading,
    isError,
    error,
  } = useGetAboutUsMohegan();

  const moheganDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-about-us",
      }),
      href: "/about-us",
    },
    { id: 3, current: true, name: aboutUsMohegan?.field_mohegan_name },
  ];

  const sectionArray = [
    { id: 1, contents: <OurCompany data={aboutUsMohegan} /> },
    { id: 2, contents: <OurCulture data={aboutUsMohegan} /> },
    { id: 3, contents: <SpiritOfAquai data={aboutUsMohegan} /> },
    { id: 4, contents: <TribalChief data={aboutUsMohegan} /> },
    { id: 5, contents: <MoheganNews data={aboutUsMohegan} /> },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsMohegan} />

      <DetailPageHero data={aboutUsMohegan} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsMohegan} />

          <MuiBreadcrumbs
            breadcrumbArr={moheganDetailBC}
            pageTitle={aboutUsMohegan?.field_mohegan_name}
            value={aboutUsMohegan?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsMohegan} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MoheganResortDetail;
