import React, { useEffect, useState } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/ErrorManage";
import { useGetMyProfile } from "@utils/api-requests/MomentumRequests";
import { useUserGetProfileRequest } from "@utils/api-requests/userRequests";

import { DashboardAccount, WelcomeMessage } from ".";
import { ChangeMarketingModal } from "@components/modal/modal-list";

const MyProfile = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [complete, setComplete] = useState(false);

  let [changeMarketingModalOpen, setChangeMarketingModalOpen] = useState(false);

  const { data: myProfile, isLoading, isError, error } = useGetMyProfile();

  const {
    data: userData,
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
  } = useUserGetProfileRequest();

  useEffect(() => {
    const currentTier =
      userData?.ProfileData?.TierInfo?.CurrentTierInfo[0]?.TierName;

    let nowTierIndex = 0;

    const tierIndexMap = {
      "INSPIRE LIFE": 0,
      "INSPIRE ELITE": 1,
      "INSPIRE PRESTIGE": 2,
    };

    nowTierIndex = tierIndexMap[currentTier] || nowTierIndex;

    setCurrentIndex(nowTierIndex);
  }, [myProfile?.field_tier, userData, complete]);

  useEffect(() => {}, [complete]);

  const sectionArray = [
    {
      id: 1,

      contents: (
        <>
          {userData?.ProfileData?.mailoptin?.length <= 1 && (
            <ChangeMarketingModal
              email={userData?.ProfileData?.Emails[0].EmailAddress}
              openModal={changeMarketingModalOpen}
              setOpenModal={setChangeMarketingModalOpen}
              userData={userData?.ProfileData}
              complete={complete}
              setComplete={setComplete}
              hideClose={true}
            />
          )}

          <WelcomeMessage
            data={myProfile}
            userData={userData?.ProfileData}
            buckets={userData?.BucketBalances}
            currentIndex={currentIndex}
          />

          <DashboardAccount
            data={myProfile}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            userData={userData}
            complete={complete}
            setComplete={setComplete}
            changeMarketingModalOpen={changeMarketingModalOpen}
            setChangeMarketingModalOpen={setChangeMarketingModalOpen}
          />
        </>
      ),
    },
  ];

  const data = {
    field_enable_section_1: "On",
  };

  return (
    <ErrorManage
      isLoading={isLoading || userIsLoading}
      isError={isError || userIsError}
      error={error || userError}
    >
      <SubpageHero pageData={myProfile} small />

      <CommonContainer>
        <PageContainer data={data} sectionArray={sectionArray} />
      </CommonContainer>
    </ErrorManage>
  );
};

export default MyProfile;
