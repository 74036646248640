import React, { memo } from "react";

import { DangerouslyHtmlContainer } from "./ItemStyling";
import { switchSlashToEmptySpace } from "@utils/modify-data/modifyData";

const DangerouslyHtml = ({ value, className, custom }) => {
  return typeof value === "string" ? (
    <DangerouslyHtmlContainer
      className={className}
      custom={custom}
      dangerouslySetInnerHTML={{
        __html: switchSlashToEmptySpace(value),
      }}
    />
  ) : (
    value
  );
};

export default memo(DangerouslyHtml);
