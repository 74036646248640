import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert } from "@mui/material";

import { yupChangePasswordProfile } from "@components/inputs/yupValidation";
import { TextInput } from "@components/inputs";
import { PrimaryButton } from "@components/buttons";
import { ForgotPasswordModalForm } from "@components/modal/ModalStyling";
import {
  ResetSubmitText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  ConfirmPasswordLabelText,
  NewPasswordLabelText,
  OldPasswordLabelText,
  ResetPasswordOldPasswordNotMatchedErrorText,
  VerifiactionCodeLabelText,
  VerificationCodeExpiredErrorText,
  VerificationCodeInvalidErrorText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { userResetPass } from "@utils/api-requests/userRequests";

const ResetPasswordForm = ({ email, setPasswordStep }) => {
  const [invalid, setInvalid] = useState("");

  const { mutate: resetPassMutate, isLoading: resetPassIsLoading } =
    useMutation(userResetPass, {
      onSuccess: (data) => {
        if (data.status === 200) {
          setPasswordStep(1);
        }
        //VC10002 -- verification code invalid
        else if (data.response?.data.Errors[0].Code === "VC10002") {
          setInvalid(<VerificationCodeInvalidErrorText />);
        }
        //VC10003 -- old password dose not match
        else if (data.response?.data.Errors[0].Code === "VC10003") {
          setInvalid(<ResetPasswordOldPasswordNotMatchedErrorText />);
        }
        // ERROR: When verification code expired
        else if (data.response?.data.Errors[0].Code === "VC10001") {
          setInvalid(<VerificationCodeExpiredErrorText />);
        } else {
          console.log(data);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yupChangePasswordProfile),
    mode: "onSubmit",
    defaultValues: {
      code: "",
      password: "",
      old_pw: "",
      confirm_pw: "",
    },
  });

  const changePasswordFormSubmit = useCallback(
    (data) => {
      if (data) {
        resetPassMutate({
          email: email,
          code: data.code,
          newPassword: data.password,
          oldPassword: data.old_password,
        });
      }
    },
    [resetPassMutate, email]
  );

  return (
    <ForgotPasswordModalForm onSubmit={handleSubmit(changePasswordFormSubmit)}>
      {invalid !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "16px" }}>
          {invalid}
        </Alert>
      )}

      <TextInput
        control={control}
        name="code"
        label={<VerifiactionCodeLabelText />}
        placeholder="12345"
        type="text"
      />

      <TextInput
        control={control}
        name="old_password"
        label={<OldPasswordLabelText />}
        type="password"
      />

      <TextInput
        control={control}
        name="password"
        label={<NewPasswordLabelText />}
        type="password"
      />

      <TextInput
        control={control}
        name="confirm_pw"
        label={<ConfirmPasswordLabelText />}
        type="password"
      />

      <PrimaryButton buttonType="submit" noArrow disable={resetPassIsLoading}>
        {resetPassIsLoading ? <SubmitLoadingText /> : <ResetSubmitText />}
      </PrimaryButton>
    </ForgotPasswordModalForm>
  );
};

export default ResetPasswordForm;
