import styled from "@emotion/styled";
import { Accordion, AccordionDetails } from "@mui/material";

import {
  CommonDescFour,
  CommonDescOne,
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleSix,
  CommonTitleThree,
  CommonTitleTwo,
  infoZIndex,
} from "@assets/styles/CommonStyles";
import { mq, mqMax } from "@utils/media-query/mediaQuery";
import {isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";

export const FindYourInspirationContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "2rem 0",
  marginTop: "7.2rem",
  textAlign: "center",
}));

export const FindYourInspirationIframeContainer = styled.div(() => ({
  padding: "56.25% 0 0 0",
  position: "relative",
}));

export const FindYourInspirationIframe = styled.iframe(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

// STYLE: OurCompany.jsx
export const OurCompanyContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [mq("desktop")]: {
    gap: "7.2rem 0",
  },
}));

export const OurCompanyDescOne = styled(CommonDescOne)(() => ({
  textAlign: "center",

  [mq("desktop")]: {
    maxWidth: "80%",
  },
}));

export const OurCompanyInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem 10rem",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const OurCompanyDesc = styled(CommonDescOne)(({ theme }) => ({
  strong: {
    color: theme.color.point_primary_color,
  },
}));

// STYLE: TribalChief.jsx
export const TribalChiefContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem 0",
  alignItems: "center",
  justifyContent: "center",
}));

export const TribalChiefDesc = styled(CommonDescOne)(() => ({
  textAlign: "center",

  [mq("desktop")]: {
    maxWidth: "80%",
  },
}));

// STYLE: CareerLinks.jsx
export const CareerLinksContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 2.4rem",

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const CareerLinksItem = styled.div(() => ({
  flex: 1,
  width: "100%",
  height: "100%",
  position: "relative",
  aspectRatio: "7 / 5",
  borderRadius: "2.5rem",
  overflow: "hidden",

  "&:hover > div": {
    opacity: 1,
    zIndex: "4",
  },

  "&:hover > p": {
    opacity: 0,
  },

  "& figure:before": {
    content: '""',
    height: "100%",
    width: "100%",
    display: "block",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0.61%, rgba(0, 0, 0, 0.00) 69.83%)",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "1",
  },
}));

export const CareerLinksText = styled(CommonTitleTwo)(({ theme }) => ({
  position: "absolute",
  left: "3.2rem",
  bottom: "3.2rem",
  color: theme.color.white,
  textTransform: "uppercase",
  transition: "all 0.3s",
  zIndex: "2",
}));

export const CareerLinksInformation = styled.div(({ theme }) => ({
  opacity: 0,
  top: 0,
  left: 0,
  position: "absolute",
  zIndex: infoZIndex,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "4rem 0",
  background: theme.opacity.colored_overlay,
  width: "100%",
  height: "100%",
  padding: "1.6rem",
  fontSize: "2.8rem",
  fontWeight: theme.fontWeight.extraBold,
  color: theme.color.white,
  textTransform: "uppercase",
  transition: "all 0.3s",

  [mq("desktop")]: {
    fontSize: "3.6rem",
  },
}));

// STYLE: MoheganNews.jsx
export const MoheganNewsInformation = styled(CommonTitleThree)(({ theme }) => ({
  position: "absolute",
  bottom: "1.6rem",
  left: "1.6rem",
  zIndex: infoZIndex,
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  maxWidth: "calc(100%  - 3.2rem)",
  color: theme.color.white,

  [mq("desktop")]: {
    bottom: "5.6rem",
    left: "5.6rem",
    gap: "0 2rem",
    flexDirection: "row",
    maxWidth: "calc(100%  - 11.2rem)",
  },
}));

// STYLE: FloorMap.jsx
export const FloorMapPlaceList = styled.ul(({ theme }) => ({
  display: "flex",
  margin: "4rem 0 8rem",
  color: theme.color.point_primary_color,
  fontWeight: theme.fontWeight.bold,
  fontSize: "2.4rem",
  flexWrap: "wrap",

  "&>li": {
    cursor: "pointer",
    width: "25%",
  },

  [mq("desktop")]: {
    "&>li": {
      width: "25%",
    },
  },
}));

// STYLE: MediaArticle.jsx
export const MediaArticleContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  gap: "7.2rem 0",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "12rem",
  width: "100%",
}));

export const MediaArticleContents = styled.div(() => ({
  textAlign: "left",
  width: "100%",

  img: {
    display: "block",
    margin: "0 auto 4rem",
  },
}));

export const MediaArticleHtmlCustom = {
  "& > p, & > ul, & > ol": {
    marginBottom: "4rem",
  },
};

// STYLE: SpiritOfAquai.jsx
export const SpiritOfAquaiContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "7.2rem 0",
}));

export const SpiritOfAquaiTextList = styled(CommonTitleSix)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.6rem 5rem",
  padding: "0 0 2rem",
  flexWrap: "wrap",
  margin: "0 auto",

  span: {
    width: "100%",
    textAlign: "center",
  },

  [mq("tablet")]: {
    span: {
      width: "30%",
      textAlign: "center",
    },
  },

  [mq("desktop")]: {
    flexWrap: "nowrap",
    span: {
      width: "fit-content",
      wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
      textAlign: "center",
    },

    "&>span:not(:last-of-type)": {
      paddingRight: "5rem",
      borderRight: `1px solid ${theme.color.light_grey}`,
    },
  },
}));

export const SpiritOfAquaiDescripition = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "124rem",
  margin: "0 auto",
  width: "100%",
  gap: "6rem",
  alignItems: "center",
  justifyContent: "space-between",

  "& > figure": {
    maxWidth: "33rem",
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const SpiritOfAquaiInformation = styled.ul(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  gap: "1.6rem 0",
  width: "100%",

  [(mq("desktop"), mq("tablet"))]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "12rem 6rem",
  },
}));

export const SpiritOfAquaiItem = styled.li(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",

  header: {
    height: "4rem",
    marginBottom: "1.6rem",
    fontSize: "2.4rem",
    fontWeight: theme.fontWeight.extraBold,

    [mq("desktop")]: {
      height: "8rem",
    },
  },

  "&:first-of-type header": {
    color: "#922795",
  },

  "&:nth-of-type(2) header": {
    color: "#E1B11A",
  },

  "&:nth-of-type(3) header": {
    color: "#B8292F",
  },

  "&:last-of-type header": {
    color: "#000000",
  },
}));

// STYLE: AboutUsPrivacyPolicy.jsx
export const AboutUsPrivacyPolicyContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column-reverse",
  gap: "2rem 10rem",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const AboutUsPrivacyPolicyInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem 0",
  lineHeight: "3.2rem",
  fontWeight: theme.fontWeight.bold,
}));

// STYLE: InformationHandling.jsx
export const InformationHandlingInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "7.2rem 0",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  textAlign: "center",

  [mq("desktop")]: {
    maxWidth: "80%",
  },
}));

// STYLE: PrivacyPolicyAnchorList.jsx
export const PrivacyPolicyAnchorListContainer = styled.ul(
  ({ theme, modal }) => ({
    position: "relative",
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "6.4rem 3.2rem",
    margin: "7.2rem 0",
    padding: "7.2rem 0",

    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      height: "0.2rem",
      background: theme.color.medium_grey,
      width: "25.6rem",
    },

    "&:after": {
      top: "auto",
      bottom: 0,
    },

    [mq("tablet")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },

    [mq("desktop")]: {
      gridTemplateColumns: `repeat(${modal ? 2 : 4}, 1fr)`,
    },
  })
);

export const PrivacyPolicyAnchorListItem = styled.li(({ theme }) => ({
  width: "100%",
  fontSize: isJapanese ? "1.8rem" : "2rem",
  color: "#9747FF",
  fontWeight: theme.fontWeight.bold,
  textAlign: "center",
}));

// STYLE: PrivacyPolicyContents.jsx
export const PrivacyPolicyContentsContainer = styled.section(
  ({ theme, disableTopBorder }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem 0",
    marginBottom: "5.6rem",
    fontFamily: theme.fontFamily.secondaryFont,
    fontSize: "1.4rem",
    lineHeight: "1.5em",
    position: "relative",
    margin: `${disableTopBorder ? "0" : "7.2rem 0"}`,

    [mq("mobile")]: {
      fontSize: "1.4rem",
    },

    [mq("desktop")]: {
      fontSize: "1.8rem",
    },

    p: {
      fontSize: "inherit",
    },

    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      height: "0.2rem",
      background: "rgb(151, 151, 151)",
      width: "25.6rem",
      marginTop: "-3.2rem",
      display: `${disableTopBorder ? "none" : "block"}`,
    },

    "h1, h2": {
      marginBottom: "2rem",
      lineHeight: "1.5em",
      fontFamily: theme.fontFamily.primaryFont,
      fontWeight: 700,
      textTransform: "uppercase",

      [mq("mobile")]: {
        fontSize: "2.4rem",
      },

      [mq("desktop")]: {
        fontSize: "4.8rem",
      },
    },

    h3: {
      marginBottom: "2rem",
      lineHeight: "1.5em",
      fontFamily: theme.fontFamily.primaryFont,
      fontWeight: 600,

      [mq("mobile")]: {
        fontSize: "1.8rem",
      },

      [mq("desktop")]: {
        fontSize: "2rem",
      },
    },

    "ul, ol": {
      listStyle: "initial",
      marginTop: "1rem",
      paddingLeft: "2rem",
      marginBottom: "2rem",

      "&>li": {
        fontSize: "1.6rem",
      },

      [mq("desktop")]: {
        paddingLeft: "4rem",

        "&>li": {
          fontSize: "1.8rem",
        },
      },
    },

    ul: {
      "&>li": {
        listStyle: "initial",
      },
    },

    table: {
      width: "100%",
      minWidth: "60rem",
      height: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,

      overflow: "hidden",

      tableLayout: "fixed",

      "& thead": {
        background: theme.color.primary_color,
        color: theme.color.white,

        th: {
          borderTop: `1px solid ${theme.color.primary_light_color}`,
          fontFamily: theme.fontFamily.primaryFont,
          fontWeight: theme.fontWeight.medium,

          "&:not(:last-of-type)": {
            padding: "2rem",
            borderRight: `1px solid ${theme.color.primary_light_color}`,
          },
        },
      },

      "& .fixed": {
        position: "sticky",
        left: 0,
        zIndex: infoZIndex,
      },

      "th, td": {
        padding: "2rem 1.6rem",
        textAlign: "center",
        borderRight: `1px solid ${theme.color.light_grey}`,
        borderTop: `1px solid ${theme.color.light_grey}`,
      },

      td: {
        background: theme.color.white,
      },
    },

    ".table-scroll-wrapper": {
      margin: "7.2rem 0",

      [mqMax("desktop")]: {
        overflowX: "auto",
      },
    },

    ".table-scroll": {
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 1rem 5rem",
      borderRadius: "2.5rem",
      overflow: "hidden",
      [mqMax("desktop")]: {
        lineHeight: "1.4",
        fontWeight: "400",
        fontSize: "1.6rem",
        overflowWrap: "normal",
        marginBottom: "5rem",
        border: "1px solid rgb(205, 204, 204)",

        fontFamily: theme.fontFamily.secondaryFont,
        overflowX: "auto",
        background: "rgb(255, 255, 255)",
      },
    },

    section: {
      "+ section": {
        marginTop: "3.2rem",
      },
    },
  })
);

export const PrivacyPolicyContentsListWrapper = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
}));

// STYLE: AboutUsMore.jsx
export const AboutUsMoreContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1.6rem 0",
  background: "#68507C",
  borderRadius: "2.5rem",
  padding: "1.6rem",
  color: theme.color.white,
  textTransform: "uppercase",

  [mq("desktop")]: {
    flexDirection: "row",
    padding: "4rem 5rem",
  },
}));

export const AboutUsMoreTitle = styled(CommonTitleThree)(({ theme }) => ({
  maxWidth: "80%",
  fontWeight: theme.fontWeight.extraBold,
  color: theme.color.secondary_color,
}));

export const AboutUsMoreButtons = styled.div(() => ({
  display: "flex",
  gap: "1.6rem",
  flexWrap: "wrap",

  [mq("tablet")]: {
    flexWrap: "nowrap",
  },

  [mq("desktop")]: {
    gap: "0 4rem",
  },
}));

// STYLE: SitemapList.jsx
export const SitemapListHeader = styled(CommonTitleThree)(({ theme }) => ({
  fontWeight: theme.fontWeight.extraBold,
}));

export const SitemapListWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "3.2rem 0",
  marginBottom: "8rem",
}));

export const SitemapListSubmenu = styled(CommonTitleSix)(({ theme }) => ({
  display: "flex",
  gap: "1.6rem 6rem",
  flexWrap: "wrap",
}));

export const SitemapListDetailmenu = styled(CommonDescFour)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  marginTop: "2rem",
  color: theme.color.point_primary_color,
}));

// STYLE: SitemapList.jsx
export const CustomerCenterListAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  marginBottom: "1.6rem",

  "&::before": {
    display: "none",
  },

  "& .MuiAccordionSummary-content": {
    margin: 0,
  },

  "& .MuiAccordionSummary-root": {
    minHeight: "auto",
    padding: 0,
  },
}));

export const CustomerCenterListAccordionHeader = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  fontSize: "1.2rem",
  minHeight: "6rem",
  paddingRight: "2.4rem",
  color: theme.color.secondary_grey,
  fontWeight: theme.fontWeight.medium,
  borderRadius: "1.5rem",
  boxShadow: theme.shadow.primary,
  overflow: "hidden",

  [mq("desktop")]: {
    minHeight: "8rem",
    borderRadius: "2.5rem",
  },
}));

export const CustomerCenterLeft = styled.div(() => ({
  display: "flex",
  height: "100%",
  flex: 1,
}));

export const CustomerCenterArrow = styled.img(({ expanded }) => ({
  transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
  transition: "all 0.3s",
}));

export const CustomerCenterTitle = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "0.6rem 0",
  height: "100%",
  padding: "0.8rem 2.4rem",
  justifyContent: "center",

  "& > span": {
    textTransform: "uppercase",
    fontWeight: theme.fontWeight.extraBold,
    color: theme.color.primary_color,
  },
}));

export const CustomerCenterListCounter = styled.span(({ theme, expanded }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "0 0 6rem",
  color: expanded ? theme.color.white : theme.color.medium_grey,
  background: expanded ? theme.color.primary_color : theme.color.grey_white,
  fontSize: "3.2rem",
  fontWeight: theme.fontWeight.extraBold,

  [mq("desktop")]: {
    flex: "0 0 9rem",
  },
}));

export const CustomerCenterListAccordionDetails = styled(AccordionDetails)(
  ({ theme }) => ({
    padding: "1.6rem",
    background: theme.color.primary_dimmed_color,
    color: theme.color.primary_color,
    textAlign: "center",
    fontSize: isJapanese ? "1.8rem" : "2rem",

    p: {
      fontFamily: theme.fontFamily.secondaryFont,
      lineHeight: 1.5,
    },

    [mq("desktop")]: {
      marginTop: "1rem",
      padding: "7.2rem",
    },
  })
);

// STYLE: AboutUsGettingHere.jsx
export const AboutUsGettingHereContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem 10rem",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const AboutUsGettingHereInformation = styled(CommonTitleFour)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
}));

export const AboutUsGettingHereWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem 0",
}));

// STYLE: GettingHereMap.jsx
export const GettingHereMapInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  position: "absolute",
  bottom: "2rem",
  left: "2rem",
  color: theme.color.white,
  zIndex: infoZIndex,
  maxWidth: "calc(100% - 4rem)",

  [mq("desktop")]: {
    bottom: "7.2rem",
    left: "7.2rem",
  },
}));

export const GettingHereMapAddress = styled(CommonTitleOne)(() => ({
  fontSize: "2rem",

  [mq("tablet")]: {
    fontSize: "3.6rem",
  },

  [mq("desktop")]: {
    fontSize: "4.8rem",
  },
}));

// STYLE: GettingHereTable.jsx
export const GettingHereTableContainer = styled.div(() => ({
  marginTop: "4rem",
  paddingBottom: "3rem",

  ".swiper": {
    [mq("desktop")]: {
      pointerEvents: "none",
    },
  },

  ".swiper-wrapper": {
    [mq("desktop")]: {
      flexFlow: "row wrap",
      gridGap: "3.2rem",
    },
  },

  ".swiper-slide": {
    [mq("desktop")]: {
      marginRight: "0 !important",
      width: "auto !important",
      flex: "1 1 476px",
    },
  },
}));

export const GettingHereHowDesc = styled(CommonDescOne)(() => ({
  marginBottom: "1rem",
}));

export const GettingHereHowTitle = styled(CommonTitleOne)(() => ({
  margin: "3.2rem 0",
  textAlign: "center",
}));

// STYLE: CareersDetail.jsx
export const CareersDetailContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "6rem 0",

  [mq("desktop")]: {
    gap: "12rem 0",
  },
}));

export const CareerDetailLinkedButton = styled.a(({ theme }) => ({
  borderRadius: "1.5rem",
  background: theme.color.white,
  color: theme.color.primary_color,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1.6rem",
  width: "100%",
  padding: "0 1.6rem",
  fontFamily: theme.fontFamily.primaryFont,
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.6rem",
  letterSpacing: "0.3rem",
  textTransform: "uppercase",
  transition: "all 0.3s",
  whiteSpace: "nowrap",
  maxWidth: "36rem",
  height: "5.6rem",

  [mq("desktop")]: {
    maxWidth: "40rem",
    height: "7.2rem",
  },
}));

export const DrupalTableCommonStyle = styled.div(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "5rem 0",
    marginBottom: "5.6rem",
    fontSize: "1.4rem",
    lineHeight: "1.5em",
    position: "relative",

    [mq("mobile")]: {
      fontSize: "1.4rem",
    },

    [mq("desktop")]: {
      gap: "8rem 1rem",
      fontSize: "1.6rem",
    },

    p: {
      fontSize: "inherit",
    },




    table: {
      width: '100%',
      minWidth: "60rem",
      height: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
      overflow: "hidden",
      tableLayout: "fixed",

      thead: {
        color: theme.color.white,

        tr: {
          "&.colored-row": {
            th: {
              background: "#593C72",
            }
          },
        },

        th: {
          background: theme.color.primary_color,
          textTransform: "uppercase",
          borderTop: `1px solid #4D2B6C`,
          padding: "1.6rem 0.8rem",

          "&:not(:last-of-type)": {
            borderRight: `1px solid #4D2B6C`,
          },

          "strong": {
            display: "block",
          },
        },
      },

      th: {
        padding: "1.6rem 0.8rem",
        border: "none",
        background: theme.color.primary_color,
      },

      tbody: {
        tr: {
          ":nth-of-type(2n) td": {
            background: theme.color.light_white,
          },
        },

        td: {
          fontFamily: theme.fontFamily.secondaryFont,
        }
      },

      "& .fixed": {
        position: "sticky",
        left: 0,
        zIndex: infoZIndex,
      },


      td: {
        padding: "2rem 0.8rem",
        textAlign: "center",
        borderTop: `1px solid ${theme.color.light_grey}`,
        background: theme.color.white,

        "&:not(:last-of-type)": {
          borderRight: `1px solid ${theme.color.light_grey}`,
        },
      },
    },

    ".table-scroll-wrapper": {
      margin: "7.2rem 0",

      [mqMax("desktop")]: {
        overflowX: "auto",
      },
    },

    ".table-scroll": {
      border: `1px solid ${theme.color.light_grey}`,
      borderRadius: "2.5rem",
      overflow: "hidden",

      [mqMax("desktop")]: {
        lineHeight: "1.4",
        fontWeight: "400",
        fontSize: "1.6rem",
        overflowWrap: "normal",
        marginBottom: "5rem",
        border: "1px solid rgb(205, 204, 204)",
        overflowX: "auto",
        background: "rgb(255, 255, 255)",
      },
    },
  })
);