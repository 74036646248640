import React from "react";
import { DangerouslyHtml } from "@components/item";
import { TableSixContainer } from "./TableStyling";

const TableSix = ({ data }) => {
  return (
    <TableSixContainer>
      <DangerouslyHtml value={data} />
    </TableSixContainer>
  );
};

export default TableSix;
