import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumModal } from "@components/modal/modal-list";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { useGetMomentumTiersBenefits } from "@utils/api-requests/MomentumRequests";
// import { JoinOurNewsletterText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { CustomMetatag } from "@utils/metatags";

import { MembershipTierStandard, TiersBenefits } from ".";

const MomentumTiersDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  // const handleOpenModal = useCallback(() => {
  //   setModalOpen(true);
  // }, []);

  const {
    data: momentumTiersBenefits,
    isFetching,
    isError,
    error,
  } = useGetMomentumTiersBenefits();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-momentum",
        }),
        href: "/momentum",
      },
      {
        id: 3,
        current: true,
        name: momentumTiersBenefits?.field_page_name,
      },
    ];

    return roomDetails;
  }, [intl, momentumTiersBenefits?.field_page_name]);

  const sectionArray = [
    {
      id: 1,
      titleDirection: "left",
      noMargin: true,
      contents: (
        <TiersBenefits
          data={momentumTiersBenefits?.field_tier_information}
          noPadding
        />
      ),
    },
    {
      id: 2,
      contents: <MembershipTierStandard data={momentumTiersBenefits} />,
    },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <CustomMetatag metaData={momentumTiersBenefits} />

      <DetailPageHero data={momentumTiersBenefits} />

      <CommonContainer>
        <>
          <NotificationToggle data={momentumTiersBenefits} />

          {sessionStorage.getItem("signupAction") !== "false" ? (
            <MuiBreadcrumbs
              breadcrumbArr={sitemapDetailBC}
              pageTitle={momentumTiersBenefits?.field_page_name}
              value={momentumTiersBenefits?.field_full_description}
            />
          ) : (
            <MuiBreadcrumbs
              breadcrumbArr={sitemapDetailBC}
              pageTitle={momentumTiersBenefits?.field_page_name}
              value={momentumTiersBenefits?.field_full_description}
            />
          )}
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={momentumTiersBenefits} />

        <MomentumLayout data={momentumTiersBenefits} noLearnMore />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MomentumTiersDetail;
