import { Select } from "@mui/material";

const { default: styled } = require("@emotion/styled");

// TextInput.jsx
export const TextInputWrapper = styled.div(() => ({
  minHeight: "8rem",
  width: "100%",
}));

// SelectInput.jsx
export const StyledSelectInputContainer = styled.div(() => ({
  minHeight: "8rem",
  width: "100%",
}));

export const StyledSelectInput = styled(Select)(() => ({
  flex: 1,
  minWidth: "10rem",
  width: "100%",
  maxHeight: "5.6rem",
}));
