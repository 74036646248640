import React from 'react';
import {useParams} from "react-router-dom";

import {
  DetailPageDescriptionContainer,
  DetailPageDescriptionDesc,
  DetailPageDescriptionfeatureTags
} from "@components/sub-page/SubPageStyling";
import {useGetCasinoGameDetail } from "@utils/api-requests/CasinoRequests";
import {BoxInformationOne, DangerouslyHtml, NotificationToggle, OfferRecommendation} from "@components/item";
import {CommonTitleOne} from "@assets/styles/CommonStyles";
import {CustomMetatag} from "@utils/metatags";
import {DetailPageContainer, DetailPageHero} from "@components/sub-page";
import {CommonContainer} from "@components/layout/ContainerStyle";
import {PageBottomContents} from "@components/layout";
import {ErrorManage} from "@screens/index";
import {useIntl} from "react-intl";

const CasinoGameDetail = () => {
  const alias = useParams().gameAlias;
  const intl = useIntl();

  const {data: casinoGameDetail, isFetching,isError, error} = useGetCasinoGameDetail(alias);

  const information = [
    {
      id: 1,
      header: intl.formatMessage({
        id: "lang-game-type",
      }),
      data: casinoGameDetail?.field_game_type,
    },
    {
      id: 2,
      header:intl.formatMessage({
        id: "lang-game-name",
      }),
      data: casinoGameDetail?.field_game_name,
    },
    {
      id: 3,
      header: intl.formatMessage({
        id: "lang-number-of-tables",
      }),
      data: casinoGameDetail?.field_number_of_tables,
    },
    {
      id: 3,
      header: intl.formatMessage({
        id: "lang-variation-types",
      }),
      data: casinoGameDetail?.field_variation_types,
    },
  ];

  const sectionArray = [
    {
      id: 1,
      contents: <DetailPageDescriptionContainer>
        <BoxInformationOne
          information={information}
        >
          <NotificationToggle data={casinoGameDetail} />

          <div>
            <CommonTitleOne>{casinoGameDetail?.field_game_name}</CommonTitleOne>

            <DetailPageDescriptionfeatureTags>
              {casinoGameDetail?.field_feature_tags?.map((item) => {
                return <p key={item}>{item}</p>;
              })}
            </DetailPageDescriptionfeatureTags>

            <DetailPageDescriptionDesc>
              <DangerouslyHtml value={casinoGameDetail?.field_full_description} />
            </DetailPageDescriptionDesc>
          </div>
        </BoxInformationOne>
      </DetailPageDescriptionContainer>,
    },
  ];



  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={casinoGameDetail} />

      <DetailPageHero data={casinoGameDetail} />

      <CommonContainer>
        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={casinoGameDetail} />
      </PageBottomContents>
    </ErrorManage>


  );
};

export default CasinoGameDetail;