import {
  CommonTitleFive,
  sliderButtonZIndex,
} from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";
import { SwiperSlide } from "swiper/react";

// STYLE: WelcomeMessage.jsx
export const WelcomeMessageContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexFlow: "row wrap",
  marginBottom: "3.2rem",
  paddingBottom: "3.2rem",
  gap: "3.2rem",
  borderBottom: `2px dotted ${theme.color.light_grey}`,
  [mq("desktop")]: {
    justifyContent: "space-between",
  },
}));

export const WelcomeMessageInformation = styled.ul(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1.6rem 3.2rem",
  marginTop: "3.2rem",
}));

export const WelcomeMessageItem = styled.li(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.8rem 0",

  p: {
    fontWeight: theme.fontWeight.Black,
  },
}));

export const WelcomeMessageCard = styled.div(({ url }) => ({
  backgroundImage: `url("${url}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  flex: "0 1 40rem",
  minHeight: "23.1rem",
  display: "flex",
  alignItems: "flex-end",
  padding: "2.4rem 4rem",
  color: "white",
  textShadow: "0px 2px 2px black",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "0.1em",
  fontSize: "12px",
  lineHeight: "1.25",
  position: "relative",

  ".qr-container:not(.full-screen)": {
    position: "absolute",
    top: 16,
    right: 32,
  },
}));

export const DashboardAccountContainer = styled.div(() => ({
  padding: "3.2rem 0",

  [mq("desktop")]: {
    padding: "6.4rem 0",
  },
}));

// STYLE: MyProfileDashboard.jsx
export const MyProfileDashboardArrowLeft = {
  position: "absolute",
  top: "50%",
  left: "-2.8rem",
  zIndex: sliderButtonZIndex,
  transform: "translateY(-50%)",
};

export const MyProfileDashboardArrowRight = {
  position: "absolute",
  top: "50%",
  right: "-2.8rem",
  zIndex: sliderButtonZIndex,
  transform: "translateY(-50%)",
};

export const MyProfileDashboardArrowWrapper = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "0 1.6rem",
  marginBottom: "2.4rem",
}));

export const MyProfileDashboardSlider = styled(SwiperSlide)(({ url }) => ({
  position: "relative",
  padding: "3.2rem",
  backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  borderRadius: "2.5rem",

  [mq("desktop")]: {
    padding: "6.4rem 7.2rem",
  },
}));

export const MyProfileDashboardInformation = styled.div(({ theme, dark }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  color: dark ? theme.color.base_black : theme.color.white,

  [mq("desktop")]: {
    justifyContent: "flex-start",
  },
}));

export const MyProfileDashboardBenefitList = styled.ul(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 3.2rem",
  marginBottom: "3.2rem",
}));

export const MyProfileDashboardButtons = styled.div(() => ({
  display: "flex",
  gap: "1.6rem",
  width: "100%",
  flexWrap: "wrap",
}));

// STYLE: MyProfileAccount.jsx
export const MyProfileAccountContainer = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "0 8rem",
  marginBottom: "8rem",
  border: `1px solid ${theme.color.light_grey}`,
  borderRadius: "1.5rem",
  overflow: "hidden",
}));

export const MyProfileAccountInformation = styled.div(() => ({
  width: "45rem",
  padding: "3.2rem",
}));

export const MyProfileAccountInformationWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "3.2rem 0",
  marginTop: "1rem",
}));

export const MyProfileAccountValue = styled(CommonTitleFive)(({ theme }) => ({
  width: "100%",
  minHeight: "3.4rem",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.color.light_grey}`,
  paddingBottom: "0.4rem",
}));

export const MyProfileAccountImage = styled.img(() => ({
  flex: 1,
}));

export const MyProfileAccountEditPassword = styled.button(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: theme.fontWeight.extraBold,
}));
