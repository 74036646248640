import React, { useEffect, useMemo, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import { FrameThree } from "@components/frame";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { SliderTemplate } from "@components/sliders";
import { ViewMenu } from "@pages/eat-drink/detail";
import {CasinoDiningImages} from "@pages/casino/CasinoStyling";
import { useGetCasinoDiningCategory } from "@utils/api-requests/CasinoRequests";
import {
  LocationText,
  OperatingHoursText,
  RestaurantInformationText,
  RestaurantTypeText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const CasinoDiningInformation = ({ data, casinoDining }) => {
  const [filterType, setFilterType] = useState("");
  const [currentData, setCurrentData] = useState("");

  const { data: casinoDiningCategory } = useGetCasinoDiningCategory();

  const aboutSpiritAquaiCategories = useMemo(() => {
    const result = casinoDiningCategory?.map((item) => {
      return {
        title: item.name,
        category_id: item.tid,
      };
    });

    return result;
  }, [casinoDiningCategory]);

  useEffect(() => {
    setCurrentData(
      data?.filter((el) => el.field_casino_dining_category.id === filterType)[0]
    );
  }, [data, filterType]);

  useEffect(() => {
    setFilterType(casinoDiningCategory?.[0].tid);
  }, [casinoDiningCategory]);

  return (
      <ListWithTabs
      tabArr={aboutSpiritAquaiCategories}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <FrameThree data={currentData} imageKey="field_icon">
        <li>
          <FrameThreeIn>
            <header>
              <LocationText/>
            </header>

            <CommonDescTwo>
              <DangerouslyHtml value={currentData?.field_location}/>
            </CommonDescTwo>
          </FrameThreeIn>

          <FrameThreeIn>
            <header>
              <RestaurantTypeText/>
            </header>

            <CommonDescTwo>
              <DangerouslyHtml
                value={
                  casinoDining
                    ? currentData?.field_restaurant_type?.name
                    : currentData?.field_restaurant_concept?.name
                }
              />
            </CommonDescTwo>
          </FrameThreeIn>
        </li>

        <li>
          <FrameThreeIn>
            <header>
              <RestaurantInformationText/>
            </header>

            <CommonDescTwo>
              <DangerouslyHtml value={currentData?.field_short_description}/>
            </CommonDescTwo>
          </FrameThreeIn>
        </li>

        <li>
          <FrameThreeIn>
            <header>
              <OperatingHoursText/>
            </header>

            <CommonDescTwo>
              {currentData?.field_operation_hours !== "" &&
              currentData?.field_operation_hours !== null ? (
                <DangerouslyHtml value={currentData?.field_operation_hours}/>
              ) : (
                "-"
              )}
            </CommonDescTwo>
          </FrameThreeIn>
        </li>
      </FrameThree>

      {currentData && (
        <CasinoDiningImages>
          <SliderTemplate length={currentData?.field_multiple_images?.length}>
            {currentData?.field_multiple_images?.map((image, idx) => {
              return (
                <SwiperSlide key={`${image?.alt}${idx}`}>
                  <ImageWrapper radius="2.5rem">
                    <Image src={image?.src} alt={image?.alt}/>
                  </ImageWrapper>
                </SwiperSlide>
              );
            })}
          </SliderTemplate>
        </CasinoDiningImages>
      )}

      {currentData?.field_view_menu_list?.length > 0 &&
        currentData?.field_view_menu_list?.[0]?.pdf !== null && (
          <ViewMenu
            viewMenuList={currentData?.field_view_menu_list}
            fileKey="pdf"
            filterType={filterType}
          />
        )}
    </ListWithTabs>
  );
};

export default CasinoDiningInformation;
