import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";

import { variousSeatsSystemInfo } from "./arenaFacilitySeats";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  SeatsSystemContainer,
  SeatsSystemInformation,
  SeatsSystemItem,
  SeatsSystemNotification,
  SeatsSystemPdf,
  SeatsSystemTotal,
  SeatsSystemWrapper,
} from "@pages/entertainment/EntertainmentStyling";
import { DangerouslyHtml, PdfPreview } from "@components/item";
import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import {
  NumberofSeatsbyFloorText,
  TheSeatsAboveAreSubjectToSomeVariationsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SeatsSystemLayout = ({ data, information }) => {
  return (
    <CommonContainer>
      <SeatsSystemContainer>
        {data?.[0]?.pdf !== null && (
          <SeatsSystemPdf>
            {data?.map((item, idx) => {
              return (
                <PdfPreview
                  ratio="1 / 1"
                  key={`${idx}+1`}
                  pdfName="location"
                  pdfLink={item?.pdf?.src}
                  noButton
                />
              );
            })}
          </SeatsSystemPdf>
        )}

        <SeatsSystemWrapper>
          <div>
            <CommonTitleTwo>
              <NumberofSeatsbyFloorText />
            </CommonTitleTwo>

            <SeatsSystemInformation>
              {information
                ?.filter((el) => el.id !== "total")
                ?.map((info, idx) => {
                  return (
                    <SeatsSystemItem key={`${info.header}${idx}`}>
                      <header>
                        <DangerouslyHtml value={info.header} />
                      </header>

                      <p>
                        <DangerouslyHtml value={info.data} />
                      </p>
                    </SeatsSystemItem>
                  );
                })}
            </SeatsSystemInformation>
          </div>

          <div>
            <SeatsSystemTotal>
              <header>
                {information
                  ?.filter((el) => el.id === "total")
                  ?.map((total) => {
                    return (
                      <p key={total.header}>
                        <DangerouslyHtml value={total.header} />
                      </p>
                    );
                  })}
              </header>

              <div>
                {information
                  ?.filter((el) => el.id === "total")
                  ?.map((total) => {
                    return <p key={total.data}>{total.data}</p>;
                  })}
              </div>
            </SeatsSystemTotal>

            <SeatsSystemNotification>
              * <TheSeatsAboveAreSubjectToSomeVariationsText />
            </SeatsSystemNotification>
          </div>
        </SeatsSystemWrapper>
      </SeatsSystemContainer>
    </CommonContainer>
  );
};

const VariousSeatsSystem = ({ data }) => {
  const [filterType, setFilterType] = useState("");

  const variousSeatsCategories = useMemo(() => {
    const result = data
      ?.filter((el) => el.title !== null && el.title !== "")
      ?.map((item) => {
        return {
          title: item.title,
          category_id: item.title,
        };
      });

    return result;
  }, [data]);

  useEffect(() => {
    setFilterType(
      data?.filter((el) => el.title !== null && el.title !== "")?.[0].title
    );
  }, [data]);

  return (
    <ListWithTabs
      tabArr={variousSeatsCategories}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <SeatsSystemLayout
        data={data?.filter((el) => el.title === filterType)}
        information={
          variousSeatsSystemInfo[
            variousSeatsCategories?.findIndex(
              (item) => item.title === filterType
            )
          ]
        }
      />
    </ListWithTabs>
  );
};

export default VariousSeatsSystem;
