import React from "react";

import { GalleryOne } from "@components/layout";
import {
  EventPackagesContainer,
  EventPackagesDesc,
  EventPackagesTitle,
} from "@pages/meet/MeetStyling";
import { DangerouslyHtml } from "@components/item";

const EventPackages = ({ data }) => {
  return (
    <div>
      {data.map((item) => {
        return (
          <EventPackagesContainer key={item.name}>
            <GalleryOne data={item.images} noClick />

            <EventPackagesTitle>
              <DangerouslyHtml value={item.name} />
            </EventPackagesTitle>

            <EventPackagesDesc>
              <DangerouslyHtml value={item.description} />
            </EventPackagesDesc>
          </EventPackagesContainer>
        );
      })}
    </div>
  );
};

export default EventPackages;
