import React, { useCallback, useState } from "react";
import { OfferListEmptyBox, OfferListEmptyInformation } from "./OffersStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { useMediaQuery } from "react-responsive";
import { image } from "src/theme";
import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { BorderButton } from "@components/buttons";
import { MomentumModal } from "@components/modal/modal-list";
import {
  BeInformedText,
  MoreOffersComingSoonText,
  NoOffersDescriptionText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const OfferEmptyBox = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <OfferListEmptyBox>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <ImageWrapper
        ratio={isDesktop ? "1 / 1" : "16 / 9"}
        custom={{ width: isDesktop ? "50%" : "100%" }}
      >
        <Image src={image.offerEmptyThumbnail} alt="Offer list is empty" />
      </ImageWrapper>

      <OfferListEmptyInformation>
        <CommonTitleOne>
          <MoreOffersComingSoonText />
        </CommonTitleOne>

        <CommonDescOne>
          <NoOffersDescriptionText />
        </CommonDescOne>

        <BorderButton
          buttonColor="primary"
          noArrow
          customstyle={{ flex: "none", marginTop: "3.2rem" }}
          buttonEvent={handleOpenModal}
        >
          <BeInformedText />
        </BorderButton>
      </OfferListEmptyInformation>
    </OfferListEmptyBox>
  );
};

export default OfferEmptyBox;
