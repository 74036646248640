import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Alert,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useMutation } from "react-query";
import { PrimaryButton } from "@components/buttons";
import {
  IAgreeText,
  IDisagreeText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  EmailExistErrorText,
  MailOptContentText,
  MailOptLabelText,
  MailUseContentText,
  MailUseLabelText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import {
  MarketingModalOptInWrapper,
  MarketingOptInLabel,
} from "@components/modal/ModalStyling";
import { PrivacyPolicyModal } from ".";
import { patchMarketingSwitch } from "@utils/api-requests/userRequests";

const ChangeMarketingForm = ({
  complete,
  setComplete,
  modalContent,
  userData,
}) => {
  let marketingNumberValue;
  let personalNumberValue;

  if (!isNaN(parseFloat(userData?.mailoptin))) {
    marketingNumberValue = userData?.mailoptin;
    personalNumberValue = userData?.personal_use || "";
  } else {
    marketingNumberValue =
      userData.mailoptin === "Yes"
        ? "1"
        : userData.mailoptin === "No"
          ? "0"
          : "";

    personalNumberValue =
      userData.personal_use === "Yes"
        ? "1"
        : userData.personal_use === "No"
          ? "0"
          : undefined;
  }

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [emailExist, setEmailExist] = useState("");

  const { mutate, isLoading } = useMutation(patchMarketingSwitch, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setComplete(true);
        const updatedMarketingSwitch = JSON.parse(data.config.data);

        userData.mailoptin = updatedMarketingSwitch.mailoptin;
        userData.personal_use = updatedMarketingSwitch.personal_use;

        setEmailExist("");
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      mailoptin:
        marketingNumberValue !== undefined
          ? String(marketingNumberValue)
          : null,
      personal_use:
        personalNumberValue !== undefined
          ? String(personalNumberValue)
          : "null",
      email: userData.Emails[0].EmailAddress,
    },
  });

  const selectedMailOptin = watch("mailoptin");
  const selectedPersonal = watch("personal_use");

  const MarketingModalFormSubmit = useCallback(
    (data) => {
      try {
        mutate({
          mailoptin: data.mailoptin,
          personal_use: data.personal_use,
          email: data.email || userData.Emails[0].EmailAddress,
        });
      } catch (error) {
        console.error("Mutation exception:", error);
      }
    },
    [mutate, userData.Emails],
  );

  return (
    <div>
      {emailExist !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "1.6rem" }}>
          {emailExist}
        </Alert>
      )}

      <PrivacyPolicyModal
        openModal={privacyPolicyOpen}
        setOpenModal={setPrivacyPolicyOpen}
      />

      <form method="post" onSubmit={handleSubmit(MarketingModalFormSubmit)}>
        <MarketingModalOptInWrapper>
          <MarketingOptInLabel>
            <MailOptLabelText />
          </MarketingOptInLabel>

          <MailOptContentText />

          <FormControl component="fieldset" error={!!errors.mailoptin}>
            <FormLabel component="legend">
              {modalContent?.field_radio_label}
            </FormLabel>
            <Controller
              control={control}
              name="mailoptin"
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  row
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={modalContent?.field_radio_yes_label}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={modalContent?.field_radio_no_label}
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText>
              {errors.mailoptin ? errors.mailoptin.message : " "}
            </FormHelperText>
          </FormControl>

          <MarketingOptInLabel>
            <MailUseLabelText />
          </MarketingOptInLabel>

          <MailUseContentText />

          <FormControl component="fieldset" error={!!errors.personal_use}>
            <FormLabel component="legend">
              {modalContent?.field_radio_label}
            </FormLabel>
            <Controller
              control={control}
              name="personal_use"
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  row
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={<IAgreeText />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={<IDisagreeText />}
                  />
                </RadioGroup>
              )}
            />
            <FormHelperText>
              {errors.mailoptin ? errors.mailoptin.message : " "}
            </FormHelperText>
          </FormControl>
        </MarketingModalOptInWrapper>

        <PrimaryButton
          buttonType="submit"
          disabled={isLoading || !selectedMailOptin || !selectedPersonal}
        >
          {isLoading ? <SubmitLoadingText /> : modalContent?.field_submit_text}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default ChangeMarketingForm;
