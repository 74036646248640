import React from "react";

import { PledgeToOurPatronsContainer } from "@pages/casino/CasinoStyling";

import { ResponsibleAccordion } from ".";
import { ResponsibleCommonItems } from "./ResponsibleGamingDetail";

const PledgeToOurPatrons = ({ data }) => {
  return (
    <ResponsibleAccordion title={data?.field_pledge_to_our_patrons_titl}>
      <PledgeToOurPatronsContainer>
        <ResponsibleCommonItems data={data?.field_pledge_to_our_patrons} />
      </PledgeToOurPatronsContainer>
    </ResponsibleAccordion>
  );
};

export default PledgeToOurPatrons;
