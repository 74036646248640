import React from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";

import {
  DetailPageContainerInformation,
  DetailPageContainerWrapper,
  DetailPageDesc,
  DetailPageTitle,
} from "./SubPageStyling";

const DetailPageContainer = ({ data, sectionArray }) => {
  return data && sectionArray ? (
    <DetailPageContainerWrapper>
      {[1, 2, 3, 4, 5, 6, 7].map((num) => {
        const titleDirection = sectionArray?.filter((el) => el.id === num)[0]
          ?.titleDirection;

        const additionalTitle = sectionArray?.filter((el) => el.id === num)[0]
          ?.title;

        const noMargin = sectionArray?.filter((el) => el.id === num)[0]
          ?.noMargin;

        const disabled = sectionArray?.filter((el) => el.id === num)[0]
          ?.disabled;

        const noTitle = sectionArray?.filter((el) => el.id === num)[0]?.noTitle;

        return (
          !disabled &&
          data[`field_enable_section_${num}`] === "On" && (
            <section key={num}>
              {!noTitle && (
                <CommonContainer noPadding={noMargin}>
                  <DetailPageContainerInformation
                    noShow={
                      !data[`field_title_${num}`] &&
                      !data[`field_description_${num}`]
                    }
                  >
                    <DetailPageTitle textAlign={titleDirection}>
                      {additionalTitle && additionalTitle}{" "}
                      <DangerouslyHtml value={data[`field_title_${num}`]} />
                    </DetailPageTitle>

                    <DetailPageDesc textAlign={titleDirection}>
                      <CommonDescOne>
                        <DangerouslyHtml
                          value={data[`field_description_${num}`]}
                        />
                      </CommonDescOne>
                    </DetailPageDesc>
                  </DetailPageContainerInformation>
                </CommonContainer>
              )}

              {sectionArray?.filter((el) => el.id === num)[0]?.contents}
            </section>
          )
        );
      })}
    </DetailPageContainerWrapper>
  ) : (
    <DetailPageContainerWrapper>
      {sectionArray &&
        sectionArray?.map((section) => {
          return section?.disabled ? null : (
            <section key={section.id}>
              {(section.title || section.desc) && (
                <div>
                  {section.title && (
                    <DetailPageTitle>{section.title}</DetailPageTitle>
                  )}

                  {section.desc && (
                    <DetailPageDesc>
                      <CommonDescOne>
                        <DangerouslyHtml value={section.desc} />
                      </CommonDescOne>
                    </DetailPageDesc>
                  )}
                </div>
              )}

              {section.contents}
            </section>
          );
        })}
    </DetailPageContainerWrapper>
  );
};

export default DetailPageContainer;
