import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import { useGetSplashBayDomeList } from "@utils/api-requests/SplashBayRequests";

import {
  SplashBayDomeInformation,
  SplashBayDomeReserveNow,
  SplashBayDomeSection,
  SplashBayOperationHours,
  SplashBayUseGuide,
} from ".";

const SplashBayDomeDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: splashBayDomeList,
    isLoading,
    isError,
    error,
  } = useGetSplashBayDomeList();

  const splashBayDomeDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-splash-bay",
        }),
        href: "/splash-bay",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-facilities",
        }),
        href: "/splash-bay",
        tab: "facilities",
      },
      {
        id: 4,
        current: true,
        name: splashBayDomeList?.field_page_title,
      },
    ];

    return roomDetails;
  }, [intl, splashBayDomeList?.field_page_title]);

  const sectionArray = [
    {
      id: 1,
      contents: <SplashBayDomeInformation data={splashBayDomeList} />,
    },
    {
      id: 2,
      contents: (
        <SplashBayDomeSection
          data={splashBayDomeList?.field_splash_bay_pool_dome}
        />
      ),
    },
    {
      id: 3,
      contents: <SplashBayOperationHours />,
    },
    {
      id: 4,
      contents: <SplashBayUseGuide data={splashBayDomeList} />,
    },
    {
      id: 5,
      contents: <SplashBayDomeReserveNow data={splashBayDomeList} />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={splashBayDomeList} />

      <DetailPageHero data={splashBayDomeList} />

      <CommonContainer>
        <>
          <NotificationToggle data={splashBayDomeList} />

          <MuiBreadcrumbs
            breadcrumbArr={splashBayDomeDetailBC}
            pageTitle={splashBayDomeList?.field_page_title}
            value={splashBayDomeList?.field_page_full_description}
            // buttonName={<BookSplashBayText />}
            // linkTo="/splash-bay/reservation"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={splashBayDomeList} />

        <MomentumLayout data={splashBayDomeList} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default SplashBayDomeDetail;
