import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "@emotion/react";

import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import { ButtonArrows, secondaryButtonStyles } from "./ButtonsStyling";
import { replaceAmpersand } from "@utils/modify-data/modifyData";

const SecondaryButtonArrow = ({ buttonColor, hover, noArrow }) => {
  const theme = useTheme();

  return (
    !noArrow &&
    (buttonColor === "white" ? (
      <ButtonArrows
        src={theme.image.linkArrowPurple.default}
        alt="right arrow"
        hover={hover}
      />
    ) : buttonColor === "blue" ? (
      <ButtonArrows
        src={theme.image.linkArrowBlue.default}
        alt="right arrow"
        hover={hover}
      />
    ) : (
      <ButtonArrows
        src={theme.image.linkArrow.default}
        alt="right arrow"
        hover={hover}
      />
    ))
  );
};

const SecondaryButton = ({
  linkTo,
  children,
  noArrow = false,
  customstyle = { width: "100%" },
  buttonColor,
  buttonEvent,
  outerLink,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [hover, setHover] = useState(false);

  const theme = useTheme();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  const activeHover = useCallback(() => {
    setHover(true);
  }, []);

  const inactiveHover = useCallback(() => {
    setHover(false);
  }, []);

  const button = useMemo(() => {
    if (linkTo) {
      return (
        <Link
          to={`${baseUrl}${linkTo}`}
          style={secondaryButtonStyles(theme, {
            customstyle,
            buttonColor,
            isDesktop,
          })}
          onMouseEnter={activeHover}
          onMouseLeave={inactiveHover}
        >
          {children}

          <SecondaryButtonArrow
            buttonColor={buttonColor}
            hover={hover}
            noArrow={noArrow}
          />
        </Link>
      );
    }

    if (outerLink && !buttonEvent) {
      return (
        <a
          href={replaceAmpersand(outerLink)}
          style={secondaryButtonStyles(theme, {
            customstyle,
            buttonColor,
            isDesktop,
          })}
          target="_blank"
          rel="noreferrer"
          onMouseEnter={activeHover}
          onMouseLeave={inactiveHover}
        >
          {children}

          <SecondaryButtonArrow
            buttonColor={buttonColor}
            hover={hover}
            noArrow={noArrow}
          />
        </a>
      );
    }

    if (buttonEvent) {
      return (
        <button
          style={secondaryButtonStyles(theme, {
            customstyle,
            buttonColor,
            isDesktop,
          })}
          onMouseEnter={activeHover}
          onMouseLeave={inactiveHover}
          onClick={buttonEvent}
          type="button"
        >
          {children}

          <SecondaryButtonArrow
            buttonColor={buttonColor}
            hover={hover}
            noArrow={noArrow}
          />
        </button>
      );
    }

    return (
      <button
        style={secondaryButtonStyles(theme, {
          customstyle,
          buttonColor,
          isDesktop,
        })}
        onMouseEnter={activeHover}
        onMouseLeave={inactiveHover}
        type="button"
      >
        {children}

        <SecondaryButtonArrow
          buttonColor={buttonColor}
          hover={hover}
          noArrow={noArrow}
        />
      </button>
    );
  }, [
    activeHover,
    baseUrl,
    buttonColor,
    buttonEvent,
    children,
    customstyle,
    hover,
    inactiveHover,
    isDesktop,
    linkTo,
    noArrow,
    outerLink,
    theme,
  ]);

  return button;
};

export default memo(SecondaryButton);
