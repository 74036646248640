import React, { useCallback, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { image } from "src/theme";
import { downloadPdf } from "@utils/modify-data/modifyData";

import {
  PdfPreviewContainer,
  PdfPreviewMapIcon,
  PdfPreviewMapText,
} from "./ItemStyling";
import { MapText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfPreviewMap = ({ pdfName, pdfLink, noButton }) => {
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    setError(error);
  };

  const handleDownloadPdf = useCallback(() => {
    downloadPdf(pdfName, pdfLink);
  }, [pdfLink, pdfName]);

  return (
    <PdfPreviewContainer>
      {error ? (
        <p>Error loading PDF: {error.message}</p>
      ) : (
        <div style={{ height: "100%" }}>
          {pdfLink && (
            <Document
                file={`${window.location.protocol}//${window.location.host}${
                    pdfLink.charAt(0) === "/" ? "" : "/"
                }${pdfLink}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}

          <PdfPreviewMapText>
            <MapText />
          </PdfPreviewMapText>

          {!noButton && (
            <PdfPreviewMapIcon type="button" onClick={handleDownloadPdf}>
              <img
                src={image.plusIcon.default}
                alt="plus icon"
                loading="lazy"
              />
            </PdfPreviewMapIcon>
          )}
        </div>
      )}
    </PdfPreviewContainer>
  );
};

export default PdfPreviewMap;
