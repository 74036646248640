import React, { useState } from "react";
import { CenteredModal } from "..";
import ResetPasswordForm from "@pages/momentum/sign-in/forget-password/ResetPasswordForm";
import ResetPasswordSuccessModal from "@pages/momentum/sign-in/forget-password/ResetPasswordSuccessModal";
import { useIntl } from "react-intl";
import { Alert } from "@mui/material";
import {
  PreviousVerificationCodeNotExpiredText,
  VerificationCodeSentMessage1, VerificationCodeSentMessage2
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

const ChangePasswordModal = ({ openModal, setOpenModal, email, previous }) => {
  const intl = useIntl();

  const [passwordStep, setPasswordStep] = useState(0);

  const resetPasswordStep = [
    {
      status: "reset",
      name: intl.formatMessage({
        id: "lang-reset-password-form",
      }),
      contents: (
        <ResetPasswordForm
          email={email}
          setOpenModal={setOpenModal}
          setPasswordStep={setPasswordStep}
        />
      ),
    },
    {
      status: "success",
      name: intl.formatMessage({
        id: "lang-password-changed-success",
      }),
      contents: (
        <ResetPasswordSuccessModal
          setOpenModal={setOpenModal}
          setPasswordStep={setPasswordStep}
        />
      ),
    },
  ];

  return (
    <CenteredModal
      modalName={intl.formatMessage({
        id: "lang-change-password",
      })}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalWidth="600px"
      uppercase
    >

      {resetPasswordStep[passwordStep].status === "reset" && (
        <Alert severity="warning" sx={{ width: "100%", marginBottom: "16px" }}>
          {previous ? (
          <PreviousVerificationCodeNotExpiredText />
          ) : (
            <React.Fragment>
              <VerificationCodeSentMessage1 /> <VerificationCodeSentMessage2 />
            </React.Fragment>
          )}
        </Alert>
      )}

      {resetPasswordStep[passwordStep].contents}
    </CenteredModal>
  );
};

export default ChangePasswordModal;
