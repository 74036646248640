import { CommonDescTwo } from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import {isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: TowerPreview.jsx
export const TowerPreviewInfo = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.8rem 0",
  minHeight: "18.5rem",
}));

export const TowerPreviewDesc = styled(CommonDescTwo)(() => ({
  textAlign: "center",
  lineHeight: 1.5,
  fontSize: "1.4rem",
  maxWidth: "90%",
  margin: "6rem auto 0",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  [mq("desktop")]: {
    width: "70%",
    maxWidth: "100rem",
    margin: "7rem auto 4rem",
  },
}));

export const TowerPreviewButtonWrapper = styled.div(() => ({
  display: "flex",
  flexFlow: "row wrap",
  gap: "3.2rem",
  marginTop: "3.2rem",

  "& > *": {
    flex: 1,
  },
}));

// STYLE: RoomPreview.jsx
export const RoomPreviewInformation = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  fontSize: "1.6rem",

  "& > li": {
    lineHeight: 1.5,
  },

  [mq("desktop")]: {
    fontSize: isJapanese ? "1.8rem" : "2rem",
  },
}));

export const RoomPreviewLabel = styled.b(({ theme }) => ({
  marginRight: "0.6rem",
  fontWeight: theme.fontWeight.bold,
}));

// STYLE: RoomFeatures.jsx
export const FeaturesContainer = styled.ul(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "4rem",
}));

export const FeaturesIn = styled.ul(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  gap: "2rem",
  fontFamily: theme.fontFamily.primaryFont,

  [mq("tablet")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [mq("desktop")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "5rem 15rem",
  },
}));

export const FeaturesItem = styled.li(({ theme }) => ({
  display: "flex",
  fontSize: "1.6rem",
  fontWeight: theme.fontWeight.bold,
  minHeight: "4.5rem",
  gap: "0 1rem",
}));

export const FeaturesItemIn = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.2rem 0",
  overflowWrap: "anywhere",
}));

export const FeaturesExtra = styled.p(({ theme }) => ({
  color: theme.color.medium_grey,
  fontSize: "1.4rem",
}));

export const RoomFeaturesExtraCharge = styled.strong(({ theme }) => ({
  marginLeft: "0.4rem",
  color: theme.color.red_color,
  fontWeight: theme.fontWeight.bold,
}));

// STYLE: BenefitsForGuests.jsx
export const BenefitsContainer = styled.ul(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.6rem",
  marginTop: "4rem",
  flexWrap: "wrap",
}));

export const BenefitsList = styled.li(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "4rem 0",
  minHeight: "22rem",
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.8rem",
  textAlign: "center",
  margin: "0 auto",
  lineHeight: 1.2,
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  width: "100%",
  fontFamily: theme.fontFamily.primaryFont,

  [mq("tablet")]: {
    width: "33%",
  },

  [mq("desktop")]: {
    maxWidth: "20%",
  },
}));

export const BenefitsIcon = styled.img(() => ({
  width: "10rem",
}));
