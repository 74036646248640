import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";
import { mq, mqMax } from "@utils/media-query/mediaQuery";

const { Link } = require("react-router-dom");
const { default: styled } = require("@emotion/styled");
const {
  headerContainerZIndex,
  headerMenuModalZIndex,
  infoZIndex,
  CommonDescFive,
  CommonTitleSix,
  CommonDescFour,
  headerLanguageModalZIndex,
} = require("@assets/styles/CommonStyles");

// STYLE: WebHeader.jsx
export const WebHeaderContainer = styled.header(({ headerColored, theme }) => ({
  position: "fixed",
  zIndex: headerContainerZIndex,
  width: "100%",
  padding: "3rem 0",
  background: headerColored
    ? theme.color.white
    : "linear-gradient(to bottom,rgba(0,0,0,40%), transparent);",
  color: headerColored ? theme.color.base_black : "white",
  transition: "background 0.3s",
}));

export const WebHeaderWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "3rem 0",
}));

export const WebHeaderTop = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  object: {
    pointerEvents: "none",
  },
}));

export const WebHeaderUtilBarWrapper = styled(CommonDescFive)(({ theme }) => ({
  display: "flex",
  gap: "0 2rem",
  lineHeight: 1,
}));

export const WebHeaderUtilBar = styled(Link)(({ theme, headercolored }) => ({
  display: "flex",
  paddingRight: "2rem",
  borderRight:
    headercolored === "true"
      ? `1px solid ${theme.color.secondary_grey}`
      : "1px solid white",

  "&:last-of-type": {
    borderRight: "none",
    paddingRight: "0",
  },
}));

export const WebHeaderSignUp = styled.button(({ headercolored, theme }) => ({
  paddingLeft: "2rem",
  borderLeft:
    headercolored === "true"
      ? `1px solid ${theme.color.secondary_grey}`
      : "1px solid white",
}));

export const WebHeaderBottom = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textTransform: "uppercase",
  width: "100%",
  gap: "0 0.8rem",
  minHeight: "5.28rem",
  fontFamily: theme.fontFamily.primaryFont
}));

export const BookNowButton = styled.button(({ theme, headerColored }) => ({
  padding: "0.6rem 1rem",
  display: "flex",
  alignItems: "center",
  gap: "0 1.5rem",
  background: headerColored ? theme.color.secondary_color : "white",
  borderRadius: "0.5rem",
  fontSize: "1.4rem",
  color: headerColored ? "white" : theme.color.primary_color,
  fontWeight: theme.fontWeight.medium,
  textTransform: "uppercase",

  "&:hover": {
    background: headerColored
      ? theme.color.primary_color
      : theme.color.secondary_color,
    color: "white",

    "& > div": {
      backgroundImage: `url(${theme.image.bookNowIconWhite.default})`,
    },
  },

  [mq("desktop")]: {
    padding: "1.8rem",
    borderRadius: "1rem",
  },
}));

export const BookIcon = styled.div(({ theme, headerColored }) => ({
  width: "1.5rem",
  height: "1.5rem",
  backgroundImage: headerColored
    ? `url(${theme.image.bookNowIconWhite.default})`
    : `url(${theme.image.bookNowIcon.default})`,
}));

export const MenuWrapper = styled.nav(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "1rem",
}));

export const MainMenu = styled(Link)(({ theme, active }) => ({
  color: active === "true" ? theme.color.point_primary_color : "inherit",
  textTransform: "uppercase",
  fontSize: "1.8rem",
  fontWeight: theme.fontWeight.bold,
  letterSpacing: "0.3rem",

  "@media (min-width: 1200px) and (max-width: 1300px)": {
    fontSize: "1.6rem",
  },

  ":hover": {
    color: theme.color.point_primary_color,
  },
}));

export const SubMenuContainer = styled.div(({ active }) => ({
  display: active ? "block" : "none",
  zIndex: headerContainerZIndex,
  width: "100%",
  transition: "opacity 0.3s",
}));

export const SubMenuWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "0 3rem",
  "& img": {
    imageRendering: "optimizeQuality",
  },
}));

export const SubMenuLeft = styled(CommonTitleSix)(({ theme }) => ({
  display: "flex",
  gap: "0 3rem",
  color: theme.color.tertiary_grey,

  "& img": {
    imageRendering: "optimizeQuality",
  },
}));

export const SubMenuRight = styled.div(() => ({
  width: "30rem",
  height: "100%",
}));

export const SubMenuRightTitle = styled.div(({ theme }) => ({
  position: "absolute",
  zIndex: infoZIndex,
  color: theme.color.white,
  fontWeight: theme.fontWeight.bold,
  fontSize: isJapanese ? "1.8rem" : "2rem",
  left: "1.6rem",
  bottom: "1.6rem",
}));

export const SubMenu = styled.div(({ theme }) => ({
  marginBottom: "1rem",
  fontWeight: theme.fontWeight.bold,
  textTransform: "uppercase",
  color: theme.color.base_black,
}));

export const SubMenuIn = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  paddingTop: "3rem",
}));

export const ChildMenuContainer = styled.div(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "0.4rem 0",
  paddingRight: "3rem",
  borderRight: `1px solid ${theme.color.grey_white}`,
  maxWidth: "23rem",

  "&:last-of-type": {
    borderRight: "none",
  },
}));

export const ChildMenuWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 0",
  height: "100%",
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  position: "relative",
  width: "fit-content",
  fontWeight: theme.fontWeight.medium,

  "&:hover": {
    color: theme.color.primary_color,
  },

  "&:hover > hr": {
    width: "100%",
  },
}));

export const Line = styled.hr(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: "-0.4rem",
  display: "inline-block",
  width: 0,
  height: "1px",
  background: theme.color.primary_color,
  transition: "all 0.3s",
}));

// STYLE: LanguageModal.jsx
export const LanguageContainer = styled.div(({ theme }) => ({
  position: "absolute",
  background: theme.color.secondary_color,
  left: 0,
  zIndex: headerLanguageModalZIndex,
  wordBreak: "keep-all",

  [mq("desktop")]: {
    background: "white",
    borderRadius: "1rem",
    border: "1px solid #EEE",
    boxShadow:
      "0 0 0 0 rgba(0, 0, 0, 0.03), 0 8px 18px 0 rgba(0, 0, 0, 0.03), 0 33px 33px 0 rgba(0, 0, 0, 0.03), 0 75px 45px 0 rgba(0, 0, 0, 0.02)",
    minWidth: "16.4rem",
    left: "auto",
    right: 0,
    marginTop: "0.8rem",
  },
}));

export const LanguageHeader = styled.div(({ theme }) => ({
  textAlign: "left",
  color: theme.color.base_black,
  fontSize: "1.2rem",
  fontWeight: 700,
  letterSpacing: "0.01rem",
  display: "none",
  textTransform: "uppercase",
  padding: "1.6rem 0 0.8rem",
  margin: "0 1.6rem",
  borderBottom: "1px solid #D9D9D9",
  wordSpacing: "999em",
  lineHeight: "1.5",
  cursor: "default",

  [mq("desktop")]: {
    display: "block",
  },
}));

export const Language = styled.button(({ theme, headerColored }) => ({
  position: "relative",
  minWidth: "7.2rem",
  fontSize: "1.4rem",
  textAlign: "right",
  color: headerColored ? "black" : "white",
  zIndex: 1900,
  margin: "4rem 0 0 1rem",

  [mq("desktop")]: {
    margin: 0,
    borderLeft: headerColored
      ? `1px solid ${theme.color.secondary_grey}`
      : "1px solid white",
  },
}));

export const LanguageIn = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0 0.6rem",
  paddingLeft: "1rem",
  wordBreak: "keep-all",
}));

export const ArrowDown = styled.div(({ theme, modalOpen }) => ({
  width: "1.5rem",
  height: "1rem",
  backgroundRepeat: "no-repeat",
  backgroundImage: modalOpen
    ? `url(${theme.image.downArrow.default})`
    : `url(${theme.image.downArrowWhite.default})`,
}));

// STYLE: MobileHeader.jsx
export const MobileHeaderContainer = styled.div(({ theme, headerColored }) => ({
  position: "fixed",
  top: headerColored ? 0 : "auto",
  left: 0,
  display: "flex",
  width: "100vw",
  maxWidth: "100vw",
  padding: "2rem 0",
  background: headerColored ? theme.color.white : "transparent",
  zIndex: headerContainerZIndex,

  "&:before": {
    content: '""',
    display: "block",
    background: headerColored
      ? "none"
      : "linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.0))",
    width: "100%",
    height: "100%",
    top: 0,
    position: "absolute",

    [mq("desktop")]: {
      height: "120%",
    },
  },
}));

export const MobileHeaderIn = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0 1.6rem",

  "& object": {
    pointerEvents: "none",
  },
}));

export const MobileHeaderRight = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0 2rem",

  [mqMax("mobileSmall")]: {
    gap: 0,
  },

  [mq("desktop")]: {
    gap: "0 2rem",
  },
}));

export const HamburgerButton = styled.div(({ headerColored, theme }) => ({
  position: "relative",
  width: "1rem",
  height: "1.2rem",
  transition: "all 0.3s",
  cursor: "pointer",
  padding: "1.6rem",

  "& > span": {
    background: headerColored ? theme.color.base_black : theme.color.white,
  },
}));

export const HamburgerLine = styled.span(
  ({ theme, top = "0", opacity = 1, rotate, active }) => ({
    position: "absolute",
    width: "60%",
    height: "2px",
    transition: "transform 0.3s",
    background: theme.color.base_black,
    right: 0,
    top: top,
    opacity: opacity,
    transform: rotate,

    "&:first-of-type": {
      top: active ? top : "0.8rem",
    },

    "&:last-of-type": {
      top: active ? top : "2.4rem",
    },
  })
);

// STYLE: MobileMenuModal.jsx
export const MobileMenuModalContainer = styled.div(({ theme, active }) => ({
  position: "fixed",
  top: "7.2rem",
  width: "100vw",
  height: "calc(var(--vh, 1vh) * 100)",
  minHeight: "calc(100vh - 7.2rem)",
  maxHeight: "100vh",
  paddingBottom: "15rem",
  overflowY: "auto",
  zIndex: headerMenuModalZIndex,
  left: active ? 0 : "-100vw",
  transition: "all 0.3s",
  background: theme.color.white,
}));

export const MobileMenuModalUtilBar = styled(CommonDescFour)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: "1rem",
  background: theme.color.secondary_color,
  padding: "1rem 0",
  color: theme.color.white,
  flexWrap: "wrap",
  lineHeight: 1,
}));

export const MobileMenuModalUtilLink = styled(Link)(({ theme }) => ({
  padding: "2rem 0",
  fontSize: "1.4rem",

  span: {
    paddingRight: "0.6rem",
    borderRight: `1px solid ${theme.color.white}`,
  },

  "&:last-of-type": {
    padding: "2rem 0",

    span: {
      border: 0,
    },
  },

  [mqMax("mobile")]: {
    padding: "1rem 0",
    fontSize: "1.2rem",

    "&:last-of-type": {
      padding: "1rem 0",

      span: {
        border: 0,
      },
    },
  },
}));

export const MobileMenuAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  margin: "2rem 0",

  "&:before": {
    opacity: 0,
  },
}));

export const MobileMenuAccordionSummary = styled(AccordionSummary)(
  ({ ismargin }) => ({
    minHeight: 0,

    "& .MuiAccordionSummary-content": {
      margin: ismargin === "true" ? "1.2rem" : 0,
    },

    "&.Mui-expanded": {
      minHeight: 0,
    },
  })
);

export const MobileMenuAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
}));

export const MobileMenuSummary = styled.div(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const MobileMenuArrowButton = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "100%",
  padding: "0 1.6rem",
  flex: 1,
}));

export const MobileMenuArrow = styled.img(({ expanded }) => ({
  transform: `${expanded ? "rotate(180deg)" : "rotate(0)"}`,
  transition: "all 0.3s",
  display: "block",
}));

export const MobileMenuTitle = styled(Link)(({ theme }) => ({
  fontSize: isJapanese ? "1.8rem" : "2rem",
  fontWeight: theme.fontWeight.bold,
  textTransform: "uppercase",
  padding: "1.6rem 0",
  flex: 2,
}));

export const MobileSubMenuAccordion = styled(Accordion)(() => ({
  boxShadow: "none",

  "&:before": {
    opacity: 0,
  },
}));

export const MobileMenuSubmenuTitle = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: theme.fontWeight.medium,
  color: theme.color.primary_color,
  fontSize: isJapanese ? "1.8rem" : "2rem",
}));

export const MobileMenuDetailMenuTitle = styled.div(() => ({
  padding: "0.4rem 0",
  fontSize: "1.8rem",
}));

export const MobileMenuDetailWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  color: theme.color.tertiary_grey,
  marginLeft: "4rem",
}));
