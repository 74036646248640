import React from "react";

import { useGetCasinoEventsDetail } from "@utils/api-requests/CasinoRequests";
import { BoxListFour } from "@components/box-list";

const CasinoEventItems = () => {
  const { data: casinoEventsDetail } = useGetCasinoEventsDetail();

  return (
    <BoxListFour
      data={casinoEventsDetail}
      imageKey="field_thumbnail"
      nameKey="field_event_name"
      dateKey="field_applicable_end_date"
      descKey="field_short_description"
      linkTo="/casino/events-promotions"
    />
  );
};

export default CasinoEventItems;
