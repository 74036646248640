import React from "react";

import { DangerouslyHtml } from "@components/item";

import {
  StoreImageSliderContainer,
  StoreImageSliderDesc,
} from "../ShopStyling";
import { SliderSix } from "@components/sliders";

const StoreImageSlider = ({ data }) => {
  return (
    <StoreImageSliderContainer>
      <SliderSix imageArr={data?.field_multiple_images} />

      <StoreImageSliderDesc>
        <DangerouslyHtml value={data?.field_store_description} />
      </StoreImageSliderDesc>
    </StoreImageSliderContainer>
  );
};

export default StoreImageSlider;
