import React from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";
import { FrameFour } from "@components/frame";
import { DangerouslyHtml } from "@components/item";
import { BookSplashBayText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SplashBayDomeReserveNow = ({ data, noButton }) => {
  return (
    <FrameFour
      reverse
      title={data?.field_reserve_now_title}
      url={data?.field_image[0]?.src}
      alt={data?.field_image[0]?.alt}
    >
      <CommonDescOne>
        <DangerouslyHtml value={data?.field_reserve_now_description} />
      </CommonDescOne>

      {!noButton && (
        <div>
          <PrimaryButton
            linkTo="/splash-bay/reservation"
            customstyle={{ width: "fit-content", maxWidth: "none" }}
          >
            <BookSplashBayText />
          </PrimaryButton>
        </div>
      )}
    </FrameFour>
  );
};

export default SplashBayDomeReserveNow;
