import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { image } from "src/theme";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MovableModal } from "@components/modal";
import { BookingModal } from "@components/modal/modal-list";
import { LoadingScreen } from "@screens/index";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { ReserveText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  MobileHeaderContainer,
  MobileHeaderRight,
  MobileHeaderIn,
  HamburgerButton,
  HamburgerLine,
  BookNowButton,
} from "./HeaderStyling";

const MobileHeader = ({ active, setActive, headerColored }) => {
  const intl = useIntl();

  const [bookingModal, setBookingModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const menuModalHandler = useCallback(
    () => setActive((prev) => !prev),
    [setActive],
  );

  const headerBlacked = useMemo(() => {
    if (headerColored || active) {
      return true;
    }

    return false;
  }, [headerColored, active]);

  useEffect(() => {
    if (active) {
      document.body.style.cssText = `
      overflow-y: hidden;
      width: 100%;`;
    }

    return () => {
      document.body.style.cssText = "";
    };
  }, [active]);

  const closeModal = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading) setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [loading]);

  const handleOpenBookingModal = useCallback(() => {
    setBookingModal(true);
  }, []);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  let agilysysLang;

  switch (lang) {
    case "zh-hans":
      agilysysLang = "ZH-CN";
      break;
    case "zh-hant":
      agilysysLang = "ZH-TW";
      break;
    default:
      agilysysLang = lang;
      break;
  }

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    <>
      <MovableModal
        modalName={intl.formatMessage({
          id: "lang-room-reservation",
        })}
        open={bookingModal}
        setOpen={setBookingModal}
      >
        <BookingModal />
      </MovableModal>

      {loading && <LoadingScreen />}

      <MobileHeaderContainer headerColored={headerBlacked}>
        <CommonContainer>
          <MobileHeaderIn>
            <Link
              to={baseUrl}
              target="_blank"
              onClick={() => {
                closeModal();
                setLoading(true);
              }}
            >
              <object
                data={
                  headerBlacked
                    ? image.basicLogo.default
                    : image.whiteLogo.default
                }
                alt="Mohegan Inspire Entertainment Resort"
                aria-label="Mohegan Inspire Entertainment Resort"
                height={28}
              />
            </Link>

            <MobileHeaderRight>
              <Link
                to={`https://book.rguest.sg/onecart/wbe/offers/20044/InspireEntertainmentResort?langCode=${agilysysLang.toUpperCase()}`}
              >
                <BookNowButton
                  headerColored={headerBlacked}
                  // onClick={handleOpenBookingModal}
                >
                  <ReserveText />
                </BookNowButton>
              </Link>

              <HamburgerButton
                onClick={menuModalHandler}
                headerColored={headerBlacked}
              >
                <HamburgerLine active={active} opacity={active ? 0 : 1} />

                <HamburgerLine
                  active={active}
                  top="50%"
                  opacity={1}
                  rotate={active ? "rotate(45deg)" : "0"}
                />

                <HamburgerLine
                  active={active}
                  top={active ? "50%" : "97%"}
                  opacity={1}
                  rotate={active ? "rotate(-45deg)" : "0"}
                />
              </HamburgerButton>
            </MobileHeaderRight>
          </MobileHeaderIn>
        </CommonContainer>
      </MobileHeaderContainer>
    </>
  );
};

export default MobileHeader;
