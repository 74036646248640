import React from "react";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { PrimaryButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { GetInspiredMeetingsContainer } from "./MeetStyling";

const GetInspiredMeetings = ({ data }) => {

  return (
    <CommonContainer>
      <GetInspiredMeetingsContainer>
        <ImageWrapper radius="2.5rem" height="40rem">
          <Image
            src={data?.field_thumbnail[0]?.src}
            alt={data?.field_thumbnail[0]?.alt}
          />
        </ImageWrapper>

        {
          data?.field_link &&
          (<PrimaryButton linkTo={data?.field_link?.replaceAll("internal:","")}>
            <ViewDetailsText />
          </PrimaryButton>)
        }


      </GetInspiredMeetingsContainer>
    </CommonContainer>
  );
};

export default GetInspiredMeetings;
