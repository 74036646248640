import React, { memo } from "react";
import { SwiperSlide } from "swiper/react";

import { PrimaryButton } from "@components/buttons";
import { SliderTemplate } from "@components/sliders";
import { BoxListFourItem } from "@components/box-list/BoxListFour";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/index";
import { useGetOfferDetailWithId } from "@utils/api-requests/OfferRequests";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { HomeButtonWrapper } from "./HomeStyling";

const HomeOffers = ({ data }) => {
  const {
    data: offerDetailWithId,
    isFetching,
    isError,
    error,
  } = useGetOfferDetailWithId(data?.field_home_section_offers);

  return (
    <CommonContentButtonWrapper>
      <ErrorManage isFetching={isFetching} isError={isError} error={error}>
        {data && offerDetailWithId?.length > 0 && (
          <CommonContainer>
            <SliderTemplate length={data?.length}>
              {offerDetailWithId?.slice(0, 3).map((item, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <BoxListFourItem
                      idx={idx}
                      item={item}
                      imageKey="field_offer_thumbnail"
                      nameKey="field_offer_name"
                      descKey="field_offer_short_desc"
                      linkTo="/offers/detail"
                      dateKey="field_applicable_end_date"
                    />
                  </SwiperSlide>
                );
              })}
            </SliderTemplate>
          </CommonContainer>
        )}
      </ErrorManage>

      <CommonContainer>
        <HomeButtonWrapper>
          <PrimaryButton linkTo="/offers">
            <ViewAllText />
          </PrimaryButton>
        </HomeButtonWrapper>
      </CommonContainer>
    </CommonContentButtonWrapper>
  );
};

export default memo(HomeOffers);
