import React from "react";

import { KeepInTouch } from "@components/layout";
import { useGetHomeSignUpSection } from "@utils/api-requests/HomeRequests";

const HomeSignUp = () => {
  const { data: homeSignUpSection } = useGetHomeSignUpSection();

  return <KeepInTouch data={homeSignUpSection} />;
};

export default HomeSignUp;
