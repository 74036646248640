import React from "react";

import { CommonDescOne, CommonTitleFour } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { PrimaryButton } from "@components/buttons";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  MomentumNewMemberContainer,
  MomentumNewMemberInformation,
  MomentumNewMemberTitle,
} from "./MomentumStyling";

const MomentumNewMember = ({ data }) => {
  return (
    <MomentumNewMemberContainer>
      <ImageWrapper radius="2.5rem">
        <Image
          src={data?.field_thumbnail[0]?.src}
          alt={data?.field_thumbnail[0]?.alt}
        />
      </ImageWrapper>

      <MomentumNewMemberInformation>
        <MomentumNewMemberTitle>
          <DangerouslyHtml value={data?.field_benefits_title} />
        </MomentumNewMemberTitle>

        <CommonTitleFour>
          <DangerouslyHtml value={data?.field_benefits_sub_title} />
        </CommonTitleFour>

        <CommonDescOne>
          <DangerouslyHtml value={data?.field_full_description} />
        </CommonDescOne>

        <PrimaryButton>
          <ViewDetailsText />
        </PrimaryButton>
      </MomentumNewMemberInformation>
    </MomentumNewMemberContainer>
  );
};

export default MomentumNewMember;
