import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import {
  BoxListFiveContainer,
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
} from "@components/box-list/BoxListStyling";
import {
  BoxListFiveDetailButton,
  BoxListFiveOverlays,
} from "@components/box-list/BoxListFive";
import { ErrorManage } from "@screens/index";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { useGetMeetServices } from "@utils/api-requests/MeetRequests";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const MeetServices = () => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const search = useLocation().search;

  const meetServicesRef = useRef();

  const {
    data: meetServices,
    isLoading,
    isError,
    error,
  } = useGetMeetServices();

  const [onHover, setOnHover] = useState(-1);

  const onMouseEnter = (i) => {
    setOnHover(i);
  };

  const onMouseLeave = () => {
    setOnHover(-1);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "meet-services" && meetServices) {
        scrollToTargetAdjusted(meetServicesRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [meetServices, search]);

  return (
    <CommonContainer>
      <div ref={meetServicesRef}>
        <BoxListFiveContainer
          length={
            meetServices?.filter((el) => el.field_display_on_sub === "On")
              .length
          }
        >
          <ErrorManage isLoading={isLoading} isError={isError} error={error}>
            {meetServices
              ?.filter((el) => el.field_display_on_sub === "On")
              .map((item, idx) => {

                return (
                  <BoxListFiveGridArea
                    key={item.uuid}
                    onMouseEnter={() => onMouseEnter(idx)}
                    onMouseLeave={onMouseLeave}
                  >
                    <ImageWrapper
                      radius="1.5rem"
                      ratio={
                        meetServices?.filter(
                          (el) => el.field_display_on_sub === "On"
                        ).length === 1
                          ? isDesktop
                            ? "10 / 3"
                            : "1 / 1"
                          : idx === 0 || idx === 1
                          ? "3 / 2"
                          : "1 / 1"
                      }
                    >
                      <Image
                        src={item.field_thumbnail[0]?.src}
                        alt={item.field_thumbnail[0]?.alt}
                      />

                      <BoxListFiveInformation>
                        <CommonTitleTwo>
                          <DangerouslyHtml
                            value={item.field_meet_service_title}
                          />
                        </CommonTitleTwo>

                        <BoxListFiveDesc className="ellipsis-3">
                          <DangerouslyHtml
                            value={item.field_service_description}
                          />
                        </BoxListFiveDesc>

                        <BoxListFiveDetailButton
                          onHover={onHover}
                          idx={idx}
                          item={item}
                          url={item.field_services_type.includes("Business") ? `/meet` : `/meet/services`}
                        />
                      </BoxListFiveInformation>

                      <BoxListFiveOverlays onHover={onHover} idx={idx} />
                    </ImageWrapper>
                  </BoxListFiveGridArea>
                );
              })}
          </ErrorManage>
        </BoxListFiveContainer>
      </div>
    </CommonContainer>
  );
};

export default MeetServices;
