import React, {useCallback, useMemo} from "react";
import { useParams} from "react-router-dom";

import { SliderSix } from "@components/sliders";
import {
  DetailPageContainer,
  DetailPageHero,
} from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { PageBottomContents } from "@components/layout";
import {
  BoxInformationOne,
  DangerouslyHtml,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation
} from "@components/item";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import { useGetCasinoEventsDetail } from "@utils/api-requests/CasinoRequests";
import {OffersTermsCondtions} from "@pages/offers/offers/OffersStyling";
import {changeEmptyValue, openOfferBookingSite, TranslateDayMonth} from "@utils/modify-data/modifyData";
import {useIntl} from "react-intl";
import {BookNowText} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const CasinoEventDetail = () => {
  const eventAlias = useParams().eventAlias;
  const intl = useIntl();

  const {
    data: casinoEventsDetail,
    isFetching,
    isError,
    error,
  } = useGetCasinoEventsDetail(eventAlias);

  const offerRGuestUrl = useCallback(() => {
    openOfferBookingSite(casinoEventsDetail?.field_code);
  }, [casinoEventsDetail?.field_code]);

  const offersDetailBC = useMemo(() => {
    const eventDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
        href: "/casino/",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-events-promotions",
        }),
        href: "/casino/events-promotions",
      },
      {
        id: 3,
        current: true,
        name: casinoEventsDetail?.field_event_name,
      },
    ];

    return eventDetails;
  }, [
    intl,
    casinoEventsDetail?.field_event_name
  ]);

  const information = [
    {
      id: 1,
      header: intl.formatMessage({
        id: "lang-book-between",
      }),
      object: true,
      data: (
        <>
          {/* <ComingSoonText /> */}

          <p>{TranslateDayMonth(casinoEventsDetail?.field_bookable_end_date)} ~</p>

          <p>{TranslateDayMonth(casinoEventsDetail?.field_applicable_end_date)}</p>
        </>
      ),
    },
    {
      id: 2,
      header: intl.formatMessage({
        id: "lang-stay-between",
      }),
      object: true,
      data: (casinoEventsDetail?.field_stay_start_date || casinoEventsDetail?.field_stay_end_date) && (
        <>
          {/* <ComingSoonText /> */}

          <p>{TranslateDayMonth(casinoEventsDetail?.field_stay_start_date)} ~</p>

          <p>{TranslateDayMonth(casinoEventsDetail?.field_stay_end_date)}</p>
        </>
      ),
    },
    {
      id: 3,
      header: intl.formatMessage({
        id: "lang-starts-from",
      }),
      data: casinoEventsDetail?.field_starts_from_price && (
        changeEmptyValue(casinoEventsDetail?.field_starts_from_price)
      ),
    },
  ];

  const sectionArray = [
    {
      id: 1,
      contents: (
        <BoxInformationOne
          information={information}
          noShowBox
        >
          <NotificationToggle data={casinoEventsDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={offersDetailBC}
            pageTitle={casinoEventsDetail?.field_event_name}
            value={casinoEventsDetail?.field_full_description}
            buttonEvent={offerRGuestUrl}
            buttonName={
              casinoEventsDetail?.field_offer_type?.id === "45" && <BookNowText />
            }
          />
        </BoxInformationOne>
      ),
    },
    {
      id: 2,
      contents: (
        <SliderSix imageArr={casinoEventsDetail?.field_multiple_images} />
      ),
    },
    {
      id: 3,
      contents: (
        <OffersTermsCondtions>
          <DangerouslyHtml value={casinoEventsDetail?.field_event_details} />
          <DangerouslyHtml value={casinoEventsDetail?.field_terms_condition} />
        </OffersTermsCondtions>
      ),
    },
  ];

  return (
      <ErrorManage isLoading={isFetching} isError={isError} error={error}>
        <CustomMetatag metaData={casinoEventsDetail}/>

        <DetailPageHero data={casinoEventsDetail}/>

        <CommonContainer>
          <DetailPageContainer sectionArray={sectionArray}/>
        </CommonContainer>

        <PageBottomContents>
          <OfferRecommendation data={casinoEventsDetail}/>
        </PageBottomContents>
      </ErrorManage>
  );
};

export default CasinoEventDetail;
