import React, { useEffect, useRef } from "react";

import { SliderFour } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import { useGetSplashBayServicesList } from "@utils/api-requests/SplashBayRequests";
import { useLocation } from "react-router-dom";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const SplashBayServices = () => {
  const search = useLocation().search;

  const splashBayServicesRef = useRef();

  const {
    data: splashBayServices,
    isLoading,
    isError,
    error,
  } = useGetSplashBayServicesList();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "services") {
        scrollToTargetAdjusted(splashBayServicesRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  return (
    <div ref={splashBayServicesRef}>
      <ErrorManage isLoading={isLoading} isError={isError} error={error}>
        <SliderFour
          data={splashBayServices}
          titleKey="field_service_name"
          descKey="field_short_description"
          link="/splash-bay/services"
          linkKey="url_alias"
        />
      </ErrorManage>
    </div>
  );
};

export default SplashBayServices;
