import { FormattedMessage } from "react-intl";

// Validation Texts
export const NameText = () => {
  return <FormattedMessage id="lang-name" />;
};

export const EmailInvalidFormatText = () => {
  return <FormattedMessage id="lang-email-invalid-format" />;
};

export const EmailRequiredText = () => {
  return <FormattedMessage id="lang-email-required" />;
};

export const FirstNameRequiredText = () => {
  return <FormattedMessage id="lang-first-name-required" />;
};

export const LastNameRequiredText = () => {
  return <FormattedMessage id="lang-last-name-required" />;
};

export const PasswordRequiredText = () => {
  return <FormattedMessage id="lang-password-required" />;
};

export const PasswordMinLengthText = () => {
  return <FormattedMessage id="lang-password-min-length" />;
};

export const PasswordMaxLengthText = () => {
  return <FormattedMessage id="lang-password-max-length" />;
};

export const OldPasswordRequiredText = () => {
  return <FormattedMessage id="lang-old-password-required" />;
};

export const PasswordNoMatchText = () => {
  return <FormattedMessage id="lang-password-no-match" />;
};

export const PhoneRequiredText = () => {
  return <FormattedMessage id="lang-phone-required" />;
};
export const PhoneCodeRequiredText = () => {
  return <FormattedMessage id="lang-phone-code-required" />;
};

export const PhoneInvalidFormatText = () => {
  return <FormattedMessage id="lang-phone-invalid-format" />;
};

export const RecaptchaRequriedText = () => {
  return <FormattedMessage id="lang-recaptcha-required" />;
};

export const DateofBirthRequriedText = () => {
  return <FormattedMessage id="lang-date-of-birth-required" />;
};

export const DateofBirthInvalidFormatText = () => {
  return <FormattedMessage id="lang-date-of-birth-invalid-format" />;
};

export const DateofBirthNineteenInvalidFormatText = () => {
  return <FormattedMessage id="lang-date-of-birth-nineteen-invalid-format" />;
};

export const DateofAnniversaryInvalidFormatText = () => {
  return <FormattedMessage id="lang-date-of-anniversary-invalid-format" />;
};

export const VerificationCodeRequiredText = () => {
  return <FormattedMessage id="lang-verification-code-required" />;
};

export const VerificationCodeInvalidFormat = () => {
  return <FormattedMessage id="lang-verification-code-invalid-format" />;
};

export const TypeRequiredText = () => {
  return <FormattedMessage id="lang-type-required" />;
};

export const AddressRequiredText = () => {
  return <FormattedMessage id="lang-address-required" />;
};

export const CountryRequiredText = () => {
  return <FormattedMessage id="lang-country-required" />;
};

export const StateRequiredText = () => {
  return <FormattedMessage id="lang-state-required" />;
};

export const CityRequiredText = () => {
  return <FormattedMessage id="lang-city-required" />;
};

export const PostalCodeRequiredText = () => {
  return <FormattedMessage id="lang-postal-code-required" />;
};

export const PrivacyPolicyRequiredText = () => {
  return <FormattedMessage id="lang-privacy-policy-required" />;
};

export const DateRequiredText = () => {
  return <FormattedMessage id="lang-date-required" />;
};

// Label Texts
export const DateLabelText = () => {
  return <FormattedMessage id="lang-date-label" />;
};

export const FirstNameLabelText = () => {
  return <FormattedMessage id="lang-first-name-label" />;
};

export const LastNameLabelText = () => {
  return <FormattedMessage id="lang-last-name-label" />;
};

export const EmailLabelText = () => {
  return <FormattedMessage id="lang-email-label" />;
};

export const PhoneLabelText = () => {
  return <FormattedMessage id="lang-phone-label" />;
};

export const DateofBirthLabelText = () => {
  return <FormattedMessage id="lang-date-of-birth-label" />;
};

export const DateofAnniversaryLabelText = () => {
  return <FormattedMessage id="lang-date-of-anniversary-label" />;
};

export const PrivacyPolicyLabelText = () => {
  return <FormattedMessage id="lang-privacy-policy-label" />;
};

export const AgreeTermsLabelText = () => {
  return <FormattedMessage id="lang-terms-conditions-label" />;
};

export const MailOptLabelText = () => {
  return <FormattedMessage id="lang-mail-opt-label" />;
};

export const MailOptContentText = () => {
  return (
    <FormattedMessage
      id="lang-mail-opt-content"
      values={{
        linebreak: <br />,
      }}
    />
  );
};

export const MailUseLabelText = () => {
  return <FormattedMessage id="lang-mail-use-label" />;
};

export const MailUseContentText = () => {
  return (
    <FormattedMessage
      id="lang-mail-use-content"
      values={{
        linebreak: <br />,
      }}
    />
  );
};

export const UsernameEmailLabelText = () => {
  return <FormattedMessage id="lang-username-email-label" />;
};

export const PasswordLabelText = () => {
  return <FormattedMessage id="lang-password-label" />;
};

export const ConfirmPasswordLabelText = () => {
  return <FormattedMessage id="lang-confirm-password-label" />;
};

export const NewPasswordLabelText = () => {
  return <FormattedMessage id="lang-new-password-label" />;
};

export const OldPasswordLabelText = () => {
  return <FormattedMessage id="lang-old-password-label" />;
};

export const VerifiactionCodeLabelText = () => {
  return <FormattedMessage id="lang-verification-code-label" />;
};

export const TypeLabelText = () => {
  return <FormattedMessage id="lang-type-label" />;
};

export const AddressLabelText = () => {
  return <FormattedMessage id="lang-address-label" />;
};

export const Address2LabelText = () => {
  return <FormattedMessage id="lang-address-2-label" />;
};

export const CountryLabelText = () => {
  return <FormattedMessage id="lang-country-label" />;
};

export const StateLabelText = () => {
  return <FormattedMessage id="lang-state-label" />;
};

export const CityLabelText = () => {
  return <FormattedMessage id="lang-city-label" />;
};

export const PostalCodeLabelText = () => {
  return <FormattedMessage id="lang-postal-code-label" />;
};

// ERROR Texts
export const SignInInvalidAccountErrorText = () => {
  return <FormattedMessage id="lang-sign-in-invalid-account-error" />;
};

export const SignUpProfileAlreadyExistErrorText = () => {
  return <FormattedMessage id="lang-sign-up-profile-already-exist-error" />;
};

export const ForgotPasswordProfileNotExistErrorText = () => {
  return <FormattedMessage id="lang-forgot-password-profile-not-exist-error" />;
};

export const VerificationCodeInvalidErrorText = () => {
  return <FormattedMessage id="lang-verification-code-invalid-error" />;
};

export const VerificationCodeExpiredErrorText = () => {
  return <FormattedMessage id="lang-verification-code-expired-error" />;
};

export const SignInAccountIsBlockedErrorText = () => {
  return <FormattedMessage id="lang-sign-in-account-blocked-error" />;
};

export const ResetPasswordOldPasswordNotMatchedErrorText = () => {
  return (
    <FormattedMessage id="lang-reset-password-old-password-not-matched-error" />
  );
};

export const VerificationCodeSentMessage1 = () => {
  return <FormattedMessage id="lang-verification-code-sent-message-1" />;
};

export const VerificationCodeSentMessage2 = () => {
  return <FormattedMessage id="lang-verification-code-sent-message-2" />;
};

export const VerificationCodeExpiredMessage = () => {
  return <FormattedMessage id="lang-verification-code-expired-message" />;
};

export const EmailExistErrorText = () => {
  return <FormattedMessage id="lang-email-exist-error" />;
};

export const MobileText = () => {
  return <FormattedMessage id="lang-mobile" />;
};

export const ProfileActivatedSuccessText = () => {
  // Profile activated successfully you can login now
  return <FormattedMessage id="lang-profile-activated-success" />;
};

export const PasswordChangedSuccessText = () => {
  // Your Password Changed Successfully
  return <FormattedMessage id="lang-password-changed-success" />;
};

export const LegalAgeRequiredText = () => {
  // Please confirm that you are legal age
  return <FormattedMessage id="lang-legal-age-required" />;
};

export const PreferredLanguageRequiredText = () => {
  // Preferred language is required
  return <FormattedMessage id="lang-preferred-language-required" />;
};

export const PasswordResetSuccessText = () => {
  // Your Password reset successfully
  return <FormattedMessage id="lang-password-reset-successfully" />;
};

export const PreviousVerificationCodeNotExpiredText = () => {
  // Previous Verification Code is not Expired
  return <FormattedMessage id="lang-previous-verification-code-not-expired" />;
};

export const FailLoginContactAdminText = () => {
  // You cannot login. Please contact admin.
  return <FormattedMessage id="lang-fail-login-contact-admin" />;
};

export const SelectOneText = () => {
  return <FormattedMessage id="lang-select-one" />;
};

export const YesText = () => {
  return <FormattedMessage id="lang-yes" />;
};

export const NoText = () => {
  return <FormattedMessage id="lang-no" />;
};
