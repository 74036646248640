import { CommonDescFive } from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

export const CheckMoreOffersContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column-reverse",
  gap: "4rem 10rem",

  "& > *": {
    flex: 1,
  },

  [mq("desktop")]: {
    alignItems: "center",
    flexDirection: "row",
  },
}));

export const CheckMoreOffersLeft = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4rem 0",

  [mq("desktop")]: {
    alignItems: "flex-end",
  },
}));

export const CheckMoreOffersInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  maxWidth: "100%",

  [mq("desktop")]: {
    maxWidth: "70%",
    textAlign: "right",
    alignItems: "flex-end",
    gap: "4rem 0",
  },
}));

export const OffersIncludesContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8rem 0",
}));

export const OffersTermsCondtions = styled(CommonDescFive)(({ theme }) => ({
  strong: {
    fontFamily: theme.fontFamily.primaryFont,
  },
}));

// STYLE: OfferList.jsx
export const OfferListEmptyBox = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2.5rem",
  overflow: "hidden",
  boxShadow: theme.shadow.primary,

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const OfferListEmptyInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  width: "100%",
  padding: "3.2rem",

  [mq("desktop")]: {
    width: "50%",
    padding: "6.4rem",
  },
}));
