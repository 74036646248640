import React, { memo } from "react";

import { SliderThree } from "@components/sliders";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { PrimaryButton } from "@components/buttons";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetHomeFandBSection } from "@utils/api-requests/HomeRequests";

import { HomeButtonWrapper } from "./HomeStyling";

const HomeRestaurant = () => {
  const { data: homeFandBSection } = useGetHomeFandBSection();

  return (
    <CommonContentButtonWrapper>
      <SliderThree data={homeFandBSection} />

      <CommonContainer>
        <HomeButtonWrapper>
          <PrimaryButton linkTo="/eat-and-drink">
            <ViewAllText />
          </PrimaryButton>
        </HomeButtonWrapper>
      </CommonContainer>
    </CommonContentButtonWrapper>
  );
};

export default memo(HomeRestaurant);
