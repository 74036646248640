import React, { useState, useCallback, memo, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { enAU, ja, ko, zhCN } from "date-fns/locale";
import moment from "moment/moment";

import { image } from "src/theme";
import { CommonModal } from "@components/modal";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  CalendarWrapper,
  RangeCalenderButtons,
  RangeCalenderCancelApply,
  RangeCalenderContainer,
  RangeCalenderNavigator,
  RangeCalenderResetButton,
} from "./CalendarStyling";

const RangeCalender = ({
  calendarOpen,
  setCalendarOpen,
  setDates,
  setValue,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const localization = useMemo(() => {
    switch (language) {
      case "en":
        return enAU;

      case "ko":
        return ko;

      case "ja":
        return ja;

      case "zh":
        return zhCN;

      default:
        break;
    }
  }, [language]);

  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      dateFormat: "ddd",
      key: "selection",
    },
  ]);

  function formattDate(date) {
    let toIsoFormatt = moment(date).format();

    const splitedDate = toIsoFormatt.split("T")[0];

    return splitedDate;
  }

  const handleDateSelect = useCallback((item) => {
    setSelectedDate([item.selection]);
  }, []);

  const handleResetCalendar = useCallback(() => {
    setSelectedDate([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: "selection",
      },
    ]);
  }, []);

  const handleCloseCalendar = useCallback(() => {
    setCalendarOpen(false);

    handleResetCalendar();
  }, [handleResetCalendar, setCalendarOpen]);

  const onApply = useCallback(() => {
    setDates(
      `${formattDate(selectedDate[0].startDate)} ~ ${formattDate(
        selectedDate[0].endDate
      )}`
    );

    setCalendarOpen(false);

    setValue("startdate", formattDate(selectedDate[0].startDate));

    setValue("enddate", formattDate(selectedDate[0].endDate));
  }, [setDates, selectedDate, setCalendarOpen, setValue]);

  return (
    <CommonModal
      openModal={calendarOpen}
      setOpenModal={setCalendarOpen}
      noHeader
    >
      {/* locale: https://en.wikipedia.org/wiki/IETF_language_tag */}

      <RangeCalenderContainer>
        <div>
          <CalendarWrapper>
            <DateRange
              showDateDisplay={false}
              showMonthAndYearPickers={false}
              fixedHeight={true}
              weekdayDisplayFormat="ccccc"
              monthDisplayFormat="MMMM yyyy"
              editableDateInputs={false}
              onChange={handleDateSelect}
              moveRangeOnFirstSelection={true}
              ranges={selectedDate}
              months={2}
              nextArrow={<div>button Next</div>}
              minDate={new Date()}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              direction={isMobile ? "vertical" : "horizontal"}
              locale={localization}
              color="#502875"
              rangeColors={["#502875", "#F1E5FD"]}
              dateDisplayFormat="MM.dd.yyyy"
              navigatorRenderer={(focusedDate, changeShownDate, props) => {
                return (
                  <RangeCalenderNavigator>
                    <button
                      type="button"
                      onClick={() => changeShownDate(-1, "monthOffset")}
                    >
                      <img
                        src={image.calendarArrow.default}
                        alt="Prev Month"
                        loading="lazy"
                      />
                    </button>

                    <button
                      type="button"
                      onClick={() => changeShownDate(+1, "monthOffset")}
                    >
                      <img
                        src={image.calendarArrow.default}
                        alt="Next Month"
                        loading="lazy"
                      />
                    </button>
                  </RangeCalenderNavigator>
                );
              }}
            />
          </CalendarWrapper>
        </div>

        <RangeCalenderButtons>
          <RangeCalenderResetButton onClick={handleResetCalendar}>
            Reset Calendar
          </RangeCalenderResetButton>

          <RangeCalenderCancelApply>
            <button type="button" onClick={handleCloseCalendar}>
              Cancel
            </button>

            <button type="button" onClick={onApply}>
              Apply
            </button>
          </RangeCalenderCancelApply>
        </RangeCalenderButtons>
      </RangeCalenderContainer>
    </CommonModal>
  );
};

export default memo(RangeCalender);
