import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { SliderTwo } from "@components/sliders";
import {
  useGetCasinoService,
  useGetCasinoServiceCategory,
} from "@utils/api-requests/CasinoRequests";
import { checkIncludes } from "@utils/modify-data/modifyData";

const CasinoServiceFacilities = () => {
  const [filterType, setFilterType] = useState("");

  const { data: casinoService } = useGetCasinoService();
  const { data: casinoServiceCategory } = useGetCasinoServiceCategory();

  const meetingCategory = useMemo(() => {
    const result = casinoServiceCategory?.map((item) => {
      return {
        title: item.name,
        category_id: item.name,
      };
    });

    setFilterType(casinoServiceCategory?.[0]?.field_meeting_category);

    return result;
  }, [casinoServiceCategory]);

  useEffect(() => {
    setFilterType(casinoServiceCategory?.[0]?.name);
  }, [casinoServiceCategory]);

  const currentData = useMemo(() => {
    return casinoService?.filter((el) =>
      checkIncludes(el.field_service_category, filterType)
    )[0];
  }, [filterType, casinoService]);

  return (
    <ListWithTabs
      tabArr={meetingCategory}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <SliderTwo
        direction="left"
        data={currentData}
        subTitle={currentData?.field_service_sub_category}
        desc={currentData?.field_full_description}
        title={currentData?.field_service_name}
      />
    </ListWithTabs>
  );
};

export default CasinoServiceFacilities;
