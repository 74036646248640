import React from "react";

import {
  HtmlResponsible,
  PledgeToOurEmployeesContainer,
} from "@pages/casino/CasinoStyling";

import { ResponsibleCommonItems } from "./ResponsibleGamingDetail";
import { ResponsibleAccordion } from ".";

const PledgeToOurEmployees = ({ data }) => {
  return (
    <ResponsibleAccordion title={data?.field_pledge_our_employees_title}>
      <HtmlResponsible value={data?.field_pledge_our_employees_desc} />

      <PledgeToOurEmployeesContainer>
        <ResponsibleCommonItems data={data?.field_pledge_to_our_employees} />
      </PledgeToOurEmployeesContainer>
    </ResponsibleAccordion>
  );
};

export default PledgeToOurEmployees;
