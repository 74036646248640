import React from "react";

import { DangerouslyHtml } from "@components/item";
import {
  HtmlResponsible,
  ResponsibleAccordionSubTitle,
} from "@pages/casino/CasinoStyling";

import { ResponsibleAccordion } from ".";

const MythsWithGambling = ({ data }) => {
  return (
    <ResponsibleAccordion title={data?.field_myths_with_gambling_title}>
      <ResponsibleAccordionSubTitle>
        <DangerouslyHtml value={data?.field_myths_gambling_sub_title} />
      </ResponsibleAccordionSubTitle>

      <HtmlResponsible value={data?.field_myths_gambling_description} />
    </ResponsibleAccordion>
  );
};

export default MythsWithGambling;
