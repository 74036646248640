import React, { memo, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "@emotion/react";

import { replaceAmpersand } from "@utils/modify-data/modifyData";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {breadcrumbButtonStyles, PrimaryButtonArrow, } from "./ButtonsStyling";

const BreadcrumbButton = ({
  buttonType = "button",
  buttonColor,
  buttonEvent,
  linkTo,
  outerLink,
  children,
  noArrow = false,
  detailButton,
  disable,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [hover, setHover] = useState(false);

  const theme = useTheme();

  const activeHover = useCallback(() => {
    setHover(true);
  }, []);

  const inactiveHover = useCallback(() => {
    setHover(false);
  }, []);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    if (lang !== null) return `/${lang}`;
  }, [lang]);

  return linkTo ? (
    <Link
      to={`${baseUrl}${linkTo}`}
      style={breadcrumbButtonStyles(theme, {
        isDesktop,
      })}
      onClick={buttonEvent}
      onMouseEnter={activeHover}
      onMouseLeave={inactiveHover}
    >
      {children}

      {!noArrow && (
        <PrimaryButtonArrow
          src={theme.image.linkArrow.default}
          alt="right arrow"
          hover={hover}
        />
      )}
    </Link>
  ) : outerLink ? (
    <a
      href={replaceAmpersand(outerLink)}
      style={breadcrumbButtonStyles(theme, {
        isDesktop,
      })}
      target="_blank"
      rel="noreferrer"
      onMouseEnter={activeHover}
      onMouseLeave={inactiveHover}
    >
      {children}

      {!noArrow && (
        <PrimaryButtonArrow
          src={theme.image.linkArrow.default}
          alt="right arrow"
          hover={hover}
        />
      )}
    </a>
  ) : (
    <button
      type={buttonType}
      style={breadcrumbButtonStyles(theme, {
        isDesktop,
      })}
      onMouseEnter={activeHover}
      onMouseLeave={inactiveHover}
      onClick={buttonEvent}
      disabled={disable}
    >
      {children}

      {!noArrow && (
        <PrimaryButtonArrow
          src={theme.image.linkArrow.default}
          alt="right arrow"
          hover={hover}
        />
      )}
    </button>
  );
};

export default memo(BreadcrumbButton);
