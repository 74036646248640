import React, { useMemo } from "react";

import {
  CommonDescFour,
  CommonTitleFive,
  CommonTitleOne,
} from "@assets/styles/CommonStyles";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import { ErrorManage } from "@screens/index";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import {
  useGetAgilisysSignUp,
  useGetMyProfile,
} from "@utils/api-requests/MomentumRequests";
import {
  AreYouAMomentumMemberAlreadyText,
  LoginText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  AgilisysSignUpContainer,
  AgilisysSignUpImage,
  AgilisysSignUpIn,
  AgilisysSignUpInformation,
  AgilisysSignUpLink,
  AgilisysSignUpList,
  AgilisysSignUpTitleWrapper,
  AgilisysSignUpWrapper,
} from "../MomentumStyling";
import AgilisysSignUpForm from "./AgilisysSignUpForm";
import ThirdPartyDown from "@screens/ThirdPartyDown";

const AgilisysSignUp = () => {
  const {
    data: agilisysSignUp,
    isLoading,
    isError,
    error,
  } = useGetAgilisysSignUp();

  const { data: myProfile } = useGetMyProfile();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <AgilisysSignUpContainer url={agilisysSignUp?.field_hero[0]?.src}>
        <CommonContainer>
          <AgilisysSignUpWrapper>

            {agilisysSignUp?.field_maintenance_page === undefined ||
             agilisysSignUp?.field_maintenance_page === "Off" ? (
              <React.Fragment>
                <AgilisysSignUpTitleWrapper>
                    <CommonTitleOne>
                      <DangerouslyHtml value={agilisysSignUp?.field_modal_title} />
                    </CommonTitleOne>

                    <div>
                      <DangerouslyHtml
                        value={agilisysSignUp?.field_modal_sub_title}
                      />
                    </div>
                  </AgilisysSignUpTitleWrapper>

                <AgilisysSignUpIn>
                  <div>
                    <AgilisysSignUpInformation>
                      <AgilisysSignUpImage
                        src={`${process.env.REACT_APP_SERVER_URL}${agilisysSignUp?.field_image[0].src}`}
                        alt={agilisysSignUp?.field_image[0].alt}
                        width={228}
                      />

                      <CommonTitleFive>
                        <DangerouslyHtml
                          value={agilisysSignUp?.field_main_description}
                        />
                      </CommonTitleFive>

                      <AgilisysSignUpList>
                        {myProfile?.field_tier?.map((tier) => {
                          return (
                            <li key={tier.field_name}>
                              <CommonDescFour>{tier.field_name}</CommonDescFour>
                            </li>
                          );
                        })}
                      </AgilisysSignUpList>
                    </AgilisysSignUpInformation>

                    <CommonDescFour>
                      <p>
                        <AreYouAMomentumMemberAlreadyText />
                      </p>

                      <AgilisysSignUpLink to={`${baseUrl}/momentum/sign-in`}>
                        <LoginText />
                      </AgilisysSignUpLink>
                    </CommonDescFour>
                  </div>

                  <AgilisysSignUpForm />
                </AgilisysSignUpIn>
              </React.Fragment>
            ) : (
              <ThirdPartyDown />
            )}

          </AgilisysSignUpWrapper>
        </CommonContainer>
      </AgilisysSignUpContainer>
    </ErrorManage>
  );
};

export default AgilisysSignUp;
