import {A11y, Autoplay, Grid,  Navigation, Pagination} from "swiper/modules";

export const commonSwiperOptions = {
  modules: [Pagination, Navigation, A11y, Autoplay, Grid, ],
  grabCursor: true,
  loop: true,
  initialSlide: 0,
  a11y: {
    enabled: true,
    prevSlideMessage: "previous slide",
    nextSlideMessage: "next slide",
    slideLabelMessage: "now on {{index}} of total {{slidesLength}} slides",
  },
};
