import React, { } from "react";

import {
  PrivacyPolicyContentsContainer,
} from "@pages/about-us/AboutUsStyling";

import { DangerouslyHtml } from "@components/item";

const PrivacyPolicyContents = ({ data }) => {

  return (
    <div style={{ width: "100%" }}>
      <PrivacyPolicyContentsContainer>

        <div>

          <DangerouslyHtml value={data?.field_full_page_content} />

        </div>
      </PrivacyPolicyContentsContainer>
    </div>
  );
};

export default PrivacyPolicyContents;
