import styled from "@emotion/styled";
import { Accordion, AccordionDetails } from "@mui/material";

import { mq } from "@utils/media-query/mediaQuery";

// SignatureMenu.jsx
export const SignatureMenuContainer = styled.div(() => ({
  marginBottom: "3.2rem",

  [mq("desktop")]: {
    marginBottom: "8rem",
  },
}));

// ViewMenu.jsx
export const ViewMenuContainer = styled(Accordion)(({ theme }) => ({
  padding: "1.6rem",
  boxShadow: theme.shadow.secondary,

  "&.MuiPaper-rounded": {
    borderRadius: "2.5rem",
  },

  [mq("desktop")]: {
    maxWidth: "120rem",
    margin: "0 auto",
    padding: "3rem 5rem",

    "&.Mui-expanded": {
      margin: "0 auto",
    },
  },
}));

export const ViewMenuTitle = styled(AccordionDetails)(() => ({
  width: "100%",
  padding: 0,
}));

export const ViewMenuArrow = styled.img(({ expanded }) => ({
  transition: "all 0.3s",
  height: "1.2rem",

  [mq("desktop")]: {
    height: "2rem",
  },
}));

export const ViewMenuDesc = styled(AccordionDetails)(({ theme }) => ({
  borderTop: `1px solid ${theme.color.base_black}`,
  padding: "1.6rem 1.6rem 0",

  [mq("desktop")]: {
    borderTop: `3px solid ${theme.color.base_black}`,
    padding: "2rem 1.6rem 0",
  },
}));

export const ViewMenuList = styled.ul(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "3rem 5rem",
  listStyle: "initial",
  padding: "1.6rem",

  [mq("desktop")]: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "3rem",
    padding: "3rem 2rem",
  },
}));

export const ViewMenuItem = styled.li(({ theme }) => ({
  color: theme.color.primary_color,
  textTransform: "uppercase",
  fontSize: "1.8rem",
  listStyleType: "disc",
  cursor: "pointer",

  [mq("desktop")]: {
    fontSize: "2.4rem",
  },
}));
