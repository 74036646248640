import React from "react";
import {useMediaQuery} from "react-responsive";

import {DangerouslyHtml} from "@components/item";
import {
  DrupalTableCommonStyle,
  GettingHereTableContainer,
} from "@pages/about-us/AboutUsStyling";

const GettingHereTable = ({ table}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  // const currentTable = useMemo(() => {
  //   if (data === "334") {
  //     // IN CASE: Suttle Bus
  //     return ShuttleBus;
  //   }
  // }, [data]);

  return (
    <GettingHereTableContainer>
        <DrupalTableCommonStyle>
          {
            table?.map((data) => {
              return (<DangerouslyHtml value={data} custom={{ width: isDesktop && data?.includes("half-table") ? "calc(50% - 2rem)" : "100%" }}/>)
            })
          }
        </DrupalTableCommonStyle>

      {/*<TableFive data={currentTable} hideSlider={true} />*/}
    </GettingHereTableContainer>
  );
};

export default GettingHereTable;
