import React from "react";

import {
  CommonDescOne,
  CommonDescThree,
  CommonTitleThree,
} from "@assets/styles/CommonStyles";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";

const AttractionVenuePoints = ({ data }) => {
  return (
    data?.[0].imageslist !== null && (
      <BoxListThreeContainer>
        {data?.map((feature, idx) => {
          return (
            <BoxListThreeWrapper key={feature.title} reverse={idx % 2 > 0}>
              <ImageWrapper radius="2.5rem" ratio="3 / 2">
                <Image
                  src={feature.imageslist?.src}
                  alt={feature.imageslist?.alt}
                />
              </ImageWrapper>

              <BoxListThreeInformation>
                <CommonTitleThree>
                  <DangerouslyHtml value={feature.title} />
                </CommonTitleThree>

                <div>
                  <CommonDescThree>
                    <DangerouslyHtml value={feature.subtitle} />
                  </CommonDescThree>

                  <br />

                  <CommonDescOne>
                    <DangerouslyHtml value={feature.description} />
                  </CommonDescOne>
                </div>
              </BoxListThreeInformation>
            </BoxListThreeWrapper>
          );
        })}
      </BoxListThreeContainer>
    )
  );
};

export default AttractionVenuePoints;
