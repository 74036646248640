import React, { useCallback } from "react";

import { PrimaryButton } from "@components/buttons";
import {
  ForgotPasswordFormDesc,
  ForgotPasswordModalForm,
} from "@components/modal/ModalStyling";
import {
  SignInText,
  SignInWithNewPasswordText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const PasswordDonePage = ({ setOpenModal, setPasswordStep }) => {
  const closeModal = useCallback(() => {
    setOpenModal(false);
    setPasswordStep(0);
  }, [setOpenModal, setPasswordStep]);

  return (
    <ForgotPasswordModalForm>
      <ForgotPasswordFormDesc>
        <SignInWithNewPasswordText />
      </ForgotPasswordFormDesc>

      <PrimaryButton linkTo="/momentum/sign-in" buttonEvent={closeModal}>
        <SignInText />
      </PrimaryButton>
    </ForgotPasswordModalForm>
  );
};

export default PasswordDonePage;
