import React, { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import { ImageWrapper, Video } from "@components/item/ImageRelated";

import { VideoPlayButton } from "./ShopStyling";

const CommonVideo = ({ data, children, radius }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [videoControl, setVideoControl] = useState({
    playing: false,
    muted: false,
    controls: true,
  });

  const playPauseHandler = useCallback(() => {
    setVideoControl({ ...videoControl, playing: !videoControl.playing });
  }, [videoControl]);

  return (
    <ImageWrapper
      ratio={isDesktop ? "5 / 2" : "16 / 9"}
      radius={radius ? radius : "2.5rem"}
    >
      {!videoControl.playing && (
        <VideoPlayButton
          src={image.videoPlayButton.default}
          alt="play"
          onClick={playPauseHandler}
        />
      )}

      {children && children}

      <Video src={data} muted playing status={videoControl} />
    </ImageWrapper>
  );
};

export default CommonVideo;
