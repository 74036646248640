import React from "react";

import { image } from "src/theme";
import {
  FeaturesContainer,
  RoomFeaturesExtraCharge,
  FeaturesIn,
  FeaturesItem,
  FeaturesItemIn,
} from "@pages/stay/StayStyling";
import { ExtraChargeText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { DangerouslyHtml } from "@components/item";

const RoomFeatures = ({ data }) => {
  return (
    <FeaturesContainer>
      <FeaturesIn>
        {data?.map((feature, idx) => {
          return (
            <FeaturesItem key={`${feature.title}+${idx}`}>
              <img
                src={image.checkIcon.default}
                alt="check-icon"
                width={22}
                height={22}
                loading="lazy"
              />

              <FeaturesItemIn>
                <span>
                  <DangerouslyHtml value={feature.title} />

                  <RoomFeaturesExtraCharge>
                    {feature.extra_charge && <ExtraChargeText />}
                  </RoomFeaturesExtraCharge>
                </span>
              </FeaturesItemIn>
            </FeaturesItem>
          );
        })}
      </FeaturesIn>
    </FeaturesContainer>
  );
};

export default RoomFeatures;
