import React from "react";

const BreadcrumbArrow = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.50134 12.2858L7.68251 7.16677C7.78209 7.08426 7.86202 6.98191 7.91682 6.86673C7.97163 6.75156 8 6.62629 8 6.49952C8 6.37275 7.97163 6.24748 7.91682 6.1323C7.86202 6.01713 7.78209 5.91477 7.68251 5.83226L1.50134 0.713214C0.911336 0.224675 0 0.629716 0 1.38047V11.62C0 12.3708 0.911336 12.7758 1.50134 12.2858Z"
        fill="#878787"
      />
    </svg>
  );
};

export default BreadcrumbArrow;
