import React, { useState } from "react";

import { PrimaryButton } from "@components/buttons";
import { SliderFive } from "@components/sliders";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import {
  SplashBayText,
  ViewAllText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetHomeSplashBaySection } from "@utils/api-requests/HomeRequests";

import { HomeButtonWrapper } from "./HomeStyling";

const HomeSplashBay = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const { data: homeSplashBaySection } = useGetHomeSplashBaySection();

  return (
    <CommonContainer>
      <CommonContentButtonWrapper>
        <SliderFive
          data={homeSplashBaySection}
          nameKey="field_splash_bay_name"
          supTitle={<SplashBayText />}
          subTitle={<SplashBayText />}
          slideIndex={slideIndex}
          setSlideIndex={setSlideIndex}
          desc={homeSplashBaySection?.[slideIndex]?.field_short_description}
          descKey="field_short_description"
          splashbay
        />

        <CommonContainer>
          <HomeButtonWrapper>
            <PrimaryButton linkTo="/splash-bay">
              <ViewAllText />
            </PrimaryButton>
          </HomeButtonWrapper>
        </CommonContainer>
      </CommonContentButtonWrapper>
    </CommonContainer>
  );
};

export default HomeSplashBay;
