import React, { createRef, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ja from "react-phone-number-input/locale/ja";
import en from "react-phone-number-input/locale/en";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useIntl } from "react-intl";

import PhoneNumberInput from "@components/inputs/PhoneNumberInput";
import { TextInputWrapper } from "@components/inputs/InputsStyling";
import { postKeepInTouchSubmission } from "@utils/api-requests/mailchimpRequests";
import { useMutation, useQueryClient } from "react-query";
import { yupKeepInTouch } from "@components/inputs/yupValidation";
import { DateInput, SelectInput, TextInput } from "@components/inputs";
import { PrimaryButton } from "@components/buttons";
import {
  JoinOurNewsletterText,
  PrivacyPolicyText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  DateofBirthLabelText,
  EmailExistErrorText,
  EmailLabelText,
  FirstNameLabelText,
  LastNameLabelText,
  PhoneLabelText,
  PrivacyPolicyLabelText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { useIpInformation } from "@utils/api-requests/GlobalRequests";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";

import {
  KeepInTouchFormCheckBoxContainer,
  KeepInTouchFormCheckBoxWrapper,
  KeepInTouchFormFlex,
  KeepInTouchFormRecaptcha,
  KeepInTouchFormTermsOfUse,
} from "../ModalStyling";
import { PrivacyPolicyModal } from ".";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

const KeepInTouchForm = ({ setComplete }) => {
  const intl = useIntl();

  const recaptchaRef = createRef();

  const { ipInformation } = useIpInformation();

  const [defaultCountryCode, setDefaultCountryCode] = useState("");

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [emailExist, setEmailExist] = useState("");
  const [dateVal, setDateVal] = useState("");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(postKeepInTouchSubmission, {
    onSuccess: (data) => {
      if (data.status === 200) {
        setComplete(true);
        setEmailExist("");
      } else {
        if (data?.response?.status === 400) {
          setEmailExist(<EmailExistErrorText />);

          return;
        }

        setEmailExist(data.response?.data?.title);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const openTerms = useCallback(() => {
    setPrivacyPolicyOpen(true);
  }, []);

  const preferredLanguageArr = [
    { id: 1, text: "English", value: "English", code: "en" },
    { id: 2, text: "한국어", value: "Korean", code: "ko" },
    { id: 3, text: "日本語", value: "Japanese", code: "ja" },
    {
      id: 4,
      text: "繁體中文",
      value: "Chinese-Simplified",
      code: "zh-hans",
    },
    {
      id: 5,
      text: "繁體中文",
      value: "Chinese-Traditional",
      code: "zh-hant",
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupKeepInTouch),
    mode: "onSubmit",
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      agreeToTerms: false,
      recaptcha: false,
      preferredLanguage: preferredLanguageArr.find(
        (el) => el.code === localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
      ).value,
      legalAge: false,
    },
  });

  const captchaChange = useCallback(
    (value) => {
      if (value !== null || value !== "") {
        setValue("recaptcha", true, { shouldValidate: true });

        return;
      }

      setValue("recaptcha", false, { shouldValidate: true });
    },
    [setValue]
  );

  useEffect(() => {
    if (ipInformation) {
      setDefaultCountryCode(ipInformation?.countryCode);
    }
  }, [ipInformation]);

  const keepInTouchFormSubmit = useCallback(
    (data) => {
      const browserLang = navigator.language;
      const country = browserLang.split("-")[1];

      try {
        mutate({
          email_address: data.emailAddress,
          status: "subscribed",
          merge_fields: {
            FNAME: data.firstName,
            LNAME: data.lastName,
            PHONE: data.phoneNumber,
            DOB: data.dateOfBirth.toISOString().split("T")[0],
            PREFERREDL: data.preferredLanguage,
            COUNTRY: country,
            LEGALAGE: data.legalAge ? "Yes" : "",
            PRIVACYPOL: data.agreeToTerms ? "Agree" : "",
          },
        });
      } catch (error) {
        console.error("Mutation exception:", error);
      }
    },
    [mutate]
  );

  return (
    <div>
      {emailExist !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "1.6rem" }}>
          {emailExist}
        </Alert>
      )}

      <PrivacyPolicyModal
        openModal={privacyPolicyOpen}
        setOpenModal={setPrivacyPolicyOpen}
      />

      <form method="post" onSubmit={handleSubmit(keepInTouchFormSubmit)}>
        <KeepInTouchFormFlex>
          <TextInput
            control={control}
            name="firstName"
            label={<FirstNameLabelText />}
            placeholder={intl.formatMessage({
              id: "lang-enter-first-name",
            })}
          />

          <TextInput
            control={control}
            name="lastName"
            label={<LastNameLabelText />}
            placeholder={intl.formatMessage({
              id: "lang-enter-first-name",
            })}
          />
        </KeepInTouchFormFlex>

        <div>
          <TextInput
            control={control}
            name="emailAddress"
            label={<EmailLabelText />}
            placeholder={intl.formatMessage({
              id: "lang-enter-email-address",
            })}
          />
        </div>

        {ipInformation && (
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => {
              return (
                <TextInputWrapper>
                  <PhoneInput
                    {...field}
                    placeholder={intl.formatMessage({
                      id: "lang-enter-phone-number",
                    })}
                    inputComponent={PhoneNumberInput}
                    onChange={field.onChange}
                    name="phoneNumber"
                    label={<PhoneLabelText />}
                    error={!!errors["phoneNumber"] || !!errors["phoneCode"]}
                    helperText={
                      errors["phoneNumber"]?.message ||
                      errors["phoneCode"]?.message
                    }
                    defaultCountry={defaultCountryCode}
                    labels={isJapanese ? ja : en}
                    control={control}
                  />
                </TextInputWrapper>
              );
            }}
          />
        )}

        <DateInput
          control={control}
          name="dateOfBirth"
          label={<DateofBirthLabelText />}
          dateVal={dateVal}
          setDateVal={setDateVal}
          errors={errors}
        />

        <SelectInput
          control={control}
          errors={errors}
          name="preferredLanguage"
          arr={preferredLanguageArr}
          label={intl.formatMessage({
            id: "lang-preferred-language",
          })}
          placeholder={intl.formatMessage({
            id: "lang-preferred-language",
          })}
        />

        <KeepInTouchFormCheckBoxContainer>
          <KeepInTouchFormCheckBoxWrapper>
            <Controller
              control={control}
              name="legalAge"
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox onChange={field.onChange} name="legalAge" />
                    }
                    label={intl.formatMessage({
                      id: "lang-legal-age",
                    })}
                  />
                );
              }}
            />
          </KeepInTouchFormCheckBoxWrapper>

          <KeepInTouchFormCheckBoxWrapper>
            <div>
              <Controller
                control={control}
                name="agreeToTerms"
                render={({ field }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={field.onChange}
                          name="agreeToTerms"
                        />
                      }
                      label={<PrivacyPolicyLabelText />}
                    />
                  );
                }}
              />

              <KeepInTouchFormTermsOfUse type="button" onClick={openTerms}>
                <PrivacyPolicyText />
              </KeepInTouchFormTermsOfUse>
            </div>
          </KeepInTouchFormCheckBoxWrapper>

          <FormHelperText error>
            {errors.legalAge ? errors.legalAge.message : " "}
          </FormHelperText>

          <FormHelperText error>
            {errors.agreeToTerms ? errors.agreeToTerms.message : " "}
          </FormHelperText>
        </KeepInTouchFormCheckBoxContainer>

        <KeepInTouchFormRecaptcha>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_SERVER_RECAPTCHA}
            onChange={captchaChange}
          />

          <FormHelperText error>
            {errors.recaptcha && errors.recaptcha?.message}
          </FormHelperText>
        </KeepInTouchFormRecaptcha>

        <PrimaryButton buttonType="submit" disable={isLoading}>
          {isLoading ? <SubmitLoadingText /> : <JoinOurNewsletterText />}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default KeepInTouchForm;
