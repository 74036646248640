import React from "react";
import { useIntl } from "react-intl";

import { CommonTitleOne } from "@assets/styles/CommonStyles";
import {
  BoxInformationOne,
  DangerouslyHtml,
  NotificationToggle,
} from "@components/item";
import { TranslateDayMonth } from "@utils/modify-data/modifyData";

import {
  DetailPageDescriptionContainer,
  DetailPageDescriptionDesc,
  DetailPageDescriptionfeatureTags,
} from "./SubPageStyling";

const DetailPageDescription = ({ data, eventInfo, children, noShowBox }) => {
  const intl = useIntl();

  const information = [
    {
      id: 1,
      header: intl.formatMessage({
        id: "lang-applicable-end-date",
      }),
      data: TranslateDayMonth(data?.field_applicable_end_date),
    },
    {
      id: 2,
      header: intl.formatMessage({
        id: "lang-bookable-end-date",
      }),
      data: TranslateDayMonth(data?.field_bookable_end_date),
    },
    {
      id: 3,
      disabled: !eventInfo,
      header: intl.formatMessage({
        id: "lang-event-info",
      }),
      data: data?.field_event_details ? data?.field_event_details : "-",
    },
  ];

  return (
    <DetailPageDescriptionContainer>
      <BoxInformationOne
        noShowBox={noShowBox}
        information={information}
        subTitle={data?.field_event_type_name}
      >
        <NotificationToggle data={data} />

        <div>
          <CommonTitleOne>{data?.field_event_name}</CommonTitleOne>

          <DetailPageDescriptionfeatureTags>
            {data?.field_feature_tags?.map((item) => {
              return <p key={item}>{item}</p>;
            })}
          </DetailPageDescriptionfeatureTags>

          <DetailPageDescriptionDesc>
            <DangerouslyHtml value={data?.field_full_description} />
          </DetailPageDescriptionDesc>

          {children}
        </div>
      </BoxInformationOne>


    </DetailPageDescriptionContainer>
  );
};

export default DetailPageDescription;
