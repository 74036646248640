import React from "react";

import { ListOneDesc, ListTwoContainer, ListTwoItem } from "./LayoutStyling";
import { DangerouslyHtml } from "@components/item";

const ListTwo = ({ data }) => {
  return (
    data?.length > 0 && (
      <ListTwoContainer>
        {data?.map((item) => {
          return (
            <ListTwoItem key={item.name}>
              <img
                width={300}
                src={`${process.env.REACT_APP_SERVER_URL}${item?.thumbnail.src}`}
                alt={item.thumbnail?.alt}
                loading="lazy"
              />

              <header>
                <DangerouslyHtml value={item.name} />
              </header>

              {item.description && (
                <ListOneDesc>
                  <DangerouslyHtml value={item.description} />
                </ListOneDesc>
              )}
            </ListTwoItem>
          );
        })}
      </ListTwoContainer>
    )
  );
};

export default ListTwo;
