import React, { useCallback, useEffect } from "react";

import { useIntl } from "react-intl";
import { IsDesktop } from "@utils/media-query/mediaQuery";
import {
  MomentumModalButton,
  MomentumModalDesc,
  MomentumModalTitle,
  RoomReservationModalContainer,
  RoomReservationModalRight,
} from "@components/modal/ModalStyling";

import { ErrorManage } from "@screens/index";
import { DangerouslyHtml } from "@components/item";
import { SecondaryButton } from "@components/buttons";
import { CloseText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { CommonModal } from "..";
import { ChangeMarketingForm } from "@components/modal/modal-list/index";
import { useGetContent } from "@utils/api-requests/GlobalRequests";

const ChangeMarketingModal = ({
  openModal,
  setOpenModal,
  complete,
  setComplete,
  userData,
  hideClose,
}) => {
  const intl = useIntl();
  const {
    data: marketingModal,
    isError,
    error,
  } = useGetContent("marketingModal");

  const handleClose = useCallback(() => {
    setOpenModal(false);

    setComplete(false);
  }, [setComplete, setOpenModal]);

  const imageStyle = {
    position: "sticky",
    top: 0,
    backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}${marketingModal?.field_image[0]?.src}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignSelf: "stretch",
  };

  useEffect(() => {
    if (!userData?.mailoptin) {
      setOpenModal(true);
    }
  }, [complete]);

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        modalName={intl.formatMessage({
          id: "lang-mail-opt-label",
        })}
        openModal={openModal}
        setOpenModal={setOpenModal}
        hideClose={hideClose}
        noPadding
        noHeader
      >
        <RoomReservationModalContainer>

          <RoomReservationModalRight>
            <MomentumModalTitle>
              {complete
                ? marketingModal?.field_thank_you_title
                : marketingModal?.field_modal_title}
            </MomentumModalTitle>

            <MomentumModalDesc>
              <DangerouslyHtml
                value={
                  complete
                    ? marketingModal?.field_thank_you_description
                    : ""
                }
              />
            </MomentumModalDesc>

            {complete ? (

              <>
                <SecondaryButton
                  buttonEvent={handleClose}
                  customstyle={MomentumModalButton}
                  buttonColor="white"
                >
                  <CloseText />
                </SecondaryButton>
              </>
            ) : (
              <>
                <ChangeMarketingForm
                  complete={complete}
                  setComplete={setComplete}
                  modalContent={marketingModal}
                  userData={userData}
                />
              </>
            )}
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default ChangeMarketingModal;
