import React, { memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingScreen } from ".";
import LoadingSpinner from "./LoadingSpinner";

export const ErrorManage = ({
  isLoading,
  isFetching,
  error,
  isError,
  children,
  noLoading,
}) => {
  const navigate = useNavigate();

  const result = useMemo(() => {
    if (isFetching) {
      return <LoadingSpinner />;
    }

    if (isLoading) {
      if (noLoading) {
        return null;
      } else {
        return <LoadingScreen />;
      }
    }

    if (error?.status === 500 || error?.response?.status === 500) {
      navigate("/not-found");

      return;
    }

    if (isError) {
      console.log(`Error Occured:
        ${error?.message} : ${error?.response?.statusText}`);

      navigate("/maintenance");

      return;
    }

    if (children !== undefined && children) return children;

    return "No Data";
  }, [
    error?.status,
    isFetching,
    isLoading,
    error?.response?.status,
    error?.response?.statusText,
    error?.message,
    isError,
    children,
    noLoading,
    navigate,
  ]);

  return <>{result}</>;
};

export default memo(ErrorManage);
