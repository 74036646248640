import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { ErrorManage } from "@screens/index";
import {
  useGetMeetSightseeingDetail,
  useGetMeetSightseeingAliasGroup,
} from "@utils/api-requests/MeetRequests";
import { CustomMetatag } from "@utils/metatags";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";

import MeetSightseeingRecommendation from "./MeetSightseeingRecommendation";
import { MeetSightseeingActivities } from ".";

const MeetSightseeingDetail = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const alias = useParams().sightseeingAlias;
  const intl = useIntl();

  const {
    data: meetSightseeingDetail,
    isFetching,
    isError,
    error,
  } = useGetMeetSightseeingDetail(alias);

  const { data: meetSightseeingUuidGroup } = useGetMeetSightseeingAliasGroup();

  const roomDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-meet",
        }),
        href: "/meet",
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-sightseeing",
        }),
        href: "/meet",
        tab: "meet-sightseeing",
      },
      {
        id: 4,
        current: true,
        name: meetSightseeingDetail?.field_sightseeing_title,
      },
    ];

    return roomDetails;
  }, [intl, meetSightseeingDetail?.field_sightseeing_title]);

  const sectionArray = [
    {
      id: 1,
      disabled:
        meetSightseeingDetail?.field_near_the_resort_informatio?.length === 0,
      titleDirection: "left",
      noMargin: true,
      contents: <MeetSightseeingActivities data={meetSightseeingDetail} />,
    },
    {
      id: 2,
      titleDirection: "left",
      noMargin: true,
      contents: (
        <MeetSightseeingRecommendation
          data={meetSightseeingDetail?.field_attraction_areas}
        />
      ),
    },
  ];

  useEffect(() => {
    if (meetSightseeingUuidGroup) {
      setCurrentIndex(
        meetSightseeingUuidGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [meetSightseeingUuidGroup, alias]);

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={meetSightseeingDetail} />

      <DetailPageHero data={meetSightseeingDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={meetSightseeingDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={roomDetailBC}
            pageTitle={meetSightseeingDetail?.field_sightseeing_title}
            value={meetSightseeingDetail?.field_full_description}
            prevUUid={meetSightseeingUuidGroup?.[currentIndex - 1]}
            nextUUid={meetSightseeingUuidGroup?.[currentIndex + 1]}
            url={`/meet/sightseeing`}
          />
        </>

        <DetailPageContainer
          data={FilterDetailPageData(
            meetSightseeingDetail?.field_sightseeing_category
          )}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={meetSightseeingDetail} />

        <MomentumLayout data={meetSightseeingDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MeetSightseeingDetail;
