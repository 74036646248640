import React from "react";

import {
  DetailPageContainerInformation,
  DetailPageDesc,
  DetailPageTitle,
} from "@components/sub-page/SubPageStyling";
import { TiersBenefits } from "@pages/momentum/detail";
import { ErrorManage } from "@screens/index";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { useGetMomentumTiersBenefits } from "@utils/api-requests/MomentumRequests";

import { CommonModal } from "..";

const AllMomentumBenefitModal = ({ openModal, setOpenModal }) => {
  const {
    data: momentumTiersBenefits,
    isError,
    error,
  } = useGetMomentumTiersBenefits();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        name="Arena Inquiry"
        description="Arena Inquiry"
        openModal={openModal}
        setOpenModal={setOpenModal}
        purpleClose
      >
        <DetailPageContainerInformation>
          <DetailPageTitle textAlign="left">
            {FilterDetailPageData("Tiers and Benefits")?.field_title_1}
          </DetailPageTitle>

          <DetailPageDesc textAlign="left">
            {FilterDetailPageData("Tiers and Benefits")?.field_description_1}
          </DetailPageDesc>
        </DetailPageContainerInformation>

        <TiersBenefits
          data={momentumTiersBenefits?.field_tier_information}
          noPadding
        />
      </CommonModal>
    </ErrorManage>
  );
};

export default AllMomentumBenefitModal;
