import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { SliderSix } from "@components/sliders";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { RoomReservationModal } from "@components/modal/modal-list";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import {
  useGetRoomDetail,
  useGetRoomAliasGroup,
} from "@utils/api-requests/StayRequests";
import { checkIncludes, textFormat } from "@utils/modify-data/modifyData";
import { BookYourRoomText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { BenefitsForGuests, RoomFeatures, RoomPreview } from ".";

const RoomDetail = ({ pageInfo }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const intl = useIntl();

  const alias = useParams().roomAlias;
  const categoryId = useParams().roomCategoryId;

  const { data: roomUuidGroup } = useGetRoomAliasGroup(categoryId);

  const {
    data: roomDetail,
    isFetching,
    isError,
    error,
    isFetched,
  } = useGetRoomDetail(alias);

  const roomDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-stay",
        }),
        href: "/stay",
      },
      {
        id: 3,
        name: textFormat(roomDetail?.parent_target_id),
        href: "/stay",
        tab: roomDetail?.parent_target_id_en,
      },
      { id: 4, current: true, name: roomDetail?.field_room_name },
    ];

    if (roomDetail?.parent_target_id_en === "") {
      return roomDetails.filter(
        (el) => el.name !== roomDetail?.parent_target_id_en
      );
    }

    return roomDetails;
  }, [
    intl,
    roomDetail?.field_room_name,
    roomDetail?.parent_target_id,
    roomDetail?.parent_target_id_en,
  ]);

  const sectionArray = [
    {
      id: 1,
      disabled:
        roomDetail?.field_multiple_images === "" ||
        !roomDetail?.field_multiple_images,
      contents: <SliderSix imageArr={roomDetail?.field_multiple_images} />,
    },
    {
      id: 2,
      title: roomDetail?.parent_target_id,
      contents: <RoomPreview data={roomDetail?.room_sub_types} uuid={alias} />,
    },
    {
      id: 3,
      contents: <RoomFeatures data={roomDetail?.field_features_list} />,
    },
    {
      id: 4,
      contents: <BenefitsForGuests data={roomDetail?.field_benefits_list} />,
    },
  ];

  useEffect(() => {
    if (
      isFetched &&
      roomDetail &&
      !checkIncludes(roomDetail?.parent_target_id_en, categoryId)
    ) {
      navigate("/not-found");
    }
  }, [categoryId, isFetched, navigate, roomDetail]);

  useEffect(() => {
    if (roomUuidGroup) {
      setCurrentIndex(roomUuidGroup?.findIndex((item) => item === "/" + alias));
    }
  }, [roomUuidGroup, alias]);

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={roomDetail} />

      <RoomReservationModal openModal={openModal} setOpenModal={setOpenModal} />

      <DetailPageHero data={roomDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={roomDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={roomDetailBC}
            pageTitle={roomDetail?.field_room_name}
            value={roomDetail?.field_full_description}
            prevUUid={roomUuidGroup?.[currentIndex - 1]}
            nextUUid={roomUuidGroup?.[currentIndex + 1]}
            url={`/stay/room/${categoryId}`}
            buttonName={<BookYourRoomText />}
            buttonEvent={() => setOpenModal(true)}
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={roomDetail} />

        <MomentumLayout data={roomDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default RoomDetail;
