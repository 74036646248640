import React from "react";

import { image } from "src/theme";
import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { ErrorManage } from "@screens/index";
import { useGetEatDrinkReservationModal } from "@utils/api-requests/GlobalRequests";

import { CommonModal } from "..";
import {
  ModalBorderButton,
  RoomReservationModalContainer,
  RoomReservationModalRight,
} from "../ModalStyling";

const EatDrinkReservationModal = ({ openModal, setOpenModal }) => {
  const {
    data: eatDrinkReservationModal,
    isError,
    error,
  } = useGetEatDrinkReservationModal();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        noName
        name="Arena Inquiry"
        description="Arena Inquiry"
        openModal={openModal}
        setOpenModal={setOpenModal}
        noPadding
        noHeader
      >
        <RoomReservationModalContainer>
          <ImageWrapper>
            <Image
              src={eatDrinkReservationModal?.field_thumbnail?.[0].src}
              alt={eatDrinkReservationModal?.field_thumbnail?.[0].alt}
            />
          </ImageWrapper>

          <RoomReservationModalRight>
            <CommonTitleOne>
              <DangerouslyHtml
                value={eatDrinkReservationModal?.field_modal_title}
              />
            </CommonTitleOne>

            <CommonDescTwo>
              <DangerouslyHtml
                value={eatDrinkReservationModal?.field_modal_description}
              />
            </CommonDescTwo>

            <ModalBorderButton
              href={`mailto:${eatDrinkReservationModal?.field_email_address}`}
            >
              {eatDrinkReservationModal?.field_button_name}

              <img src={image.linkArrowPurple.default} alt="arrow icon" />
            </ModalBorderButton>
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default EatDrinkReservationModal;
