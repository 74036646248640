import React from "react";

import {
  CommonDescFive,
  CommonDescOne,
  CommonDescThree,
  CommonTitleThree,
} from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeImageSource,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";

const MeetSightseeingRecommendation = ({ data }) => {
  return (
    data?.length > 0 && (
      <BoxListThreeContainer>
        {data?.map((feature, idx) => {
          return (
            <BoxListThreeWrapper key={feature.name} reverse={idx % 2 > 0}>
              <div style={{ width: "100%" }}>
                <ImageWrapper radius="2.5rem" ratio="3 / 2">
                  <Image
                    src={feature.thumbnail?.src}
                    alt={feature.thumbnail?.alt}
                  />
                </ImageWrapper>

                <BoxListThreeImageSource left={idx % 2}>
                  <CommonDescFive>
                    <DangerouslyHtml value={feature.field_image_source} />
                  </CommonDescFive>
                </BoxListThreeImageSource>
              </div>

              <BoxListThreeInformation>
                <CommonTitleThree>
                  <DangerouslyHtml value={feature.name} />
                </CommonTitleThree>

                <div>
                  <CommonDescThree>
                    <DangerouslyHtml value={feature.subtitle} />
                  </CommonDescThree>

                  <br />

                  <CommonDescOne>
                    <DangerouslyHtml value={feature.description} />
                  </CommonDescOne>
                </div>
              </BoxListThreeInformation>
            </BoxListThreeWrapper>
          );
        })}
      </BoxListThreeContainer>
    )
  );
};

export default MeetSightseeingRecommendation;
