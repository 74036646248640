import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MonthlyCalendar } from "@components/calendar";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { ErrorManage } from "@screens/index";
import {
  useGetEntertainmentEventList,
  useGetEventListAliasGroup,
} from "@utils/api-requests/EntertainmentRequests";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { CustomMetatag } from "@utils/metatags";

import EntertainmentEventItems from "./EntertainmentEventItems";

const EntertainmentEventList = () => {
  const intl = useIntl();

  const { eventsAlias } = useParams();

  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: eventListAliasGroup } = useGetEventListAliasGroup();

  const {
    data: entertainmentEventList,
    isLoading,
    isError,
    error,
  } = useGetEntertainmentEventList(eventsAlias);

  const restaurantDetailBC = useMemo(() => {
    return [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-entertainment",
        }),
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-events",
        }),
      },
      {
        id: 4,
        current: true,
        name: entertainmentEventList?.field_event_category_name,
      },
    ];
  }, [entertainmentEventList?.field_event_category_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <EntertainmentEventItems type={eventsAlias} />,
    },
    {
      id: 2,
      disabled: entertainmentEventList?.field_event_calender.length <= 0,
      contents: (
        <MonthlyCalendar data={entertainmentEventList?.field_event_calender} />
      ),
    },
  ];

  useEffect(() => {
    if (eventListAliasGroup) {
      setCurrentIndex(0);
      // setCurrentIndex(
      //   eventListAliasGroup?.findIndex((el) => {
      //     checkIncludes(el, eventsAlias);
      //   })
      // );
    }
  }, [eventListAliasGroup, eventsAlias]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={entertainmentEventList} />

      <DetailPageHero data={entertainmentEventList} />

      <CommonContainer>
        <>
          <NotificationToggle data={entertainmentEventList} />

          <MuiBreadcrumbs
            breadcrumbArr={restaurantDetailBC}
            pageTitle={entertainmentEventList?.field_event_category_name}
            value={entertainmentEventList?.field_description}
            prevUUid={eventListAliasGroup?.[currentIndex - 1]}
            nextUUid={eventListAliasGroup?.[currentIndex + 1]}
            url="/entertainment/event"
          />
        </>

        <DetailPageContainer
          data={FilterDetailPageData(
            entertainmentEventList?.field_event_category_name
          )}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={entertainmentEventList} />

        <MomentumLayout data={entertainmentEventList} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default EntertainmentEventList;
