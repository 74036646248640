import React from "react";

import { CommonTitleOne, CommonTitleTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";

import { FrameFourContainer, FrameFourRight } from "./FrameStyling";
import { DangerouslyHtml } from "@components/item";

const FrameFour = ({
  reverse,
  url,
  alt,
  ratio = "3 / 2",
  mainTitle,
  title,
  children,
  clickEvent,
}) => {
  return (
    <FrameFourContainer reverse={reverse}>
      <div
        style={{ width: "100%" }}
        onClick={() => {
          if (clickEvent) {
            clickEvent();
          }
        }}
      >
        <ImageWrapper
          ratio={ratio}
          radius="2.5rem"
          custom={{
            cursor: clickEvent ? "pointer" : "default",
          }}
        >
          <Image src={url} alt={alt} />
        </ImageWrapper>
      </div>

      <FrameFourRight>
        {mainTitle && (
          <CommonTitleOne>
            <DangerouslyHtml value={mainTitle} />
          </CommonTitleOne>
        )}

        {title && (
          <CommonTitleTwo>
            <DangerouslyHtml value={title} />
          </CommonTitleTwo>
        )}

        {children}
      </FrameFourRight>
    </FrameFourContainer>
  );
};

export default FrameFour;
