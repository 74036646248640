import React from "react";

import { BoxListFour } from "@components/box-list";
import { useGetMomentumLifestyleEventsItems } from "@utils/api-requests/MomentumRequests";

const MomentumLifestyleItemList = () => {
  const { data: momentumLifestyleEventsItems } =
    useGetMomentumLifestyleEventsItems();

  return (
    <BoxListFour
      data={momentumLifestyleEventsItems}
      imageKey="field_thumbnail"
      nameKey="field_event_name"
      dateKey="field_applicable_end_date"
      descKey="field_short_description"
      linkTo="/momentum/lifestyle-events"
    />
  );
};

export default MomentumLifestyleItemList;
