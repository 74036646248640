import {
  AddressRequiredText,
  CityRequiredText,
  CountryRequiredText,
  DateRequiredText,
  DateofAnniversaryInvalidFormatText,
  DateofBirthInvalidFormatText,
  DateofBirthNineteenInvalidFormatText,
  DateofBirthRequriedText,
  EmailInvalidFormatText,
  EmailRequiredText,
  FirstNameRequiredText,
  LastNameRequiredText,
  LegalAgeRequiredText,
  OldPasswordRequiredText,
  PasswordMaxLengthText,
  PasswordMinLengthText,
  PasswordNoMatchText,
  PasswordRequiredText,
  PhoneCodeRequiredText,
  PhoneInvalidFormatText,
  PhoneRequiredText,
  PostalCodeRequiredText,
  PreferredLanguageRequiredText,
  PrivacyPolicyRequiredText,
  RecaptchaRequriedText,
  StateRequiredText,
  TypeRequiredText,
  VerificationCodeInvalidFormat,
  VerificationCodeRequiredText,
  SelectOneText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

export const emailInvalidFormat = <EmailInvalidFormatText />;
export const emailRequired = <EmailRequiredText />;
export const firstNameRequired = <FirstNameRequiredText />;
export const lastNameRequired = <LastNameRequiredText />;
export const passwordMinLengthText = <PasswordMinLengthText />;
export const passwordMaxLengthText = <PasswordMaxLengthText />;
export const passwordRequired = <PasswordRequiredText />;
export const oldPasswordRequired = <OldPasswordRequiredText />;
export const passwordNoMatch = <PasswordNoMatchText />;

export const phoneCodeRequired = <PhoneCodeRequiredText />;
export const phoneRequired = <PhoneRequiredText />;
export const phoneInvalidFormat = <PhoneInvalidFormatText />;
export const recaptchaRequired = <RecaptchaRequriedText />;
export const dateofBirthRequired = <DateofBirthRequriedText />;
export const dateofBirthInvalidFormat = <DateofBirthInvalidFormatText />;
export const dateofBirthNineteenInvalidFormat = (
  <DateofBirthNineteenInvalidFormatText />
);
export const dateofAnniversaryInvalidFormat = (
  <DateofAnniversaryInvalidFormatText />
);
export const verificationCodeRequired = <VerificationCodeRequiredText />;
export const verificationCodeInvalidFormat = <VerificationCodeInvalidFormat />;
export const typeRequired = <TypeRequiredText />;
export const addressRequired = <AddressRequiredText />;
export const countryRequired = <CountryRequiredText />;
export const stateRequired = <StateRequiredText />;
export const cityRequired = <CityRequiredText />;
export const postalCodeRequired = <PostalCodeRequiredText />;

export const privacyPolicyRequired = <PrivacyPolicyRequiredText />;
export const marketingOptRequired = <SelectOneText />;
export const marketingUseRequired = <SelectOneText />;
export const dateRequired = <DateRequiredText />;
export const legalAgeRequired = <LegalAgeRequiredText />;
export const preferredLanguageRequired = <PreferredLanguageRequiredText />;
