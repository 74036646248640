import React from "react";

import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { InformationHandlingInformation } from "@pages/about-us/AboutUsStyling";

const InformationHandling = ({ data }) => {
  return (
    <div>
      <InformationHandlingInformation>
        <ImageWrapper width="30rem">
          <Image
            src={data?.field_logo[0]?.src}
            alt={data?.field_logo[0]?.alt}
          />
        </ImageWrapper>

        <CommonTitleOne>
          <DangerouslyHtml value={data?.field_page_title} />
        </CommonTitleOne>

        <CommonDescOne>
          <DangerouslyHtml value={data?.field_page_full_description} />
        </CommonDescOne>
      </InformationHandlingInformation>
    </div>
  );
};

export default InformationHandling;
