import {
  CommonDescOne,
  CommonTitleOne,
  infoZIndex,
} from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

export const SignInContainer = styled.div(() => ({
  position: "relative",
}));

export const SignInWrapper = styled.div(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column-reverse",
  gap: "3.2rem 6.4rem",
  alignItems: "center",
  zIndex: infoZIndex,
  maxWidth: "153.6rem",
  padding: "24rem 2.4rem",
  width: "100%",
  left: "50%",
  transform: "translate(-50%)",

  [mq("desktop")]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const SignInInformation = styled.div(({ theme }) => ({
  flex: 1,
  color: theme.color.white,
  maxWidth: "55rem",
}));

export const SignInTitle = styled(CommonTitleOne)(() => ({
  margin: "0.8rem 0 3.2rem",
  fontSize: "3.2rem",
}));

export const SignInDesc = styled(CommonDescOne)(() => ({
  marginBottom: "3.2rem",
}));

export const SignInFormWrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "4rem 1.6rem",
  gap: "3rem 0",
  placeItems: "center",
  background: theme.color.white,
  borderRadius: "2.5rem",
  width: "100%",
  maxWidth: "55rem",

  "&>div": {
    margin: 0,
  },

  [mq("desktop")]: {
    padding: "4rem 6.4rem",
  },
}));

export const SignInForm = styled.form(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "3rem 0",
  width: "100%",
  maxWidth: "42rem",
}));

export const SignInAccount = styled(CommonDescOne)(({ theme }) => ({
  color: theme.color.primary_grey,
  fontSize: "1.8rem",
}));

export const FindMyId = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  fontSize: "1.4rem",
  lineHeight: "2.4rem",
  color: theme.color.primary_grey,
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.Black,
}));

export const SignInPasswordIcon = styled.img(() => ({
  cursor: "pointer",
}));
