import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";

import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { SliderOneArrowWrapper, SliderTemplateSlider } from "./SlidersStyling";
import { SwiperArrowButton } from "@components/buttons";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import { IsDefault } from "@utils/media-query/mediaQuery";

const SliderTemplate = ({ children, length }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    loop: isMobile,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <div>
      <IsDefault>
        {(isMobile || length !== 2) && (
          <SliderOneArrowWrapper>
            <SwiperArrowButton ref={navPrevRef} direction="left" />

            <MobileSlideText />

            <SwiperArrowButton ref={navNextRef} direction="right" />
          </SliderOneArrowWrapper>
        )}
      </IsDefault>

      <SliderTemplateSlider
        {...custom}
        onResize={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        length={length}
      >
        {children}
      </SliderTemplateSlider>
    </div>
  );
};

export default SliderTemplate;
