import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";

import {DetailPageContainer, DetailPageHero} from "@components/sub-page";
import {CommonContainer, CommonMobileContainer,} from "@components/layout/ContainerStyle";
import {BookYourInspire, MuiBreadcrumbs, NotificationToggle, OfferRecommendation,} from "@components/item";
import {PageBottomContents} from "@components/layout";
import {MomentumModal} from "@components/modal/modal-list";
import {ErrorManage} from "@screens/index";
import {useGetCasinoTiers} from "@utils/api-requests/CasinoRequests";
import {CustomMetatag} from "@utils/metatags";

import {CasinoTiersBenefits, CasinoTiersStandard} from ".";

const CasinoTiersDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const { data: casinoTiers, isFetching, isError, error } = useGetCasinoTiers();

  const casinoTierDetailBC = useMemo(() => {
    return [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
        href: "/casino",
      },
      {
        id: 3,
        current: true,
        name: casinoTiers?.field_page_name,
      },
    ];
  }, [casinoTiers?.field_page_name, intl]);

  const sectionArray = [
    {
      id: 1,
      titleDirection: "left",
      contents: <CasinoTiersBenefits data={casinoTiers?.field_tiers_} />,
    },
    {
      id: 2,
      contents: (
        <CommonMobileContainer>
          {sessionStorage.getItem("signupAction") !== "false" ? (
            <BookYourInspire
              data={casinoTiers}
              noMargin
              buttonEvent={handleOpenModal}
            />
          ) : (
            <BookYourInspire data={casinoTiers} noMargin />
          )}
        </CommonMobileContainer>
      ),
    },
    { id: 3, contents: <CasinoTiersStandard data={casinoTiers} />, disabled: true },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <CustomMetatag metaData={casinoTiers} />

      <DetailPageHero data={casinoTiers} />

      <CommonContainer>
        <>
          <NotificationToggle data={casinoTiers} />

          {sessionStorage.getItem("signupAction") !== "false" ? (
            <MuiBreadcrumbs
              breadcrumbArr={casinoTierDetailBC}
              pageTitle={casinoTiers?.field_page_name}
              value={casinoTiers?.field_full_description}
            />
          ) : (
            <MuiBreadcrumbs
              breadcrumbArr={casinoTierDetailBC}
              pageTitle={casinoTiers?.field_page_name}
              value={casinoTiers?.field_full_description}
            />
          )}
        </>
      </CommonContainer>

      <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={casinoTiers} />

      </PageBottomContents>
    </ErrorManage>
  );
};

export default CasinoTiersDetail;
