import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeSubTitle,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { DangerouslyHtml } from "@components/item";

const MeetOurChefs = ({ data }) => {
  return (
    <BoxListThreeContainer>
      {data?.map((chef, idx) => {
        return (
          <BoxListThreeWrapper
            key={`${chef.fieldFirstname} ${chef.fieldLasttname}`}
            reverse={idx % 2 > 0}
          >
            <ImageWrapper radius="2.5rem" ratio="3 / 2">
              <Image src={chef?.image?.src} alt={chef?.image?.alt} />
            </ImageWrapper>

            <BoxListThreeInformation>
              <CommonTitleOne>
                <BoxListThreeSubTitle>
                  <DangerouslyHtml value={chef.designation} />
                </BoxListThreeSubTitle>
                {chef.fieldFirstname} {chef.fieldLasttname}
              </CommonTitleOne>

              <CommonDescTwo>
                <DangerouslyHtml value={chef.fieldIntro} />
              </CommonDescTwo>
            </BoxListThreeInformation>
          </BoxListThreeWrapper>
        );
      })}
    </BoxListThreeContainer>
  );
};

export default MeetOurChefs;
