import styled from "@emotion/styled";
import {
  CommonDescTwo,
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleThree,
} from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";
import { Link } from "react-router-dom";

// STYLE: MomentumLifestyle.jsx
export const MomentumLifestyleDesc = styled(CommonDescTwo)(({ theme }) => ({
  color: theme.color.primary_grey,
  textAlign: "center",

  a: {
    color: theme.color.point_primary_color,
    borderBottom: `1px solid ${theme.color.point_primary_color}`,
    fontFamily: theme.fontFamily.primaryFont,
    fontWeight: theme.fontWeight.medium,
  },
}));

// STYLE: MomentumNewMember.jsx
export const MomentumNewMemberContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 7.2rem",
  alignItems: "center",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MomentumNewMemberInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  color: theme.color.primary_grey,
}));

export const MomentumNewMemberTitle = styled(CommonTitleOne)(({ theme }) => ({
  color: theme.color.base_black,
}));

// STYLE: TiersBenefits.jsx
export const TiersBenefitsContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 0",
}));

// STYLE: MembershipTierStandard.jsx
export const MembershipTierStandardContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "2rem 0",

  "&>*": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MembershipTierStandardTitle = styled(CommonTitleThree)(
  ({ theme }) => ({
    fontWeight: theme.fontWeight.Black,
  })
);

export const MembershipTierStandardList = styled.ul(() => ({
  marginTop: "3.2rem",
  paddingLeft: "3rem",

  "&>li": {
    listStyle: "initial",
    marginBottom: "2rem",
  },
}));

// STYLE: AgilisysSignUp.jsx
export const AgilisysSignUpContainer = styled.div(({ url }) => ({
  backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  paddingBlock: "20rem",
}));

export const AgilisysSignUpTitleWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "5rem",
  fontSize: "1.2rem",
  textTransform: "uppercase",
}));

export const AgilisysSignUpWrapper = styled.div(({ theme }) => ({
  background: theme.color.white,
  borderRadius: "1.5rem",
  padding: "4rem 1.6rem",
  width: "fit-content",
  margin: "0 auto",

  [mq("desktop")]: {
    padding: "4rem 6.4rem",
  },
}));

export const AgilisysSignUpIn = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "2.4rem 5rem",
  flexDirection: "column",

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const AgilisysSignUpImage = styled.img(() => ({
  width: "23rem",
  margin: "0 auto",
}));

export const AgilisysSignUpLink = styled(Link)(({ theme }) => ({
  color: theme.color.point_primary_color,
  borderBottom: `1px solid ${theme.color.point_primary_color}`,
}));

export const AgilisysSignUpInformation = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3.2rem 0",
  padding: "3.2rem 1.6rem",
  background: theme.color.blue_light_color,
  borderRadius: "1.5rem",
  marginBottom: "3.2rem",

  [mq("desktop")]: {
    maxWidth: "32rem",
  },
}));

export const AgilisysSignUpList = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "3rem",

  li: {
    listStyle: "initial",
  },
}));

// STYLE: AgilisysSignUpForm.jsx
export const AgilisysSignUpFormContainer = styled.form(() => ({
  [mq("desktop")]: {
    minWidth: "65rem",
    maxWidth: "65rem",
  },
}));

export const AgilisysSignUpFormSection = styled.section(({ theme }) => ({
  marginBottom: "3.2rem",
  borderBottom: `1px dotted ${theme.color.base_black}`,

  "& > div": {
    display: "flex",
    gap: "0 3.2rem",
    flexDirection: "column",

    [mq("tablet")]: {
      flexDirection: "row",
    },

    [mq("desktop")]: {
      flexDirection: "row",
    },
  },
}));

export const AgilisysSignUpFormSectionTitle = styled(CommonTitleFour)(() => ({
  marginBottom: "3.2rem",
  display: "flex",
  alignItems: "flex-end",
  letterSpacing: "0.1em",

  "small": {
    fontSize: "1.6rem",
    opacity: "0.6"
  },

  ".MuiFormControlLabel-label": {
    fontSize: "inherit !important",
    fontFamily: "inherit !important",
    fontWeight: "700"
  }
}));

export const AgilisysSignUpFormButtons = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  gap: "0 2rem",
  marginTop: "3.2rem"
}));
