// location information based on ip - https://ipapi.co/
export const ipapiUrl = "https://pro.ip-api.com/json/";

// third party api urls
export const roomBookingUrl =
  "https://book.rguest.sg/onecart/wbe/room/20044/InspireEntertainmentResort";
export const offerBookingUrl =
  "https://book.rguest.sg/onecart/wbe/calendar/20044/InspireEntertainmentResort/";

// For getting information for all pages
export const pageInfoUrl = "/api/v1/page/default";

// For getting information for all datail pages
export const detailPageInfoUrl = "/api/v1/subpage/detail-info";

// OfferRecommendation.jsx
export const offerRecommendationUrl = "/api/v1/subpage/offers";

// Redirect Urls
export const redirectUrlsUrl = "/api/v1/page/redirection";

// SignIn.jsx
export const signInUrl = "/api/v1/sign-in";

// Home.jsx
export const homeBannerSliderUrl = "/api/v1/home-slider";
export const homeOffersUrl = "/api/v1/home/offers-section";
export const homeTowerUrl = "/api/v1/home/tower-section";
export const homeRoomUrl = "/api/v1/home/room-section";
export const homeShopUrl = "/api/v1/home/shop-section";
export const homefandbUrl = "/api/v1/home/f-b-Section";
export const homeEntertainmentUrl = "/api/v1/home/entertainment-section";
export const homeSplashBayUrl = "/api/v1/home/splash-bay-section";
export const homeMiceUrl = "/api/v1/home/mice-section";
export const homeSignUpUrl = "/api/v1/home/sign-up-section";
export const roomReservationModalUrl = "/api/v1/assets/room-reservation-modal";
export const keepInTouchModalUrl = "/api/v1/assets/keep-in-touch-modal";
export const UnauthorizedEmailModalUrl = "/api/v1/assets/unauthorized-email";
export const arenaInquiryModalUrl = "/api/v1/assets/arena-inquiry-modal";
export const AgilisysSignUpModalUrl = "/api/v1/assets/momentum-sign-up-modal";
export const myProfileUrl = "/api/v1/momentum/my-profile";
export const EatDrinkReservationModalUrl =
  "/api/v1/assets/eat-drink-reservation-modal";
export const homeTopBannerUrl = "/api/v1/assets/home-top-banner";
export const myPageUrl = "/api/v1/page/basic-page";
export const marketingModalUrl = "/api/v1/assets/marketing-modal";

// About Us.jsx
export const aboutUsKnowMoreUrl = "/api/v1/about-us/know-more";
export const aboutUsInspireResortUrl = "/api/v1/about-us/inspire-resort";
export const aboutUsCareersUrl = "/api/v1/about-us/careers";
export const aboutUsMoheganUrl = "/api/v1/about-us/mohegan-resort";
export const aboutUsFloorGuideUrl = "/api/v1/about-us/floor-guide";
export const aboutUsFloorMapCategoryUrl =
  "/api/v1/taxonomies-list?vocab=floor_guide_category";
export const aboutUsPrivacyPolicyUrl = "/api/v1/about-us/privacy-policy";
export const aboutUsTermsConditionsUrl = "/api/v1/about-us/terms-conditions";
export const aboutUsHealthSafetyUrl = "/api/v1/about-us/health-safety";
export const aboutUsSitemapUrl = "/api/v1/about-us/sitemap";
export const aboutUsCustomerCenterPageUrl =
  "/api/v1/about-us/customer-center/page";
export const aboutUsCustomerCenterListUrl =
  "/api/v1/about-us/customer-center/list";
export const aboutUsCustomerCenterCategoryUrl =
  "/api/v1/taxonomies-list?vocab=customer_service_category";
export const aboutUsGettingHereUrl = "/api/v1/about-us/getting-here";
export const aboutUsHowtoGetHereCategoryUrl =
  "/api/v1/taxonomies-list?vocab=how_to_get_here";
export const aboutUsGalleryUrl = "/api/v1/about-us/gallery";
export const aboutUsRecentGalleryUrl = "/api/v1/get-recent-images";
export const aboutUsMediaCenterPageUrl = "/api/v1/about-us/media-center/page";
export const aboutUsMediaCenterListUrl = "/api/v1/about-us/media-center/list";
export const aboutUsGalleryCategoryUrl =
  "/api/v1/taxonomies-list?vocab=about_us_gallery_category";

// Offers
export const offerCategoryListUrl =
  "/api/v1/menu_custom_api?menu_name=offers-category-types";
export const offerListUrl = "/api/v1/subpage/offers";
export const offerTypedUrl = "/api/v1/offers/pages";

// Stay
export const stayOfferRecommendationUrl = "/api/v1/stay/offer-recommendation";
export const restaurantSeonalPickUrl =
  "/api/v1/subpage/section/restaurant-seasonal-list";
export const stayPreviewUrl =
  "/api/v1/menu_custom_api?menu_name=stay-tower-preview";
export const stayRoomsUrl = "/api/v1/subpage/stays?_format=json";
export const stayRoomsDetailUrl = "/api/v1/subpage/stay-details";
export const amenityListUrl = "/api/v1/subpage/hotel-amenities?_format=json";

// Eat & Drink
export const restaurantsCatogoryUrl =
  "/api/v1/menu_custom_api?menu_name=restaurants-category";
export const restaurantListUrl = "/api/v1/subpage/restaurants?_format=json";
export const eatDrinkOfferRecommendationUrl =
  "/api/v1/eat-drink/offer-recommendation";
export const restaurantSignatureUrl =
  "/api/v1/subpage/section/signaturemenu-list";
export const restaurantDetailUrl = "/api/v1/subpage/restaurants-detail";

// SplashBay
export const splashBayDomeUrl = "/api/v1/splash-bay/splash-bay-dome";
export const splashBayOfferRecommendationUrl =
  "/api/v1/splash-bay/offer-recommendation";
export const splashBayServicesUrl = "/api/v1/splash-bay/services";
export const splashBayReserveUrl = "/api/v1/splash-bay/reserve";
export const splashUseGuideCatogoryUrl =
  "/api/v1/menu_custom_api?menu_name=restaurants-category";
export const howToReserveCategoriesUrl =
  "/api/v1/taxonomies-list?vocab=splash_bay_reservation";

// Shop
export const shopOfferRecommendationUrl = "/api/v1/shop/offer-recommendation";
export const shopSubFeaturesUrl = "/api/v1/shop/sub-features";
export const storeCategoriesUrl = "/api/v1/taxonomies-list?vocab=shop_category";
export const storeListUrl = "/api/v1/shop/stores";

// Entertainment
export const entertainmentOfferRecommendationUrl =
  "/api/v1/entertainment/offer-recommendation";
export const entertainmentUrl = "/api/v1/entertainment-event?_format=json";
export const inspireArenaUrl = "/api/v1/entertainment/inspire-arena";
export const entertainmentAttractionVenueUrl =
  "/api/v1/entertainment/attraction_venues";
export const entertainmentPerformancesUrl =
  "/api/v1/entertainment/inspire-arena/performances";
export const performancesCategoriesUrl =
  "/api/v1/taxonomies-list?vocab=performance_categories";
export const discoveryCategoriesUrl =
  "/api/v1/taxonomies-list?vocab=discovery_park_type";
export const entertainmentEventListUrl =
  "/api/v1/entertainment-event/list_page";
export const entertainmentEventItemsUrl = "/api/v1/entertainment-event";

// Meet
export const meetOfferRecommendationUrl = "/api/v1/meet/offer-recommendation";
export const meetInfoUrl = "/api/v1/subpage/info/meet";
export const meetInspireMeetingSuccessUrl =
  "/api/v1/subpage/detail/meeting-success";

export const meetWeddingEventUrl = "/api/v1/subpage/section/wedding-list";
export const meetWeddingDetailUrl = "/api/v1/subpage/meet/wedding-detail";
export const meetServicesUrl = "/api/v1/subpage/section/meet-services";

export const meetMeetPackageUrl = "/api/v1/subpage/meet/wedding-event/packages";
export const meetSightseeingUrl = "/api/v1/subpage/section/sightseeing-list";
export const meetSightseeingDetailUrl =
  "/api/v1/subpage/meet/attraction/sightseeing-detail";
export const meetWhyUsUrl = "/api/v1/subpage/meet/whyus";
export const meetFAQCategoriesUrl =
  "/api/v1/taxonomies-list?vocab=meet_wedding_faqcatgory";
export const meetFAQListUrl = "/api/v1/subpage/meet/whyus/faq";

// Casino
export const casinoOfferRecommendationUrl =
  "/api/v1/casino/offer-recommendation";
export const casinoVerySpecialUrl = "/api/v1/casino/very-special";
export const casinoDiningUrl = "/api/v1/casino/casino-dining";
export const casinoDiningCategoryUrl =
  "/api/v1/taxonomies-list?vocab=casino_restaurants";
export const casinoMembershipUrl = "/api/v1/casino/membership-tiers";
export const casinoServieceUrl = "/api/v1/casino/service-facilities";
export const casinoServiceCategoryUrl =
  "/api/v1/taxonomies-list?vocab=casino_service_facilities";
export const casinoResponsibleGamingUrl = "/api/v1/casino/responsible-gaming";
export const casinoAboutInspireUrl = "/api/v1/casino/about-inspire-casino";
export const casinoCasinoEventsUrl = "/api/v1/casino/casino-events";
export const casinoCasinoEventsListUrl = "/api/v1/casino/casino-events/list";
export const casinoIntroCategoryUrl =
  "/api/v1/taxonomies-list?vocab=casino_floor_information";
export const casinoGamesCategoryUrl =
  "/api/v1/taxonomies-list?vocab=casino_game_category";

export const casinoGamesUrl = "/api/v1/casino/games";

// Momentum
export const momentumOfferRecommendationUrl =
  "/api/v1/momentum/offer-recommendation";
export const momentumIntroductionUrl = "/api/v1/momentum/introduction";
export const momentumTiersBenefitsUrl = "/api/v1/momentum/tiers-benefits";
export const momentumCategoryListUrl =
  "/api/v1/taxonomies-list?vocab=momentum_tiers";
export const momentumLifestyleEventsUrl = "/api/v1/momentum/lifestyle-events";
export const momentumLifestyleEventsDetailUrl =
  "/api/v1/momentum/lifestyle-events-detail";
export const momentumTermsConditionsUrl =
  "/api/v1/assets/momentum-terms-conditions";

// Header
export const headerListUrl = "/api/v1/menu_custom_api?menu_name=header";
export const headerUtilBarUrl =
  "/api/v1/menu_custom_api?menu_name=header-util-bar";

// Footer
export const footerAddressUrl = "/api/v1/footer-wd-1?_format=json";
export const footerMenuUrl = "/api/v1/menu_custom_api?menu_name=footer";
export const footerContactUrl = "/api/v1/footer-wd-3?_format=json";
export const footerSnsUrl = "/api/v1/footer-wd-4?_format=json";
export const newsletterSubmitUrl = "/newsletter";

// Pdf file upload
export const pdfFileUrl = "/api/v1/assets/pdf-file-upload";

//Agilysys API
export const userRegistration = "/engage/loyalty/profile/register";
export const getAPIToken = "/engage/common/token";
export const sendVerificationCode = "/engage/common/VerifyCode";
export const activateProfile = "/engage/loyalty/profile/activate";
export const loginUser = "/engage/loyalty/profile/login";
export const forgotPass = "/engage/loyalty/profile/forget/password";
export const resetPass = "/engage/loyalty/profile/reset/password";
export const getUserProfile = "/engage/loyalty/profile";
export const drupalWebFormUrl = "/webform_rest/submit?format=json";
export const drupalWebFormGetUser = "/webform_rest/get/getUserDetails";
export const drupalWebFormUpdateUser = "/webform_rest/register_user/submission";
