const { default: styled } = require("@emotion/styled");

// STYLE: RangeCalender.jsx
export const DateWrapper = styled.button(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0 1rem",
  width: "fit-content",
  padding: "2rem 10rem",
  border: `1px solid ${theme.color.primary_color}`,
}));

export const RangeCalenderContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CalendarWrapper = styled.div(() => ({
  width: "fit-content",
  marginTop: "1rem",
}));

export const RangeCalenderButtons = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1.4rem",
  fontFamily: theme.fontFamily.primaryFont
}));

export const RangeCalenderCancelApply = styled.div(({ theme }) => ({
  display: "flex",
  gap: "0 1rem",

  "& > button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "12rem",
    height: "3.5rem",
    color: theme.color.white,
    borderRadius: "0.5rem",
    fontWeight: theme.fontWeight.medium,
    background: theme.color.secondary_color,
    textTransform: "uppercase",

    "&:first-of-type": {
      background: theme.color.light_grey,
    },
  },
}));

export const RangeCalenderResetButton = styled.button(({ theme }) => ({
  color: theme.color.primary_color,
  fontWeight: theme.fontWeight.medium,
}));

export const RangeCalenderNavigator = styled.div(() => ({
  position: "absolute",
  top: "50%",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",

  "&>button": {
    "&:last-of-type": {
      transform: "rotate(-180deg)",
    },
  },
}));
