import React from "react";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { ListOne } from "@components/layout";

const WhyIsSpecial = ({ data }) => {
  return (
    <CommonContainer>
      <ListOne data={data} />
    </CommonContainer>
  );
};

export default WhyIsSpecial;
