import React, { memo, useMemo } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { HighlightsSection, OfferRecommendation } from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { CustomMetatag } from "@utils/metatags";
import { useGetStayOfferRecommendation } from "@utils/api-requests/StayRequests";

import { Amenities, TowerPreview } from ".";

const Stay = ({ pageInfo }) => {
  const { data: stayOfferRecommendation } = useGetStayOfferRecommendation();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      titleDirection: "left",
      disabled:
        stayOfferRecommendation?.field_highlights.length === 0 ||
        !stayOfferRecommendation,
      contents: (
        <CommonContainer>
          <HighlightsSection data={stayOfferRecommendation} />
        </CommonContainer>
      ),
    },
    {
      id: 2,
      contents: <TowerPreview />,
    },
    {
      id: 3,
      contents: <Amenities />,
    },
  ];

  return (
    <>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={stayOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </>
  );
};

export default memo(Stay);
