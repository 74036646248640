import React from "react";
import { useMediaQuery } from "react-responsive";

import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { PrimaryButton } from "@components/buttons";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { useGetAboutUsCareers } from "@utils/api-requests/AboutUsRequests";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { ErrorManage } from "@screens/index";

const AboutUsCareers = () => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const { data: aboutUsCareers, isError, error } = useGetAboutUsCareers();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonContentButtonWrapper>
        <ImageWrapper
          ratio={isDesktop ? "5 / 2" : "16 / 9"}
          radius={isDesktop ? "2.5rem" : "1.5rem"}
        >
          <Image
            src={aboutUsCareers?.field_thumbnail[0]?.src}
            alt={aboutUsCareers?.field_thumbnail[0]?.alt}
          />
        </ImageWrapper>

        <PrimaryButton linkTo="/about-us/careers" noArrow>
          <ViewDetailsText />
        </PrimaryButton>
      </CommonContentButtonWrapper>
    </ErrorManage>
  );
};

export default AboutUsCareers;
