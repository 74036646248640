import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { FrameThree } from "@components/frame";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import { DangerouslyHtml } from "@components/item";
import {
  LocationText,
  OperatingHoursText,
  RestaurantTypeText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const RestaurantInformation = ({ data }) => {
  return (
    data?.field_display_info === "On" && (
      <FrameThree data={data}>
        <li>
          <FrameThreeIn>
            <header>
              <LocationText />
            </header>

            <CommonDescTwo>
              <DangerouslyHtml
                value={
                  data?.field_dining_location !== ""
                    ? data?.field_dining_location
                    : "-"
                }
              />
            </CommonDescTwo>
          </FrameThreeIn>

          <FrameThreeIn>
            <header>
              <RestaurantTypeText />
            </header>

            <CommonDescTwo>
              {data?.field_restaurant_concept !== "" ? (
                <DangerouslyHtml value={data?.field_restaurant_concept} />
              ) : (
                "-"
              )}
            </CommonDescTwo>
          </FrameThreeIn>
        </li>

        <li>
          <FrameThreeIn>
            <header>
              <OperatingHoursText />
            </header>

            <CommonDescTwo>
              {data?.field_operational_hours !== "" ? (
                <DangerouslyHtml value={data?.field_operational_hours} />
              ) : (
                "-"
              )}
            </CommonDescTwo>
          </FrameThreeIn>
        </li>
      </FrameThree>
    )
  );
};

export default RestaurantInformation;
