import React, { useMemo, useState } from "react";

import { SliderTwo } from "@components/sliders";
import { ListWithTabs } from "@components/list";
import { checkIncludes } from "@utils/modify-data/modifyData";
import { MeetingRoomText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const MeetingRooms = ({ data }) => {
  const [filterType, setFilterType] = useState("");

  const meetingCategory = useMemo(() => {
    setFilterType(data?.[0]?.theme);

    if (data && data?.length === 1) {
      return "";
    }

    const result = data?.map((item) => {
      return {
        title: item.theme,
        category_id: item.theme,
      };
    });

    return result;
  }, [data]);

  const currentData = useMemo(() => {
    return data?.filter((el) => checkIncludes(el.theme, filterType))[0];
  }, [filterType, data]);

  return (
    <ListWithTabs
      tabArr={meetingCategory}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <SliderTwo
        direction="left"
        data={currentData}
        imageKey="imageslist"
        subTitle={<MeetingRoomText />}
        desc={currentData?.description}
        title={currentData?.name}
      />
    </ListWithTabs>
  );
};

export default MeetingRooms;
