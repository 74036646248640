// SplashBayOperationHours.jsx
import { FormattedMessage } from "react-intl";

import {
  AdultsText,
  AreaText,
  CategoryText,
  ChildrenText,
  GeneralText,
  HeightText,
  LengthText,
  RoomDimensionsText,
  RoomNameText,
  SummerSeasonText,
  WeekdaysText,
  WeekendsHolidaysText,
  WidthText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

export const meetingSuccessThead = [
  [
    {
      id: 1,
      name: <RoomNameText />,
      rowSpan: 2,
    },
    {
      id: 2,
      name: (
        <>
          <p>
            <RoomDimensionsText />
          </p>

          <p>
            (<LengthText /> x <WidthText /> x <HeightText />)
          </p>
        </>
      ),
      colSpan: 3,
    },
    {
      id: 3,
      name: <AreaText />,
    },
  ],
  [
    {
      id: 1,
      name: "Ft / M",
      colSpan: 3,
    },
    {
      id: 2,
      name: "Sq.Ft / Sqm",
    },
  ],
];

export const ballroomTbody = [
  [
    {
      id: 1,
      name: "INSPIRE Ballroom",
      fixed: "true",
    },
    {
      id: 2,
      name: "147.6 / 45",
    },
    {
      id: 3,
      name: "188 / 57.3",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "29,891 / 2,777",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE1",
      fixed: "true",
    },
    {
      id: 2,
      name: "147.6 / 45",
    },
    {
      id: 3,
      name: "37 / 11.3",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "5,554 / 510",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE2",
      fixed: "true",
    },
    {
      id: 2,
      name: "73.5 / 22.4",
    },
    {
      id: 3,
      name: "40.7 / 12.4",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "2,992 / 278",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE3",
      fixed: "true",
    },
    {
      id: 2,
      name: "73.5 / 22.4",
    },
    {
      id: 3,
      name: "40.7 / 12.4",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "2,992 / 278",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE4",
      fixed: "true",
    },
    {
      id: 2,
      name: "73.5 / 22.4",
    },
    {
      id: 3,
      name: "40.7 / 12.4",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "2,992 / 278",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE5",
      fixed: "true",
    },
    {
      id: 2,
      name: "73.5 / 22.4",
    },
    {
      id: 3,
      name: "40.7 / 12.4",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "2,992 / 278",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE6",
      fixed: "true",
    },
    {
      id: 2,
      name: "49.2 / 15",
    },
    {
      id: 3,
      name: "37 / 11.3",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "1,830 / 170",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE7",
      fixed: "true",
    },
    {
      id: 2,
      name: "49.2 / 15",
    },
    {
      id: 3,
      name: "37 / 11.3",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "1,776 / 165",
    },
  ],
  [
    {
      id: 1,
      name: "INSPIRE8",
      fixed: "true",
    },
    {
      id: 2,
      name: "49.2 / 15",
    },
    {
      id: 3,
      name: "37 / 11.3",
    },
    {
      id: 4,
      name: "27.9 / 8.5",
    },
    {
      id: 5,
      name: "1,840 / 171",
    },
  ],
];

export const meetingRoomsTbody = [
  [
    {
      id: 1,
      name: "Mountain",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "147.6 / 45",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "8,234 / 765",
    },
  ],
  [
    {
      id: 1,
      name: "Mountain1",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "29.8 / 9.1",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,560 / 154",
    },
  ],
  [
    {
      id: 1,
      name: "Mountain2",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "29.2 / 8.9",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,615 / 150",
    },
  ],
  [
    {
      id: 1,
      name: "Mountain3",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "29.2 / 8.9",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,615 / 150",
    },
  ],
  [
    {
      id: 1,
      name: "Mountain4",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "29.2 / 8.9",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,615 / 150",
    },
  ],
  [
    {
      id: 1,
      name: "Mountain5",
      fixed: "true",
    },
    {
      id: 2,
      name: "55.7 / 17",
    },
    {
      id: 3,
      name: "25.9 / 9.3",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,281 / 119",
    },
  ],
  [
    {
      id: 1,
      name: "Valley",
      fixed: "true",
    },
    {
      id: 2,
      name: "42.6 / 13",
    },
    {
      id: 3,
      name: "30.5 / 9.3",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,281 / 119",
    },
  ],
];

export const studioTbody = [
  [
    {
      id: 1,
      name: "Island",
      fixed: "true",
    },
    {
      id: 2,
      name: "65.6 / 20",
    },
    {
      id: 3,
      name: "38.4 / 11.7",
    },
    {
      id: 4,
      name: "11.5 / 3.5",
    },
    {
      id: 5,
      name: "2,530 / 235",
    },
  ],
  [
    {
      id: 1,
      name: "Ocean",
      fixed: "true",
    },
    {
      id: 2,
      name: "54.1 / 16.5",
    },
    {
      id: 3,
      name: "30.8 / 9.4",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,668 / 155",
    },
  ],
  [
    {
      id: 1,
      name: "River",
      fixed: "true",
    },
    {
      id: 2,
      name: "54.8 / 16.7",
    },
    {
      id: 3,
      name: "30.8 / 9.4",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "1,690 / 157",
    },
  ],
  [
    {
      id: 1,
      name: "Lake",
      fixed: "true",
    },
    {
      id: 2,
      name: "28.5 / 8.7",
    },
    {
      id: 3,
      name: "30.8 / 9.4",
    },
    {
      id: 4,
      name: "13.8 / 4.2",
    },
    {
      id: 5,
      name: "818 / 76",
    },
  ],
];

export const splashTableThead = [
  [
    {
      id: 1,
      name: <CategoryText />,
      colSpan: 2,
    },
    {
      id: 2,
      name: <WeekdaysText />,
    },
    {
      id: 3,
      name: <WeekendsHolidaysText />,
    },
  ],
];

export const splashTbody = [
  [
    {
      id: 1,
      name: <GeneralText />,
      colSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: "10:00 ~ 19:00",
    },
    {
      id: 3,
      name: "10:00 ~ 21:00",
    },
  ],

  [
    {
      id: 1,
      name: <SummerSeasonText />,
      rowSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: "July 1 to August 31",
      fixed: "true",
    },
    {
      id: 3,
      name: "10:00 ~ 19:00",
    },
    {
      id: 4,
      name: "10:00 ~ 21:00",
    },
  ],

  [
    {
      id: 1,
      name: "July 1 to August 31",
      rowSpan: 1,
      fixed: "true",
    },
    {
      id: 2,
      name: "10:00 ~ 21:00",
      colSpan: 2,
    },
  ],
];

// HowToReserve.jsx
export const onlineReservationThead = [
  [
    {
      id: 1,
      name: <CategoryText />,
      colSpan: 2,
      rowSpan: 2,
    },
    {
      id: 2,
      name: <WeekdaysText />,
      colSpan: 2,
    },
    {
      id: 3,
      name: <WeekendsHolidaysText />,
      colSpan: 2,
    },
  ],
  [
    { id: 1, name: <AdultsText /> },
    { id: 2, name: <ChildrenText /> },
    { id: 3, name: <AdultsText /> },
    { id: 4, name: <ChildrenText /> },
  ],
];

export const onlineReservationTbody = [
  [
    {
      id: 1,
      name: <GeneralText />,
      colSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],

  [
    {
      id: 1,
      name: <SummerSeasonText />,
      rowSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: "July 1 to August 31",
      fixed: "true",
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 6,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],

  [
    {
      id: 1,
      name: "July 22 to August 20",
      rowSpan: 1,
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
];

export const onlineReservationPackageThead = [
  [
    {
      id: 1,
      name: <CategoryText />,
    },
    { id: 2, name: <WeekdaysText /> },
    { id: 3, name: <WeekendsHolidaysText /> },
  ],
];

export const onlineReservationPackageTbody = [
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 01</p>

          <p>
            (1 <AdultsText /> + 2 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 02</p>

          <p>
            (2 <AdultsText /> + 1 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 03</p>

          <p>
            (2 <AdultsText /> + 2 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
];

export const offlineReservationTbody = [
  [
    {
      id: 1,
      name: <GeneralText />,
      colSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],

  [
    {
      id: 1,
      name: <SummerSeasonText />,
      rowSpan: 2,
      fixed: "true",
    },
    {
      id: 2,
      name: "July 1 to August 31",
      fixed: "true",
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 6,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],

  [
    {
      id: 1,
      name: "July 22 to August 20",
      rowSpan: 1,
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 4,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 5,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
];

export const offlineReservationPackageTbody = [
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 01</p>

          <p>
            (1 <AdultsText /> + 2 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 02</p>

          <p>
            (2 <AdultsText /> + 1 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
  [
    {
      id: 1,
      name: (
        <>
          <p>Packages 03</p>

          <p>
            (2 <AdultsText /> + 2 <ChildrenText />)
          </p>
        </>
      ),
      fixed: "true",
    },
    {
      id: 2,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
    {
      id: 3,
      name: <FormattedMessage id="lang-krw" values={{ value: "00,000" }} />,
    },
  ],
];
