import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  CapacitySizeText,
  LocationText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  FrameSixContainer,
  FrameSixHeader,
  FrameSixTexts,
} from "./FrameStyling";
import { DangerouslyHtml } from "@components/item";

const FrameSix = ({ data }) => {
  return (
    <FrameSixContainer>
      <ImageWrapper radius="2.5rem">
        <Image
          src={data?.field_image[0]?.src}
          alt={data?.field_image[0]?.alt}
        />
      </ImageWrapper>

      <FrameSixTexts>
        <li>
          <FrameSixHeader>
            <LocationText />
          </FrameSixHeader>

          <DangerouslyHtml value={data?.field_meet_room_location} />
        </li>

        <li>
          <FrameSixHeader>
            <CapacitySizeText />
          </FrameSixHeader>

          <DangerouslyHtml value={data?.field_meet_room_capacity} />
        </li>

        <li>
          <CommonDescTwo>
            <DangerouslyHtml value={data?.field_meet_room_description} />
          </CommonDescTwo>
        </li>
      </FrameSixTexts>
    </FrameSixContainer>
  );
};

export default FrameSix;
