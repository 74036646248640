import React from "react";

import { CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import {
  HtmlResponsible,
  ResponsibleAccordionSubTitle,
  TogetherMakeDifferenceCanada,
  TogetherMakeDifferenceContainer,
  TogetherMakeDifferenceHeader,
  TogetherMakeDifferenceItem,
} from "@pages/casino/CasinoStyling";

import { ResponsibleAccordion } from ".";

const TogetherMakeDifference = ({ data }) => {
  return (
    <ResponsibleAccordion title={data?.field_together_title}>
      <ResponsibleAccordionSubTitle>
        <DangerouslyHtml value={data?.field_together_sub_title} />
      </ResponsibleAccordionSubTitle>

      <TogetherMakeDifferenceContainer>
        {data?.field_together_contact?.map((item) => {
          return (
            <TogetherMakeDifferenceItem key={item.field_name}>
              <TogetherMakeDifferenceHeader>
                <DangerouslyHtml value={item.field_name} />
              </TogetherMakeDifferenceHeader>

              <HtmlResponsible value={item.field_full_description} />
            </TogetherMakeDifferenceItem>
          );
        })}
      </TogetherMakeDifferenceContainer>

      <TogetherMakeDifferenceCanada>
        <CommonTitleOne>
          <DangerouslyHtml value={data?.field_together_canada_title} />
        </CommonTitleOne>

        <TogetherMakeDifferenceItem>
          <TogetherMakeDifferenceHeader>
            <DangerouslyHtml
              value={data?.field_together_canada_residents[0].field_name}
            />
          </TogetherMakeDifferenceHeader>

          <HtmlResponsible
            value={
              data?.field_together_canada_residents[0].field_full_description
            }
          />
        </TogetherMakeDifferenceItem>
      </TogetherMakeDifferenceCanada>
    </ResponsibleAccordion>
  );
};

export default TogetherMakeDifference;
