import React, { useEffect } from "react";

import { SliderSeven } from "@components/sliders";
import { useGetStoreList } from "@utils/api-requests/ShopRequests";

const SelectedShops = ({ setDisableSelected }) => {
  const { data: storeList } = useGetStoreList("all");

  useEffect(() => {
    if (storeList) {
      if (
        storeList?.filter((el) => el.field_show_in_selected_shops === "On")
          .length > 0
      ) {
        setDisableSelected(false);

        return;
      }

      setDisableSelected(true);
    }
  }, [setDisableSelected, storeList]);

  return (
    <SliderSeven
      data={storeList?.filter((el) => el.field_show_in_selected_shops === "On")}
      nameKey="field_shop_"
      categoryKey="shop_category_name"
      descKey="field_shop_short_description"
      linkKey="url_alias"
    />
  );
};

export default SelectedShops;
