import React from "react";

import { scrollInToViewBasic } from "@utils/scroll-behavior/ScrollIntoView";

import { PaginationContainer, StyledPagination } from "./ItemStyling";

const MuiPagination = ({ listRef, count, page, setPage }) => {
  const handleChange = (_, value) => {
    scrollInToViewBasic(listRef);

    setPage(value);
  };

  return (
    <PaginationContainer>
      <StyledPagination
        onChange={handleChange}
        count={count}
        page={page}
        variant="outlined"
        color="primary"
        size="large"
      />
    </PaginationContainer>
  );
};

export default MuiPagination;
