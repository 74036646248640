import { Swiper } from "swiper/react";
import styled from "@emotion/styled";

import {
  CommonDescOne,
  CommonTitleFive,
  CommonTitleOne,
  infoZIndex,
  shareButtonZIndex,
} from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";

import { Link } from "react-router-dom";
import {isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";

// STYLE: Home.jsx
export const HomeDemoLink = styled(Link)(({ theme }) => ({
  marginTop: "2rem",
  padding: "1rem 2rem",
  background: theme.color.primary_color,
  color: "white",
}));

// STYLE: MainBanner.jsx
export const MainBannerLeftArrow = {
  position: "absolute",
  left: "5rem",
  top: "50%",
  zIndex: 2,
};

export const MainBannerRightArrow = {
  position: "absolute",
  right: "5rem",
  top: "50%",
  zIndex: 2,
};

export const HomeButtonWrapper = styled.div(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const MainBannerContainer = styled.div(() => ({
  position: "relative",
  marginBottom: "2.4rem",

  [mq("desktop")]: {
    marginBottom: "12rem",
  },
}));

export const MainBannerSwiper = styled(Swiper)(() => ({
  position: "relative",
  maxHeight: "80rem",
  height: "65%",
  minHeight: "65%",

  [mq("desktop")]: {
    maxHeight: "85rem",
    height: "calc(100vh - 150px)",
    minHeight: "300px",
  },
}));

export const MainBannerViewMoreButton = styled.button(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "nowrap",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  whiteSpace: "nowrap",
  gap: "0 1rem",
  position: "absolute",
  right: "50%",
  zIndex: 999,
  padding: "1.8rem 4rem",
  borderRadius: "0.5rem",
  // background: "rgba(0, 0, 0, 40%)",
  // border: "1px solid #fff",
  bottom: "2rem",
  color: theme.color.white,
  textTransform: "uppercase",
  fontWeight: theme.fontWeight.bold,
  letterSpacing: "0.2rem",
  lineHeight: "1.8rem",
  transform: "translateX(50%)",
  [mq("desktop")]: {
    bottom: "10rem",
  },
}));

export const MainBannerIFrameWrapper = styled.div(() => ({
  display: "flex",
  flexFlow: "column",
  alignItems: "flex-end",
}));

export const MainBannerIFrame = styled.iframe(() => ({
  width: "90vw",
  height: "auto",
  aspectRatio: "16 / 9",

  [mq("desktop")]: {
    width: "70vw",
  },
}));

export const MainBannerIFrameClose = styled.div(() => ({
  display: "block",
  marginBottom: "1.6rem",
  cursor: "pointer",
}));

export const MainBannerImage = {
  maxHeight: "59.2rem",

  [mq("desktop")]: {
    maxHeight: "85rem",
  },

  "& video": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    inset: "0px",
  },

  "@media (min-resolution: 90dpi)": {
    // when zoom level is over than 100%
    // height: "90vh",
    // maxHeight: "none",
  },
};

export const MainBannerButton = {
  zIndex: shareButtonZIndex,
  position: "absolute",
  bottom: "40%",
  left: "50%",
  transform: "translate(-50%)",
  maxWidth: "fit-content",
};

export const MainBannerBooking = styled.form(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: "-7.5rem",
  zIndex: infoZIndex,
  padding: "3rem 2rem",
  background: theme.color.white,
  borderRadius: "2.5rem",
  boxShadow: theme.shadow.secondary,
  flexDirection: "column",
  gap: "3rem 0",
  fontFamily: theme.fontFamily.primaryFont,

  [mq("desktop")]: {
    flexDirection: "row",
    minWidth: "110rem",
    height: "15rem",
    padding: "0 5rem",
  },
}));

export const MainBannerBookingWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 3rem",
  flex: 1,

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MainBannerBookingSection = styled.section(({ theme }) => ({
  display: "flex",

  flexDirection: "column",
  justifyContent: "space-between",
  paddingRight: "3rem",

  [mq("desktop")]: {
    "&:not(:last-child)": {
      borderRight: `1px solid ${theme.color.light_grey}`,
    },
  },
}));

export const MainBannerBookingHeader = styled.section(({ theme }) => ({
  color: theme.color.pale_grey,
  textTransform: "uppercase",
  letterSpacing: "0.2rem",
  whiteSpace: "nowrap",
  minHeight: "3.6rem",
}));

export const MainBannerBookingButton = styled(CommonTitleFive)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  gap: "0 2rem",
  fontWeight: theme.fontWeight.medium,
  whiteSpace: "nowrap",
  lineHeight: 1.5,
}));

// STYLE: HomeTower.jsx
export const HomeTowerContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 2rem",
  aspectRatio: "5 / 2",

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const HomeTowerWrapper = styled.div(({ active }) => ({
  width: "100%",
  transition: "width 0.5s",
  transformOrigin: "center center",
  cursor: "pointer",
  height: active ? "38rem" : "5rem",

  [mq("desktop")]: {
    width: active ? "90%" : "5%",
    height: "100%",
  },
}));

export const HomeTowerImageInfo = styled.div(() => ({
  position: "relative",
  height: "100%",
  minHeight: "5.2rem",

  [mq("desktop")]: {
    display: "flex",
  },
}));

export const HomeTowerDetails = styled.div(() => ({
  position: "absolute",
  top: "12rem",
  height: "calc(100% - 12rem)",
  width: "calc(100% - 4rem)",
  left: "50%",
  transform: "translateX(-50%)",
  flex: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2.4rem 0",
  justifyContent: "space-between",
  padding: "0 1rem 3.2rem",
  background: "#FCF6E3",
  borderRadius: "2.5rem 2.5rem 0 0",
  textAlign: "center",

  [mq("desktop")]: {
    position: "static",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    padding: "9rem 6rem",
    textAlign: "left",
    transform: "unset",
    borderRadius: "0 2.5rem 2.5rem 0",
  },
}));

export const HomeTowerDesc = styled(CommonDescOne)(() => ({
  padding: "3.2rem 1.6rem 0 1.6rem",
  fontSize: "1.4rem",

  [mq("desktop")]: {
    padding: 0,
  },
}));

export const HomeTowerDetailsTitle = styled(CommonTitleOne)(() => ({
  marginBottom: "4rem",
}));

export const HomeTowerInformation = styled(CommonTitleOne)(
  ({ theme, active }) => ({
    position: "relative",
    width: "100%",
    color: theme.color.white,
    top: active ? "5.6rem" : "20%",
    whiteSpace: active ? "break-spaces" : "nowrap",
    textAlign: "center",
    textTransform: "uppercase",

    [mq("desktop")]: {
      position: "absolute",
      textTransform: "initial",
      transform: active ? "rotate(0) translateX(-50%)" : "rotate(-90deg)",
      top: "auto",
      bottom: "4rem",
      left: active ? "50%" : "0",
      fontSize: "3.6rem",
    },
  })
);
