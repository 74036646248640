import React from "react";

import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import {
  BenefitsContainer,
  BenefitsIcon,
  BenefitsList,
} from "@pages/stay/StayStyling";

const BenefitsForGuests = ({ data }) => {
  return (
    <CommonContainer>
      <BenefitsContainer className="stay-benefits">
        {data?.map((benefit) => {
          return (
            <BenefitsList key={benefit.title}>
              {benefit?.image !== null && (
                <BenefitsIcon
                  src={`${process.env.REACT_APP_SERVER_URL}${benefit?.image?.src}`}
                  alt={benefit?.image?.alt}
                />
              )}

              <header>
                <DangerouslyHtml value={benefit.title} />
              </header>
            </BenefitsList>
          );
        })}
      </BenefitsContainer>
    </CommonContainer>
  );
};

export default BenefitsForGuests;
