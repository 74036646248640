import React from "react";

import { SecondaryButton } from "@components/buttons";
import { FrameTwo } from "@components/frame";
import { useGetAboutUsMohegan } from "@utils/api-requests/AboutUsRequests";
import {
  AboutUsText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { ErrorManage } from "@screens/index";

const AboutUsMoheganResort = () => {
  const { data: aboutUsMohegan, isError, error } = useGetAboutUsMohegan();

  return (
    <ErrorManage isError={isError} error={error}>
      <FrameTwo
        className="ellipsis-6"
        subTitle={<AboutUsText />}
        title={aboutUsMohegan?.field_mohegan_name}
        desc={aboutUsMohegan?.field_short_description}
        image={aboutUsMohegan?.field_thumbnail?.[0]}
        button={
          <SecondaryButton
            buttonColor="white"
            linkTo={`/about-us/mohegan-resort`}
          >
            <ViewDetailsText />
          </SecondaryButton>
        }
      />
    </ErrorManage>
  );
};

export default AboutUsMoheganResort;
