import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { PrimaryButton } from "@components/buttons";
import { TextInput } from "@components/inputs";
import { yupForgotPassword } from "@components/inputs/yupValidation";
import { ForgotPasswordModalForm } from "@components/modal/ModalStyling";
import {
  ForgotSubmitText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useMutation } from "react-query";
import { userProfileActiveVerificationCodeSendRequest } from "@utils/api-requests/userRequests";
import { Alert } from "@mui/material";
import {
  EmailLabelText,
  ForgotPasswordProfileNotExistErrorText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";

const ForgotPasswordForm = ({ setPasswordStep, setEmail, setWarning }) => {
  const [invalid, setInvalid] = useState("");

  const { mutate: activationMutate, isLoading: activationLoading } =
    useMutation(userProfileActiveVerificationCodeSendRequest, {
      onSuccess: (data) => {
        if (data.status === 201) {
          if (data?.data?.[0]?.Code === "PW0001") {
            // Previous Verification Code is not expired.

            setWarning({
              previous: true,
            });
          }

          setPasswordStep(1);
          setEmail(JSON.parse(data.config.data).emailID);
        }
        // ERROR: Profile Does not exists
        else if (data.response?.data.Errors[0].Code === "P0002") {
          setInvalid(<ForgotPasswordProfileNotExistErrorText />);
        } else {
          console.log("error", data);
        }
      },
      onError: (error) => {
        console.log("error", error);
      },
    });

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(yupForgotPassword),
    mode: "onSubmit",
    defaultValues: {
      email: "",
    },
  });

  const forgotPasswordFormSubmit = useCallback(
    (data) => {
      if (data) {
        activationMutate({ email: data.email, type: 2 });
      }
    },
    [activationMutate]
  );

  return (
    <ForgotPasswordModalForm onSubmit={handleSubmit(forgotPasswordFormSubmit)}>
      {invalid !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "16px" }}>
          {invalid}
        </Alert>
      )}

      <TextInput
        control={control}
        name="email"
        label={<EmailLabelText />}
        placeholder="name@email.com"
        type="email"
      />

      <PrimaryButton buttonType="submit" disable={activationLoading}>
        {activationLoading ? <SubmitLoadingText /> : <ForgotSubmitText />}
      </PrimaryButton>
    </ForgotPasswordModalForm>
  );
};

export default ForgotPasswordForm;
