import React, { memo, useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "@emotion/react";

import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  ButtonArrows,
  StyledBorderButton,
  StyledBorderLink,
  borderButtonStyles,
} from "./ButtonsStyling";

const BorderButton = ({
  linkTo,
  children,
  noArrow,
  small,
  customstyle,
  buttonEvent,
  buttonColor,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [hover, setHover] = useState(false);

  const theme = useTheme();

  const activeHover = useCallback(() => {
    setHover(true);
  }, []);

  const inactiveHover = useCallback(() => {
    setHover(false);
  }, []);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return linkTo ? (
    <StyledBorderLink
      to={`${baseUrl}${linkTo}`}
      style={borderButtonStyles(theme, {
        small,
        isDesktop,
        buttonColor,
        customstyle,
      })}
      onMouseEnter={activeHover}
      onMouseLeave={inactiveHover}
      buttoncolor={buttonColor}
    >
      {children}

      {!noArrow && (
        <ButtonArrows
          src={theme.image.linkArrow.default}
          alt="right arrow"
          hover={hover}
        />
      )}
    </StyledBorderLink>
  ) : (
    <StyledBorderButton
      style={borderButtonStyles(theme, {
        small,
        isDesktop,
        buttonColor,
        customstyle,
      })}
      onMouseEnter={activeHover}
      onMouseLeave={inactiveHover}
      onClick={buttonEvent}
      buttoncolor={buttonColor}
    >
      {children}

      {!noArrow && (
        <ButtonArrows
          src={theme.image.linkArrow.default}
          alt="right arrow"
          hover={hover}
        />
      )}
    </StyledBorderButton>
  );
};

export default memo(BorderButton);
