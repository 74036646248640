import React from "react";
import { css, Global } from "@emotion/react";

import { mq } from "@utils/media-query/mediaQuery";
import { AppTheme, color } from "src/theme";
import {
  isChineseLanguage,
  isJapanese, isKorean,
} from "@utils/lanugauge-settings/LanguageOptions";

const style = css`
  html {
    font-size: 62.5%;
    box-sizing: border-box;
    line-height: 1.2;
    overflow-x: hidden;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset;
    -webkit-text-fill-color: #000;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  body {
    width: 100vw;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0 !important;
    margin: 0;
    font-family: ${AppTheme.fontFamily.secondaryFont};
    color: ${color.base_black};
    // word-wrap: ${isJapanese || isChineseLanguage ? "break-word" : "normal"};
    word-break: ${isJapanese || isKorean ? "keep-all" : "normal"};

    &::-webkit-scrollbar {
      width: 1rem;
      height: 0.8rem;
      background-color: ${color.light_white};
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${color.pale_grey};
      border-radius: 1rem;
      height: 0.8rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ${mq("desktop")} {
      font-size: 1.6rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 1rem;
      height: 0.8rem;
      background-color: ${color.light_white};
      border-radius: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${color.pale_grey};
      border-radius: 1rem;
      height: 0.8rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  p + p {
    margin-block-start: 0.6rem;
  }

  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }

  button,
  a {
    &:hover {
      transition: all 0.3s;
    }
  }

  ul,
  li {
    list-style: none;
  }

  button {
    color: inherit;
    font: inherit;
    cursor: pointer;
    background: inherit;
  }

  input {
    font-size: 2rem;
    font-family: ${AppTheme.fontFamily.primaryFont};
    font-weight: 500;
    padding-bottom: 0.4rem;
    display: block;
  }
  
  .modal-change-password {
    max-width: 300px;
  }
  
  section:empty {
    display: none;
  }
`;

function GlobalStyle() {
  return <Global styles={style} />;
}

export default GlobalStyle;
