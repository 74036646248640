// at polyfill

if (!Array.prototype.at) {
  Array.prototype.at = function(index) {
    // Converts index to an integer
    index = Math.trunc(index) || 0;
    if (index < 0) index += this.length;
    if (index < 0 || index >= this.length) return undefined;
    return this[index];
  };
}

// puppeteer polyfill
(function () {
  if (typeof globalThis === 'undefined') {
    // Adding ESLint disable comments to avoid the 'no-undef' rule triggering
    Object.defineProperty(Object.prototype, '__magic__', {
      get: function() {
        return this;
      },
      configurable: true // This makes it possible to delete the property later.
    });
    __magic__.globalThis = __magic__; // eslint-disable-line no-undef
    delete Object.prototype.__magic__; // eslint-disable-line no-undef
  }
})();