import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { image } from "src/theme";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { SliderSix } from "@components/sliders";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
} from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  CareerDetailLinkedButton,
  CareersDetailContainer,
} from "@pages/about-us/AboutUsStyling";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsCareers } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";
import {
  CareersText,
  ConnectToLinkedinText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { CareerLinks, FindYourInspiration } from ".";
import { aboutUsRouteList } from "../aboutUsRouteList";

const CareersDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsCareers,
    isLoading,
    isError,
    error,
  } = useGetAboutUsCareers();

  const careersDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-about-us",
      }),
      href: "/about-us",
    },
    { id: 3, current: true, name: aboutUsCareers?.field_career_name },
  ];

  const sectionArray = [
    {
      id: 1,
      contents: <FindYourInspiration data={aboutUsCareers} />,
    },
    {
      id: 2,
      contents: (
        <CareersDetailContainer>
          <SliderSix imageArr={aboutUsCareers?.field_multiple_images} />

          <BookYourInspire
            noMargin
            subTitle={<CareersText />}
            copywrite={aboutUsCareers?.field_linked_in_copywrite}
          >
            <CareerDetailLinkedButton
              href={aboutUsCareers?.field_linked_in_link}
              target="_blank"
              rel="noreferrer"
            >
              <img src={image.linkedInLogo.default} alt="" height={20} />

              <ConnectToLinkedinText />
            </CareerDetailLinkedButton>
          </BookYourInspire>
        </CareersDetailContainer>
      ),
    },
    {
      id: 3,
      contents: <CareerLinks data={aboutUsCareers?.field_career_link_list} />,
    },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsCareers} />

      <DetailPageHero data={aboutUsCareers} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsCareers} />

          <MuiBreadcrumbs
            breadcrumbArr={careersDetailBC}
            pageTitle={aboutUsCareers?.field_career_name}
            value={aboutUsCareers?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsCareers} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default CareersDetail;
