import React from "react";

import { CommonDescOne, CommonTitleThree } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";

const TravelRedefined = ({ data }) => {
  return (
    <BoxListThreeContainer>
      <BoxListThreeWrapper alignItems="flex-start">
        <ImageWrapper radius="2.5rem" ratio="3 / 2">
          <Image
            src={data?.field_logo[0]?.src}
            alt={data?.field_logo[0]?.alt}
          />
        </ImageWrapper>

        <BoxListThreeInformation>
          <CommonTitleThree Black>
            <DangerouslyHtml value={data.field_travel_redefined_title} />
          </CommonTitleThree>

          <CommonDescOne>
            <DangerouslyHtml value={data.field_travel_redefined_desc} />
          </CommonDescOne>
        </BoxListThreeInformation>
      </BoxListThreeWrapper>
    </BoxListThreeContainer>
  );
};

export default TravelRedefined;
