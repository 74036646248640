import React, { useCallback, useEffect, useMemo, useState } from "react";
import ja from "react-phone-number-input/locale/ja";
import { useIntl } from "react-intl";
import en from "react-phone-number-input/locale/en";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  Alert,
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import {
  yupAgilisysSignUp,
  mergedYupAgilisysSignUp,
} from "@components/inputs/yupValidation";
import { DateInput, SelectInput, TextInput } from "@components/inputs";

import { TextInputWrapper } from "@components/inputs/InputsStyling";
import { PrimaryButton } from "@components/buttons";
import PhoneNumberInput from "@components/inputs/PhoneNumberInput";
import {
  PrivacyPolicyModal,
  MarketingLearnMoreModal,
} from "@components/modal/modal-list";
import {
  KeepInTouchFormCheckBoxWrapper,
  KeepInTouchFormTermsOfUse,
  MarketingOptInLabel,
  MarketingOptInWrapper,
} from "@components/modal/ModalStyling";
import ActivationCodeModal from "@components/modal/modal-list/ActivationCodeModal";
import AutoCompleteSelect from "@components/inputs/AutoCompleteSelect";
import ErrorModal from "@components/modal/modal-list/ErrorModal";
import {
  userProfileActiveVerificationCodeSendRequest,
  userRegistrationRequest,
} from "@utils/api-requests/userRequests";
import {
  AddressText,
  BasicInfomationText,
  IAgreeText,
  IDisagreeText,
  LearnMoreText,
  SubmitLoadingText,
  SubmitText,
  TermsConditionsText,
  UsernamePasswordText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  Address2LabelText,
  AddressLabelText,
  AgreeTermsLabelText,
  CityLabelText,
  ConfirmPasswordLabelText,
  CountryLabelText,
  DateofBirthLabelText,
  FirstNameLabelText,
  LastNameLabelText,
  MailOptLabelText,
  MailOptContentText,
  PasswordLabelText,
  PhoneLabelText,
  PostalCodeLabelText,
  SignUpProfileAlreadyExistErrorText,
  StateLabelText,
  TypeLabelText,
  UsernameEmailLabelText,
  MailUseLabelText,
  MailUseContentText,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { convertDate } from "@utils/modify-data/modifyData";
import { useIpInformation } from "@utils/api-requests/GlobalRequests";
import {
  countrySwitchLanguage,
  isJapanese,
} from "@utils/lanugauge-settings/LanguageOptions";
import countries from "@utils/lanugauge-settings/countries.json";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  AgilisysSignUpFormButtons,
  AgilisysSignUpFormContainer,
  AgilisysSignUpFormSection,
  AgilisysSignUpFormSectionTitle,
} from "../MomentumStyling";

const AgilisysSignUpForm = () => {
  const intl = useIntl();

  const { ipInformation } = useIpInformation();

  const [defaultCountryCode, setDefaultCountryCode] = useState("");

  const [signUpData, setSignUpData] = useState();

  const [activationCodeModal, setActivationCodeModal] = useState(false);
  const [alertModal, setAlertModal] = useState({ open: false, data: "" });
  const [dateVal, setDateVal] = useState("");
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailExist, setEmailExist] = useState("");

  const countryOptionArr = useMemo(() => {
    const countryCode = countrySwitchLanguage(
      localStorage.getItem(LOCAL_STORAGE_LANGUAGE),
    );

    const getValueForCountry = (countryCode) => {
      return countries.map((row) => row[countryCode]);
    };

    return getValueForCountry(countryCode);
  }, []);

  const [toggleAddressForm, setToggleAddressForm] = useState(false); // State to manage whether to show the site or not

  const handleToggleAddressForm = (event) => {
    setValue("showAddressValidation", event.target.checked);
    setToggleAddressForm(event.target.checked); // Update the state based on checkbox value
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: toggleAddressForm
      ? yupResolver(mergedYupAgilisysSignUp)
      : yupResolver(yupAgilisysSignUp),
    mode: "onSubmit",
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      emailAddressType: "1",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      phoneType: "1",
      phoneCode: "",
      dateofBirth: "",
      addressType: toggleAddressForm ? "1" : "",
      addressLine1: "",
      addressLine2: "",
      countryCode: "",
      stateCode: "",
      cityCode: "",
      zipCode: "",
      mailoptin: "",
      personal_use: "",
    },
  });

  const { mutate, isLoading } = useMutation(userRegistrationRequest, {
    onSuccess: async (data) => {
      // ERROR: Profile already exists. Activated. in Agylysis
      if (data.response?.data.Errors[0].Code === "P0002") {
        setEmailExist(<SignUpProfileAlreadyExistErrorText />);

        return;
      }

      activationMutate({
        email: JSON.parse(data.config.data).emails[0].emailAddress,
        type: 1,
      });

      if (data.response?.data.Errors[0].Code === "P00012") {
        // pending
        activationMutate({
          email: JSON.parse(data.config.data).emails[0].emailAddress,
          type: 1,
        });
      }
    },
    onError: (error) => {
      console.log(error);

      setAlertModal({ open: true, data: JSON.stringify(error) });
    },
  });

  const { mutate: activationMutate, isLoading: activationLoading } =
    useMutation(userProfileActiveVerificationCodeSendRequest, {
      onSuccess: (data) => {
        if (data.status === 201) {
          setActivationCodeModal(true);

          setEmail(JSON.parse(data.config.data).emailID);
        } else {
          setAlertModal({
            open: true,
            data: JSON.stringify(data.response?.data),
          });
        }
      },
      onError: (error) => {
        console.log(error);
        setAlertModal({ open: true, data: JSON.stringify(error) });
      },
    });

  useEffect(() => {
    if (ipInformation) {
      setDefaultCountryCode(ipInformation?.countryCode);
    }
  }, [ipInformation]);

  const phoneTypeArr = [
    {
      id: 1,
      text: intl.formatMessage({
        id: "lang-mobile",
      }),
      value: "1",
    },
  ];

  const addressTypeArr = [
    {
      id: 1,
      text: intl.formatMessage({
        id: "lang-home",
      }),
      value: "1",
    },
  ];

  const openTerms = useCallback(() => {
    setPrivacyPolicyOpen(true);
  }, []);

  const openMarketing = useCallback(() => {
    setMarketingOpen(true);
  }, []);

  const signUpFormSubmit = useCallback(
    (data) => {
      setSignUpData(data);

      const parsedPhoneNumber = parsePhoneNumber(data.phoneNumber);

      let obj = {
        systemcode: process.env.REACT_APP_AGILISYS_API_SYSTEMCODE,
        corpCode: process.env.REACT_APP_AGILISYS_API_CORPCODE,
        siteCode: process.env.REACT_APP_AGILISYS_API_SITECODE,
        firstName: data.firstName,
        lastName: data.lastName,
        emails: [
          {
            emailAddressType: data.emailAddressType,
            emailAddress: data.emailAddress,
          },
        ],
        phones: [
          {
            phoneType: data.phoneType,
            phoneNumber: parsedPhoneNumber.nationalNumber,
            phoneCountryCode: parsedPhoneNumber.country,
            phoneCode: parsedPhoneNumber.countryCallingCode,
          },
        ],
        password: data.password,
        dateofBirth: convertDate(data.dateOfBirth),
        // anniversaryDate: new Date(data.anniversaryDate)
        //   ?.toISOString()
        //   .split("T")[0],
        addresses: [],
        mailoptin: data.mailoptin || "No",
        personal_use: data.personal_use || "No",
      };

      let addressObject = [
        {
          addressType: data.addressType,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          countryCode: data.countryCode,
          stateCode: data.stateCode,
          cityCode: data.cityCode,
          zipCode: data.zipCode,
        },
      ];

      if (toggleAddressForm) {
        obj = { ...obj, addresses: addressObject };
      }

      mutate(obj);
    },
    [mutate, toggleAddressForm],
  );

  return (
    <>
      <ActivationCodeModal
        openModal={activationCodeModal}
        email={email}
        setOpenModal={setActivationCodeModal}
        signUpData={signUpData}
      />

      <ErrorModal
        openModal={alertModal.open}
        setOpenModal={setAlertModal}
        message={alertModal.data}
      />

      <AgilisysSignUpFormContainer
        onSubmit={handleSubmit(signUpFormSubmit)}
        name="signUpForm"
      >
        {emailExist !== "" && (
          <Alert severity="error" sx={{ width: "100%", marginBottom: "16px" }}>
            {emailExist}
          </Alert>
        )}

        <PrivacyPolicyModal
          openModal={privacyPolicyOpen}
          setOpenModal={setPrivacyPolicyOpen}
        />

        <MarketingLearnMoreModal
          openModal={marketingOpen}
          setOpenModal={setMarketingOpen}
        />

        <AgilisysSignUpFormSection>
          <AgilisysSignUpFormSectionTitle Black>
            <UsernamePasswordText />
          </AgilisysSignUpFormSectionTitle>

          <TextInput
            control={control}
            name="emailAddress"
            label={<UsernameEmailLabelText />}
            type="email"
            placeholder={intl.formatMessage({
              id: "lang-enter-email-address",
            })}
            maxLength={50}
          />

          <div>
            <TextInput
              control={control}
              name="password"
              label={<PasswordLabelText />}
              type="password"
              placeholder="*******"
              maxLength={50}
            />

            <TextInput
              control={control}
              name="confirmPassword"
              label={<ConfirmPasswordLabelText />}
              type="password"
              placeholder="*******"
              maxLength={50}
            />
          </div>
        </AgilisysSignUpFormSection>

        <AgilisysSignUpFormSection>
          <AgilisysSignUpFormSectionTitle Black>
            <BasicInfomationText />
          </AgilisysSignUpFormSectionTitle>

          <div>
            <TextInput
              control={control}
              name="firstName"
              label={<FirstNameLabelText />}
              placeholder={intl.formatMessage({
                id: "lang-first-name-label",
              })}
              maxLength={15}
            />

            <TextInput
              control={control}
              name="lastName"
              label={<LastNameLabelText />}
              placeholder={intl.formatMessage({
                id: "lang-last-name-label",
              })}
              maxLength={15}
            />
          </div>

          <div>
            <DateInput
              name="dateOfBirth"
              label={<DateofBirthLabelText />}
              control={control}
              dateVal={dateVal}
              setDateVal={setDateVal}
              errors={errors}
            />

            {/*
            <DateInput
              name="anniversaryDate"
              label={<DateofAnniversaryLabelText />}
              control={control}
              dateVal={anniversaryDateVal === null ? "" : anniversaryDateVal}
              setDateVal={setAnniversaryDateVal}
              errors={errors}
            /> */}
          </div>

          <div>
            {ipInformation && (
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => {
                  return (
                    <TextInputWrapper>
                      <PhoneInput
                        {...field}
                        placeholder={intl.formatMessage({
                          id: "lang-enter-phone-number",
                        })}
                        inputComponent={PhoneNumberInput}
                        onChange={field.onChange}
                        name="phoneNumber"
                        label={<PhoneLabelText />}
                        error={!!errors["phoneNumber"] || !!errors["phoneCode"]}
                        helperText={
                          errors["phoneNumber"]?.message ||
                          errors["phoneCode"]?.message
                        }
                        defaultCountry={defaultCountryCode}
                        labels={isJapanese ? ja : en}
                        control={control}
                      />
                    </TextInputWrapper>
                  );
                }}
              />
            )}

            <SelectInput
              control={control}
              errors={errors}
              name="phoneType"
              arr={phoneTypeArr}
              label={<TypeLabelText />}
              placeholder={intl.formatMessage({
                id: "lang-type-label",
              })}
            />
          </div>
        </AgilisysSignUpFormSection>

        <AgilisysSignUpFormSection>
          <AgilisysSignUpFormSectionTitle Black>
            <Controller
              control={control}
              name="addressFormToggle"
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={toggleAddressForm}
                        onChange={handleToggleAddressForm}
                        name="addressFormToggle"
                      />
                    }
                    label={<AddressText />}
                  />
                );
              }}
            />
          </AgilisysSignUpFormSectionTitle>

          {toggleAddressForm && (
            <>
              <div>
                <TextInput
                  control={control}
                  name="addressLine1"
                  label={<AddressLabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-address-label",
                  })}
                  maxLength={50}
                />

                <SelectInput
                  control={control}
                  errors={errors}
                  name="addressType"
                  arr={addressTypeArr}
                  label={<TypeLabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-type-label",
                  })}
                />
              </div>

              <div>
                <TextInput
                  control={control}
                  name="addressLine2"
                  label={<Address2LabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-address-2-label",
                  })}
                  maxLength={50}
                />
              </div>

              <div>
                <TextInput
                  control={control}
                  name="cityCode"
                  label={<CityLabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-city-label",
                  })}
                  maxLength={30}
                />

                <TextInput
                  control={control}
                  name="zipCode"
                  label={<PostalCodeLabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-postal-code-label",
                  })}
                  maxLength={10}
                />
              </div>

              <div>
                {countryOptionArr && (
                  <AutoCompleteSelect
                    control={control}
                    errors={errors}
                    name="countryCode"
                    arr={countryOptionArr}
                    label={<CountryLabelText />}
                    setValue={setValue}
                    placeholder={intl.formatMessage({
                      id: "lang-country-label",
                    })}
                  />
                )}

                {/* {countryOptionArr && (
                  <SelectInput
                    control={control}
                    errors={errors}
                    name="countryCode"
                    arr={countryOptionArr}
                    label={<CountryLabelText />}
                    placeholder={intl.formatMessage({
                      id: "lang-country-label",
                    })}
                    country
                  />
                )} */}

                <TextInput
                  control={control}
                  name="stateCode"
                  label={<StateLabelText />}
                  placeholder={intl.formatMessage({
                    id: "lang-state-label",
                  })}
                  maxLength={30}
                />
              </div>
            </>
          )}
        </AgilisysSignUpFormSection>

        <KeepInTouchFormCheckBoxWrapper>
          <div>
            <Controller
              control={control}
              name="agreeToTerms"
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox onChange={field.onChange} name="agreeToTerms" />
                    }
                    label={<AgreeTermsLabelText />}
                  />
                );
              }}
            />

            <KeepInTouchFormTermsOfUse type="button" onClick={openTerms}>
              <TermsConditionsText />
            </KeepInTouchFormTermsOfUse>
          </div>
        </KeepInTouchFormCheckBoxWrapper>

        <FormHelperText error>
          {errors.agreeToTerms ? errors.agreeToTerms.message : " "}
        </FormHelperText>

        <KeepInTouchFormCheckBoxWrapper>
          <div>
            <MarketingOptInWrapper>
              <MarketingOptInLabel>
                <MailOptLabelText />
              </MarketingOptInLabel>

              <MailOptContentText />

              <Controller
                control={control}
                name="mailoptin"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        value="1"
                        onChange={field.onChange}
                        control={<Radio />}
                        label={<IAgreeText />}
                      />
                      <FormControlLabel
                        value="0"
                        onChange={field.onChange}
                        control={<Radio />}
                        label={<IDisagreeText />}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </MarketingOptInWrapper>
          </div>
        </KeepInTouchFormCheckBoxWrapper>

        <FormHelperText error>
          {errors.mailoptin ? errors.mailoptin.message : " "}
        </FormHelperText>

        <KeepInTouchFormCheckBoxWrapper>
          <div>
            <MarketingOptInWrapper>
              <MarketingOptInLabel>
                <MailUseLabelText />
              </MarketingOptInLabel>

              <MailUseContentText />

              <Controller
                control={control}
                name="personal_use"
                render={({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        value="1"
                        onChange={field.onChange}
                        control={<Radio />}
                        label={<IAgreeText />}
                      />
                      <FormControlLabel
                        value="0"
                        onChange={field.onChange}
                        control={<Radio />}
                        label={<IDisagreeText />}
                      />
                    </RadioGroup>
                  );
                }}
              />
            </MarketingOptInWrapper>
          </div>
        </KeepInTouchFormCheckBoxWrapper>

        <FormHelperText error>
          {errors.personal_use ? errors.personal_use.message : " "}
        </FormHelperText>

        <AgilisysSignUpFormButtons>
          <PrimaryButton
            buttonType="submit"
            disable={isLoading || activationLoading}
          >
            {isLoading || activationLoading ? (
              <SubmitLoadingText />
            ) : (
              <SubmitText />
            )}
          </PrimaryButton>
        </AgilisysSignUpFormButtons>
      </AgilisysSignUpFormContainer>
    </>
  );
};

export default AgilisysSignUpForm;
