import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import {
  CommonDescOne,
  CommonDescTwo,
  CommonTitleOne,
} from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { SecondaryButton, SwiperArrowButton } from "@components/buttons";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  SliderTwoArrowWrapper,
  SliderTwoButtonWrapper,
  SliderTwoHours,
  SliderTwoInformation,
  SliderTwoLeft,
  SliderTwoRight,
  SliderTwoWrapper,
} from "./SlidersStyling";

const SliderSeven = ({
 direction = "right",
 data,
 nameKey,
 categoryKey,
 descKey,
 linkKey,
 categoryIdKey,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  const [slideIndex, setSlideIndex] = useState(0);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
  };

  return (
    data && (
      <CommonContainer noMargin={isMobile} noPadding={isMobile}>
        <SliderTwoWrapper direction={direction}>
          <SliderTwoLeft>
            {data && (
              <Swiper
                {...custom}
                onSlideChange={(swiper) => {
                  setSlideIndex(swiper.realIndex);
                }}
                style={{ height: "100%" }}
                onResize={(swiper) => {
                  swiper.params.navigation.prevEl = navPrevRef.current;
                  swiper.params.navigation.nextEl = navNextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = navPrevRef.current;
                  swiper.params.navigation.nextEl = navNextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
              >
                {data?.map((image, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ImageWrapper
                        ratio={isDefault ? "3 / 2" : "auto"}
                        radius={
                          isDefault
                            ? 0
                            : direction === "left"
                              ? "0 2.5rem 2.5rem 0"
                              : "2.5rem 0 0 2.5rem"
                        }
                      >
                        <Image
                          src={image?.field_thumbnail[0]?.src}
                          alt={image?.field_thumbnail[0]?.alt}
                        />
                      </ImageWrapper>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </SliderTwoLeft>

          <SliderTwoRight direction={direction}>
            <SliderTwoInformation>
              <CommonDescTwo>
                <DangerouslyHtml value={data?.[slideIndex]?.[categoryKey]} />
              </CommonDescTwo>

              <CommonTitleOne>
                <DangerouslyHtml value={data?.[slideIndex]?.[nameKey]} />
              </CommonTitleOne>

              <CommonDescOne>
                <DangerouslyHtml
                  value={data?.[slideIndex]?.[descKey]}
                  className="ellipsis-6"
                />
              </CommonDescOne>

              {data?.[slideIndex]?.field_operation_hours_long && (
                <CommonDescTwo>
                  <SliderTwoHours>
                    <img
                      src={image.timeIcon.default}
                      alt="clock icon"
                      loading="lazy"
                    />

                    <DangerouslyHtml
                      value={data?.[slideIndex]?.field_operation_hours_long}
                    />
                  </SliderTwoHours>
                </CommonDescTwo>
              )}
            </SliderTwoInformation>

            <SliderTwoButtonWrapper>
              <SecondaryButton
                customstyle={{ maxWidth: "100%" }}
                buttonColor="white"
                linkTo={`/shop/store${
                  categoryIdKey
                    ? `/${data?.[slideIndex]?.[categoryIdKey]?.id}`
                    : `/${data?.[slideIndex]?.field_parent_id}`
                }${data?.[slideIndex]?.[linkKey]}`}
              >
                <ViewDetailsText />
              </SecondaryButton>

              {data?.length > 1 && (
                <SliderTwoArrowWrapper>
                  <SwiperArrowButton
                    ref={navPrevRef}
                    direction="left"
                    design="border"
                  />

                  <SwiperArrowButton
                    ref={navNextRef}
                    direction="right"
                    design="border"
                  />
                </SliderTwoArrowWrapper>
              )}
            </SliderTwoButtonWrapper>
          </SliderTwoRight>
        </SliderTwoWrapper>
      </CommonContainer>
    )
  );
};

export default SliderSeven;
