import React from "react";

import { BoxListFour } from "@components/box-list";
import { ErrorManage } from "@screens/index";
import { useGetEntertainmentEventItems } from "@utils/api-requests/EntertainmentRequests";

const EntertainmentEventItems = ({ type }) => {
  const {
    data: entertainmentEventItems,
    isLoading,
    isError,
    error,
  } = useGetEntertainmentEventItems(type);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <BoxListFour
        data={entertainmentEventItems}
        linkTo={`/entertainment/event/detail`}
        imageKey="field_thumbnail"
        nameKey="field_event_name"
        typeKey="field_category_id"
        dateKey="field_dates_and_times"
        descKey="field_event_short_description"
        html
      />
    </ErrorManage>
  );
};

export default EntertainmentEventItems;
