import React, { memo, useMemo } from 'react';

import { PageContainer, SubpageHero } from '@components/sub-page';
import { CommonContainer } from '@components/layout/ContainerStyle';
import { MomentumLayout, PageBottomContents } from '@components/layout';
import { CustomMetatag } from '@utils/metatags';

import OfferList from './OfferList';

const Offers = ({ pageInfo }) => {
  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: <OfferList />,
    },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <CommonContainer>
        <PageContainer data={pageData} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </div>
  );
};

export default memo(Offers);
