import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";

import {
  BoxInformationOneChildren,
  BoxInformationOneContainer,
  BoxInformationOneHeader,
  BoxInformationOneList,
  BoxInformationOneSubTitle,
  BoxInformationOneTitle,
  BoxInformationOneWrapper,
} from "./ItemStyling";
import { DangerouslyHtml } from ".";

const BoxInformationOne = ({
  information,
  title,
  subTitle,
  children,
  noShowBox,
}) => {
  return (
    <BoxInformationOneContainer>
      <BoxInformationOneChildren>{children}</BoxInformationOneChildren>

      {!noShowBox && (
        <BoxInformationOneWrapper>
          {title && (
            <BoxInformationOneTitle>
              <DangerouslyHtml value={title} />
            </BoxInformationOneTitle>
          )}

          {subTitle && (
            <BoxInformationOneSubTitle>
              <DangerouslyHtml value={subTitle} />
            </BoxInformationOneSubTitle>
          )}

          <BoxInformationOneList>
            {information
              .filter((el) => !el.disabled)
              ?.map((info) => {
                return info?.data && (
                  <li key={info.header}>
                    <BoxInformationOneHeader>
                      <DangerouslyHtml value={info.header} />
                    </BoxInformationOneHeader>

                    {info?.object ? (
                      info.data
                    ) : (
                      <CommonDescTwo>
                        <DangerouslyHtml value={info.data} />
                      </CommonDescTwo>
                    )}
                  </li>
                );
              })}
          </BoxInformationOneList>
        </BoxInformationOneWrapper>
      )}
    </BoxInformationOneContainer>
  );
};

export default BoxInformationOne;
