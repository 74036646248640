// Server bug
// to make it work locally we need to
// change "post" to "put" in the request
import axios from "axios";

import { newsletterSubmitUrl } from "./apiUrls";

// KeepInTouchFrom.jsx
export const postKeepInTouchSubmission = async (requestData) => {
  try {
    const response = await axios.post(
      newsletterSubmitUrl,
      { ...requestData },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};
