import React, { memo } from "react";
import { Controller } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";

import { mq } from "@utils/media-query/mediaQuery";

import { TextInputWrapper } from "./InputsStyling";

const TextInput = ({
  type = "text",
  control,
  name,
  label,
  placeholder,
  onClick,
  readOnly,
  endAdornment,
  customValue,
  maxLength,
}) => {
  return (
    <TextInputWrapper>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, ref, name },
          formState: { errors },
        }) => {
          return (
            <TextField
              variant="outlined"
              autoComplete={type === "password" ? "new-password" : "off"}
              margin="dense"
              type={type}
              label={label}
              onChange={onChange}
              onClick={onClick}
              value={customValue ? customValue : value}
              name={name}
              inputRef={ref}
              error={!!errors[name]}
              helperText={errors[name]?.message}
              fullWidth
              placeholder={placeholder}
              inputProps={{ maxLength: maxLength }}
              InputProps={{
                readOnly: readOnly,
                endAdornment: endAdornment && (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "1.4rem",

                  [mq("desktop")]: {
                    fontSize: "1.6rem",
                  },
                },
              }}
            />
          );
        }}
      />
    </TextInputWrapper>
  );
};

export default memo(TextInput);
