import React from "react";

import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { FiveEventsAreaDescription } from "@pages/entertainment/EntertainmentStyling";

const FiveEventsArea = ({ data }) => {
  return (
    <div>
      {data?.field_logo && (
        <ImageWrapper ratio="2 / 1" radius="2.5rem">
          <Image
            src={data?.field_logo[0]?.src}
            alt={data?.field_logo[0]?.alt}
          />
        </ImageWrapper>
      )}

      <FiveEventsAreaDescription>
        <DangerouslyHtml value={data?.field_5_event_stages_description} />
      </FiveEventsAreaDescription>
    </div>
  );
};

export default FiveEventsArea;
