import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import {
  howToReserveCategoriesUrl,
  splashBayDomeUrl,
  splashBayOfferRecommendationUrl,
  splashBayReserveUrl,
  splashBayServicesUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// SplashBay.jsx
export function useGetSplashBayOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashBayOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["splashBayOfferRecommendation", language],
    getSplashBayOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
      enabled: !!language,
    }
  );
}

// SplashBayDome.jsx, SplashBayDomeDetail.jsx
export function useGetSplashBayDomeList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashBayDomeList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayDomeUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["splashBayDomeList", language], getSplashBayDomeList, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// SplashBayServices.jsx
export function useGetSplashBayServicesList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashBayServicesList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayServicesUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["splashBayServices", language], getSplashBayServicesList, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// SplashBayServicesDetail.jsx
export function useGetSplashBayServiceDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashBayServiceDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayServicesUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["splashServiceDetail", language, localStorage, alias],
    getSplashBayServiceDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// SplashBayServicesDetail.jsx for left and right arrow
export function useGetSplashServiceAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashServiceAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayServicesUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["splashServiceAliasWGroup", language, localStorage],
    getSplashServiceAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// SplashBayReserveNow.jsx
export function useGetSplashBayReserveNow() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getSplashBayReserveNow = useCallback(async () => {
    const { status, data } = await axios.get(
      `${splashBayReserveUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["splashBayReserveNow", language], getSplashBayReserveNow, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// SplashBayReserveNow.jsx
export function useGetHowToReserveCategory() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getHowToReserveCategory = useCallback(async () => {
    const { status, data } = await axios.get(
      `${howToReserveCategoriesUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["howToReserveCategory", language], getHowToReserveCategory, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}
