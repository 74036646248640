import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  BoxInformationOne,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MomentumModal } from "@components/modal/modal-list";
import { ErrorManage } from "@screens/index";
import {
  TranslateDayMonth,
  changeEmptyValue,
  openOfferBookingSite,
} from "@utils/modify-data/modifyData";
import { useGetOfferDetail } from "@utils/api-requests/OfferRequests";
import { CustomMetatag } from "@utils/metatags";
import { BookNowText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { OffersIncludes } from ".";

const OffersDetail = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const intl = useIntl();
  const alias = useParams().offersAlias;

  const {
    data: offerDetail,
    isLoading,
    isError,
    error,
  } = useGetOfferDetail(alias);

  const offerRGuestUrl = useCallback(() => {
    openOfferBookingSite(offerDetail?.field_code);
  }, [offerDetail?.field_code]);

  const offersDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-offers",
        }),
        href: "/offers",
      },
      {
        id: 3,
        name: `${offerDetail?.field_offer_type.name} ${intl.formatMessage({
          id: "lang-offers",
        })}`,
        href: "/offers",
        tab: offerDetail?.field_offer_type.nameEn,
      },
      {
        id: 3,
        current: true,
        name: offerDetail?.field_offer_name,
      },
    ];

    return roomDetails;
  }, [
    intl,
    offerDetail?.field_offer_name,
    offerDetail?.field_offer_type.name,
    offerDetail?.field_offer_type.nameEn,
  ]);

  const information = [
    {
      id: 1,
      header: intl.formatMessage({
        id: "lang-book-between",
      }),
      object: true,
      data: (
        <>
          {/* <ComingSoonText /> */}

          <p>{TranslateDayMonth(offerDetail?.field_bookable_end_date)} ~</p>

          <p>{TranslateDayMonth(offerDetail?.field_applicable_end_date)}</p>
        </>
      ),
    },
    {
      id: 2,
      header: intl.formatMessage({
        id: "lang-stay-between",
      }),
      object: true,
      data: (
        <>
          {/* <ComingSoonText /> */}

          <p>{TranslateDayMonth(offerDetail?.field_stay_start_date)} ~</p>

          <p>{TranslateDayMonth(offerDetail?.field_stay_end_date)}</p>
        </>
      ),
    },
    {
      id: 3,
      header: intl.formatMessage({
        id: "lang-starts-from",
      }),
      data: changeEmptyValue(offerDetail?.field_starts_from_price),
    },
    {
      id: 4,
      header: intl.formatMessage({
        id: "lang-event-info",
      }),
      data: offerDetail?.field_description,
    },
  ];

  const sectionArray = [
    {
      id: 1,
      contents: (
        <BoxInformationOne
          information={information}
          noShowBox={offerDetail?.field_offer_type.id !== "45"}
        >
          <NotificationToggle data={offerDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={offersDetailBC}
            pageTitle={offerDetail?.field_offer_name}
            value={offerDetail?.field_offer_full_description}
            buttonEvent={offerRGuestUrl}
            buttonName={
              offerDetail?.field_offer_type.id === "45" && <BookNowText />
            }
          />
        </BoxInformationOne>
      ),
    },
    {
      id: 2,
      disabled:
        offerDetail?.field_terms_condition === "" &&
        (offerDetail?.field_offer_includes[0]?.field_name === null ||
          offerDetail?.length === 0),
      contents: <OffersIncludes data={offerDetail} />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <CustomMetatag metaData={offerDetail} />

      <DetailPageHero data={offerDetail} />

      <CommonContainer>
        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={offerDetail} />

        <MomentumLayout data={offerDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default OffersDetail;
