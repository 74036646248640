import React, { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";
import he from "he";

import { image } from "src/theme";
import { CommonTitleOne } from "@assets/styles/CommonStyles";
import {
  ResponsibleAccordionAll,
  ResponsibleAccordionDetails,
  ResponsibleAccordionSummary,
} from "@pages/casino/CasinoStyling";
import { DangerouslyHtml } from "@components/item";

const ResponsibleAccordion = ({ title, children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [expanded, setExpanded] = useState(true);

  const toggleAccordion = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <ResponsibleAccordionAll expanded={expanded} onChange={toggleAccordion}>
      <ResponsibleAccordionSummary
        expandIcon={
          <img
            src={image.notificationArrow.default}
            alt="arrow up"
            width={isDesktop ? 42 : 24}
            loading="lazy"
          />
        }
      >
        <CommonTitleOne>
          <DangerouslyHtml value={he.decode(title)} />
        </CommonTitleOne>
      </ResponsibleAccordionSummary>

      <ResponsibleAccordionDetails>{children}</ResponsibleAccordionDetails>
    </ResponsibleAccordionAll>
  );
};

export default ResponsibleAccordion;
