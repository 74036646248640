import React, { memo, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

import { SwiperArrowButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { CommonFullContainer } from "@components/layout/ContainerStyle";
import { MobileSlideText } from "@components/item/MobileScrollArrow";
import { doubleData } from "@utils/modify-data/modifyData";
import { IsDefault, IsDesktop } from "@utils/media-query/mediaQuery";

import {
  SliderOneArrowWrapper,
  SliderOneContainer,
  SliderOneInformation,
  SliderOnePreview,
  SliderOneSliderInformation,
  SliderOneSliderWrapper,
  SliderOneThumbnail,
  SliderOneThumbnailFigure,
  SliderOneThumbnailImage,
} from "./SlidersStyling";

const SliderOne = ({
  data,
  swiper,
  setSwiper,
  slideIndex,
  setSlideIndex,
  children,
  imageFieldName,
}) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [changedData, setChangedData] = useState();
  const [slidesPerView, setSlidesPerView] = useState(1.25);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const onClickThumbnail = (index) => {
    if (swiper) {
      swiper?.slideToLoop(index, 1000);
    }
  };

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      1200: {
        slidesPerView: 1.25,
      },
    },
    loopedSlides: slidesPerView,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
  };

  useEffect(() => {
    doubleData(slidesPerView, data, setChangedData);
  }, [data, slidesPerView]);

  return (
    changedData?.length > 0 && (
      <CommonFullContainer>
        <SliderOneContainer>
          <SliderOneArrowWrapper noShow={data.length <= 1}>
            <SwiperArrowButton ref={navPrevRef} direction="left" />

            <MobileSlideText />

            <SwiperArrowButton ref={navNextRef} direction="right" />
          </SliderOneArrowWrapper>

          <IsDesktop>
            <SliderOnePreview>
              <SliderOneInformation>{children}</SliderOneInformation>
            </SliderOnePreview>

            <SliderOneThumbnail>
              {data
                ?.filter((_, idx) => idx < slideIndex)
                .map((image, idx) => {
                  return (
                    <SliderOneThumbnailFigure
                      ratio="1 / 1"
                      width={66}
                      height={66}
                      key={idx}
                      pointer
                      onClick={() => onClickThumbnail(idx)}
                    >
                      <SliderOneThumbnailImage
                        src={`${process.env.REACT_APP_SERVER_URL}${image[imageFieldName]?.[0]?.src}`}
                        alt={image[imageFieldName]?.[0]?.alt}
                        radius="50%"
                      />
                    </SliderOneThumbnailFigure>
                  );
                })}
            </SliderOneThumbnail>
          </IsDesktop>

          {changedData && (
            <SliderOneSliderWrapper>
              <Swiper
                {...custom}
                onResize={(swiper) => {
                  setSlidesPerView(swiper.params.slidesPerView);
                  swiper.params.navigation.prevEl = navPrevRef.current;
                  swiper.params.navigation.nextEl = navNextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                onSwiper={setSwiper}
                onSlideChange={(swiper) => {
                  if (swiper.realIndex < data?.length) {
                    setSlideIndex(swiper.realIndex);

                    return;
                  }

                  if (swiper.realIndex >= data?.length * 2) {
                    setSlideIndex(swiper.realIndex - data?.length * 2);

                    return;
                  }

                  setSlideIndex(swiper.realIndex - data?.length);
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = navPrevRef.current;
                  swiper.params.navigation.nextEl = navNextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
              >
                {changedData?.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{ width: "60%", position: "relative" }}
                    >
                      <ImageWrapper
                        ratio="3 / 2"
                        radius={isDesktop ? "2.5rem" : "2.5rem 2.5rem 0 0"}
                      >
                        <Image
                          src={item?.[imageFieldName]?.[0]?.src}
                          alt={item?.[imageFieldName]?.[0]?.alt}
                          radius={isDesktop ? "2.5rem" : "2.5rem 2.5rem 0 0"}
                          scale={
                            isDesktop && index % data?.length !== slideIndex
                              ? "scale(0.6)"
                              : "scale(1)"
                          }
                        />
                      </ImageWrapper>

                      <IsDefault>
                        <SliderOneSliderInformation>
                          {children}
                        </SliderOneSliderInformation>
                      </IsDefault>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </SliderOneSliderWrapper>
          )}
        </SliderOneContainer>
      </CommonFullContainer>
    )
  );
};

export default memo(SliderOne);
