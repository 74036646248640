import React, { forwardRef, memo } from "react";

import { image } from "src/theme";

import { ArrowButton, ArrowImage } from "./ButtonsStyling";

const SwiperArrowButton = forwardRef((props, ref) => {
  return props.direction === "left" ? (
    <ArrowButton
      ref={ref}
      style={props.custom}
      type="button"
      design={props.design}
      className={props.className}
    >
      <ArrowImage
        src={image.sliderArrow.default}
        alt="left arrow icon"
        direction="left"
      />
    </ArrowButton>
  ) : (
    <ArrowButton
      ref={ref}
      style={props.custom}
      type="button"
      design={props.design}
      className={props.className}
    >
      <ArrowImage src={image.sliderArrow.default} alt="right arrow icon" />
    </ArrowButton>
  );
});

export default memo(SwiperArrowButton);
