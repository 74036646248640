import React from "react";
import TableSix from "@components/table/TableSix";

const GettingHereParkingFee = ({ data }) => {
  return (
    <>
      <TableSix data={data?.field_parking_fee} />
    </>
  );
};

export default GettingHereParkingFee;
