import React, { memo, useMemo } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { DangerouslyHtml, OfferRecommendation } from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { SliderSix } from "@components/sliders";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import {
  useGetMeetInformation,
  useGetMeetOfferRecommendation,
} from "@utils/api-requests/MeetRequests";

import { MeetConferenceAreaDesc } from "./MeetStyling";
import {
  GetInspiredMeetings,
  MeetServices,
  MeetSightseeing,
  MeetingSuccess,
  WeddingEvent,
  WhyIsSpecial,
} from ".";

const Meet = ({ pageInfo }) => {
  const { data: meetOfferRecommendation } = useGetMeetOfferRecommendation();

  const {
    data: meetInformation,
    isFetching,
    isError,
    error,
  } = useGetMeetInformation();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <WhyIsSpecial data={meetInformation?.field_why_we_are_special} />
      ),
    },
    {
      id: 2,
      disabled:
        meetInformation?.field_multiple_images === "" ||
        !meetInformation?.field_multiple_images,
      contents: (
        <CommonContainer>
          <SliderSix imageArr={meetInformation?.field_multiple_images} />

          <MeetConferenceAreaDesc>
            <DangerouslyHtml value={meetInformation?.field_description} />
          </MeetConferenceAreaDesc>
        </CommonContainer>
      ),
    },
    { id: 3, contents: <MeetingSuccess /> },
    {
      id: 4,
      titleDirection: "left",
      contents: <WeddingEvent />,
    },
    {
      id: 5,
      contents: <MeetServices />,
    },
    {
      id: 6,
      contents: <GetInspiredMeetings data={meetInformation} />,
    },
    {
      id: 7,
      contents: <MeetSightseeing />,
    },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <PageContainer data={pageData} sectionArray={sectionArray} />

      <PageBottomContents>
        <OfferRecommendation data={meetOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default memo(Meet);
