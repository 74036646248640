import React from "react";
import { Navigate } from "react-router-dom";

const BlockRouter = ({ ipInformation, children }) => {
  if (ipInformation?.countryCode !== "CN") {
    return children;
  }

  return <Navigate to="/" />;
};

export default BlockRouter;
