import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  DangerouslyHtml,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  ResponsibleAccordionItem,
  ResponsibleAccordionTitle,
} from "@pages/casino/CasinoStyling";
import { CasinoEvents } from "@pages/casino";
import { ErrorManage } from "@screens/index";
import { useGetCasinoResponsibleGaming } from "@utils/api-requests/CasinoRequests";
import { CustomMetatag } from "@utils/metatags";

import {
  MythsWithGambling,
  PledgeToOurEmployees,
  PledgeToOurPatrons,
  TogetherMakeDifference,
  UnderageGaming,
  WhenNoLongerGame,
} from ".";

export const ResponsibleCommonItems = ({ data }) => {
  return data?.map((item) => {
    return (
      <ResponsibleAccordionItem key={item.field_name}>
        <ResponsibleAccordionTitle>{item.field_name}</ResponsibleAccordionTitle>

        <CommonDescOne>
          <DangerouslyHtml value={item.field_full_description} />
        </CommonDescOne>
      </ResponsibleAccordionItem>
    );
  });
};

const ResponsibleGamingDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: casinoResponsibleGamings,
    isLoading,
    isError,
    error,
  } = useGetCasinoResponsibleGaming();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
      },
      {
        id: 3,
        current: true,
        name: casinoResponsibleGamings?.field_page_name,
      },
    ];

    return roomDetails;
  }, [casinoResponsibleGamings?.field_page_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <PledgeToOurPatrons data={casinoResponsibleGamings} />,
    },
    {
      id: 2,
      contents: <PledgeToOurEmployees data={casinoResponsibleGamings} />,
    },
    { id: 3, contents: <WhenNoLongerGame data={casinoResponsibleGamings} /> },
    { id: 4, contents: <MythsWithGambling data={casinoResponsibleGamings} /> },
    {
      id: 5,
      contents: <TogetherMakeDifference data={casinoResponsibleGamings} />,
    },
    { id: 6, contents: <UnderageGaming data={casinoResponsibleGamings} /> },
    {
      id: 7,
      contents: <CasinoEvents />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={casinoResponsibleGamings} />

      <DetailPageHero data={casinoResponsibleGamings} />

      <CommonContainer>
        <>
          <NotificationToggle data={casinoResponsibleGamings} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={casinoResponsibleGamings?.field_page_name}
            value={casinoResponsibleGamings?.field_full_description}
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={casinoResponsibleGamings} />

        <MomentumLayout data={casinoResponsibleGamings} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default ResponsibleGamingDetail;
