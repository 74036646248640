import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import {
  CommonDescFour,
  CommonDescTwo,
  CommonTitleOne,
} from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton, SecondaryButton } from "@components/buttons";
import { SliderOne } from "@components/sliders";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import {
  TowerPreviewButtonWrapper,
  TowerPreviewInfo,
} from "@pages/stay/StayStyling";
import {
  ViewAllText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetHomeRoomSection } from "@utils/api-requests/HomeRequests";

import { HomeButtonWrapper } from "./HomeStyling";

const HomeRoom = () => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [swiper, setSwiper] = useState();
  const [slideIndex, setSlideIndex] = useState(0);

  const { data: homeRoomSection } = useGetHomeRoomSection();

  return (
    <CommonContentButtonWrapper>
      <SliderOne
        data={homeRoomSection}
        swiper={swiper}
        setSwiper={setSwiper}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        imageFieldName="field_thumbnail"
      >
        <TowerPreviewInfo>
          <CommonDescFour>
            <DangerouslyHtml
              value={homeRoomSection?.[slideIndex]?.field_category}
            />
          </CommonDescFour>

          <CommonTitleOne>
            <DangerouslyHtml
              value={homeRoomSection?.[slideIndex]?.field_room_title}
            />
          </CommonTitleOne>

          <CommonDescTwo>
            <DangerouslyHtml
              className={isDesktop ? "ellipsis-6" : "ellipsis-4"}
              value={homeRoomSection?.[slideIndex]?.field_full_description}
            />
          </CommonDescTwo>
        </TowerPreviewInfo>

        <TowerPreviewButtonWrapper>
          <SecondaryButton
            buttonColor="white"
            noArrow
            linkTo={`${homeRoomSection?.[slideIndex]?.url_alias}`}
          >
            <ViewDetailsText />
          </SecondaryButton>
        </TowerPreviewButtonWrapper>
      </SliderOne>

      <CommonContainer>
        <HomeButtonWrapper>
          <PrimaryButton noArrow linkTo="/stay">
            <ViewAllText />
          </PrimaryButton>
        </HomeButtonWrapper>
      </CommonContainer>
    </CommonContentButtonWrapper>
  );
};

export default HomeRoom;
