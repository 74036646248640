import React from "react";

import { Image, ImageWrapper } from "@components/item/ImageRelated";

import { ListOneContainer, ListOneDesc, ListOneItem } from "./LayoutStyling";
import { DangerouslyHtml } from "@components/item";
import {CommonContentButtonWrapper} from "@components/layout/ContainerStyle";

const ListOne = ({ data, imageKey = "image", titleKey = "title", detailButton }) => {
  return (
    <CommonContentButtonWrapper>
      {data?.length > 0 && (
        <ListOneContainer>
          {data?.map((item, idx) => {
            return (
              <ListOneItem key={item[titleKey]} length={data?.length}>
                <ImageWrapper height="7.8rem" width="7.8rem">
                  <Image
                    custom={{objectFit: "contain"}}
                    src={item?.[imageKey]?.src}
                    alt={item?.[imageKey]?.alt}
                  />
                </ImageWrapper>

                <header>
                  <DangerouslyHtml value={item[titleKey]}/>
                </header>

                {item.description && (
                  <ListOneDesc>
                    <DangerouslyHtml value={item.description}/>
                  </ListOneDesc>
                )}

              </ListOneItem>
            );
          })}
        </ListOneContainer>
      )}

    </CommonContentButtonWrapper>
  );
};

export default ListOne;
