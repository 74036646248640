import React, { useMemo, useState } from "react";

import { DangerouslyHtml } from "@components/item";
import { SliderSix } from "@components/sliders";
import { ListWithTabs } from "@components/list";
import { SplashBayDomeSectionDesc } from "@pages/splash-bay/SplashBayStyling";

const SplashBayDomeSection = ({ data }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const featureNames = useMemo(() => {
    const result = data?.map((item, idx) => {
      return {
        title: item.category,
        category_id: idx,
      };
    });

    return result;
  }, [data]);

  return (
    <ListWithTabs
      tabArr={featureNames}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
    >
      <SliderSix
        imageArr={data?.[currentTab].gallery}
        currentTab={currentTab}
      />

      <SplashBayDomeSectionDesc>
        <DangerouslyHtml value={data?.[currentTab].description} />
      </SplashBayDomeSectionDesc>
    </ListWithTabs>
  );
};

export default SplashBayDomeSection;
