import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import {
  CommonDescTwo,
  CommonTitleFour,
  CommonTitleFive,
  CommonTitleThree,
  CommonTitleTwo,
  infoZIndex,
  CommonTitleSeven,
} from "@assets/styles/CommonStyles";
import { galleryGrid } from "@components/layout/LayoutStyling";
import { mq, mqMax } from "@utils/media-query/mediaQuery";
import {isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";
import { CardActionArea } from "@mui/material";

// STYLE: BoxListOne.jsx

export const BoxListOneItem = styled(Link)(() => ({
  maxWidth: "36rem",

  [mqMax("mobileSmall")]: {
    flex: "1 0 calc(100vw - 3.2rem)",
  },
}));

export const BoxListOneCardArea = styled(CardActionArea)(() => ({
  height: "48rem",
  cursor: "default",
  [mq("mobile")]: {
    height: "39.4rem",
  },
}));

export const BoxListOneTitle = styled(CommonTitleFour)(({ theme }) => ({
  marginBottom: "2rem",
  fontWeight: theme.fontWeight.Black,
}));

// STYLE: BoxListTwo.jsx
export const BoxListTwoContainer = styled.div(() => ({
  position: "relative",
  display: "flex",
  gap: "0 3rem",
  width: "100%",
  paddingBottom: "2rem",
  overflowX: "auto",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  [mq("desktop")]: {
    paddingBottom: 0,
  },
}));

export const BoxListTwoItem = styled.div(() => ({
  flex: 1,
  minWidth: "30rem",
  position: "relative",
  borderRadius: "2.5rem",
  overflow: "hidden",

  [mq("desktop")]: {
    minWidth: "auto",
  },
}));

export const BoxListTwoContents = styled.header(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3rem 0",
  position: "absolute",
  bottom: "6rem",
  minWidth: "90%",
  left: "50%",
  transform: "translateX(-50%)",
  color: theme.color.white,
  zIndex: infoZIndex,
}));

// STYLE: BoxListThree.jsx
export const BoxListThreeContainer = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "5rem 0",
  width: "100%",

  [mq("desktop")]: {
    gap: "8rem 0",
  },
}));

export const BoxListThreeWrapper = styled.li(
  ({ reverse, alignItems = "center" }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem 0",
    alignItems: "flex-start",

    "& > *": {
      flex: 1,
    },

    [mq("desktop")]: {
      gap: "0 12rem",
      alignItems: alignItems,
      flexDirection: reverse ? "row-reverse" : "row",
    },
  })
);

export const BoxListThreeInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  width: "100%",

  [mq("desktop")]: {
    gap: "3rem 0",
  },
}));

export const BoxListThreeImageSource = styled.div(({ theme, left }) => ({
  display: "flex",
  justifyContent: "flex-start",
  color: theme.color.secondary_grey,
  marginTop: "0.4rem",

  [mq("desktop")]: {
    justifyContent: left ? "flex-end" : "flex-start",
  },
}));

export const BoxListThreeBoldTitle = styled(CommonTitleThree)(({ theme }) => ({
  fontWeight: theme.fontWeight.Black,
}));

export const BoxListThreeSubTitle = styled(CommonTitleFour)(({ theme }) => ({
  fontWeight: theme.fontWeight.regular,
}));

export const BoxListThreeStep = styled(CommonTitleFive)(({ theme }) => ({
  color: theme.color.primary_grey,
  textTransform: "uppercase",
}));

// STYLE: BoxListFour.jsx
export const BoxListFourContainer = styled.ul(() => ({
  width:'100%',
  display: "grid",
  gap: "4rem",
  gridTemplateColumns: "repeat(1, 1fr)",

  [mq("tablet")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [mq("desktop")]: {
    paddingBottom: "6rem",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

export const BoxListFourItemContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  boxShadow: theme.shadow.primary,
  borderRadius: "1.5rem",
  overflow: "hidden",
}));

export const BoxListFourItemInformation = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  width: "100%",
  padding: "1.6rem",
  background: theme.color.white,

  [mq("desktop")]: {
    minHeight: "32rem",
    padding: "3rem",
  },
}));

export const BoxListFourItemIn = styled.div(({ centered }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: centered ? "center" : "flex-start",
  textAlign: centered ? "center" : "left",
  gap: "1.4rem 0",
  marginBottom: "1.4rem",
}));

export const BoxListFourItemTitle = styled(CommonTitleFour)(() => ({
  lineHeight: "2.4rem",
  maxHeight: "4.8rem",

  [mq("desktop")]: {
    lineHeight: "3.2rem",
    maxHeight: "6.4rem",
  },
}));

export const BoxListFourItemType = styled(CommonTitleSeven)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  alignItems: "flex-start",
  gap: "0 0.2rem",
  fontWeight: 700,
  lineHeight: "1.5",
  color: theme.color.primary_grey,
  textTransform: "uppercase",
  letterSpacing: "0.12rem",
  fontSize: "1.6rem",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",

  span: {
    display: "flex",
    lineHeight: 1,
    gap: "0 0.2rem",

    "&>div": {
      paddingBottom: 0,
    },
  },

  [mq("desktop")]: {
    fontSize: "1.6rem",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export const BoxListFourDate = styled.div(() => ({
  fontStyle: "italic",
}));

export const BoxListFourDesc = styled(CommonDescTwo)(() => ({
  minHeight: "4.6rem",
}));

// STYLE: BoxListFive.jsx
export const BoxListFiveContainer = styled.div(({ length }) => ({
  width: "100%",
  display: "grid",
  gap: "2.5rem",
  gridTemplateColumns: "repeat(1, 1fr)",

  [mq("tablet")]: {
    gridTemplateColumns: length === 1 ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
  },

  [mq("desktop")]: {
    gridTemplateAreas: galleryGrid(length),
    gridTemplateColumns: "auto",
  },
}));

export const BoxListFiveGridArea = styled.section(() => ({
  [mq("desktop")]: {
    "&:nth-of-type(1)": {
      gridArea: "a",
    },

    "&:nth-of-type(2)": {
      gridArea: "b",
    },

    "&:nth-of-type(3)": {
      gridArea: "c",
    },

    "&:nth-of-type(4)": {
      gridArea: "d",
    },

    "&:nth-of-type(5)": {
      gridArea: "e",
    },

    "&:nth-of-type(6)": {
      gridArea: "f",
    },
  },
}));

export const BoxListFiveInformation = styled.div(
  ({ theme, align = "column" }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem 6rem",
    position: "absolute",
    left: "2.4rem",
    bottom: "2.4rem",
    zIndex: infoZIndex,
    color: theme.color.white,
    width: "calc(100% - 8rem)",

    [mq("desktop")]: {
      flexDirection: align,
      alignItems: align === "column" ? "flex-start" : "flex-end",
      left: "4rem",
      bottom: "4rem",
    },
  })
);

export const BoxListFiveTexts = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  maxWidth: "100%",

  [mq("desktop")]: {
    minWidth: "36rem",
  },
}));

export const BoxListFiveTitle = styled(CommonTitleTwo)(() => ({
  textTransform: "uppercase",
}));

export const BoxListFiveDesc = styled(CommonDescTwo)(() => ({
  [mq("desktop")]: {
    maxWidth: "100%",
  },
}));

// STYLE: BoxListSix.jsx
export const BoxListSixContainer = styled.div(() => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent:'center',
  width: "100%",
  gap: "0 2.4rem",
  overflowX: "auto",
  paddingBottom: "2rem",

  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const BoxListSixGrid = styled.div(() => ({
  gap: "3.2rem",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",

  [mq("desktop")]: {
    justifyContent: "center",
  },
}));

export const BoxListSixItem = styled.div(({ ratio, scroll }) => ({
  flex: "0 1 50rem",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: ratio ? ratio : "1 / 1",
  borderRadius: "1.5rem",
  overflow: "hidden",
  maxWidth: scroll ? "30rem" : "auto",
  minWidth: scroll ? "30rem" : "auto",

  [mq("desktop")]: {
    flex: "0 1 30%",
    maxWidth: 'auto',
    minWidth:'30%',

    "&:hover div": {
      opacity: 1,
    },
  },
}));

export const BoxListSixInformation = styled.div(({ theme }) => ({
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "2rem 0",
  bottom: 0,
  left: 0,
  width: "100%",
  minHeight: "50%",
  textAlign: "center",
  color: "white",
  borderRadius: "1.5rem",
  background: theme.color.primary_color,
  transition: "all 0.3s",
  padding: "1.6rem 0",

  [mq("desktop")]: {
    opacity: 0,
    padding: 0,
    background: theme.opacity.colored_overlay,
    minHeight: "100%",
  },
}));

export const BoxListSixTexts = styled.header(() => ({
  maxWidth: "80%",
}));

export const BoxListSixName = styled(CommonTitleTwo)(({ theme }) => ({
  marginBottom: "1rem",
  fontWeight: theme.fontWeight.bold,
}));

export const BoxListSixDetailLink = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "0 1rem",
  minWidth: "24rem",
  padding: "1rem 3rem",
  color: theme.color.primary_color,
  fontSize: isJapanese ? "1.8rem" : "2rem",
  background: theme.color.white,
  borderRadius: "1rem",
  fontWeight: theme.fontWeight.bold,
  textTransform: "uppercase",
  fontFamily: theme.fontFamily.primaryFont,

  "& img": {
    transition: "all 0.3s",
  },

  "&:hover img": {
    marginLeft: "1rem",
  },
}));

// STYLE: BoxListSeven.jsx
export const BoxListSevenContainer = styled.div(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  gap: "2rem",

  [mq("tablet")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [mq("desktop")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

export const BoxListSevenInforamtion = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
  position: "absolute",
  left: "2rem",
  bottom: "4rem",
  maxWidth: "calc(100% - 4rem)",
  color: theme.color.white,
  zIndex: infoZIndex,
}));

export const BoxListSevenTitle = styled(CommonTitleTwo)(() => ({
  textTransform: "uppercase",
}));

// STYLE: BoxListEight.jsx

export const BoxListEightItem = styled.div(() => ({
  position: "relative",
  aspectRatio: "5 / 6",
  borderRadius: "1.5rem",
  overflow: "hidden",

  "&:hover > div": {
    opacity: 1,
  },
}));

export const BoxListEightInformation = styled.div(({ theme }) => ({
  opacity: 1,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "3rem 0",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  textAlign: "center",
  padding: "0 2rem",
  color: "white",
  borderRadius: "1.5rem",
  background: theme.opacity.colored_overlay,
  transition: "all 0.3s",

  [mq("desktop")]: {
    opacity: 0,
  },
}));

export const BoxListEightDesc = styled(CommonDescTwo)(() => ({
  maxWidth: "80%",
}));

// STYLE: BoxListNine.jsx
export const BoxListNineContainer = styled.div(() => ({
  display: "flex",
  gap: "0 2.4rem",
  padding: "1rem",
  width: "100%",
  overflowX: "auto",
  paddingBottom: "2rem",

  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

export const BoxListNineItem = styled.div(({ theme }) => ({
  flex: 1,
  padding: "4.8rem 2.4rem",
  boxShadow: theme.shadow.primary,
  borderRadius: "2.5rem",

  minWidth: "32rem",
}));

export const BoxListNineTitle = styled(CommonTitleTwo)(({ theme }) => ({
  color: theme.color.primary_grey,
  textAlign: "center",
  margin: "2.8rem 0 4rem",
}));

export const BoxListNineBenefitPoint = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  paddingLeft: "3rem",

  "&>li": {
    listStyle: "initial",
  },
}));
