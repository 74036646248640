import React from "react";

import { CommonDescOne, CommonTitleFour } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsPrivacyPolicy } from "@utils/api-requests/AboutUsRequests";
import { PrivacyPolicyText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  AboutUsPrivacyPolicyContainer,
  AboutUsPrivacyPolicyInformation,
} from "./AboutUsStyling";

const AboutUsPrivacyPolicy = () => {
  const {
    data: aboutUsPrivacyPolicy,
    isError,
    error,
  } = useGetAboutUsPrivacyPolicy();

  return (
    <ErrorManage isError={isError} error={error}>
      <AboutUsPrivacyPolicyContainer>
        <AboutUsPrivacyPolicyInformation>
          <CommonTitleFour>
            <DangerouslyHtml
              value={aboutUsPrivacyPolicy?.field_thumbnail_title}
            />
          </CommonTitleFour>

          <CommonDescOne>
            <DangerouslyHtml
              value={aboutUsPrivacyPolicy?.field_main_description}
            />
          </CommonDescOne>

          <PrimaryButton linkTo="/about-us/privacy-policy" noArrow>
            <PrivacyPolicyText />
          </PrimaryButton>
        </AboutUsPrivacyPolicyInformation>

        <ImageWrapper ratio="7 / 6" radius="2.5rem">
          <Image
            src={aboutUsPrivacyPolicy?.field_thumbnail[0]?.src}
            alt={aboutUsPrivacyPolicy?.field_thumbnail[0]?.alt}
          />
        </ImageWrapper>
      </AboutUsPrivacyPolicyContainer>
    </ErrorManage>
  );
};

export default AboutUsPrivacyPolicy;
