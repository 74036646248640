import React, { memo, useMemo } from "react";
import { useIntl } from 'react-intl';
import { Helmet } from "react-helmet-async";
import { useTheme } from "@emotion/react";
import {LOCAL_STORAGE_LANGUAGE} from "@utils/api-requests/static-data";

const CustomMetatag = ({ children, metaData }) => {
  const intl = useIntl();
  const theme = useTheme();
  const metaInformation = useMemo(() => {
    if (
      metaData &&
      metaData?.field_meta_information !== "" &&
      metaData?.field_meta_information
    ) {
      return JSON.parse(metaData.field_meta_information);
    }

    return undefined;
  }, [metaData]);

  const siteTitle = intl.formatMessage({ id: 'lang-title'});
  const siteDescription = intl.formatMessage({ id: 'lang-description'});

  return metaInformation ? (
    <Helmet>

      <title>{metaInformation?.title || siteTitle}</title>

      <meta name="description" content={metaInformation?.description || siteDescription}/>

      {/* Naver, Kakaotalk, Facebook Preview Image & Description */}
      <meta property="og:type" content={metaInformation?.og_type || "website"}/>
      <meta property="og:url" content={metaInformation?.og_url || window.location.href}/>
      <meta property="og:title" content={metaInformation?.og_title || metaInformation?.title || siteTitle}/>
      <meta property="og:image" content={metaInformation?.og_image || theme.image.ogMedia} />
      <meta property="og:image:url" content={metaInformation?.og_image || theme.image.ogMedia}/>
      <meta property="og:video" content={metaInformation?.og_video}/>
      <meta
        property="og:description"
        content={metaInformation?.og_description || metaInformation?.description || siteDescription}
      />
      <meta property="og:site_name" content={metaInformation?.og_site_name || "INSPIRE Entertainment Resort"}/>
      <meta property="og:locale" content={metaInformation?.og_locale || localStorage.getItem(LOCAL_STORAGE_LANGUAGE)}/>

      {/* Twitter */}
      <meta name="twitter:card" content={metaInformation?.twitter_cards_type}/>
      <meta
        name="twitter:title"
        content={metaInformation?.twitter_cards_title || metaInformation?.title || siteTitle}
      />
      <meta
        name="twitter:description"
        content={metaInformation?.twitter_cards_description || metaInformation?.description || siteDescription}
      />
      <meta
        name="twitter:image"
        content={metaInformation?.twitter_cards_image || theme.image.ogMedia}
      />
      <meta
        name="twitter:url"
        content={metaInformation?.twitter_cards_page_url || window.location.href}
      />

      {children}
    </Helmet>
  ) : (
    <Helmet>
      <title>{siteTitle}</title>
    </Helmet>
  );
};

export default memo(CustomMetatag);
