import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Backdrop } from "@mui/material";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import {
  BookYourInspire,
  DangerouslyHtml,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { SliderSix } from "@components/sliders";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  CommonContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import { MainBannerIFrame } from "@pages/home/HomeStyling";
import { ErrorManage } from "@screens/index";
import {
  useGetEventsUuidGroup,
  useGetWeddingEventDetail,
} from "@utils/api-requests/MeetRequests";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { CustomMetatag } from "@utils/metatags";

import {
  EventPackages,
  FacilityServices,
  FamilyParty,
  PaebaekRoomInformation,
  PlanningCatering,
  PrivateParty,
  WeddingThemes,
} from ".";

const WeddingEventDetail = () => {
  const intl = useIntl();
  const meetTypes = useParams().meetTypes;

  const [currentIndex, setCurrentIndex] = useState(0);

  const [openIFrame, setOpenIFrame] = useState(false);

  const {
    data: weddingEventDetail,
    isFetching,
    isError,
    error,
  } = useGetWeddingEventDetail(meetTypes);

  const { data: eventsUuidGroup } = useGetEventsUuidGroup();

  const firstRender = useMemo(() => {
    if (
      weddingEventDetail?.field_multiple_images !== "" ||
      weddingEventDetail?.field_multiple_images
    ) {
      return <SliderSix imageArr={weddingEventDetail?.field_multiple_images} />;
    }

    if (weddingEventDetail?.field_display_party_info === "On") {
      return <FamilyParty data={weddingEventDetail?.field_family_parties} />;
    }

    if (weddingEventDetail?.field_display_package_info === "On") {
      return <EventPackages data={weddingEventDetail?.field_package_list} />;
    }
  }, [weddingEventDetail]);

  const secondRender = useMemo(() => {
    if (
      weddingEventDetail?.field_facilities_services?.length > 0 &&
      weddingEventDetail?.field_facilities_services?.[0]?.name !== null
    ) {
      return (
        <FacilityServices
          data={weddingEventDetail?.field_facilities_services}
        />
      );
    }

    if (weddingEventDetail?.field_party_types?.[0]?.name !== null) {
      return <PrivateParty data={weddingEventDetail?.field_party_types} />;
    }
  }, [weddingEventDetail]);

  const thirdRender = useMemo(() => {
    if (weddingEventDetail?.field_field_display_wedding_info === "On") {
      return <WeddingThemes data={weddingEventDetail?.field_themes} />;
    }

    if (weddingEventDetail?.field_display_room_info === "On") {
      return <PaebaekRoomInformation data={weddingEventDetail} />;
    }

    if (weddingEventDetail?.field_full_description !== "") {
      return (
        <CommonDescOne>
          <DangerouslyHtml value={weddingEventDetail?.field_full_description} />
        </CommonDescOne>
      );
    }
  }, [weddingEventDetail]);

  const fourthRender = useMemo(() => {
    if (weddingEventDetail?.field_planning_catering?.[0]?.name !== null) {
      return (
        <PlanningCatering data={weddingEventDetail?.field_planning_catering} />
      );
    }
  }, [weddingEventDetail]);

  const sectionArray = [
    {
      id: 1,
      contents: firstRender,
    },
    {
      id: 2,
      contents: secondRender,
    },
    {
      id: 3,
      noMargin: true,
      titleDirection: weddingEventDetail?.field_meet_types.includes("Wedding")
        ? "left"
        : "center",
      contents: thirdRender,
    },
    {
      id: 4,
      disabled: weddingEventDetail?.field_planning_catering[0]?.name === null,
      contents: fourthRender,
    },
  ];

  const eventDetailBC = useMemo(() => {
    const eventDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-meet",
        }),
        href: "/meet",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-wedding-and-event",
        }),
        href: "/meet",
        tab: "wedding-event",
      },
      { id: 4, current: true, name: weddingEventDetail?.field_event_title },
    ];

    return eventDetails;
  }, [intl, weddingEventDetail?.field_event_title]);

  useEffect(() => {
    if (eventsUuidGroup) {
      setCurrentIndex(
        eventsUuidGroup?.findIndex((item) => item === "/" + meetTypes)
      );
    }
  }, [eventsUuidGroup, meetTypes]);

  const handleClose = () => {
    setOpenIFrame(false);
  };
  const handleOpen = () => {
    setOpenIFrame(true);
  };

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openIFrame}
        onClick={handleClose}
      >
        <MainBannerIFrame
          title="Meet-Booking"
          src={weddingEventDetail?.field_widget?.[0]?.field_link.uri}
          frameborder="0"
          allowfullscreen
        />
      </Backdrop>

      <CustomMetatag metaData={weddingEventDetail} />

      <DetailPageHero data={weddingEventDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={weddingEventDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={eventDetailBC}
            pageTitle={weddingEventDetail?.field_event_title}
            value={weddingEventDetail?.field_description}
            prevUUid={eventsUuidGroup?.[currentIndex - 1]}
            nextUUid={eventsUuidGroup?.[currentIndex + 1]}
            url={`/meet/wedding-events`}
            // buttonName={<BookYourEventText />}
            // buttonEvent={handleOpen}
          />
        </>

        <DetailPageContainer
          data={FilterDetailPageData(meetTypes)}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire
            data={weddingEventDetail}
            buttonEvent={handleOpen}
            noButton
          />
        </CommonMobileContainer>

        <OfferRecommendation data={weddingEventDetail} />

        <MomentumLayout data={weddingEventDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default WeddingEventDetail;
