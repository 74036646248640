import {
  CommonDescOne,
  CommonDescTwo,
  CommonTitleOne,
  CommonTitleThree,
  infoZIndex,
  shareButtonZIndex,
} from "@assets/styles/CommonStyles";
import {isJapanese, isKorean} from "@utils/lanugauge-settings/LanguageOptions";
import { mq } from "@utils/media-query/mediaQuery";

const { default: styled } = require("@emotion/styled");

// STYLE: DetailPageHero.jsx
export const DetailPageHeroContainer = styled.div(({ small, url }) => ({
  position: "relative",
  width: "100%",
  height: "50vh",
  maxHeight: "57.4rem",

  backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",

  [mq("desktop")]: {
    aspectRatio: small ? "6 / 1" : "3 / 1",
    height: "100%",
  },
}));

export const DetailPageHeroIn = {
  height: "100%",
};

// STYLE: DetailPageContainer.jsx
export const DetailPageContainerWrapper = styled.section(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "6rem 0",

  [mq("desktop")]: {
    gap: "12rem 0",
  },
}));

export const DetailPageContainerIn = styled.section(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "6rem 0",
}));

export const DetailPageContainerInformation = styled.div(({ noShow }) => ({
  marginBottom: noShow ? 0 : "3.2rem",

  [mq("desktop")]: {
    marginBottom: noShow ? 0 : "7.2rem",
  },
}));

export const DetailPageTitle = styled(CommonTitleOne)(({ textAlign }) => ({
  textAlign: textAlign ? textAlign : "center",
  fontSize: "3.2rem",
}));

export const DetailPageDesc = styled.div(({ textAlign }) => ({
  textAlign: textAlign ? textAlign : "center",
  maxWidth: textAlign === "left" ? "100%" : "90%",
  margin: "2rem auto 0",
}));

export const DetailPageHeroShareButton = styled.button(({ theme }) => ({
  position: "absolute",
  bottom: "-2rem",
  right: "2.4rem",
  width: "4rem",
  height: "4rem",
  borderRadius: "50%",
  background: theme.color.white,
  boxShadow: theme.shadow.primary,
  zIndex: shareButtonZIndex,

  border: `1px solid ${theme.color.primary_color}`,

  img: {
    width: "2rem",
  },

  [mq("tablet")]: {
    width: "5.6rem",
    height: "5.6rem",
    bottom: "-2.8rem",
  },

  [mq("desktop")]: {
    right: 0,
    width: "7.2rem",
    height: "7.2rem",
    bottom: "-3.6rem",
    border: "none",

    img: {
      width: "2.8rem",
    },
  },
}));

export const DetailPageHeroButtonIn = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
}));

// STYLE: PageContainer.jsx
export const PageContainerWrapper = styled.section(() => ({
  "&:not(:last-of-type)": {
    marginBottom: "6rem",
  },

  [mq("desktop")]: {
    "&:not(:last-of-type)": {
      marginBottom: "12rem",
    },
  },
}));

export const PageContainerSectionName = styled.div(
  ({ textAlign = "center", noMarginBottom }) => ({
    marginBottom: "2.4rem",
    textAlign: textAlign,

    [mq("desktop")]: {
      marginBottom: noMarginBottom ? 0 : "7.2rem",
    },
  })
);

export const PageContainerSectionDesc = styled(CommonDescTwo)(
  ({ direction }) => ({
    maxWidth: "100%",
    fontSize: "1.4rem",
    margin: direction !== "left" ? "0.8rem auto 0" : "0.8rem 0 0",

    [mq("desktop")]: {
      maxWidth: "60%",
    },
  })
);

// STYLE: SubpageHero.jsx
export const SubpageContainer = styled.div(() => ({
  marginBottom: "2.4rem",

  [mq("desktop")]: {
    marginBottom: "7rem",
  },
}));

export const SubPageHeroImage = styled.div(({ url, small }) => ({
  position: "relative",
  width: "100%",
  height: "50vh",
  maxHeight: "19rem",
  overflow: "hidden",
  backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}${url}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",

  [mq("desktop")]: {
    aspectRatio: "3 / 1",
    height: "100vh",
    minHeight: small ? "25rem" : "44.4rem",
    maxHeight: small ? "25rem" : "44.4rem",
  },
}));

export const SubpageTitleWrapper = styled(CommonTitleThree)(({ theme }) => ({
  position: "absolute",
  zIndex: infoZIndex,
  left: "50%",
  top: "auto",
  transform: "translate(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "1rem",
  color: theme.color.white,
  maxWidth: "153.6rem",
  width: "100%",
  paddingInline: "2.4rem",
  letterSpacing: "0.2rem",
  height: "fit-content",
  bottom: "4rem",

  [mq("desktop")]: {
    gap: "1rem 2rem",
    bottom: "8rem",
    fontSize: "3.2rem",
  },

  span: {
    flex: 1,
    display: "block",
    width: "100%",
    height: "0.2rem",
    background: `rgba(255, 255, 255, 18%)`,
  },

}));

export const SubpageDescription = styled(CommonDescTwo)(() => ({
  lineHeight: 1.5,
  fontSize: "1.4rem",
  maxWidth: "90%",
  margin: "6rem auto 0",
  wordBreak: isJapanese || isKorean ? "keep-all" : "normal",
  textAlign: "left",

  [mq("desktop")]: {
    width: "70%",
    maxWidth: "100rem",
    margin: "7rem auto 0",
  },
}));

export const DetailPageHeroTitle = styled.div(({ theme }) => ({
  position: "absolute",
  zIndex: infoZIndex,
  bottom: "10rem",
  left: "50%",
  transform: "translate(-50%)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  fontWeight: theme.fontWeight.bold,
  color: theme.color.white,
  maxWidth: "153.6rem",
  width: "100%",
  paddingInline: "2.4rem",
  fontSize: "2.4rem",

  [mq("desktop")]: {
    fontSize: "5rem",
  }
}));

// STYLE: DetailPageInformationBox.jsx
export const DetailPageInformationBoxContainer = styled.div(({ noBox }) => ({
  display: noBox ? "block" : "flex",
  gap: "0 4rem",
  justifyContent:'space-between',

  [mq("mobile")]: {
    flexDirection: "column",
  },

  [mq("desktop")]: {
    flexFlow: "row",
  },
}));

export const DetailPageInformationBoxRight = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width:'100%',
  height: 'fit-content',


  [mq("mobile")]: {
    flex: "1",
  },

  [mq("desktop")]: {
    flex: "auto",
    width:'fit-content',
    maxWidth:'33.6rem'
  },
}));

export const DetailPageInformationBoxLogo = styled.div(() => ({
  maxWidth: "20rem",
  width: "100%",
  margin:'4rem auto 1.6rem',

  [mq("mobile")]: {
    flex: "1",
  },

  [mq("desktop")]: {
    flex: "auto",
    marginBottom: "3.2rem",
  },
}));

export const DetailPageInformationBoxInformation = styled.ul(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "2.4rem 0",
  background: "#F2F7FF",
  height: "fit-content",
  padding: "1.6rem",
  borderRadius: "1.5rem",
  boxShadow: theme.shadow.secondary,
  zIndex: infoZIndex,

  [mq("mobile")]: {
    width: "100%",
    marginBottom: "1.6rem",
  },

  [mq("desktop")]: {
    flex: 1,
    width: "auto",
    padding: "3.2rem",
    borderRadius: "2.5rem",
    margin: "5rem 0",
  },
}));

export const DetailPageInformationBoxItem = styled.li(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
  fontWeight: 700,
  textTransform: "uppercase",

  [mq("mobile")]: {
    fontSize: "1.2rem",
    letterSpacing: "0.1em",
  },

  [mq("desktop")]: {
    fontSize: "1.6rem",
    letterSpacing: "0.2em",
  },

  "&>p": {
    color: theme.color.blue_point_color,
    fontFamily: theme.fontFamily.secondaryFont,
    fontWeight: theme.fontWeight.regular,
    textTransform: "capitalize",
    letterSpacing: "normal",

    [mq("mobile")]: {
      fontSize: "1.6rem",
    },

    [mq("desktop")]: {
      fontSize: "1.8rem",
    },
  },
}));

// STYLE: DetailPageDescription.jsx
export const DetailPageDescriptionDesc = styled(CommonDescOne)(() => ({
  [mq("desktop")]: {
    maxWidth: "50%",
  },
}));

export const DetailPageDescriptionContainer = styled.div(() => ({
  marginTop: "5rem",
}));

export const DetailPageDescriptionfeatureTags = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: "1.8rem 0 5rem",
  fontWeight: theme.fontWeight.medium,
  gap: "0.4rem",

  "&>p:not(:last-of-type)": {
    paddingRight: "0.4rem",
    borderRight: "1px solid black",
  },

  p : {
    marginBlockStart: 0
  }
}));
