import {
  CommonDescOne,
  CommonTitleFour,
  CommonTitleOne,
} from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: Meet.jsx
export const MeetConferenceAreaDesc = styled(CommonDescOne)(() => ({
  textAlign: "center",
  marginTop: "8rem",
}));

// STYLE: GetInspiredMeetings.jsx
export const GetInspiredMeetingsContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8rem 0",
}));

// STYLE: InquiryInformation.jsx
export const InquiryInformationContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "1.6rem",
  background: theme.color.primary_color,
  borderRadius: "1.5rem",
  gap: "1rem 0",

  [mq("desktop")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4rem 5rem",
    borderRadius: "2.5rem",
  },
}));

export const InquiryInformationItem = styled.div(({ theme }) => ({
  display: "flex",
  gap: "0 2rem",
  color: theme.color.secondary_color,
}));

export const InquiryInformationWrapper = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  gap: "2rem 1rem",

  span: {
    fontSize: "1.8rem",
    fontWeight: theme.fontWeight.bold,
  },

  div: {
    fontSize: "1.6rem",
    color: theme.color.white,
    fontWeight: theme.fontWeight.medium,
  },

  [mq("desktop")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.6rem 1rem",

    span: {
      fontSize: "2.2rem",
    },

    div: {
      fontSize: "2.8rem",
      color: theme.color.white,
    },
  },
}));

// STYLE: MeetingSuccess.jsx
export const MeetingSuccessContainer = styled.div(() => ({
  minHeight: "60rem",

  [mq("desktop")]: {
    minHeight: "64rem",
  },
}));

// STYLE: MeetingSuccessInformation.jsx
export const MeetingSuccessInformationContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 10rem",

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const MeetingSuccessInformationLeft = styled.div(() => ({
  flex: 1,
}));

export const MeetingSuccessInformationRight = styled.ul(() => ({
  flex: 2,
}));

export const MeetingSuccessInformationLocation = styled.ul(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "3rem",
}));

export const MeetingSuccessInformationHeader = styled.div(({ theme }) => ({
  fontSize: "1.2rem",
  color: theme.color.primary_grey,
  fontWeight: theme.fontWeight.medium,
  textAlign: "right",
  textTransform: "uppercase",
}));

export const MeetingSuccessInformationFileList = styled.div(({ theme }) => ({
  display: "flex",
  gap: "0 1.6rem",
  marginTop: "0.8rem",
  fontWeight: theme.fontWeight.bold,
  fontSize: "1.2rem",
  textTransform: "uppercase",

  "&>li": {
    cursor: "pointer",

    span: {
      borderBottom: `1px solid ${theme.color.base_black}`,
    },

    ":hover": {
      color: theme.color.point_primary_color,

      span: {
        borderBottom: `1px solid ${theme.color.point_primary_color}`,
      },
    },
  },

  "&>li:not(:last-of-type)": {
    borderRight: `3px solid ${theme.color.light_grey}`,
    paddingRight: "1.6rem",
  },
}));

// STYLE: EventPackages.jsx
export const EventPackagesContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "9rem",
  textAlign: "center",
}));

export const EventPackagesTitle = styled(CommonTitleOne)(() => ({
  margin: "8rem 0 2rem",
}));

export const EventPackagesDesc = styled(CommonDescOne)(() => ({
  [mq("desktop")]: {
    maxWidth: "50%",
  },
}));

// STYLE: MeetSightseeingActivities.jsx
export const MeetSightseeingActivitiesContainer = styled.div(() => ({
  background: "#F9F3FF",
  borderRadius: "2.5rem",
  padding: "1.6rem 1.6rem",

  [mq("desktop")]: {
    padding: "6.4rem 5rem",
  },
}));

export const MeetSightseeingActivitiesImage = styled.img(() => ({
  width: "100%",
  borderRadius: "1.5rem",
  margin: "2rem 0 4rem",

  [mq("desktop")]: {
    borderRadius: "2.5rem",
    margin: "2rem 0 8rem",
  },
}));

export const MeetSightseeingActivitiesList = styled.ul(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(1, 1fr)",
  gap: "2.4rem 0",

  [mq("tablet")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },

  [mq("desktop")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },

  li: {
    display: "flex",
    gap: "0 1.4rem",
    fontWeight: theme.fontWeight.medium,
    fontSize: "1.4rem",
  },
}));

export const MeetSightseeingActivitiesName = styled(CommonTitleFour)(
  ({ theme }) => ({
    fontWeight: theme.fontWeight.Black,
    color: theme.color.primary_color,
    textAlign: "center",
  })
);

export const MeetSightseeingActivitiesCounter = styled.span(({ theme }) => ({
  width: "2.4rem",
  height: "2.4rem",
  color: theme.color.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.color.primary_color,
  borderRadius: "50%",
}));
