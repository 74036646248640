import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import {
  SitemapListDetailmenu,
  SitemapListHeader,
  SitemapListSubmenu,
  SitemapListWrapper,
} from "@pages/about-us/AboutUsStyling";
import { useGetHeaderListQuery } from "@utils/api-requests/GlobalRequests";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { DangerouslyHtml } from "@components/item";

const SitemapList = () => {
  const { data: headerList } = useGetHeaderListQuery();

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);
  return (
    <div>
      {headerList?.map((header) => {
        return (
          <SitemapListWrapper key={header.title}>
            <SitemapListHeader>
              <DangerouslyHtml value={header.title} />
            </SitemapListHeader>

            <SitemapListSubmenu>
              {header?.children &&
                header?.children?.map((submenu) => {
                  return (
                    <div key={submenu.title}>
                      <DangerouslyHtml value={submenu.title} />

                      {submenu?.children && (
                        <SitemapListDetailmenu>
                          {submenu?.children.map((detailmenu) => {
                            return (
                              <Link
                                key={detailmenu.title}
                                to={`${baseUrl}${detailmenu.url}${
                                  submenu.category_id !== null
                                    ? `/${submenu.category_id}`
                                    : ""
                                }${
                                  detailmenu.content_link !== ""
                                    ? `${detailmenu.content_link}`
                                    : ""
                                }${
                                  detailmenu?.category_id !== null
                                    ? `?category=${detailmenu.category_id}`
                                    : ""
                                }`}
                              >
                                <DangerouslyHtml value={detailmenu.title} />
                              </Link>
                            );
                          })}
                        </SitemapListDetailmenu>
                      )}
                    </div>
                  );
                })}
            </SitemapListSubmenu>
          </SitemapListWrapper>
        );
      })}
    </div>
  );
};

export default SitemapList;
