import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import {
  CommonTitleFour,
  CommonTitleOne,
  CommonTitleTwo,
  infoZIndex,
} from "@assets/styles/CommonStyles";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: FrameOne.jsx
export const FrameOneWrapper = styled.div(() => ({
  position: "relative",
  width: "100%",
  borderRadius: "2.5rem",
  overflow: "hidden",
  minHeight: "30rem",
}));

export const FrameOneInformation = styled.header(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: "2rem 12rem",
  position: "absolute",
  bottom: "3rem",
  left: "50%",
  transform: "translateX(-50%)",
  width: "90%",
  marginBottom: 0,
  color: theme.color.white,
  zIndex: infoZIndex,

  [mq("desktop")]: {
    bottom: "7rem",
    flexDirection: "row",
    marginBottom: "2rem",
  },
}));

export const FrameOneInformationWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",
}));

// STYLE: FrameTwo.jsx
export const FrameTwoContainer = styled.div(({ direction }) => ({
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "stretch",
  borderRadius: "2.5rem",
  overflow: "hidden",

  [mq("desktop")]: {
    flexDirection: direction === "right" ? "row-reverse" : "row",
  },
}));

export const FrameTwoLeft = styled.div(({ theme, inspireArena, tertiary }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "3rem 0",
  padding: "3.2rem",
  background: tertiary ? theme.color.tertiary_color: theme.color.primary_color,
  color: theme.color.white,
  ul: {
    listStyle: "none",

    li: {
      position: "relative",
      listStyle: "none",

      "&:before": {
        content: `url(${theme.image.flagIcon.default})`,
        position: "absolute",
        display: inspireArena ? "inline-block" : "none",
        left: "-3rem",
        top: "0.4rem",
      },
    },
  },

  [mq("desktop")]: {
    padding: "7rem 5rem",
  },
}));

export const FrameTwoInformation = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",

  [mq("desktop")]: {
    gap: "3rem 0",
  },
}));

export const FrameTwoRight = styled.div(() => ({
  figure: {
    flex: 2,
    height: "100%",

    [mq("desktop")]: {
      minWidth: "80rem",
    },
  },
}));

export const FrameThreeRight = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadow.tertiary,
  padding: "1.6rem",

  [mq("desktop")]: {
    padding: "5.6rem",
    minWidth: "43.8rem"
  },
}));

export const FrameTwoImageCustom = {
  flex: 2,
  height: "auto",
};

// STYLE: FrameTrhee.jsx
export const FrameThreeInformation = styled.div(({ theme, noImage }) => ({
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.shadow.secondary,
  borderRadius: "2.5rem",
  overflow: "hidden",
  width: "100%",
  margin: noImage ? "0 auto 5rem" : 0,

  "&>li": {
    gap: "0 2rem",
  },

  [mq("desktop")]: {
    width: noImage ? "80%" : "100%",
    marginBottom: "10rem",
    flexDirection: "row",
  },
}));

export const FrameThreeLeft = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  [mq("desktop")]: {
    padding: "5.6rem",
    aspectRatio: "16 / 9",
  },
}));

export const FrameThreeLogo = styled.div(() => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "30rem",
  width: "100%",
  minWidth: "20rem",
  padding: "3.2rem 0",
  margin: "0 auto",

  [mq("desktop")]: {
    padding: 0,
  },
}));

export const FrameThreeBoxHeader = styled(CommonTitleTwo)(({ theme }) => ({
  marginBottom: "1.6rem",
  textTransform: "uppercase",
  color: theme.color.primary_color,

  [mq("desktop")]: {
    marginBottom: "3rem",
    fontSize: "4rem",
  },
}));

export const FrameThreeList = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.6rem 0",
  width: "100%",

  "& > li": {
    display: "flex",
    flexDirection: "column",
    gap: "1.6rem 0",
  },

  [mq("desktop")]: {
    gap: "3rem 0",

    "& > li": {
      flexDirection: "row",
      gap: "0 1rem",
    },
  },
}));

export const FrameThreeIn = styled(CommonTitleFour)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",

  "& > header": {
    fontFamily: theme.fontFamily.primaryFont,
  },

  [mq("desktop")] : {
    minWidth: "30rem",
  }
}));

// STYLE: FrameFour.jsx
export const FrameFourContainer = styled.div(({ reverse }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.6rem 6rem",

  "& > *": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: reverse ? "row-reverse" : "row",
  },
}));

export const FrameFourRight = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "1.6rem 0",
  textAlign: "center",

  [mq("desktop")]: {
    gap: "3rem 0",
    textAlign: "left",
  },
}));

// MomentumLayout.jsx
export const MomentumLayoutContainer = {
  marginTop: "6rem",

  [mq("desktop")]: {
    marginTop: "12rem",
  },
};

export const MomentumLayoutLink = styled(Link)(({ theme }) => ({
  display: "flex",
  gap: "0 1.6rem",
  width: "fit-content",
  margin: "3rem 0",
  fontSize: "1.6rem",
  fontWeight: theme.fontWeight.bold,
  color: theme.color.pale_grey,
  textTransform: "uppercase",
  letterSpacing: "0.2rem",
  fontFamily: theme.fontFamily.primaryFont,
}));

// STYLE: FrameFivejsx
export const FrameFiveContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8rem 0",
}));

export const FrameFiveItem = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

export const FrameFiveTitle = styled(CommonTitleOne)(() => ({
  margin: "3rem 0 2rem",
}));

export const FrameFiveDesc = styled.div(() => ({
  [mq("desktop")]: {
    maxWidth: "60%",
  },
}));

// STYLE: FrameSixjsx
export const FrameSixContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem 10rem",

  "& > *": {
    flex: 1,
  },

  [mq("desktop")]: {
    flexDirection: "row",
  },
}));

export const FrameSixTexts = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem 0",

  "&>li": {
    display: "flex",
    gap: "0 2rem",
  },
}));

export const FrameSixHeader = styled.header(({ theme }) => ({
  fontWeight: theme.fontWeight.Black,
}));
