import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { image } from "src/theme";
import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { ListWithTabs } from "@components/list";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import { PrimaryButton } from "@components/buttons";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import { ArtistSpacesInformation } from "@pages/entertainment/EntertainmentStyling";
import { checkIncludes, downloadPdf } from "@utils/modify-data/modifyData";
import { PdfDownloadText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const ArtistSpaces = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const [filterType, setFilterType] = useState("");

  const spaceCategory = useMemo(() => {
    const result = data?.map((item) => {
      return {
        title: item.field_type,
        category_id: item.field_type,
      };
    });

    return result;
  }, [data]);

  useEffect(() => {
    setFilterType(data?.[0]?.field_type);
  }, [data]);

  const currentData = useMemo(() => {
    return data?.filter((el) => checkIncludes(el.field_type, filterType))[0];
  }, [data, filterType]);

  return (
    <ListWithTabs
      tabArr={spaceCategory}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <CommonContentButtonWrapper>
        <ImageWrapper
          radius={isDesktop ? "2.5rem" : "1.5rem"}
          ratio={isDesktop ? "2 / 1" : "1 / 1"}
        >
          <Image
            src={currentData?.field_instruction_image?.src}
            alt={currentData?.field_instruction_image?.alt}
          />

          <ArtistSpacesInformation>
            <CommonTitleOne>
              <DangerouslyHtml value={currentData?.field_type} />
            </CommonTitleOne>

            <CommonDescOne>
              <DangerouslyHtml value={currentData?.field_info_description} />
            </CommonDescOne>
          </ArtistSpacesInformation>

          <GradientOverlay />
        </ImageWrapper>

        {currentData?.field_pdf?.src && (
          <PrimaryButton
            noArrow
            buttonEvent={() => downloadPdf("ddd", currentData?.field_pdf?.src)}
          >
            <PdfDownloadText />

            <img
              src={image.downloadIcon.default}
              alt="download icon"
              width={25}
              height={25}
              loading="lazy"
            />
          </PrimaryButton>
        )}
      </CommonContentButtonWrapper>
    </ListWithTabs>
  );
};

export default ArtistSpaces;
