import React, { useCallback, useMemo } from "react";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { TableTwo } from "@components/table";
import { DangerouslyHtml, PdfPreview } from "@components/item";
import {
  MeetingSuccessInformationContainer,
  MeetingSuccessInformationFileList,
  MeetingSuccessInformationHeader,
  MeetingSuccessInformationLeft,
  MeetingSuccessInformationLocation,
  MeetingSuccessInformationRight,
} from "@pages/meet/MeetStyling";
import {
  ballroomTbody,
  meetingRoomsTbody,
  meetingSuccessThead,
  studioTbody,
} from "@utils/table-data/tableData";
import {
  LocationText,
  PdfDownloadText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { downloadPdf } from "@utils/modify-data/modifyData";

const MeetingSuccessInformation = ({ data }) => {
  const pdfDownload = useCallback((name, link) => {
    downloadPdf(name, link);
  }, []);

  const meetingSuccessTbody = useMemo(() => {
    switch (data?.field_meeting_category) {
      case "Studio":
        return studioTbody;

      case "Meeting Rooms":
        return meetingRoomsTbody;

      case "INSPIRE Ballroom":
        return ballroomTbody;

      default:
        break;
    }
  }, [data?.field_meeting_category]);

  return (
    <MeetingSuccessInformationContainer>
      {data?.field_upload_file && (
        <MeetingSuccessInformationLeft>
          <PdfPreview
            pdfName="location"
            pdfLink={data?.field_upload_file}
            ratio="2 / 3"
          />
        </MeetingSuccessInformationLeft>
      )}

      <MeetingSuccessInformationRight>
        <MeetingSuccessInformationLocation>
          {data?.field_meeting_location && (
            <div>
              <header>
                <LocationText />
              </header>

              <CommonDescTwo>
                <DangerouslyHtml value={data?.field_meeting_location} />
              </CommonDescTwo>
            </div>
          )}

          {data?.field_pdf_file_list?.length > 0 && (
            <div>
              <MeetingSuccessInformationHeader>
                <PdfDownloadText />
              </MeetingSuccessInformationHeader>

              <MeetingSuccessInformationFileList>
                {data?.field_pdf_file_list.map((pdf) => {
                  return (
                    <li
                      key={pdf.name}
                      onClick={() => pdfDownload(pdf.name, pdf.pdf?.src)}
                    >
                      <span>
                        <DangerouslyHtml value={pdf.name} />
                      </span>
                    </li>
                  );
                })}
              </MeetingSuccessInformationFileList>
            </div>
          )}
        </MeetingSuccessInformationLocation>

        <li>
          <TableTwo thead={meetingSuccessThead} tbody={meetingSuccessTbody} />
        </li>
      </MeetingSuccessInformationRight>
    </MeetingSuccessInformationContainer>
  );
};

export default MeetingSuccessInformation;
