import React, { memo, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { ClickAwayListener, Tooltip, Zoom } from "@mui/material";
import { useTheme } from "@emotion/react";

import { image } from "src/theme";
import { CommonTitleFour } from "@assets/styles/CommonStyles";
import { NormalOverlay } from "@components/item/Overlays";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import { UrlCopiedText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  DetailPageHeroButtonIn,
  DetailPageHeroContainer,
  DetailPageHeroIn,
  DetailPageHeroShareButton,
  DetailPageHeroTitle,
} from "./SubPageStyling";

const DetailPageHero = ({ data, subTitle, title, desc, small = false }) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setOpen]);

  useEffect(() => {
    setTimeout(() => {
      if (open) {
        handleTooltipClose();
      }
    }, 3000);
  }, [open]);

  return (
    data?.field_hero !== "" &&
    data?.field_hero?.[0]?.src && (
      <DetailPageHeroContainer small={small || data?.field_banner_size === "True"} url={data?.field_hero?.[0]?.src}>
        <CommonContainer custom={DetailPageHeroIn}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              TransitionComponent={Zoom}
              PopperProps={{
                disablePortal: true,
                sx: {
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: theme.color.tertiary_color,
                    fontSize: "1.6rem",
                    padding: "1rem",
                    fontWeight: theme.fontWeight.bold,
                    letterSpacing: "0.2rem",
                  },

                  "& .MuiTooltip-arrow": {
                    color: theme.color.tertiary_color,
                  },
                },
              }}
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={<UrlCopiedText />}
              arrow
              placement="top"
            >
              <DetailPageHeroShareButton>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleTooltipOpen}
                >
                  <DetailPageHeroButtonIn>
                    <img
                      src={image.shareIcon.default}
                      alt="Share"
                      loading="lazy"
                    />
                  </DetailPageHeroButtonIn>
                </CopyToClipboard>
              </DetailPageHeroShareButton>
            </Tooltip>
          </ClickAwayListener>
        </CommonContainer>

        <NormalOverlay />

        {(subTitle || title || desc) && (
          <DetailPageHeroTitle>
            <CommonTitleFour>
              <DangerouslyHtml value={subTitle} />
            </CommonTitleFour>

            <p>
              <DangerouslyHtml value={title} />
            </p>

            {desc && (
              <CommonTitleFour>
                <DangerouslyHtml value={desc} />
              </CommonTitleFour>
            )}
          </DetailPageHeroTitle>
        )}
      </DetailPageHeroContainer>
    )
  );
};

export default memo(DetailPageHero);
