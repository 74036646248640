import { CommonTitleFive, footerLogoZIndex } from "@assets/styles/CommonStyles";
import { isJapanese } from "@utils/lanugauge-settings/LanguageOptions";
import { mq } from "@utils/media-query/mediaQuery";

const { Link } = require("react-router-dom");
const { default: styled } = require("@emotion/styled");

export const FooterContainer = styled.footer(({ theme, noMargin }) => ({
  position: "relative",
  height: "100%",
  marginTop: noMargin ? 0 : "10rem",
  fontFamily: theme.fontFamily.primaryFont,
  backgroundColor:"#E4E2DF",
  backgroundImage: `url(${theme.image.footerBackgroundMobile})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "115% 100%",
  fontWeight: theme.fontWeight.regular,
  fontSize: "1.4rem",
  lineHeight: "1.5em",
  zIndex:100,

  [mq("tablet")]: {
    backgroundSize: "100% auto",
  },

  [mq("desktop")]: {
    marginTop: noMargin ? 0 : "16rem",
    backgroundImage: `url(${theme.image.footerBackground})`,
    padding: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.color.primary_color,
  },
}));

export const FooterTop = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  paddingBottom: "3rem",

  [mq("desktop")]: {
    gap: "0 6rem",
    flexDirection: "row",
    paddingBottom: 0,
  },
}));

export const FooterTopLeft = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "80%",
  margin: "0 auto",

  [mq("desktop")]: {
    gap: "4rem 0",
    maxWidth: "100%",
    margin: 0,
  },
}));

export const FooterLogoWrapper = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  zIndex: footerLogoZIndex,
  margin: "3.2rem 0 5rem",

  [mq("desktop")]: {
    padding: "8rem 4rem",
    height: "100%",
    margin: 0,
  },
}));

export const FooterLogo = styled.object(() => ({
  width: "100%",
  maxWidth: "20rem",

  [mq("desktop")]: {
    width: "25rem",
    justifyContent: "flex-end",
  },
}));

export const Address = styled.address(({ theme }) => ({
  marginBottom: "1.8rem",
  fontStyle: "normal",
  lineHeight: 1.2,
  fontWeight: theme.fontWeight.bold,

  "& p + p": {
    margin: 0,
  },
}));

export const MenuWrapper = styled.div(() => ({
  marginBottom: "1rem",
  fontSize: "1.4rem",
  lineHeight: "1.5em",
}));

export const MenuTitle = styled(Link)(({ theme}) => ({
  display: "block",
  marginBottom: "3rem",
  fontWeight: theme.fontWeight.bold,
  fontSize: isJapanese ? "1.8rem" : "2rem",
}));

export const FooterLink = styled(Link)(({theme, bold}) => ({
  fontWeight: bold ==="true"? theme.fontWeight.bold : theme.fontWeight.regular,
}))

export const FooterNav = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem 0",
  maxWidth: "20rem",
  margin: "0 auto",

  "& a:hover": {
    textDecoration: "underline",
  },

  [mq("desktop")]: {
    margin: 0,
  },
}));

export const UnauthorizedCollectionOfEmail = styled.button(() => ({
  textAlign: "left",
}));

export const FooterTopRight = styled.div(() => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  gap: "2rem 0",

  "& section": {
    width: "100%",
  },

  "& section.center": {
    marginTop: 0,
  },

  [mq("desktop")]: {
    padding: "4rem 0",
    justifyContent: "space-between",
    flexDirection: "row",
    textAlign: "left",
    alignItems: "flex-start",

    "& section.center": {
      marginTop: "5.4rem",
    },
  },
}));

export const FooterHotelSection = styled.section(() => ({
  maxWidth: "36rem",
}));

export const SectionHeader = styled(CommonTitleFive)(({ theme }) => ({
  fontWeight: theme.fontWeight.bold,
  marginBottom: "3rem",
}));

export const ContactList = styled.ul(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem 0",
}));

export const ContactItem = styled.li(() => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "0 1.4rem",
  flexWrap: "wrap",
}));

export const FooterBusinessNumber = styled.div(() => ({
  marginTop: "5rem",
}));

export const FooterBottom = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "3rem 0",
  paddingTop: "1.6rem",
  paddingBottom: "1.6rem",
  background: theme.color.white,
  fontSize: "1.2rem",

  [mq("desktop")]: {
    flexDirection: "row",
    gap: "0 14rem",
  },
}));

export const FooterBottomWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column-reverse",
  alignItems: "center",
  justifyContent: "space-between",

  [mq("mobile")]: {
    gap: "1rem 0",
  },

  [mq("desktop")]: {
    gap: "4rem 0",
    flexDirection: "row",
  },
}));

export const SnsWrapper = styled.section(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem 0",
  textTransform: "uppercase",

  [mq("desktop")]: {
    gap: "0 3rem",
    flexDirection: "row",
  },
}));

export const LinkGroup = styled.ul(() => ({
  display: "flex",
  gap: "1rem 3rem",
  flexWrap: "wrap",
  justifyContent: "flex-end",

  [mq("desktop")]: {
    gap: "0 2rem",
    flexWrap: "nowrap",
  },

  a: {
    display: "block",
  },

  object: {
    height: "3rem",
    pointerEvents: "none",
  },
}));

export const CopyRight = styled.div(() => ({
  textAlign: "center",

  [mq("desktop")]: {
    textAlign: "left",
  },
}));
