import React, { memo } from "react";

import { TabButtons } from "@components/item";
import { ErrorManage } from "@screens/index";

const ListWithTabs = ({
  color,
  tabArr,
  changeEvent,
  currentTab,
  setCurrentTab,
  filterType,
  setFilterType,
  children,
  tabKey,
  isLoading,
  isError,
  error,
  titleKey,
}) => {
  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      {tabArr?.length !== 0 && (
        <TabButtons
          color={color}
          changeEvent={changeEvent}
          tabArr={tabArr}
          tabKey={tabKey}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          filterType={filterType}
          setFilterType={setFilterType}
          titleKey={titleKey}
        />
      )}

      {children}
    </ErrorManage>
  );
};

export default memo(ListWithTabs);
