import React from "react";

import { image } from "src/theme";
import { InformationAlertText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { DangerouslyHtml } from ".";
import {
  NotificationToggleAccordion,
  NotificationToggleContainer,
  NotificationToggleDetails,
  NotificationToggleHeader,
  NotificationToggleWrapper,
} from "./ItemStyling";

const NotificationToggle = ({ data }) => {
  return (
    data?.field_notification?.length > 0 &&
    data?.field_notification?.[0].field_on_off === "1" && (
      <NotificationToggleContainer>
        <NotificationToggleAccordion defaultExpanded>
          <NotificationToggleHeader
            expandIcon={
              <img
                src={image.notificationArrow.default}
                alt="down arrow"
                loading="lazy"
              />
            }
          >
            <NotificationToggleWrapper>
              <div>
                <img
                  src={image.notificationIcon.default}
                  alt="Exclamation mark"
                  loading="lazy"
                />

                <span>
                  <InformationAlertText />
                </span>
              </div>
            </NotificationToggleWrapper>
          </NotificationToggleHeader>

          <NotificationToggleDetails>
            <DangerouslyHtml
              value={data?.field_notification?.[0].field_notification_contents}
            />
          </NotificationToggleDetails>
        </NotificationToggleAccordion>
      </NotificationToggleContainer>
    )
  );
};

export default NotificationToggle;
