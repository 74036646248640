import React from "react";
import { useMediaQuery } from "react-responsive";

import { CommonDescFour } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import {
  SpiritOfAquaiContainer,
  SpiritOfAquaiDescripition,
  SpiritOfAquaiInformation,
  SpiritOfAquaiItem,
  SpiritOfAquaiTextList,
} from "@pages/about-us/AboutUsStyling";

const SpiritOfAquai = ({ data }) => {
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  return (
    <SpiritOfAquaiContainer>
      <SpiritOfAquaiTextList>
        {data?.field_spirit_of_aquai_list?.split(", ").map((item) => {
          return <span key={item}>{item}</span>;
        })}
      </SpiritOfAquaiTextList>

      <SpiritOfAquaiDescripition>
        <ImageWrapper width={isDefault ? "13rem" : "33rem"}>
          <Image
            src={data?.field_featured_image[0]?.src}
            alt={data?.field_featured_image[0]?.alt}
          />
        </ImageWrapper>

        <SpiritOfAquaiInformation>
          {data?.field_spirit_of_aquai_descriptio.map((aquai) => {
            return (
              <SpiritOfAquaiItem key={aquai.field_title}>
                <header>
                  <DangerouslyHtml value={aquai.field_title} />
                </header>

                <CommonDescFour>
                  <DangerouslyHtml value={aquai?.field_full_description} />
                </CommonDescFour>
              </SpiritOfAquaiItem>
            );
          })}
        </SpiritOfAquaiInformation>
      </SpiritOfAquaiDescripition>
    </SpiritOfAquaiContainer>
  );
};

export default SpiritOfAquai;
