import { useIntl } from "react-intl";

import {
  useGetDetailPageInformation,
  useGetPageInformation,
} from "@utils/api-requests/GlobalRequests";
import { AllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { offerBookingUrl, roomBookingUrl } from "@utils/api-requests/apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

export function concatAllValue(data) {
  const allTab = [{ category_id: "all", title: <AllText /> }];

  if (data) {
    return allTab.concat(data);
  }
}

export function changeRatioDependingDataLength(length, idx) {
  switch (length) {
    case 1:
      return "3 / 1";

    case 2:
      return "1 / 1";

    case 3:
      if (idx === 0) {
        return "5 / 2";
      }

      return "3 / 2";

    case 4:
      if (idx === 0) {
        return "5 / 2";
      }

      return "4 / 5";

    case 5:
      if (idx === 0 || idx === 1) {
        return "6 / 5";
      }

      return "4 / 5";

    case 0:
      return "1 / 1";

    default:
      return "1 / 1";
  }
}

export function FilterPageData(pageType) {
  const { data: pageInformation } = useGetPageInformation();

  return pageInformation?.filter((el) =>
    checkIncludes(el.field_select_page_type, pageType)
  )[0];
}

export function FilterDetailPageData(pageType) {
  const { data: detailPageInformation } = useGetDetailPageInformation();

  return detailPageInformation?.filter((el) =>
    checkIncludes(el.field_detail_page_name, pageType)
  )[0];
}

export function downloadPdf(title, url) {
  const width = 600;
  const height = 600;

  // const left = (window.screen.width - width) / 2;
  // const top = (window.screen.height - height) / 2;

  const pdfPopup = window.open(
    `${window.location.protocol}//${window.location.host}${
      url.charAt(0) === "/" ? "" : "/"
    }${url}`,
    "_blank",
    title
    // `left=${left}, top=${top}`
  );

  pdfPopup.addEventListener("load", function () {
    pdfPopup.resizeTo(width, height);
    // pdfPopup.moveTo(left, 10);
    pdfPopup.document.title = title;
  });
}

export function TranslateDayMonth(fullday) {
  const intl = useIntl();

  function translateAll(fullday) {
    if (fullday) {
      return intl.formatMessage({
        id: `lang-${fullday?.toLowerCase()}`,
        defaultMessage: null,
      });
    }

    return "";
  }

  const result = translateAll(fullday?.split(",")[0]) + ", ";

  if (fullday) {
    return result + fullday?.split(",")[1];
  } else {
    return "-";
  }
}

export function replaceAmpersand(link) {
  if (link) {
    return link?.replaceAll("&amp;", "&");
  }
}

export function getSearchValue(search) {
  // const params = new URLSearchParams(search);
  // const category = params.get("category");

  return decodeURIComponent(search.replace("?category=", ""));
}

export function getIndexOfCategory(list, filterType, setCurrentTab) {
  const CategoryIdArr = list?.map((category) => {
    return category?.category_id;
  });

  const foundIndex = CategoryIdArr?.findIndex((item) => item === filterType);

  setCurrentTab(foundIndex);
}

export function changeEmptyValue(value) {
  if (value | (value === null) | (value === "")) {
    return "-";
  }

  return value;
}

export function checkIncludes(original, comparison) {
  return JSON.stringify(original)
    ?.toLowerCase()
    .replaceAll("-", " ")
    .includes(JSON.stringify(comparison)?.toLowerCase().replaceAll("-", " "));
}

export function chunkData(data = [], size = 6) {
  const arr = [];

  for (let i = 0; i < data.length; i += size) {
    arr.push(data.slice(i, i + size));
  }

  return arr;
}

export function doubleData(
  slidesPerView,
  data,
  setChangedData,
  repeat,
  noRepeat
) {
  if ((data?.length === 1 && repeat !== "repeat") || noRepeat) {
    setChangedData(data);
  } else if (data?.length !== 0) {
    const targetLength = Math.ceil(slidesPerView * 2);

    const repetitions = Math.ceil(targetLength / data?.length) + 1;

    const result = Array.from({ length: repetitions }, () => [...data]).flat();

    setChangedData(result);
  }
}

export function switchLanguageToFitBooking(lang) {
  switch (lang) {
    case "ko":
      return "KO";

    case "en":
      return "EN";

    case "ja":
      return "JA";

    case "zh-hant":
      return "ZH-TW";

    case "zh-hans":
      return "ZH-CN";

    default:
      return "EN";
  }
}

export function openBookingSite(data, defaultCode) {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  window.open(
    `${roomBookingUrl}/${data.startdate}/${data.enddate}/${
      data.offercode === "" ? defaultCode : data.offercode
    }/${data.Noguest - data.children}?children=${
      data.children
    }&langCode=${switchLanguageToFitBooking(lang)}`,
    "_blank"
  );
}

export function openOfferBookingSite(data) {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
  window.open(
    `${offerBookingUrl}${data}?langCode=${switchLanguageToFitBooking(lang)}`,
    "_blank"
  );
}

export const textFormat = (text) => {
  if (text) {
    var updated_text = "";

    updated_text = text.replaceAll("-", " ");

    updated_text = updated_text.replaceAll("and", "&");

    return updated_text;
  }
  return "";
};

export function changePrimaryFontFamily() {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  switch (lang) {
    case "en":
      return "'Noto Sans', sans-serif";

    case "ko":
      return "'Noto Sans KR', sans-serif";

    case "ja":
      return "'Noto Sans JP', sans-serif";

    case "zh-hant":
      return "'Noto Sans SC', sans-serif";

    case "zh-hans":
      return "'Noto Sans SC', sans-serif";

    default:
      return "'Noto Sans', sans-serif";
  }
}

export function changeSecondaryFontFamily() {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  switch (lang) {
    case "en":
      return "'Arsenal', sans-serif";

    case "ko":
      return "'NanumSquare', 'Noto Sans KR', sans-serif";

    case "ja":
      return "'Noto Sans JP', sans-serif";

    case "zh-hant":
      return "'Noto Sans SC', sans-serif";

    case "zh-hans":
      return "'Noto Sans SC', sans-serif";

    default:
      return "'Arsenal', sans-serif";
  }
}

export const daysUntilNextBirthday = (dateOfBirth) => {
  const today = new Date();
  const dob = new Date(dateOfBirth);

  dob.setFullYear(today.getFullYear());

  if (dob.getTime() < today.getTime()) {
    dob.setFullYear(today.getFullYear() + 1);
  }

  const timeDiff = dob.getTime() - today.getTime();
  const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  return daysRemaining;
};

export const convertDate = (date) => {
  let newDate = new Date(date);
  let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
  let day = ("0" + newDate.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join("-");
};

export const numberPadding = (number) => {
  return number.toString().padStart(2, "0");
};

export function switchSlashToEmptySpace(value) {
  if (typeof value === "string" && value.includes("://")) {
    return value;
  } else if (typeof value === "string") {
    return value.replaceAll("//", "<wbr/>");
  }
}

export function decodeUrl(value) {
  return value.replaceAll(" ", "-").replaceAll("&#039;", "'")
}
