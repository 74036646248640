import React from "react";
import { useParams } from "react-router-dom";

import {
  DetailPageContainer,
  DetailPageDescription,
  DetailPageHero,
} from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { SliderSix } from "@components/sliders";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { OfferRecommendation } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetMomentumLifestyleEventsDetail } from "@utils/api-requests/MomentumRequests";
import { CustomMetatag } from "@utils/metatags";

const MomentumLifestyleDetail = () => {
  const uuid = useParams().eventUuid;

  const {
    data: momentumLifestyleEventsDetail,
    isLoading,
    isError,
    error,
  } = useGetMomentumLifestyleEventsDetail(uuid);

  const sectionArray = [
    {
      id: 1,
      contents: (
        <DetailPageDescription data={momentumLifestyleEventsDetail} eventInfo />
      ),
    },
    {
      id: 2,
      contents: (
        <SliderSix
          imageArr={momentumLifestyleEventsDetail?.field_multiple_images}
        />
      ),
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={momentumLifestyleEventsDetail} />

      <DetailPageHero data={momentumLifestyleEventsDetail} />

      <CommonContainer>
        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={momentumLifestyleEventsDetail} />

        <MomentumLayout data={momentumLifestyleEventsDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MomentumLifestyleDetail;
