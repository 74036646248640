import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isDate, parse } from "date-fns";

import {
  phoneInvalidFormat,
  phoneRequired,
  dateRequired,
  dateofBirthInvalidFormat,
  dateofBirthRequired,
  emailInvalidFormat,
  emailRequired,
  firstNameRequired,
  lastNameRequired,
  privacyPolicyRequired,
  marketingOptRequired,
  marketingUseRequired,
  passwordRequired,
  verificationCodeRequired,
  passwordNoMatch,
  oldPasswordRequired,
  typeRequired,
  addressRequired,
  countryRequired,
  stateRequired,
  cityRequired,
  postalCodeRequired,
  verificationCodeInvalidFormat,
  recaptchaRequired,
  dateofBirthNineteenInvalidFormat,
  passwordMinLengthText,
  passwordMaxLengthText,
  legalAgeRequired,
} from "./inputErrorMessage";
import { FormattedMessage } from "react-intl";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function parseDateString(_, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());

  return parsedDate;
}

// const isDateInValidRange = (date) => {
//   const currentDate = new Date();

//   const parsedDate = new Date(date);

//   const minDate = new Date(
//     currentDate.getFullYear() - 100,
//     currentDate.getMonth(),
//     currentDate.getDate()
//   );

//   return parsedDate <= currentDate && parsedDate >= minDate;
// };

export const yupKeepInTouch = Yup.object().shape({
  firstName: Yup.string().required(firstNameRequired),
  lastName: Yup.string().required(lastNameRequired),
  emailAddress: Yup.string()
    .required(emailRequired)
    .matches(EMAIL_REGEX, { message: emailInvalidFormat }),
  phoneNumber: Yup.string().test(
    "isValidPhoneNumber",
    phoneInvalidFormat,
    function (value) {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value);
    },
  ),
  dateOfBirth: Yup.date()
    .required(dateofBirthRequired)
    .transform(parseDateString)
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 19)),
      dateofBirthNineteenInvalidFormat,
    )
    .min(
      new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
      dateofBirthInvalidFormat,
    ),
  agreeToTerms: Yup.bool()
    .oneOf([true], privacyPolicyRequired)
    .required(privacyPolicyRequired),
  legalAge: Yup.bool()
    .oneOf([true], legalAgeRequired)
    .required(legalAgeRequired),
  recaptcha: Yup.bool()
    .oneOf([true], recaptchaRequired)
    .required(recaptchaRequired),
  preferredLanguage: Yup.string().required(
    <FormattedMessage id="lang-preferred-language-required" />,
  ),
});

export const yupBooking = Yup.object().shape({
  startdate: Yup.string().required(dateRequired),
});

export const yupSignIn = Yup.object().shape({
  email: Yup.string().required(emailRequired),
  password: Yup.string().required(passwordRequired),
});

export const yupForgotPassword = Yup.object().shape({
  email: Yup.string().required(emailRequired),
});

export const yupVerificationCode = Yup.object().shape({
  code: Yup.string()
    .required(verificationCodeRequired)
    .max(5, verificationCodeInvalidFormat),
});

export const yupChangePasswordCode = Yup.object().shape({
  code: Yup.string()
    .required(verificationCodeRequired)
    .max(5, verificationCodeInvalidFormat),
  password: Yup.string()
    .required(passwordRequired)
    .min(8, passwordMinLengthText)
    .max(50, passwordMaxLengthText),
  confirm_pw: Yup.string()
    .required(passwordRequired)
    .oneOf([Yup.ref("password")], passwordNoMatch),
});

export const yupChangePassword = Yup.object().shape({
  password: Yup.string()
    .required(passwordRequired)
    .min(8, passwordMinLengthText)
    .max(50, passwordMaxLengthText),
  confirm_pw: Yup.string()
    .required(passwordRequired)
    .oneOf([Yup.ref("password")], passwordNoMatch),
});

export const yupChangePasswordProfile = Yup.object().shape({
  old_password: Yup.string().required(oldPasswordRequired),
  password: Yup.string()
    .required(passwordRequired)
    .min(8, passwordMinLengthText)
    .max(50, passwordMaxLengthText),
  confirm_pw: Yup.string()
    .required(passwordRequired)
    .oneOf([Yup.ref("password")], passwordNoMatch),
});

export const yupAgilisysSignUp = Yup.object().shape({
  firstName: Yup.string().required(firstNameRequired),
  lastName: Yup.string().required(lastNameRequired),
  emailAddress: Yup.string()
    .required(emailRequired)
    .matches(EMAIL_REGEX, { message: emailInvalidFormat }),
  phoneNumber: Yup.string()
    .required(phoneRequired)
    .test("isValidPhoneNumber", phoneInvalidFormat, function (value) {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value);
    }),
  phoneType: Yup.string().required(typeRequired),
  password: Yup.string()
    .required(passwordRequired)
    .min(8, passwordMinLengthText)
    .max(50, passwordMaxLengthText),
  confirmPassword: Yup.string()
    .required(passwordRequired)
    .oneOf([Yup.ref("password")], passwordNoMatch),

  dateOfBirth: Yup.date()
    .required(dateofBirthRequired)
    .transform(parseDateString)
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 19)),
      dateofBirthNineteenInvalidFormat,
    )
    .min(
      new Date(new Date().setFullYear(new Date().getFullYear() - 100)),
      dateofBirthInvalidFormat,
    ),

  agreeToTerms: Yup.bool()
    .oneOf([true], privacyPolicyRequired)
    .required(privacyPolicyRequired),

  mailoptin: Yup.string().required(marketingOptRequired),

  personal_use: Yup.string().required(marketingUseRequired),
});

export const yupAgilisysSignUpAddress = Yup.object().shape({
  addressType: Yup.string().required(typeRequired),
  addressLine1: Yup.string().required(addressRequired),
  countryCode: Yup.string().required(countryRequired),
  stateCode: Yup.string().required(stateRequired),
  cityCode: Yup.string().required(cityRequired),
  zipCode: Yup.string().required(postalCodeRequired),
});

// Merge both yup schema depending on the checkbox
export const mergedYupAgilisysSignUp = Yup.object().shape({
  ...yupAgilisysSignUp.fields,
  ...yupAgilisysSignUpAddress.fields,
});
