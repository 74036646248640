import React, { memo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItemButton } from "@mui/material";

import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";
import { languageList } from "@utils/lanugauge-settings/LanguageOptions";

import { SwitchLanguageText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  ArrowDown,
  Language,
  LanguageContainer,
  LanguageHeader,
  LanguageIn,
} from "./HeaderStyling";

const LanguageModal = ({
  langModalRef,
  headerBlacked,
  languageModal,
  setLanguageModal,
}) => {
  const location = useLocation();

  const navigate = useNavigate();

  const handleLanguageSelect = useCallback(
    (code) => {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, code);

      navigate(
        location.pathname.replace(location.pathname.split("/")[1], code)
      );

      window.location.reload(false);
    },
    [location.pathname, navigate]
  );

  const handleToggleLanguageModal = useCallback(() => {
    setLanguageModal((prev) => !prev);
  }, [setLanguageModal]);

  const currentLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
    ? localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
    : false;

  return (
    <Language
      onClick={handleToggleLanguageModal}
      ref={langModalRef}
      headerColored={headerBlacked}
    >
      <LanguageIn>
        {currentLanguage &&
          languageList?.filter(
            (el) => el.code === localStorage.getItem(LOCAL_STORAGE_LANGUAGE)
          )[0].name}

        <ArrowDown modalOpen={headerBlacked} />
      </LanguageIn>

      {languageModal && (
        <LanguageContainer>
          <LanguageHeader>
            <SwitchLanguageText />
          </LanguageHeader>

          <List component="div" aria-label="secondary mailbox folder">
            {languageList?.map((language, index) => {
              if (language.code !== currentLanguage) {
                return (
                  <ListItemButton
                    key={language.id}
                    onClick={() => handleLanguageSelect(language.code)}
                  >
                    <p>{language.name}</p>
                  </ListItemButton>
                );
              }

              return null;
            })}
          </List>
        </LanguageContainer>
      )}
    </Language>
  );
};

export default memo(LanguageModal);
