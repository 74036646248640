import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { CommonDescOne, CommonTitleThree } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { mq } from "@utils/media-query/mediaQuery";

// STYLE: CasinoDiningInformation.jsx
export const CasinoDiningImages = styled.div(() => ({
  marginTop: "8rem",
  marginBottom: "4rem",
}));

// STYLE: ResponsibleAccordion.jsx
export const ResponsibleAccordionAll = styled(Accordion)(() => ({
  boxShadow: "none",
}));

export const ResponsibleAccordionSummary = styled(AccordionSummary)(
  ({ theme }) => ({
    borderBottom: `4px solid ${theme.color.base_black}`,
    borderTop: "none",
  })
);

export const ResponsibleAccordionDetails = styled(AccordionDetails)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4.8rem 0",
  padding: "4.8rem 0",
}));

export const ResponsibleAccordionItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "3.2rem 0",
  padding: "3.2rem",
  borderRadius: "2.5rem",
  background: theme.color.blue_light_color,

  [mq("desktop")]: {
    padding: "4.8rem",
  },
}));

export const ResponsibleAccordionTitle = styled(CommonTitleThree)(
  ({ theme }) => ({
    fontWeight: theme.fontWeight.Black,
  })
);

export const ResponsibleAccordionSubTitle = styled(CommonDescOne)(() => ({
  maxWidth: "80%",
  margin: "0 auto",
  textAlign: "center",
}));

export const HtmlResponsible = styled(DangerouslyHtml)(({ theme }) => ({
  fontSize: "1.8rem",
  fontFamily: theme.fontFamily.secondaryFont,

  strong: {
    fontFamily: theme.fontFamily.primaryFont,
  },

  [mq("desktop")]: {
    fontSize: "2.4rem",
  },
}));

// STYLE: PledgeToOurPatrons.jsx
export const PledgeToOurPatronsContainer = styled.div(() => ({
  display: "grid",
  gap: "2.4rem",

  [mq("desktop")]: {
    gridTemplateAreas: `"a a b b"
    "c c c c"`,

    "&>*:nth-of-type(1)": { gridArea: "a" },

    "&>*:nth-of-type(2)": { gridArea: "b" },

    "&>*:nth-of-type(3)": { gridArea: "c" },
  },
}));

// STYLE: PledgeToOurEmployees.jsx
export const PledgeToOurEmployeesContainer = styled.div(() => ({
  display: "grid",
  gap: "2.4rem",

  [mq("desktop")]: {
    gridTemplateRows: "repeat(2, minmax(46rem, auto))",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

// STYLE: TogetherMakeDifference.jsx
export const TogetherMakeDifferenceContainer = styled.div(({ theme }) => ({
  display: "grid",
  gap: "2.4rem",
  maxWidth: "95rem",
  margin: "0 auto",

  [(mq("desktop"), mq("tablet"))]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

export const TogetherMakeDifferenceItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  placeItems: "center",
  gap: "3.2rem 0",
  textAlign: "center",
  maxWidth: "50rem",
  padding: "4.8rem",
  background: theme.color.tertiary_color,
  borderRadius: "2.5rem",
  color: theme.color.white,
}));

export const TogetherMakeDifferenceHeader = styled(CommonTitleThree)(
  ({ theme }) => ({
    textTransform: "uppercase",
    fontWeight: theme.fontWeight.Black,
  })
);

export const TogetherMakeDifferenceCanada = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  placeItems: "center",
  gap: "8rem 0",
}));

export const CasinoDiningWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  placeItems: "center",
  gap: "8rem 0",
}));
