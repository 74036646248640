import React, { memo, useRef, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";

import { SwiperArrowButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";

import {
  SliderTwoArrowWrapper,
  SliderTwoButtonWrapper,
  SliderTwoDesc,
  SliderTwoInformation,
  SliderTwoLeft,
  SliderTwoRight,
  SliderTwoSubTitle,
  SliderTwoTitle,
  SliderTwoWrapper,
} from "./SlidersStyling";

const SliderTwo = ({
  direction,
  className = "ellipsis-6",
  data,
  imageKey = "field_multiple_images",
  subTitle,
  button,
  filterType,
  desc,
  title,
}) => {
  const isDefault = useMediaQuery({ maxWidth: 1199 });

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const [swiper, setSwiper] = useState();

  const custom = {
    ...commonSwiperOptions,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
  };

  useEffect(() => {
    if (swiper) swiper.slideTo(0, 0);
  }, [filterType, swiper]);

  return (
    data && (
      <SliderTwoWrapper direction={direction} isSmall={!desc}>
        <SliderTwoLeft>
          {data && (
            <Swiper
              {...custom}
              style={{ height: "100%" }}
              onSwiper={setSwiper}
              onResize={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {data?.[imageKey] !== "" &&
                data?.[imageKey]?.map((image) => {
                  return (
                    <SwiperSlide key={image?.src}>
                      <ImageWrapper
                        ratio={isDefault ? "3 / 2" : "auto"}
                        radius={
                          isDefault
                            ? 0
                            : direction === "left"
                            ? "0 2.5rem 2.5rem 0"
                            : "2.5rem 0 0 2.5rem"
                        }
                      >
                        <Image src={image?.src} alt={image?.alt} />
                      </ImageWrapper>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}
        </SliderTwoLeft>

        <SliderTwoRight direction={direction}>
          <SliderTwoInformation>
            <SliderTwoSubTitle>{subTitle}</SliderTwoSubTitle>

            <SliderTwoTitle>
              <DangerouslyHtml value={title} />
            </SliderTwoTitle>

            {desc && (
              <SliderTwoDesc className={button ? className : ""}>
                <DangerouslyHtml value={desc} />
              </SliderTwoDesc>
            )}
          </SliderTwoInformation>

          {data && (
            <SliderTwoButtonWrapper>
              {button}

              <SliderTwoArrowWrapper noShow={data?.[imageKey]?.length <= 1}>
                <SwiperArrowButton
                  ref={navPrevRef}
                  direction="left"
                  design="border"
                />

                <SwiperArrowButton
                  ref={navNextRef}
                  direction="right"
                  design="border"
                />
              </SliderTwoArrowWrapper>
            </SliderTwoButtonWrapper>
          )}
        </SliderTwoRight>
      </SliderTwoWrapper>
    )
  );
};

export default memo(SliderTwo);
