import React from "react";

import { SliderFour } from "@components/sliders";
import { useGetHomeEntertainmentSection } from "@utils/api-requests/HomeRequests";

const HomeEntertainment = () => {
  const { data: homeEntertainmentSection } = useGetHomeEntertainmentSection();

  return (
    <SliderFour
      data={homeEntertainmentSection}
      titleKey="field_entertainment_name"
      descKey="field_full_description"
      subTitleKey="field_entertainment_sub_name"
      icon="field_image"
      iconNameKey="field_entertainment_type"
      onlyLinkKey="field_linked_url"
    />
  );
};

export default HomeEntertainment;
