import React, { memo } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageHero } from "@components/sub-page";
import { MuiBreadcrumbs } from "@components/item";
import { SliderSix } from "@components/sliders";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import { useGetEntertainmentDetail } from "@utils/api-requests/EntertainmentRequests";

const EntertainmentDetail = () => {
  const intl = useIntl();
  const alias = useParams().eventAlias;

  const {
    data: entertainmentDetail,
    isLoading,
    isError,
    error,
  } = useGetEntertainmentDetail(alias);

  const entertainmentDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-entertainment",
      }),
    },
    { id: 3, current: true, name: entertainmentDetail?.field_event_name },
  ];

  return (
    <div>
      <CustomMetatag metaData={entertainmentDetail} />

      <ErrorManage isLoading={isLoading} isError={isError} error={error}>
        <DetailPageHero data={entertainmentDetail} />
      </ErrorManage>

      <CommonContainer>
        <MuiBreadcrumbs
          breadcrumbArr={entertainmentDetailBC}
          pageTitle={entertainmentDetail?.field_event_name}
          value={entertainmentDetail?.field_event_full_description}
        />

        <SliderSix imageArr={entertainmentDetail?.field_multiple_images}
                   />
      </CommonContainer>
    </div>
  );
};

export default memo(EntertainmentDetail);
