import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { PageBottomContents } from "@components/layout";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { SliderSix } from "@components/sliders";
import {CasinoMemebershipTiers, } from "@pages/casino";
import { ErrorManage } from "@screens/index";
import { useGetCasinoDining } from "@utils/api-requests/CasinoRequests";
import { CustomMetatag } from "@utils/metatags";

import { CasinoDiningInformation } from ".";

const CasinoDiningDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: casinoDining,
    isFetching,
    isError,
    error,
  } = useGetCasinoDining();

  const servicesDetailBC = useMemo(() => {
    return [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-casino",
        }),
        href: "/casino",
      },
      {
        id: 3,
        current: true,
        name: casinoDining?.field_name,
      },
    ];
  }, [casinoDining?.field_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <SliderSix imageArr={casinoDining?.field_multiple_images} />,
    },
    {
      id: 2,
      contents: (
        <CasinoDiningInformation
          casinoDining
          data={casinoDining?.field_casino_dining_information}
        />
      ),
    },
    { id: 3, contents: <CasinoMemebershipTiers /> },
  ];

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={casinoDining} />

      <DetailPageHero data={casinoDining} />

      <CommonContainer>
        <>
          <NotificationToggle data={casinoDining} />

          <MuiBreadcrumbs
            breadcrumbArr={servicesDetailBC}
            pageTitle={casinoDining?.field_name}
            value={casinoDining?.field_full_description}
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={casinoDining} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default CasinoDiningDetail;
