import React from "react";

import { SecondaryButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  CareerLinksContainer,
  CareerLinksInformation,
  CareerLinksItem,
  CareerLinksText,
} from "@pages/about-us/AboutUsStyling";
import { ApplyText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { DangerouslyHtml } from "@components/item";
import { replaceAmpersand } from "@utils/modify-data/modifyData";

const CareerLinks = ({ data }) => {
  return (
    <CareerLinksContainer>
      {data?.map((career) => {
        return (
          <CareerLinksItem key={career?.field_link.text}>
            <ImageWrapper>
              <Image
                src={career?.field_thumbnail?.src}
                alt={career?.field_thumbnail?.alt}
              />
            </ImageWrapper>

            <CareerLinksInformation>
              <p>
                <DangerouslyHtml value={career?.field_link.text} />
              </p>

              <SecondaryButton
                buttonColor="white"
                outerLink={replaceAmpersand(career?.field_link.uri)}
              >
                <ApplyText />
              </SecondaryButton>
            </CareerLinksInformation>

            <CareerLinksText>
              <DangerouslyHtml value={career?.field_link.text} />
            </CareerLinksText>
          </CareerLinksItem>
        );
      })}
    </CareerLinksContainer>
  );
};

export default CareerLinks;
