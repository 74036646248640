import React, {useEffect, useRef, useState} from "react";
import { useMediaQuery } from "react-responsive";
import { Swiper } from "swiper/react";

import {
  CommonDescFive,
  CommonTitleFive,
  CommonTitleFour,
} from "@assets/styles/CommonStyles";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import {
  BorderButton,
  PrimaryButton,
  SecondaryButton,
  SwiperArrowButton,
} from "@components/buttons";
import {
  AllMomentumBenefitModal,
  SingleMomentumBenefitModal,
} from "@components/modal/modal-list";
import { DangerouslyHtml } from "@components/item";

import {
  MyProfileDashboardArrowLeft,
  MyProfileDashboardArrowRight,
  MyProfileDashboardArrowWrapper,
  MyProfileDashboardBenefitList,
  MyProfileDashboardButtons,
  MyProfileDashboardInformation,
  MyProfileDashboardSlider,
} from "./MyProfileStyling";
import { IsDefault, IsDesktop } from "@utils/media-query/mediaQuery";

const MyProfileDashboard = ({
  data,
  currentIndex,
  setCurrentIndex,
  userData,
}) => {
  const [tierNow, setTierNow] = useState()

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const navMobilePrevRef = useRef(null);
  const navMobileNextRef = useRef(null);

  const isDesktop = useMediaQuery({ minWidth: 1200 });

  const [singleBenefitOpen, setSingleBenefitOpen] = useState(false);
  const [allBenefitOpen, setAllBenefitOpen] = useState(false);

  const custom = {
    ...commonSwiperOptions,
    slidesPerView: 1,
    loopedSlides: 1,
    spaceBetween: 0,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
  };

  const currentPoints =
    userData && userData[1]?.BucketBalanceInPoints;

  function getRemainPoints(tier) {
    return (tier?.field_points_breakpoints - currentPoints).toLocaleString(
      "ko-KR"
    );
  }

  return (
    <>
      <SingleMomentumBenefitModal
        openModal={singleBenefitOpen}
        setOpenModal={setSingleBenefitOpen}
        currentIndex={tierNow}
      />

      <AllMomentumBenefitModal
        openModal={allBenefitOpen}
        setOpenModal={setAllBenefitOpen}
      />

      {data && (
        <div style={{ position: "relative" }}>
          {data.field_tier?.slice(currentIndex, 3)?.length > 1 && (
            <>
              <IsDefault>
                <MyProfileDashboardArrowWrapper>
                  <SwiperArrowButton ref={navMobilePrevRef} direction="left" />

                  <SwiperArrowButton ref={navMobileNextRef} direction="right" />
                </MyProfileDashboardArrowWrapper>
              </IsDefault>

              <IsDesktop>
                <SwiperArrowButton
                  ref={navPrevRef}
                  direction="left"
                  custom={MyProfileDashboardArrowLeft}
                />

                <SwiperArrowButton
                  ref={navNextRef}
                  direction="right"
                  custom={MyProfileDashboardArrowRight}
                />
              </IsDesktop>
            </>
          )}

          <Swiper
            {...custom}
            onResize={(swiper) => {
              if (isDesktop) {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
              } else {
                swiper.params.navigation.prevEl = navMobilePrevRef.current;
                swiper.params.navigation.nextEl = navMobileNextRef.current;
              }

              swiper.navigation.init();
              swiper.navigation.update();
            }}
            onInit={(swiper) => {
              swiper.slideTo(currentIndex, 0);

              if (isDesktop) {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
              } else {
                swiper.params.navigation.prevEl = navMobilePrevRef.current;
                swiper.params.navigation.nextEl = navMobileNextRef.current;
              }
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            onSlideChange={(swiper) => {
              setTierNow(swiper.realIndex);
            }}
          >
            {data?.field_tier?.slice(currentIndex, 3)?.map((tier, i) => {
              return (
                <MyProfileDashboardSlider
                  url={tier.field_thumbnail.src}
                  key={tier.field_name}
                >
                  <MyProfileDashboardInformation
                    dark={i === 2 - currentIndex}
                  >
                    <div>
                      <CommonTitleFour uppercase>
                        {tier.field_name}
                      </CommonTitleFour>

                      <CommonTitleFive>
                        <DangerouslyHtml
                          value={
                            i === currentIndex - currentIndex
                              ? data?.field_current_points_texts.replaceAll(
                                  "{{number}}",
                                  currentPoints
                                )
                              : data?.field_ea.replaceAll(
                                  "{{number}}",
                                  getRemainPoints(tier)
                                )
                          }
                        />
                      </CommonTitleFive>
                    </div>

                    <MyProfileDashboardBenefitList>
                      {data?.field_percent_labels?.map((_, idx) => {
                        return (
                          <li key={data?.field_percent_labels[idx]}>
                            <CommonDescFive>
                              {data?.field_percent_labels[idx]}
                            </CommonDescFive>

                            <CommonTitleFive>
                              {tier.field_percent_for_each_label[idx]}
                            </CommonTitleFive>
                          </li>
                        );
                      })}
                    </MyProfileDashboardBenefitList>

                    <MyProfileDashboardButtons>
                      {/*<BorderButton*/}
                      {/*  noArrow*/}
                      {/*  buttonColor={*/}
                      {/*    i === 2 - currentIndex ? "primary" : ""*/}
                      {/*  }*/}
                      {/*  customstyle={{ width: "fit-content", flex: "none" }}*/}
                      {/*  buttonEvent={() => setSingleBenefitOpen(true)}*/}
                      {/*>*/}
                      {/*  {data?.field_full_list_button_name}*/}
                      {/*</BorderButton>*/}

                      {i === 2 - currentIndex ? (
                        <PrimaryButton
                          noArrow
                          buttonEvent={() => setAllBenefitOpen(true)}
                          customstyle={{
                            width: isDesktop ? "100%" : "fit-content",
                          }}
                        >
                          {data?.field_compare_tiers_button_name}
                        </PrimaryButton>
                      ) : (
                        <SecondaryButton
                          noArrow
                          buttonColor="white"
                          customstyle={{
                            width: isDesktop ? "100%" : "fit-content",
                          }}
                          buttonEvent={() => setAllBenefitOpen(true)}
                        >
                          {data?.field_compare_tiers_button_name}
                        </SecondaryButton>
                      )}
                    </MyProfileDashboardButtons>
                  </MyProfileDashboardInformation>
                </MyProfileDashboardSlider>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default MyProfileDashboard;
