import React, { useMemo } from "react";

import {
  ImageWrapperFigure,
  ImageWrapperImage,
  ReactPlayerWrapper,
  VideoContainer,
} from "./ItemStyling";

export const ImageWrapper = ({
  width,
  height,
  minHeight,
  ratio,
  radius,
  children,
  custom,
}) => {
  return (
    <ImageWrapperFigure
      width={width}
      height={height}
      ratio={ratio}
      minHeight={minHeight}
      radius={radius}
      custom={custom}
    >
      {children}
    </ImageWrapperFigure>
  );
};

export const Image = ({ src, alt, scale, radius, custom }) => {
  const modifiedSrc = useMemo(() => {
    if (src?.includes("static")) {
      return src;
    }

    return `${process.env.REACT_APP_SERVER_URL}${src}`;
  }, [src]);

  return (
    src && (
      <ImageWrapperImage
        src={modifiedSrc}
        alt={alt}
        scale={scale}
        radius={radius}
        loading="lazy"
        custom={custom}
      />
    )
  );
};

export const Video = ({ src, status, ...props }) => {
  if (src?.charAt(0) === "/") {
    src = src.slice(1); // Remove the first character (the '/')
  }

  return (
    src && (
      <VideoContainer>
        <ReactPlayerWrapper
          {...props}
          width="100%"
          height="auto"
          url={`${process.env.REACT_APP_SERVER_URL}${src}`}
          playing={status.playing}
          muted={status.muted}
          controls={status.controls}
        />
      </VideoContainer>
    )
  );
};
