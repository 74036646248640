import React, { useMemo, useState } from "react";

import { SecondaryButton } from "@components/buttons";
import { SliderTwo } from "@components/sliders";
import { ListWithTabs } from "@components/list";
import { ErrorManage } from "@screens/index";
import { SeeTheMenuText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetRestaurantSignatureList } from "@utils/api-requests/EatDrinkRequests";
import { downloadPdf } from "@utils/modify-data/modifyData";

import { SignatureMenuContainer } from "../EatDrinkStyling";

const SignatureMenu = ({ menuType }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const {
    data: restaurantSignatureList,
    isLoading,
    isError,
    error,
  } = useGetRestaurantSignatureList(menuType);

  const currentData = useMemo(() => {
    return restaurantSignatureList?.[currentTab];
  }, [currentTab, restaurantSignatureList]);

  return (
    <SignatureMenuContainer>
      {restaurantSignatureList?.length > 0 && (
        <ErrorManage isLoading={isLoading} isError={isError} error={error}>
          <ListWithTabs
            tabArr={restaurantSignatureList}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            tabKey="uuid"
            titleKey="field_signature_item"
          >
            <SliderTwo
              direction="left"
              data={currentData}
              subTitle="SIGNATURE MENU"
              title={currentData?.field_signature_item}
              button={
                <SecondaryButton
                  buttonEvent={() =>
                    downloadPdf(
                      currentData?.field_signature_item,
                      currentData.field_signature_menu_file
                    )
                  }
                  buttonColor="white"
                  noArrow
                >
                  <SeeTheMenuText />
                </SecondaryButton>
              }
            />
          </ListWithTabs>
        </ErrorManage>
      )}
    </SignatureMenuContainer>
  );
};

export default SignatureMenu;
