import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";

import {
  FrameFiveContainer,
  FrameFiveDesc,
  FrameFiveItem,
  FrameFiveTitle,
} from "./FrameStyling";
import { useMediaQuery } from "react-responsive";
import { DangerouslyHtml } from "@components/item";

const FrameFive = ({ data }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });

  return (
    data?.length > 0 && (
      <FrameFiveContainer>
        {data?.map((item) => {
          return (
            <FrameFiveItem key={item.title}>
              <ImageWrapper
                ratio={isDesktop ? "3 / 1" : "16 / 9"}
                radius={isDesktop ? "2.5rem" : "1.5rem"}
              >
                <Image src={item.image?.src} alt={item.image?.alt} />
              </ImageWrapper>

              <FrameFiveTitle>
                <DangerouslyHtml value={item.title} />
              </FrameFiveTitle>

              <FrameFiveDesc>
                <CommonDescTwo>
                  <DangerouslyHtml value={item.description} />
                </CommonDescTwo>
              </FrameFiveDesc>
            </FrameFiveItem>
          );
        })}
      </FrameFiveContainer>
    )
  );
};

export default FrameFive;
