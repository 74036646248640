import React, { useMemo, useState } from "react";
import he from "he";

import { ListWithTabs } from "@components/list";
import { GalleryOne } from "@components/layout";
import {
  useGetAboutUsGalleryCategory,
  useGetAboutUsGalleryList,
} from "@utils/api-requests/AboutUsRequests";
import { concatAllValue } from "@utils/modify-data/modifyData";

const AboutUsGalleryList = () => {
  const [filterType, setFilterType] = useState("all");

  const { data: aboutUsGalleryList } = useGetAboutUsGalleryList(filterType);

  const { data: aboutUsGalleryCategory } = useGetAboutUsGalleryCategory();

  const galleryCategories = useMemo(() => {
    const result = aboutUsGalleryCategory?.map((item) => {
      return {
        title: he.decode(item.name),
        category_id: item.tid,
      };
    });

    return result;
  }, [aboutUsGalleryCategory]);

  return (
    <ListWithTabs
      tabArr={concatAllValue(galleryCategories)}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <GalleryOne data={aboutUsGalleryList} gallery />
    </ListWithTabs>
  );
};

export default AboutUsGalleryList;
