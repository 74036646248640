import React, { memo, useMemo, useState } from "react";

import { image } from "src/theme";
import { CommonDescOne } from "@assets/styles/CommonStyles";
import { FrameFour } from "@components/frame";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { MomentumModal } from "@components/modal/modal-list";
import {
  MomentumLayoutContainer,
  MomentumLayoutLink,
} from "@components/frame/FrameStyling";
import {
  JoinMomentumMembershipText,
  JoinOurNewsletterText,
  LearnMoreText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  CommonContainer,
  MomentumLayoutButtonWrapper,
  MomentumLayoutInformation,
} from "./ContainerStyle";

const MomentumLayout = ({ data, noLearnMore }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const momentumData = useMemo(() => {
    if (data?.field_momentum_list?.length > 0) {
      return data?.field_momentum_list?.[0];
    }

    return undefined;
  }, [data?.field_momentum_list]);

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  // const handleOpenModal = useCallback(() => {
  //   setModalOpen(true);
  // }, []);

  // const goToMomentumSignUp = useCallback(() => {
  //   navigate(`${baseUrl}/momentum/join-momentum`);
  // }, [baseUrl, navigate]);

  return (
    <CommonContainer custom={MomentumLayoutContainer}>
      <MomentumModal
        openModal={modalOpen}
        setOpenModal={setModalOpen}
        complete={complete}
        setComplete={setComplete}
      />

      <FrameFour
        mainTitle={
          momentumData?.field_title ? momentumData?.field_title : "MOMENTUM"
        }
        ratio="2 / 1"
        url={
          momentumData?.field_thumbnail[0]?.src
            ? momentumData?.field_thumbnail[0]?.src
            : image.momentumThumbnail
        }
        alt={
          momentumData?.field_thumbnail[0]?.alt
            ? momentumData?.field_thumbnail[0]?.alt
            : "Momentum Thumbnail"
        }
      >
        <MomentumLayoutInformation>
          <CommonDescOne>
            {momentumData?.field_description ? (
              <DangerouslyHtml value={momentumData?.field_description} />
            ) : (
              <JoinMomentumMembershipText />
            )}
          </CommonDescOne>

          {!noLearnMore && (
            <MomentumLayoutLink to={`${baseUrl}/momentum/tiers-benefits`}>
              {momentumData?.field_link_text ? (
                momentumData?.field_link_text
              ) : (
                <LearnMoreText />
              )}

              <img
                src={image.learnMoreArrow.default}
                alt="Right Arrow"
                loading="lazy"
                width={20}
              />
            </MomentumLayoutLink>
          )}
        </MomentumLayoutInformation>

        {sessionStorage.getItem("signupAction") !== "false" && (
          <MomentumLayoutButtonWrapper>
            <PrimaryButton linkTo="/momentum/join-momentum">
              {momentumData?.field_button_name ? (
                momentumData?.field_button_name
              ) : (
                <JoinOurNewsletterText />
              )}
            </PrimaryButton>
          </MomentumLayoutButtonWrapper>
        )}
      </FrameFour>
    </CommonContainer>
  );
};

export default memo(MomentumLayout);
