import React, { useMemo } from "react";

import { PageContainer, SubpageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout } from "@components/layout";
import { CustomMetatag } from "@utils/metatags";

import {
  AboutUsGallery,
  AboutUsGettingHere,
  AboutUsInspireResort,
  AboutUsMediaCenter,
  AboutUsMore,
  AboutUsPrivacyPolicy,
  AboutUsMoheganResort,
  AboutUsCareers,
} from ".";

const AboutUs = ({ pageInfo }) => {
  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      contents: <AboutUsInspireResort />,
    },
    { id: 2, contents: <AboutUsCareers /> },
    { id: 3, contents: <AboutUsMediaCenter /> },
    { id: 4, contents: <AboutUsGettingHere /> },
    { id: 5, contents: <AboutUsMoheganResort /> },
    { id: 6, contents: <AboutUsPrivacyPolicy /> },
    { id: 7, contents: <AboutUsMore /> },
    { id: 8, contents: <AboutUsGallery /> },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <CommonContainer>
        <PageContainer data={pageData} sectionArray={sectionArray} />

        <MomentumLayout data={pageInfo} />
      </CommonContainer>
    </div>
  );
};

export default AboutUs;
