import React, { memo, useCallback } from "react";

import { image } from "src/theme";

import {
  ArrowButton,
  ArrowImage,
  ArrowImageButtons,
  MobileScrollArrowText,
} from "@components/buttons/ButtonsStyling";
import { scrollToElementHorizontal } from "@utils/scroll-behavior/ScrollIntoView";
import { IsDefault } from "@utils/media-query/mediaQuery";

export const MobileSlideText = () => {
  return (
    <IsDefault>
      <MobileScrollArrowText>
        <hr />

        <span>Slide</span>

        <hr />
      </MobileScrollArrowText>
    </IsDefault>
  );
};

const MobileScrollArrow = ({ length, currentItem, setCurrentItem, refs }) => {
  const prevHandler = useCallback(() => {
    if (currentItem === 0) {
      return;
    }

    setCurrentItem((prev) => prev - 1);

    scrollToElementHorizontal(refs, currentItem - 1);
  }, [currentItem, refs, setCurrentItem]);

  const nextHandler = useCallback(() => {
    if (length <= currentItem + 1) {
      return;
    }
    setCurrentItem((prev) => prev + 1);

    scrollToElementHorizontal(refs, currentItem + 1);
  }, [currentItem, length, refs, setCurrentItem]);

  return (
    <ArrowImageButtons>
      <ArrowButton onClick={prevHandler}>
        <ArrowImage
          src={image.sliderArrow.default}
          alt="left arrow icon"
          direction="left"
        />
      </ArrowButton>

      <MobileSlideText />

      <ArrowButton onClick={nextHandler}>
        <ArrowImage
          src={image.sliderArrow.default}
          alt="left arrow icon"
          direction="right"
        />
      </ArrowButton>
    </ArrowImageButtons>
  );
};

export default memo(MobileScrollArrow);
