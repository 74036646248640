import { useCallback } from "react";
import axios from "axios";
import { useQuery } from "react-query";

import { checkIncludes } from "@utils/modify-data/modifyData";

import {
  meetFAQCategoriesUrl,
  meetFAQListUrl,
  meetInfoUrl,
  meetInspireMeetingSuccessUrl,
  meetMeetPackageUrl,
  meetOfferRecommendationUrl,
  meetServicesUrl,
  meetSightseeingDetailUrl,
  meetSightseeingUrl,
  meetWeddingDetailUrl,
  meetWeddingEventUrl,
  meetWhyUsUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Meet.jsx
export function useGetMeetOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["meetOfferRecommendation", language, localStorage],
    getMeetOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// Meet.jsx
export function useGetMeetInformation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetInformation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetInfoUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["meetInformation", language, localStorage],
    getMeetInformation,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetingSuccess.jsx
export function useGetMeetMeetingSuccess() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetMeetingSuccess = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetInspireMeetingSuccessUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["inspireMeetingSuccess", language, localStorage],
    getMeetMeetingSuccess,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetingSuccessDetail.jsx
export function useGetMeetMeetingSuccessDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetMeetingSuccessDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetInspireMeetingSuccessUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["meetingSuccessDetail", language, localStorage, alias],
    getMeetMeetingSuccessDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetingSuccessDetail.jsx for left and right arrow
export function useGetMeetingSuccessAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetingSuccessAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetInspireMeetingSuccessUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["meetingSuccessAliasGroup", language, localStorage],
    getMeetingSuccessAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// WeddingEvent.jsx
export function useGetWeddingEventList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getWeddingEventList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetWeddingEventUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["weddingEventList", language, localStorage],
    getWeddingEventList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// WeddingEventDetail.jsx
export function useGetWeddingEventDetail(meetTypes) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getWeddingEventDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetWeddingDetailUrl}?language=${language}`
    );

    if (status === 200) {
      return data.filter((el) =>
        checkIncludes(el.field_meet_types, meetTypes)
      )?.[0];
    }
  }, [language, meetTypes]);

  return useQuery(
    ["weddingEventDetail", language, localStorage, meetTypes],
    getWeddingEventDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// WeddingEventDetail.jsx
export function useGetMeetPackage() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetPackageDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetMeetPackageUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["meetPackageDetail", language, localStorage],
    getMeetPackageDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// EventsDetail.jsx for left and right arrow
export function useGetEventsUuidGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getEventsUuidGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetWeddingEventUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return "/" + item.field_meet_types;
      });
    }
  }, [language]);

  return useQuery(
    ["eventsUuidGroup", language, localStorage],
    getEventsUuidGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetServices.jsx
export function useGetMeetServices() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetServices = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetServicesUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(["meetServices", language, localStorage], getMeetServices, {
    notifyOnChangeProps: "tracked",
  });
}

// MeetServicesDetail.jsx
export function useGetMeetServicesDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetServiceDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetServicesUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["meetServiceDetail", language, localStorage, alias],
    getMeetServiceDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetServicesDetail.jsx for left and right arrow
export function useGetMeetServicesAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetServiceAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetServicesUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["meetServicesAliasGroup", language, localStorage],
    getMeetServiceAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetSightseeing.jsx
export function useGetMeetSightSeeing() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetSightSeeing = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetSightseeingUrl}?language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["meetSightseeing", language, localStorage],
    getMeetSightSeeing,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetSightseeingDetail.jsx
export function useGetMeetSightseeingDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetSightseeing = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetSightseeingDetailUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["meetSightseeingDetail", language, localStorage, alias],
    getMeetSightseeing,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// MeetSightseeingDetail.jsx for left and right arrow
export function useGetMeetSightseeingAliasGroup() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetSightseeingAliasGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetSightseeingUrl}?language=${language}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language]);

  return useQuery(
    ["meetSightseeingAliasGroup", language, localStorage],
    getMeetSightseeingAliasGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

export function useGetMeetWhyUsDetail(category) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getMeetWhyUsList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetWhyUsUrl}?language=${language}`
    );

    if (status === 200) {
      if (category) {
        return data?.filter((el) =>
          checkIncludes(el.field_faq_category, category)
        )[0];
      } else {
        return data;
      }
    }
  }, [category, language]);

  return useQuery(
    ["meetWhyUsList", language, localStorage, category],
    getMeetWhyUsList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// FAQ.jsx
export function useGetFAQCategoryList() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getFAQCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetFAQCategoriesUrl}&language=${language}`
    );

    if (status === 200) {
      return data;
    }
  }, [language]);

  return useQuery(
    ["FAQCategoryList", language, localStorage],
    getFAQCategoryList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

export function useGetFAQList(page, filterType) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getFAQList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${meetFAQListUrl}?language=${language}&page=${
        page - 1
      }&type=${filterType}`
    );

    if (status === 200) {
      return data;
    }
  }, [language, page, filterType]);

  return useQuery(
    ["FAQList", language, localStorage, page, filterType],
    getFAQList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}
