const HEADER_HEIGHT = "180px";
const HEADER_MOBILE_HEIGHT = "100px";

export function scrollInToViewBasic(scrollRef) {
  scrollRef.current.scrollIntoView({ behavior: "smooth" });

  scrollRef.current.scrollTo({
    behavior: "smooth",
    block: "nearest",
    inline: "center",
  });
}

export function scrollToTargetAdjusted(scrollRef) {
  if (matchMedia("screen and (min-width: 1024px)").matches) {
    scrollRef.current.style.scrollMargin = HEADER_HEIGHT;
  } else {
    scrollRef.current.style.scrollMargin = HEADER_MOBILE_HEIGHT;
  }

  scrollRef.current.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
    inline: "center",
  });

  scrollRef.current.scrollTo({
    behavior: "smooth",
    block: "nearest",
    inline: "center",
  });
}

export const scrollToElement = (refs, idx) => {
  if (refs.current[idx]) {
    refs.current[idx].style.scrollMargin = HEADER_HEIGHT;

    refs.current[idx].scrollIntoView({ behavior: "smooth" });

    refs.current[idx].scrollIntoView({
      behavior: "smooth",
    });
  }
};

export const scrollToElementHorizontal = (refs, idx) => {
  if (refs.current[idx]) {
    refs.current[idx].scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
  }
};
