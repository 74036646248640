import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { image } from "src/theme";

import { CommonDescTwo, CommonTitleThree } from "@assets/styles/CommonStyles";
import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { DangerouslyHtml } from "@components/item";
import { PrimaryButton } from "@components/buttons";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  MediaArticleContainer,
  MediaArticleContents,
  MediaArticleHtmlCustom,
} from "@pages/about-us/AboutUsStyling";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsMediaCenterArticle } from "@utils/api-requests/AboutUsRequests";
import { BackToMediaCenterText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const MediaArticle = ({ data }) => {
  const search = useLocation().search;

  const page = useMemo(() => {
    const params = new URLSearchParams(search);

    let page = params.get("page");

    return page;
  }, [search]);

  return (
    <CommonContentButtonWrapper>
      <MediaArticleContainer>
        <img
          loading="lazy"
          src={image.articleLogo.default}
          alt="Inspire logo"
          width={160}
          height={160}
        />

        <CommonTitleThree>
          <DangerouslyHtml value={data?.field_name} />
        </CommonTitleThree>

        <MediaArticleContents>
          <CommonDescTwo>
            <DangerouslyHtml
              value={data?.field_full_description}
              custom={MediaArticleHtmlCustom}
            />
          </CommonDescTwo>
        </MediaArticleContents>
      </MediaArticleContainer>

      <PrimaryButton linkTo={`/about-us/media-center?page=${page}`} noArrow>
        <BackToMediaCenterText />
      </PrimaryButton>
    </CommonContentButtonWrapper>
  );
};

const MediaCenterArticle = () => {
  const alias = useParams().mediaAlias;
  const {
    data: aboutUsMediaCenterArticle,
    isLoading,
    isError,
    error,
  } = useGetAboutUsMediaCenterArticle(alias);

  const sectionArray = [
    {
      id: 1,
      contents: <MediaArticle data={aboutUsMediaCenterArticle} />,
    },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <DetailPageHero data={aboutUsMediaCenterArticle} />

      <CommonContainer>
        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsMediaCenterArticle} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MediaCenterArticle;
