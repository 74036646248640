import React from "react";
import { useIntl } from "react-intl";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { PrimaryButton } from "@components/buttons";
import { TableFour } from "@components/table";
import { NoticeText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import {
  MembershipTierStandardContainer,
  MembershipTierStandardList,
  MembershipTierStandardTitle,
} from "../MomentumStyling";
import { DangerouslyHtml } from "@components/item";

const MembershipTierStandard = ({ data }) => {
  const intl = useIntl();

  const tierStandardList = [
    {
      id: 1,
      title: intl.formatMessage({
        id: "lang-redemption-of-points",
      }),
      contents: data?.field_redemption_of_points?.split(", "),
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: "lang-expiration-of-points",
      }),
      contents: data?.field_expiration_of_points?.split(", "),
    },
  ];

  const category = [
    {
      name: intl.formatMessage({
        id: "lang-evaluation-period",
      }),
      field: "evaluation_period",
    },
    {
      name: intl.formatMessage({
        id: "lang-start-date",
      }),
      field: "start_date",
    },
    {
      name: intl.formatMessage({
        id: "lang-maintenance-period",
      }),
      field: "maintenance_period",
    },
  ];

  return (
    <CommonContentButtonWrapper>
      <TableFour data={data} category={category} />
      <MembershipTierStandardContainer>
        {tierStandardList?.map((tierStandard) => {
          return (
            <div key={tierStandard.title}>
              <MembershipTierStandardTitle>
                <DangerouslyHtml value={tierStandard.title} />
              </MembershipTierStandardTitle>

              <MembershipTierStandardList>
                {tierStandard?.contents?.map((item) => {
                  return (
                    <li key={item}>
                      <CommonDescOne>
                        <DangerouslyHtml value={item} />
                      </CommonDescOne>
                    </li>
                  );
                })}
              </MembershipTierStandardList>
            </div>
          );
        })}
      </MembershipTierStandardContainer>

      <PrimaryButton linkTo={`/about-us/customer-center?filter=284`}>
        <NoticeText />
      </PrimaryButton>
    </CommonContentButtonWrapper>
  );
};

export default MembershipTierStandard;
