export const MOMENTUM_BENEFIT_CATEGORY = [
  { name: "Hotel", field: "hotel_benefits" },
  { name: "Food and Beverage", field: "dining_benefits" },
  { name: "Shopping", field: "shoppng_benefits" },
  { name: "Attractions & Entertainment", field: "entertainment_benfits" },
  { name: "Transportation", field: "parking_benefits" },
  { name: "Loyalty", field: "personal_privileges" },
];

export const MAIN_AD_BANNER_HEIGHT = "20rem";

export const SCROLL_INTO_VIEW_TIMER = 2000;

export const QUERY_KEY_USER_LOGGED_IN = "userLoggedIn";
export const LOCAL_STORAGE_LANGUAGE = "INSPIRE-language";
export const LOCAL_STORAGE_USER_ID = "INSPIRE-user";
export const LOCAL_STORAGE_VISTED = "INSPIRE-home-visited";
export const LOCAL_STORAGE_REMEMBER = "INSPIRE-remember-me";
export const LOCAL_STORAGE_USER = "INSPIRE-user-data";
