import { CommonDescOne, CommonDescTwo } from "@assets/styles/CommonStyles";
import styled from "@emotion/styled";

export const SplashBayDomeSectionDesc = styled(CommonDescOne)(() => ({
  marginTop: "2rem",
}));

export const HowToReserveContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "7.2rem 0",

  "&>section": {
    width: "100%",
  },
}));

export const HowToReserveSectionTitle = styled.div(({ theme }) => ({
  marginTop: "8rem",
  marginBottom: "2rem",
  fontSize: "4rem",
  fontWeight: theme.fontWeight.Black,
  textAlign: "center",
  textTransform: "uppercase",
  color: theme.color.primary_color,
}));

export const HowToReserveSectionDesc = styled(CommonDescTwo)(() => ({
  maxWidth: "80%",
  textAlign: "center",
  margin: "0 auto",
}));

export const HowToReserveFloorGuideButton = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0 3rem",
}));
