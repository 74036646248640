import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { FrameTwo } from "@components/frame";
import { RoomReservationModal } from "@components/modal/modal-list";
import {
  RoomPreviewInformation,
  RoomPreviewLabel,
} from "@pages/stay/StayStyling";
import {
  RoomConfigurationText,
  RoomSizeText,
  RoomTypeText,
  ViewTypeText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { SecondaryButton } from "@components/buttons";
import { downloadPdf } from "@utils/modify-data/modifyData";

const RoomPreview = ({ data, uuid }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    setCurrentTab(0);
  }, [uuid]);

  const bedNames = useMemo(() => {
    const result = data?.map((item, idx) => {
      return {
        title: item.bed_name,
        category_id: idx,
      };
    });

    return result;
  }, [data]);

  const currentData = useMemo(() => {
    if (currentTab) {
      return data?.[currentTab];
    } else {
      return data?.[0];
    }
  }, [currentTab, data]);

  return (
    <div>
      <RoomReservationModal openModal={openModal} setOpenModal={setOpenModal} />

      {bedNames && (
        <ListWithTabs
          tabArr={bedNames}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        >
          <FrameTwo
            subTitle={<RoomConfigurationText />}
            title={currentData?.bed_name}
            desc={currentData?.short_description}
            image={currentData?.thumbnail}
            button={
              currentData?.field_pdf_download.src ? (
                <SecondaryButton
                  buttonColor="white"
                  buttonEvent={() =>
                    downloadPdf(
                      "floor plan",
                      currentData?.field_pdf_download.src
                    )
                  }
                >
                  floor plan
                </SecondaryButton>
              ) : null
            }
          >
            <RoomPreviewInformation>
              <li>
                <RoomPreviewLabel>
                  <RoomSizeText />:
                </RoomPreviewLabel>

                {currentData?.room_size}
              </li>

              <li>
                <RoomPreviewLabel>
                  <RoomTypeText />:
                </RoomPreviewLabel>

                {currentData?.bed_type}
              </li>

              {currentData?.view_type !== null ? (
                <li>
                  <RoomPreviewLabel>
                    <ViewTypeText />:
                  </RoomPreviewLabel>

                  {currentData?.view_type}
                </li>
              ) : null}
            </RoomPreviewInformation>
          </FrameTwo>
        </ListWithTabs>
      )}
    </div>
  );
};

export default RoomPreview;
