import React from "react";

import {
  FrameThreeInformation,
  FrameThreeLeft,
  FrameThreeList,
  FrameThreeLogo,
  FrameThreeRight,
} from "./FrameStyling";

const FrameThree = ({ data, children, noImage, imageKey = "field_logo" }) => {
  return (
    <FrameThreeInformation noImage={noImage} className="information-wrapper">
      {data?.[imageKey] !== "" &&
        data?.[imageKey] &&
        data?.[imageKey].length > 0 && (
          <FrameThreeLeft>
            <FrameThreeLogo>
              <img
                loading="lazy"
                width="100%"
                src={`${process.env.REACT_APP_SERVER_URL}${data?.[imageKey][0]?.src}`}
                alt={data?.[imageKey][0]?.alt}
              />
            </FrameThreeLogo>
          </FrameThreeLeft>
        )}

      <FrameThreeRight>
        <FrameThreeList>{children}</FrameThreeList>
      </FrameThreeRight>
    </FrameThreeInformation>
  );
};

export default FrameThree;
