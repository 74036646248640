import React, { useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import { SwiperArrowButton } from "@components/buttons";
import { PdfPreview } from "@components/item";
import {
  MonthlyCalendarContainer,
  MonthlyCalendarSliderWrapper,
} from "@pages/entertainment/EntertainmentStyling";

const MonthlyCalendar = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    slidesPerView: 1,
    loopedSlides: 1,
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    loop: false,
  };

  const refinedMonthData = useMemo(() => {
    if (data?.[slideIndex]?.field_book_splash_bay_link) {
      const split = data?.[slideIndex]?.field_book_splash_bay_link.split("-");
      return split[0] + " / " + split[1];
    }
  }, [data, slideIndex]);

  return (
    <MonthlyCalendarContainer>
      <div>
        <MonthlyCalendarSliderWrapper>
          <SwiperArrowButton ref={navPrevRef} direction="left" />

          {refinedMonthData}

          <SwiperArrowButton ref={navNextRef} direction="right" />
        </MonthlyCalendarSliderWrapper>
      </div>

      <Swiper
        {...custom}
        style={{ width: "100%" }}
        onSlideChange={(swiper) => {
          setSlideIndex(swiper.realIndex);
        }}
        onResize={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navPrevRef.current;
          swiper.params.navigation.nextEl = navNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <PdfPreview
                ratio="1 / 1"
                pdfName="Montly Schedule"
                pdfLink={item?.field_floor_guide?.src}
                noButton
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </MonthlyCalendarContainer>
  );
};

export default MonthlyCalendar;
