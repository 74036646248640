import React from "react";

import { CommonTitleTwo } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { GradientOverlay } from "@components/item/Overlays";
import {
  BoxListFiveContainer,
  BoxListFiveDesc,
  BoxListFiveGridArea,
  BoxListFiveInformation,
} from "@components/box-list/BoxListStyling";

const PlanningCatering = ({ data }) => {
  return (
    <BoxListFiveContainer length={2}>
      {data?.map((item, idx) => {
        return (
          <BoxListFiveGridArea key={idx}>
            <ImageWrapper
              radius="1.5rem"
              ratio={idx === 0 || idx === 1 ? "3 / 2" : "1 / 1"}
            >
              <Image src={item.thumbnail?.src} alt={item.thumbnail?.alt} />

              <BoxListFiveInformation>
                <CommonTitleTwo>
                  <DangerouslyHtml value={item.name[0]?.value} />
                </CommonTitleTwo>

                <BoxListFiveDesc className="ellipsis-3">
                  <DangerouslyHtml value={item.description} />
                </BoxListFiveDesc>
              </BoxListFiveInformation>

              <GradientOverlay opacity="0.15" />
            </ImageWrapper>
          </BoxListFiveGridArea>
        );
      })}
    </BoxListFiveContainer>
  );
};

export default PlanningCatering;
