import React, { useCallback } from "react";

import { DangerouslyHtml } from "@components/item";
import { SecondaryButton } from "@components/buttons";
import { useGetKeepInTouchModal } from "@utils/api-requests/GlobalRequests";
import { IsDesktop } from "@utils/media-query/mediaQuery";
import { CloseText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import { CommonModal } from "..";
import { KeepInTouchForm } from ".";
import {
  MomentumModalButton,
  MomentumModalDesc,
  RoomReservationModalContainer,
  RoomReservationModalRight,
  MomentumModalTitle,
} from "../ModalStyling";
import { ErrorManage } from "@screens/index";

const MomentumModal = ({ openModal, setOpenModal, complete, setComplete }) => {
  const { data: keepInTouchModal, isError, error } = useGetKeepInTouchModal();

  const handleClose = useCallback(() => {
    setOpenModal(false);

    setComplete(false);
  }, [setComplete, setOpenModal]);

  const imageStyle = {
    position: "sticky",
    top: 0,
    backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}${keepInTouchModal?.field_image[0]?.src}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    alignSelf: "stretch",
  };

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonModal
        modalName={keepInTouchModal?.field_modal_title}
        openModal={openModal}
        setOpenModal={setOpenModal}
        noPadding
        noHeader
      >
        <RoomReservationModalContainer>
          <IsDesktop>
            <div style={imageStyle} />
          </IsDesktop>

          <RoomReservationModalRight>
            <MomentumModalTitle>
              {complete
                ? keepInTouchModal?.field_thank_you_title
                : keepInTouchModal?.field_modal_title}
            </MomentumModalTitle>

            <MomentumModalDesc>
              <DangerouslyHtml
                value={
                  complete
                    ? keepInTouchModal?.field_thank_you_description
                    : keepInTouchModal?.field_modal_description
                }
              />
            </MomentumModalDesc>

            {complete ? (
              <SecondaryButton
                buttonEvent={handleClose}
                customstyle={MomentumModalButton}
                buttonColor="white"
              >
                <CloseText />
              </SecondaryButton>
            ) : (
              <KeepInTouchForm setComplete={setComplete} />
            )}
          </RoomReservationModalRight>
        </RoomReservationModalContainer>
      </CommonModal>
    </ErrorManage>
  );
};

export default MomentumModal;
