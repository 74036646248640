import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { FrameTwo } from "@components/frame";
import { BorderButton, SecondaryButton } from "@components/buttons";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ArenaInquiryModal } from "@components/modal/modal-list";
import { ErrorManage } from "@screens/index";
import {
  EntertainmentText,
  InquiryText,
  ViewDetailsText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetInspireArena } from "@utils/api-requests/EntertainmentRequests";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

import { InspireArenaButtons } from "./EntertainmentStyling";

const InspireArena = () => {
  const search = useLocation().search;

  const inspireArenaRef = useRef();

  const [openModal, setOpenModal] = useState(false);

  const {
    data: inspireArena,
    isLoading,
    isError,
    error,
  } = useGetInspireArena();

  const aboutArenaData = useMemo(() => {
    return inspireArena?.filter(
      (el) => el.field_display_about_arena === "On"
    )[0];
  }, [inspireArena]);

  const openArenaModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "inspire-arena") {
        scrollToTargetAdjusted(inspireArenaRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search]);

  return (
    <CommonContainer>
      <ArenaInquiryModal openModal={openModal} setOpenModal={setOpenModal} />

      <div ref={inspireArenaRef}>
        <ErrorManage isLoading={isLoading} isError={isError} error={error}>
          <FrameTwo
            inspireArena
            direction="right"
            ratio="1 / 1"
            className=""
            subTitle={<EntertainmentText />}
            title={aboutArenaData?.field_arena_name}
            desc={aboutArenaData?.field_description}
            image={aboutArenaData?.field_thumbnail?.[0]}
            button={
              <InspireArenaButtons>
                <SecondaryButton
                  buttonColor="white"
                  buttonEvent={openArenaModal}
                >
                  <InquiryText />
                </SecondaryButton>

                <BorderButton
                  linkTo={`/entertainment/inspire-arena${aboutArenaData?.url_alias}`}
                >
                  <ViewDetailsText />
                </BorderButton>
              </InspireArenaButtons>
            }
          />
        </ErrorManage>
      </div>
    </CommonContainer>
  );
};

export default InspireArena;
