import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import {
  shopOfferRecommendationUrl,
  shopSubFeaturesUrl,
  storeCategoriesUrl,
  storeListUrl,
} from "./apiUrls";
import { LOCAL_STORAGE_LANGUAGE } from "./static-data";

// Shop.jsx
export function useGetShopOfferRecommendation() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getShopOfferRecommendation = useCallback(async () => {
    const { status, data } = await axios.get(
      `${shopOfferRecommendationUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(
    ["shopOfferRecommendation", language, localStorage],
    getShopOfferRecommendation,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// Shop.jsx
export function useGetShopFeatures() {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getShopFeatures = useCallback(async () => {
    const { status, data } = await axios.get(
      `${shopSubFeaturesUrl}?language=${language}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language]);

  return useQuery(["shopFeatures", language, localStorage], getShopFeatures, {
    notifyOnChangeProps: "tracked",
  });
}

// StoresCategory.jsx
export function useGetStoreCategoryList(filterType) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStoreCategoryList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${storeCategoriesUrl}&language=${language}&type=${filterType}`
    );

    if (status === 200) {
      return data;
    }
  }, [language, filterType]);

  return useQuery(
    ["storeCategoryList", language, localStorage, filterType],
    getStoreCategoryList,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// StoresCategory.jsx
export function useGetStoreList(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStoreList = useCallback(async () => {
    const { status, data } = await axios.get(
      `${storeListUrl}?language=${language}${
        categoryId && categoryId !== ""
          ? `&category_name=${encodeURIComponent(categoryId)}`
          : ""
      }`
    );

    if (status === 200) {
      return data;
    }
  }, [language, categoryId]);

  return useQuery(["storeList", language, categoryId], getStoreList, {
    notifyOnChangeProps: "tracked",
    enabled: !!language,
  });
}

// StoreDetail.jsx
export function useGetStoreDetail(alias) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStoreDetail = useCallback(async () => {
    const { status, data } = await axios.get(
      `${storeListUrl}?language=${language}&url_alias=/${alias}`
    );

    if (status === 200) {
      return data[0];
    }
  }, [language, alias]);

  return useQuery(
    ["storeDetail", language, localStorage, alias],
    getStoreDetail,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}

// StoreDetail.jsx for left and right arrow
export function useGetStoreUuidGroup(categoryId) {
  const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const getStoreUuidGroup = useCallback(async () => {
    const { status, data } = await axios.get(
      `${storeListUrl}?language=${language}&category_name=${categoryId}`
    );

    if (status === 200) {
      return data.map((item) => {
        return item.url_alias;
      });
    }
  }, [language, categoryId]);

  return useQuery(
    ["storeUuidGroup", language, localStorage, categoryId],
    getStoreUuidGroup,
    {
      notifyOnChangeProps: "tracked",
    }
  );
}
