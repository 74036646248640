import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Alert } from "@mui/material";

import {
  yupChangePasswordCode,
  yupVerificationCode,
} from "@components/inputs/yupValidation";
import {
  ForgotPasswordFormDesc,
  ForgotPasswordModalForm,
  ForgotPasswordRemainDesc,
} from "@components/modal/ModalStyling";
import { TextInput } from "@components/inputs";
import { PrimaryButton } from "@components/buttons";
import {
  OkText,
  ResendTokenText,
  SubmitLoadingText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import {
  userForgotPass,
  userProfileActiveRequest,
  userProfileActiveVerificationCodeSendRequest,
  userSignUpDrupal,
} from "@utils/api-requests/userRequests";
import {
  ConfirmPasswordLabelText,
  NewPasswordLabelText,
  PreviousVerificationCodeNotExpiredText,
  VerifiactionCodeLabelText,
  VerificationCodeExpiredErrorText,
  VerificationCodeExpiredMessage,
  VerificationCodeInvalidErrorText,
  VerificationCodeSentMessage1,
  VerificationCodeSentMessage2,
} from "@utils/lanugauge-settings/static-texts/TranslatedValidationTexts";
import { numberPadding } from "@utils/modify-data/modifyData";
import { useIntl } from "react-intl";

const VerificationCodeForm = ({
  setPasswordStep,
  email,
  success,
  signUpData,
  warning,
}) => {
  const intl = useIntl();

  const [resendTrue, setResendTrue] = useState(false);
  const [timerOn, setTimerOn] = useState(true);

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const time = useRef(300);
  const timerId = useRef(null);

  useEffect(() => {
    if (timerOn) {
      timerId.current = setInterval(() => {
        setMinutes(numberPadding(parseInt(time.current / 60)));

        setSeconds(numberPadding(time.current % 60));

        time.current -= 1;
      }, 1000);
    }

    return () => clearInterval(timerId.current);
  }, [timerOn]);

  useEffect(() => {
    if (time.current <= 0) {
      setResendTrue(true);
    }
  }, [seconds]);

  const [invalid, setInvalid] = useState("");

  const passwordDefaultValue = {
    code: "",
    password: "",
    confirm_pw: "",
  };

  const codeDefaultValue = {
    code: "",
  };

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(
      setPasswordStep ? yupChangePasswordCode : yupVerificationCode
    ),
    mode: "onSubmit",
    defaultValues: setPasswordStep ? passwordDefaultValue : codeDefaultValue,
  });

  const { mutate: profileActivateMutate, isLoading } = useMutation(
    userProfileActiveRequest,
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          success({
            message: intl.formatMessage({
              id: "lang-profile-activated-success",
            }),
            path: "/momentum/sign-in",
          });

          // sign up to the drupal with information that user fill in
          userSignUpDrupal(signUpData);
        }

        // ERROR: When verification code is not matched
        else if (data.response?.data.Errors[0].Code === "VC10002") {
          setInvalid(<VerificationCodeInvalidErrorText />);
        }
        // ERROR: When verification code expired
        else if (data.response?.data.Errors[0].Code === "VC10001") {
          setInvalid(<VerificationCodeExpiredErrorText />);
        } else {
          console.log(data);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: forgotPassMutate, isLoading: forgotPassIsLoading } =
    useMutation(userForgotPass, {
      onSuccess: (data) => {
        if (data.status === 200) {
          setPasswordStep(2);
        }
        // ERROR: When verification code is not matched
        else if (data.response?.data.Errors[0].Code === "VC10002") {
          setInvalid(<VerificationCodeInvalidErrorText />);
        }
        // ERROR: When verification code expired
        else if (data.response?.data.Errors[0].Code === "VC10001") {
          setInvalid(<VerificationCodeExpiredErrorText />);
        } else {
          console.log(data);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const { mutate: activationMutate } = useMutation(
    userProfileActiveVerificationCodeSendRequest,
    {
      onSuccess: (data) => {
        if (data.status === 201) {
        } else {
          console.log(data);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const VerificationCodeFormSubmit = useCallback(
    (data) => {
      if (data) {
        let obj = {
          email: email,
          code: data.code,
        };

        if (setPasswordStep) {
          obj = {
            ...obj,
            newPassword: data.password,
          };

          forgotPassMutate(obj);
        } else {
          profileActivateMutate(obj);
        }
      }
    },
    [profileActivateMutate, forgotPassMutate, setPasswordStep, email]
  );

  const resendCode = () => {
    clearInterval(timerId.current);

    setResendTrue(false);

    setTimerOn(true);

    setMinutes(5);
    setSeconds(0);
    time.current = 300;

    activationMutate({
      email: email,
      type: success ? 1 : 2,
    });
  };

  useEffect(() => {
    if (resendTrue) {
      setTimerOn(false);
    }
  }, [resendTrue]);

  return (
    <ForgotPasswordModalForm
      onSubmit={handleSubmit(VerificationCodeFormSubmit)}
      name="verificationCode"
    >
      {warning?.previous && !resendTrue && (
        <Alert severity="warning" sx={{ width: "100%", marginBottom: "16px" }}>
          <PreviousVerificationCodeNotExpiredText />
        </Alert>
      )}

      <ForgotPasswordFormDesc>
        <p>
          {resendTrue ? (
            <VerificationCodeExpiredMessage />
          ) : (
            <VerificationCodeSentMessage1 />
          )}
        </p>

        <p>
          <VerificationCodeSentMessage2 />
        </p>
      </ForgotPasswordFormDesc>

      {invalid !== "" && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "16px" }}>
          {invalid}
        </Alert>
      )}

      {!resendTrue && (
        <>
          <TextInput
            control={control}
            name="code"
            label={<VerifiactionCodeLabelText />}
            placeholder="12345"
            type="text"
            endAdornment={
              <ForgotPasswordRemainDesc>
                {minutes} : {seconds}
              </ForgotPasswordRemainDesc>
            }
          />
        </>
      )}
      {setPasswordStep && (
        <>
          <TextInput
            control={control}
            name="password"
            label={<NewPasswordLabelText />}
            type="password"
          />

          <TextInput
            control={control}
            name="confirm_pw"
            label={<ConfirmPasswordLabelText />}
            type="password"
          />
        </>
      )}
      {resendTrue ? (
        <PrimaryButton buttonType="button" buttonEvent={resendCode}>
          <ResendTokenText />
        </PrimaryButton>
      ) : (
        <PrimaryButton buttonType="submit" disable={isLoading}>
          {isLoading || forgotPassIsLoading ? (
            <SubmitLoadingText />
          ) : (
            <OkText />
          )}
        </PrimaryButton>
      )}
    </ForgotPasswordModalForm>
  );
};

export default VerificationCodeForm;
