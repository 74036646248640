import React, { useState } from "react";
import QRCode from "react-qr-code";
import { QRWrapper, QRCodeData } from "@assets/styles/CommonStyles";
import { image } from "../../../theme";

const QRCodeComponent = ({ currentData }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleQRCodeClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  const currentDataQuotes = encodeURIComponent(currentData.replace(/\s+/g, ""));

  return (
    <QRWrapper
      className={`qr-container ${isFullScreen ? "full-screen" : ""}`}
      onClick={handleQRCodeClick}
    >
      <QRCode value={currentDataQuotes} size={isFullScreen ? 300 : 60} />

      <QRCodeData>
        {isFullScreen && (
          <>
            {currentData}
            <img
              loading="lazy"
              src={image.articleLogo.default}
              alt="Inspire logo"
              width={160}
              height={160}
            />
          </>
        )}
      </QRCodeData>
    </QRWrapper>
  );
};

export default QRCodeComponent;
