import React from "react";

import { CommonDescOne } from "@assets/styles/CommonStyles";
import { FrameFour } from "@components/frame";
import { PrimaryButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";
import { BookSplashBayText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetSplashBayReserveNow } from "@utils/api-requests/SplashBayRequests";

const SplashBayReserveNow = () => {
  const { data: splashBayReserveNow } = useGetSplashBayReserveNow();

  return (
    <FrameFour
      title={splashBayReserveNow?.field_ree}
      url={splashBayReserveNow?.field_thumbnail[0]?.src}
      alt={splashBayReserveNow?.field_thumbnail[0]?.alt}
    >
      <CommonDescOne>
        <DangerouslyHtml value={splashBayReserveNow?.field_short_description} />
      </CommonDescOne>

      <div>
        <PrimaryButton
          linkTo="/splash-bay/reservation"
          customstyle={{ width: "fit-content", maxWidth: "none" }}
        >
          <BookSplashBayText />
        </PrimaryButton>
      </div>
    </FrameFour>
  );
};

export default SplashBayReserveNow;
