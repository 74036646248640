import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { MuiBreadcrumbs, NotificationToggle } from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsCustomerCenterPage } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import { CustomerCenterList } from ".";
import { aboutUsRouteList } from "../aboutUsRouteList";

const CustomerCenterDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsCustomerCenterPage,
    isLoading,
    isError,
    error,
  } = useGetAboutUsCustomerCenterPage();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-about-us",
        }),
        href: "/about-us",
      },
      {
        id: 3,
        current: true,
        name: aboutUsCustomerCenterPage?.field_customer_center_name,
      },
    ];

    return roomDetails;
  }, [aboutUsCustomerCenterPage?.field_customer_center_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <CustomerCenterList />,
    },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList?.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsCustomerCenterPage} />

      <DetailPageHero data={aboutUsCustomerCenterPage} />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsCustomerCenterPage} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={aboutUsCustomerCenterPage?.field_customer_center_name}
            value={aboutUsCustomerCenterPage?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <MomentumLayout data={aboutUsCustomerCenterPage} />
      </PageBottomContents>

      <CommonContainer>
        <center style={{ display: 'none' }}>Customer Center </center>
      </CommonContainer>
    </ErrorManage>
  );
};

export default CustomerCenterDetail;
