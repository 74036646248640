import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import {
  CommonContainer,
  CommonDescktopContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import { ArenaInquiryModal } from "@components/modal/modal-list";
import { SliderSix } from "@components/sliders";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { ErrorManage } from "@screens/index";
import {
  useGetInspireArenaDetail,
  useGetInspireArenaAliasGroup,
} from "@utils/api-requests/EntertainmentRequests";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import { CustomMetatag } from "@utils/metatags";

import {
  ArtistSpaces,
  EntertainmentPerformances,
  FacilitySpecification,
  FacilityTechnology,
  PopularPerformances,
  VariousSeatsSystem,
  WhatsOn,
} from ".";

const InspireArenaDetail = () => {
  const alias = useParams().arenaAlias;
  const intl = useIntl();

  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: inspireArenaAliasGroup } = useGetInspireArenaAliasGroup();

  const {
    data: inspireArenaDetail,
    isFetching,
    isError,
    error,
  } = useGetInspireArenaDetail(alias);

  const firstRender = useMemo(() => {
    if (inspireArenaDetail?.field_display_arena_performance === "On") {
      return (
        <CommonDescktopContainer>
          <PopularPerformances />
        </CommonDescktopContainer>
      );
    }

    if (
      inspireArenaDetail?.field_display_arena_facilities === "On" ||
      inspireArenaDetail?.field_display_about_arena === "On"
    ) {
      return (
        <CommonContainer>
          <SliderSix imageArr={inspireArenaDetail?.field_multiple_images} />
        </CommonContainer>
      );
    }
  }, [inspireArenaDetail]);

  const secondRender = useMemo(() => {
    if (inspireArenaDetail?.field_display_arena_performance === "On") {
      return <EntertainmentPerformances data={inspireArenaDetail} />;
    }

    if (inspireArenaDetail?.field_display_arena_facilities === "On") {
      return <VariousSeatsSystem data={inspireArenaDetail?.field_sea} />;
    }

    if (inspireArenaDetail?.field_display_about_arena === "On") {
      return <FacilitySpecification data={inspireArenaDetail} />;
    }
  }, [inspireArenaDetail]);
  const thirdRender = useMemo(() => {
    if (inspireArenaDetail?.field_display_arena_performance === "On") {
      return (
        <CommonDescktopContainer>
          <WhatsOn data={inspireArenaDetail?.field_calender} />
        </CommonDescktopContainer>
      );
    }
  }, [
    inspireArenaDetail?.field_calender,
    inspireArenaDetail?.field_display_arena_performance,
  ]);

  const arenaDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-entertainment",
        }),
        href: "/entertainment",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-inspire-arena",
        }),
        href: "/entertainment",
        tab: "inspire-arena",
      },
      { id: 4, current: true, name: inspireArenaDetail?.field_arena_name },
    ];

    return roomDetails;
  }, [inspireArenaDetail?.field_arena_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: firstRender,
    },
    {
      id: 2,
      noTitle: inspireArenaDetail?.field_display_arena_performance === "On",
      contents: secondRender,
    },
    {
      id: 3,
      contents: thirdRender,
    },
    {
      id: 4,
      disabled: inspireArenaDetail?.field_display_arena_facilities === "Off",
      contents: (
        <CommonContainer>
          <FacilityTechnology
            data={inspireArenaDetail?.field_facilities_and_technologie}
          />
        </CommonContainer>
      ),
    },
    {
      id: 5,
      disabled: inspireArenaDetail?.field_display_arena_facilities === "Off",
      contents: (
        <CommonContainer>
          <ArtistSpaces data={inspireArenaDetail?.field_a} />
        </CommonContainer>
      ),
    },
  ];

  useEffect(() => {
    if (inspireArenaAliasGroup) {
      setCurrentIndex(
        inspireArenaAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [inspireArenaAliasGroup, alias]);

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <ArenaInquiryModal openModal={openModal} setOpenModal={setOpenModal} />

      <CustomMetatag metaData={inspireArenaDetail} />

      <DetailPageHero data={inspireArenaDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={inspireArenaDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={arenaDetailBC}
            pageTitle={inspireArenaDetail?.field_arena_name}
            value={inspireArenaDetail?.field_full_description}
            prevUUid={inspireArenaAliasGroup?.[currentIndex - 1]}
            nextUUid={inspireArenaAliasGroup?.[currentIndex + 1]}
            url={`/entertainment/inspire-arena`}
            // buttonName={inspireArenaDetail?.field_widget?.[0]?.field_link.text}
            buttonEvent={() => setOpenModal(true)}
          />
        </>
      </CommonContainer>

      <DetailPageContainer
        data={FilterDetailPageData(
          inspireArenaDetail?.field_detail_page_information
        )}
        sectionArray={sectionArray}
      />

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire
            data={inspireArenaDetail}
            noButton
            buttonEvent={() => setOpenModal(true)}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </CommonMobileContainer>

        <OfferRecommendation data={inspireArenaDetail} />

        <MomentumLayout data={inspireArenaDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default InspireArenaDetail;
