import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { SliderSix } from "@components/sliders";
import {
  CommonContainer,
  CommonMobileContainer,
} from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CommonVideo } from "@pages/shop";
import { ErrorManage } from "@screens/index";
import { FilterDetailPageData } from "@utils/modify-data/modifyData";
import {
  useGetAttractionVenueAliasGroup,
  useGetEntertainmentVenueDetail,
} from "@utils/api-requests/EntertainmentRequests";
import { CustomMetatag } from "@utils/metatags";

import {
  AttractionVenueExplore,
  AttractionVenueInformation,
  AttractionVenueMap,
  AttractionVenuePoints,
  AttractionVenuesCurrentExhibition,
  AttractionVenuesGallery,
  FiveEventsArea,
} from ".";

const EntertainmentVenueDetail = () => {
  const intl = useIntl();
  const alias = useParams().venueAlias;

  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: attractionVenueAliasGroup } = useGetAttractionVenueAliasGroup();

  const {
    data: entertainmentVenueDetail,
    isFetching,
    isError,
    error,
  } = useGetEntertainmentVenueDetail(alias);

  const firstRender = useMemo(() => {
    if (
      entertainmentVenueDetail?.field_which_detail_page.includes("Aurora")
      || entertainmentVenueDetail?.field_category_name.includes("Discovery Park")
    ) {
      return (
        <SliderSix imageArr={entertainmentVenueDetail?.field_multiple_images}
                   sliderDesc={entertainmentVenueDetail?.field_gallery_description}/>
      );
    }

    if (
      entertainmentVenueDetail?.field_which_detail_page.includes("Arte Museum")
    ) {
      return (
        <>
          {
            entertainmentVenueDetail?.field_is_slider_image_ === "On" &&
            <SliderSix imageArr={entertainmentVenueDetail?.field_multiple_images}
                       sliderDesc={entertainmentVenueDetail?.field_gallery_description}/>
          }

          <CommonVideo data={entertainmentVenueDetail?.field_venue_video}/>
        </>
      )
    }

    if (
      entertainmentVenueDetail?.field_which_detail_page.includes("7 Wonders") &&
      entertainmentVenueDetail?.field_point_listing?.length > 0
    ) {
      return (
        <AttractionVenuePoints
          data={entertainmentVenueDetail?.field_point_listing}
        />
      );
    }

    if (entertainmentVenueDetail?.field_which_detail_page.includes("Rotunda") ||
      entertainmentVenueDetail?.field_pdf_venue !== "" ) {
      //   entertainmentVenueDetail?.field_which_detail_page.includes("Aurora")) &&
      return (
        <AttractionVenueMap data={entertainmentVenueDetail?.field_pdf_venue} />
      );
    }
  }, [entertainmentVenueDetail]);

  const secondRender = useMemo(() => {
    if (
      (entertainmentVenueDetail?.field_category_name.includes("Rotunda") ||
        entertainmentVenueDetail?.field_category_name.includes("Aurora")) &&
      entertainmentVenueDetail?.field_point_listing?.length > 0
    ) {
      return (
        <AttractionVenuePoints
          data={entertainmentVenueDetail?.field_point_listing}
        />
      );
    }

    if (entertainmentVenueDetail?.field_category_name.includes("Arte Museum")) {
      return (
        <AttractionVenuesGallery
          data={entertainmentVenueDetail?.field_museum_gallary}
        />
      );
    }

    if (
      entertainmentVenueDetail?.field_category_name.includes("Discovery Park")
    ) {
      return <AttractionVenueExplore data={entertainmentVenueDetail} />;
    }
  }, [entertainmentVenueDetail]);

  const thirdRender = useMemo(() => {
    if (entertainmentVenueDetail?.field_category_name.includes("Arte Museum")) {
      return (
        <AttractionVenuesCurrentExhibition
          data={entertainmentVenueDetail?.field_current_exhibition}
        />
      );
    }

    if (
      entertainmentVenueDetail?.field_category_name.includes("Discovery Park")
    ) {
      return <FiveEventsArea data={entertainmentVenueDetail} />;
    }
  }, [entertainmentVenueDetail]);

  const sectionArray = [
    {
      id: 1,

      disabled:
        (entertainmentVenueDetail?.field_which_detail_page.includes(
            "Rotunda"
          ) ||
          entertainmentVenueDetail?.field_which_detail_page.includes(
            "Aurora"
          )) &&
        entertainmentVenueDetail?.field_pdf_venue === "",
      contents: firstRender,
    },
    {
      id: 2,
      contents: secondRender,
    },
    {
      id: 3,
      contents: thirdRender,
    },
  ];

  const entertainmentDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-entertainment",
        }),
        href: "/entertainment",
      },
      {
        id: 3,
        name: intl.formatMessage({
          id: "lang-attraction-venues",
        }),
        href: "/entertainment",
        tab: "attraction-venues",
      },
      {
        id: 4,
        current: true,
        name: entertainmentVenueDetail?.field_venue_name,
      },
    ];

    return roomDetails;
  }, [entertainmentVenueDetail?.field_venue_name, intl]);

  useEffect(() => {
    if (attractionVenueAliasGroup) {
      setCurrentIndex(
        attractionVenueAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [attractionVenueAliasGroup, alias]);

  return (
    <ErrorManage isLoading={isFetching} isError={isError} error={error}>
      <CustomMetatag metaData={entertainmentVenueDetail} />

      <DetailPageHero data={entertainmentVenueDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={entertainmentVenueDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={entertainmentDetailBC}
            pageTitle={entertainmentVenueDetail?.field_venue_name}
            value={entertainmentVenueDetail?.field_ent_ven_full_description}
            prevUUid={attractionVenueAliasGroup?.[currentIndex - 1]}
            nextUUid={attractionVenueAliasGroup?.[currentIndex + 1]}
            url="/entertainment/attraction-venue"
          />
        </>

        {entertainmentVenueDetail?.field_is_information_ === "On" && (
          <AttractionVenueInformation data={entertainmentVenueDetail} />
        )}

        <DetailPageContainer
          data={FilterDetailPageData(
            entertainmentVenueDetail?.field_which_detail_page
          )}
          sectionArray={sectionArray}
        />
      </CommonContainer>

      <PageBottomContents>
        <CommonMobileContainer>
          <BookYourInspire data={entertainmentVenueDetail} />
        </CommonMobileContainer>

        <OfferRecommendation data={entertainmentVenueDetail} />

        <MomentumLayout data={entertainmentVenueDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default EntertainmentVenueDetail;
