import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { ErrorManage } from "@screens/index";
import {
  useGetStoreDetail,
  useGetStoreUuidGroup,
} from "@utils/api-requests/ShopRequests";
import { CustomMetatag } from "@utils/metatags";

import { StoreInformation, StoreImageSlider } from ".";

const StoreDetail = () => {
  const intl = useIntl();

  const [currentIndex, setCurrentIndex] = useState(0);

  const alias = useParams().storeAlias;
  const categoryId = useParams().categoryId;

  const {
    data: storeDetail,
    isLoading,
    isError,
    error,
  } = useGetStoreDetail(alias);

  const { data: storeUuidGroup } = useGetStoreUuidGroup(categoryId);

  const restaurantDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-shop",
      }),
      href: "/shop",
    },
    {
      id: 3,
      name: storeDetail?.shop_category_name,
      href: "/shop",
      tab: storeDetail?.field_parent_id,
    },

    { id: 4, current: true, name: storeDetail?.field_shop_ },
  ];

  const sectionArray = [
    { id: 1, contents: <StoreInformation data={storeDetail} /> },
    {
      id: 2,
      disabled:
        storeDetail?.field_multiple_images === "" ||
        !storeDetail?.field_multiple_images,
      contents: <StoreImageSlider data={storeDetail} />,
    },
  ];

  useEffect(() => {
    if (storeUuidGroup) {
      setCurrentIndex(
        storeUuidGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [storeUuidGroup, alias]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={storeDetail} />

      <DetailPageHero data={storeDetail} />

      <CommonContainer>
        <>
          <NotificationToggle data={storeDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={restaurantDetailBC}
            pageTitle={storeDetail?.field_shop_}
            value={storeDetail?.field_shop_full_description}
            prevUUid={storeUuidGroup?.[currentIndex - 1]}
            nextUUid={storeUuidGroup?.[currentIndex + 1]}
            url={`/shop/store/${categoryId}`}
          />
        </>

        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={storeDetail} />

        <MomentumLayout data={storeDetail} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default StoreDetail;
