import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { SliderFour } from "@components/sliders";
import { useGetWeddingEventList } from "@utils/api-requests/MeetRequests";
import { scrollToTargetAdjusted } from "@utils/scroll-behavior/ScrollIntoView";
import { getSearchValue } from "@utils/modify-data/modifyData";
import { SCROLL_INTO_VIEW_TIMER } from "@utils/api-requests/static-data";

const WeddingEvent = () => {
  const search = useLocation().search;

  const weddingEventRef = useRef();

  const { data: weddingEventList } = useGetWeddingEventList();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (getSearchValue(search) === "wedding-event" && weddingEventList) {
        scrollToTargetAdjusted(weddingEventRef);
      }
    }, SCROLL_INTO_VIEW_TIMER);

    return () => clearInterval(intervalId);
  }, [search, weddingEventList]);

  return (
    <div ref={weddingEventRef}>
      <SliderFour
        data={weddingEventList}
        titleKey="field_event_title"
        descKey="field_section_description"
        linkKey="field_meet_types"
        link="/meet/wedding-events/"
      />
    </div>
  );
};

export default WeddingEvent;
