import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import { image } from "src/theme";
import { CommonDescOne } from "@assets/styles/CommonStyles";
import { TextInput } from "@components/inputs";
import { yupBooking } from "@components/inputs/yupValidation";
import { PrimaryButton } from "@components/buttons";
import { RangeCalendar } from "@components/calendar";
import {
  BookingModalArrow,
  BookingModalContainer,
  BookingModalGuestsButton,
  BookingModalGuestsButtons,
  BookingModalGuestsWrapper,
  BookingModalWrapper,
  BookingSelectPeopleModalContainer,
} from "@components/item/ItemStyling";
import {
  CheckInCheckOutText,
  CheckAvailabilityText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { openBookingSite } from "@utils/modify-data/modifyData";
import { useGetFooterQuery } from "@utils/api-requests/GlobalRequests";

export const BookingSelectPeopleModal = ({
  watch,
  setValue,
  setGuests,
  setPeopleModalOn,
  parentRef,
}) => {
  const wrapperRef = useRef(null);

  const intl = useIntl();

  const peopleArr = [
    { id: 1, label: "Adults", name: "adults" },
    { id: 2, label: "Children", name: "children" },
  ];

  function useOutsideAlerter(ref, parentRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !parentRef.current.contains(event.target)
        ) {
          setPeopleModalOn(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [parentRef, ref]);
  }

  useOutsideAlerter(wrapperRef, parentRef);

  const onPlusNumber = useCallback(
    (name) => {
      setValue("Noguest", watch("adults") + watch("children") + 1);
      setValue(name, watch(name) + 1);

      setGuests(
        `${watch("Noguest")} ${intl.formatMessage({
          id: "lang-guests",
        })}`
      );
    },
    [intl, setGuests, setValue, watch]
  );

  const onMinusNumber = useCallback(
    (name) => {
      setValue(name, watch(name) - 1);
      setValue("Noguest", watch("adults") + watch("children"));

      setGuests(
        `${watch("Noguest")} ${intl.formatMessage({
          id: "lang-guests",
        })}`
      );
    },
    [intl, setGuests, setValue, watch]
  );

  return (
    <BookingSelectPeopleModalContainer ref={wrapperRef}>
      <ul>
        {peopleArr.map((people) => {
          return (
            <li key={people.id}>
              <CommonDescOne>{people.label}</CommonDescOne>

              <BookingModalGuestsWrapper>
                <BookingModalGuestsButtons>
                  <BookingModalGuestsButton
                    type="button"
                    inActive={
                      watch(people.name) < (people.name === "children" ? 1 : 2)
                    }
                    onClick={() => onMinusNumber(people.name)}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </BookingModalGuestsButton>

                  <span>{watch(people.name)}</span>

                  <BookingModalGuestsButton
                    type="button"
                    inActive={watch("Noguest") > 3}
                    onClick={() => onPlusNumber(people.name)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </BookingModalGuestsButton>
                </BookingModalGuestsButtons>
              </BookingModalGuestsWrapper>
            </li>
          );
        })}
      </ul>
    </BookingSelectPeopleModalContainer>
  );
};

const BookingModal = () => {
  const intl = useIntl();
  const parentRef = useRef(null);

  const { footerContact } = useGetFooterQuery();

  const [peopleModalOn, setPeopleModalOn] = useState(false);

  const [calendarOpen, setCalendarOpen] = useState(false);

  const [dates, setDates] = useState(
    intl.formatMessage({
      id: "lang-select-dates",
    })
  );

  const [guests, setGuests] = useState(
    `1 ${intl.formatMessage({
      id: "lang-guests",
    })}`
  );

  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(yupBooking),
    mode: "onSubmit",
    defaultValues: {
      startdate: "",
      adults: 1,
      children: 0,
      enddate: "",
      rooms: 1,
      Noguest: 1,
      offercode: "",
    },
  });

  const signUpFormSubmit = useCallback(
    (data) => {
      openBookingSite(data, footerContact?.field_offer_default_code);
    },
    [footerContact?.field_offer_default_code]
  );

  const onClickDates = useCallback(() => {
    setCalendarOpen(true);
  }, []);

  const onClickGuests = useCallback(() => {
    setPeopleModalOn((prev) => !prev);
  }, []);

  return (
    <BookingModalContainer>
      <RangeCalendar
        calendarOpen={calendarOpen}
        setCalendarOpen={setCalendarOpen}
        setDates={setDates}
        setValue={setValue}
      />

      <form onSubmit={handleSubmit(signUpFormSubmit)}>
        <TextInput
          onClick={onClickDates}
          control={control}
          name="startdate"
          label={<CheckInCheckOutText />}
          customValue={dates}
          placeholder={intl.formatMessage({
            id: "lang-select-dates",
          })}
          readOnly
        />

        <BookingModalWrapper ref={parentRef}>
          <TextInput
            control={control}
            onClick={onClickGuests}
            name="room_guests"
            label={intl.formatMessage({
              id: "lang-number-of-guests",
            })}
            placeholder="Select People"
            readOnly
            customValue={guests}
            endAdornment={
              <BookingModalArrow
                src={image.toggleDownPurple.default}
                alt="Open Modal"
                active={peopleModalOn}
              />
            }
          />

          {peopleModalOn && (
            <BookingSelectPeopleModal
              watch={watch}
              setValue={setValue}
              setGuests={setGuests}
              parentRef={parentRef}
              setPeopleModalOn={setPeopleModalOn}
            />
          )}
        </BookingModalWrapper>

        <TextInput
          control={control}
          name="offercode"
          label={intl.formatMessage({
            id: "lang-offer-code",
          })}
          placeholder={intl.formatMessage({
            id: "lang-enter-code-here",
          })}
        />

        <PrimaryButton buttonType="submit" customstyle={{ maxWidth: "auto" }}>
          <CheckAvailabilityText />
        </PrimaryButton>
      </form>
    </BookingModalContainer>
  );
};

export default BookingModal;
