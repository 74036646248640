import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";

import {
  FrameTwoContainer,
  FrameTwoImageCustom,
  FrameTwoInformation,
  FrameTwoLeft,
  FrameTwoRight,
} from "./FrameStyling";

const FrameTwo = ({
  direction = "left",
  subTitle,
  title,
  desc,
  children,
  image,
  button,
  ratio,
  className = "ellipsis-6",
  inspireArena,
  tertiary
}) => {
  return (
    <FrameTwoContainer direction={direction}>
      <FrameTwoLeft inspireArena={inspireArena} tertiary={tertiary}>
        <FrameTwoInformation>
          {subTitle && (
            <CommonDescTwo>
              <DangerouslyHtml value={subTitle} />
            </CommonDescTwo>
          )}

          <CommonTitleOne>
            <DangerouslyHtml value={title} />
          </CommonTitleOne>

          <CommonDescTwo className={button ? className : ""}>
            <DangerouslyHtml value={desc} />
          </CommonDescTwo>

          {children}
        </FrameTwoInformation>

        {button}
      </FrameTwoLeft>

      <FrameTwoRight>
        {image && (
          <ImageWrapper
            ratio={ratio ? ratio : "3 / 2"}
            custom={FrameTwoImageCustom}
          >
            <Image src={image?.src} alt={image?.alt} />
          </ImageWrapper>
        )}
      </FrameTwoRight>
    </FrameTwoContainer>
  );
};

export default FrameTwo;
