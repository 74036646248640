import React from "react";

import {
  CommonContainer,
  CommonContentButtonWrapper,
} from "@components/layout/ContainerStyle";
import { PrimaryButton } from "@components/buttons";
import { MapWithPings } from "@components/item";
import {
  useGetEntertainmentEventList,
  useGetInspireArena,
} from "@utils/api-requests/EntertainmentRequests";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const EventsArea = () => {
  const { data: entertainmentEventList } = useGetEntertainmentEventList();

  const { data: inspireArena } = useGetInspireArena();

  return (
    <CommonContainer>
      <CommonContentButtonWrapper>
        <MapWithPings
          discovery
          image={entertainmentEventList?.field_image[0]}
          filteredData={entertainmentEventList?.field_events_area_map}
          dateKey="field_date"
        />

        <PrimaryButton
          linkTo={`/entertainment/inspire-arena/${
            inspireArena?.filter(
              (el) => el.field_category_id === "Featured Performances"
            )[0]?.uuid
          }`}
        >
          <ViewAllText />
        </PrimaryButton>
      </CommonContentButtonWrapper>
    </CommonContainer>
  );
};

export default EventsArea;
