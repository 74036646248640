import React from "react";

import { CommonDescTwo, CommonTitleOne } from "@assets/styles/CommonStyles";
import { PrimaryButton } from "@components/buttons";
import { DangerouslyHtml } from "@components/item";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const UnderageGaming = ({ data }) => {
  return (
    <div>
      <BoxListThreeContainer>
        <BoxListThreeWrapper>
          <ImageWrapper radius="2.5rem" ratio="3 / 2">
            <Image
              src={data?.field_image[0]?.src}
              alt={data?.field_image[0]?.alt}
            />
          </ImageWrapper>

          <BoxListThreeInformation>
            <CommonTitleOne>
              <DangerouslyHtml value={data?.field_underage_gambling_title} />
            </CommonTitleOne>

            <CommonDescTwo>
              <DangerouslyHtml value={data?.field_underage_gambling_descript} />
            </CommonDescTwo>

            <PrimaryButton>
              <ViewDetailsText />
            </PrimaryButton>
          </BoxListThreeInformation>
        </BoxListThreeWrapper>
      </BoxListThreeContainer>
    </div>
  );
};

export default UnderageGaming;
