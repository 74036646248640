import React, { memo } from "react";

import { SecondaryButton } from "@components/buttons";
import { FrameTwo } from "@components/frame";
import { ErrorManage } from "@screens/index";
import { useGetSeasonalPick } from "@utils/api-requests/EatDrinkRequests";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SeasonalPick = () => {
  const {
    data: seasonalPick,
    isLoading,
    isError,
    error,
  } = useGetSeasonalPick();

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <FrameTwo
        subTitle={seasonalPick?.field_seasonal_category}
        title={seasonalPick?.field_seasonal_pick_name}
        desc={seasonalPick?.field_section_description}
        image={seasonalPick?.field_thumbnail?.[0]}
        button={
          <SecondaryButton buttonColor="white">
            <a href={seasonalPick?.field_seasonal_link} target="blank">
              <ViewDetailsText />
            </a>
          </SecondaryButton>
        }
      />
    </ErrorManage>
  );
};

export default memo(SeasonalPick);
