import './polyfills';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider} from "react-query";
import { HelmetProvider } from "react-helmet-async";
import ReactDOM from 'react-dom/client';

import CustomStyle from "@assets/styles/CommonStyles";
import GlobalStyle from "@assets/styles/GlobalStyles";
import ScrollToTop from "@utils/scroll-behavior/ScrollToTop";
import { queryClientSetup } from "@utils/react-query/queryClientSetup";

import App from "./App";




const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <React.StrictMode>
      <BrowserRouter>
        <GlobalStyle />

        <CustomStyle />

        <ScrollToTop />

        <HelmetProvider>
          <QueryClientProvider client={queryClientSetup}>
            <App />
          </QueryClientProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <GlobalStyle />

        <CustomStyle />

        <ScrollToTop />

        <HelmetProvider>
          <QueryClientProvider client={queryClientSetup}>
            <App />
          </QueryClientProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
