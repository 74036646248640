import React from "react";

import { CommonDescOne, CommonTitleOne } from "@assets/styles/CommonStyles";
import { DangerouslyHtml } from "@components/item";
import {
  FindYourInspirationContainer,
  FindYourInspirationIframe,
  FindYourInspirationIframeContainer,
} from "@pages/about-us/AboutUsStyling";

const FindYourInspiration = ({ data }) => {
  return (
    <>
      <FindYourInspirationIframeContainer>
        <FindYourInspirationIframe
          src="https://player.vimeo.com/video/881887043?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="Careers at Inspire"
        />
      </FindYourInspirationIframeContainer>

      <FindYourInspirationContainer>
        <CommonTitleOne>
          <DangerouslyHtml value={data?.field_career_announcement_title} />
        </CommonTitleOne>

        <CommonDescOne>
          <DangerouslyHtml value={data?.field_career_announcement_desc} />
        </CommonDescOne>
      </FindYourInspirationContainer>
    </>
  );
};

export default FindYourInspiration;
