import React from "react";

import {
  TableTwoContainer,
  TableTwoTbodyTr,
  TableTwoWrapper,
} from "./TableStyling";

const TableTwo = ({ thead, tbody }) => {
  return (
    <TableTwoContainer id="table-scroll">
      <TableTwoWrapper className="table">
        <thead>
          {thead?.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((th) => {
                  return (
                    <th {...th} key={th.id}>
                      {th.name}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>

        <tbody>
          {tbody?.map((row, index) => {
            return (
              <TableTwoTbodyTr key={index}>
                {row.map((td) => {
                  return (
                    <td
                      {...td}
                      key={td.id}
                      className={td.fixed === "true" ? "fixed" : ""}
                    >
                      {td.name}
                    </td>
                  );
                })}
              </TableTwoTbodyTr>
            );
          })}
        </tbody>
      </TableTwoWrapper>
    </TableTwoContainer>
  );
};

export default TableTwo;
