import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { FrameThree } from "@components/frame";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import { DangerouslyHtml } from "@components/item";
import {
  FacilityInformationText,
  OpenHoursText,
  OperationInformationCautionText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SplashBayDomeInformation = ({ data }) => {
  return (
    <FrameThree data={data} noImage={data?.field_logo === ""}>
      <li>
        <FrameThreeIn>
          <header>
            <OpenHoursText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_splash_location !== ""
                  ? data?.field_splash_location
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>

        <FrameThreeIn>
          <header>
            <FacilityInformationText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={data?.field_facility_ !== "" ? data?.field_facility_ : "-"}
            />
          </CommonDescTwo>
        </FrameThreeIn>
      </li>

      <li>
        <FrameThreeIn>
          <header>
            <OperationInformationCautionText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml
              value={
                data?.field_operational_hours !== ""
                  ? data?.field_operational_hours
                  : "-"
              }
            />
          </CommonDescTwo>
        </FrameThreeIn>
      </li>
    </FrameThree>
  );
};

export default SplashBayDomeInformation;
