import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { CheckMoreOffers } from "@pages/offers/offers/detail";
import { ErrorManage } from "@screens/index";
import { CustomMetatag } from "@utils/metatags";
import { useGetMomentumLifestyleEvents } from "@utils/api-requests/MomentumRequests";

import MomentumLifestyleItemList from "./MomentumLifestyleItemList";

const MomentumLifestyleList = ({ pageInfo }) => {
  const intl = useIntl();

  const {
    data: momentumLifestyleEvents,
    isLoading,
    isError,
    error,
  } = useGetMomentumLifestyleEvents();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-momentum",
        }),
      },
      {
        id: 3,
        current: true,
        name: momentumLifestyleEvents?.field_page_name,
      },
    ];

    return roomDetails;
  }, [momentumLifestyleEvents?.field_page_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <MomentumLifestyleItemList />,
    },
    { id: 2, contents: <CheckMoreOffers data={momentumLifestyleEvents} /> },
  ];

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={momentumLifestyleEvents} />

      <DetailPageHero data={momentumLifestyleEvents} />

      <CommonContainer>
        <>
          <NotificationToggle data={momentumLifestyleEvents} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={momentumLifestyleEvents?.field_page_name}
            value={momentumLifestyleEvents?.field_full_description}
            url="/about-us"
          />
        </>

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={momentumLifestyleEvents} />

        <MomentumLayout data={momentumLifestyleEvents} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default MomentumLifestyleList;
