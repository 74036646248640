import React, { useEffect, useMemo, useState } from "react";

import { ListWithTabs } from "@components/list";
import { GettingHereHowTitle } from "@pages/about-us/AboutUsStyling";
import { useGetAboutUsHowtoGetHere } from "@utils/api-requests/AboutUsRequests";
import { FreeShuttleBusServiceText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

import GettingHereTable from "./table/GettingHereTable";

import { NotificationToggle } from "@components/item";

const GettingHereHow = ({ data }) => {
  const busNotificationData = {
    field_notification: [
      {
        field_notification_contents: data?.field_bus_notice,
        field_on_off: data?.field_bus_notice_switch === "Yes" ? "1" : 0,
      },
    ],
  };

  const { data: aboutUsHowtoGetHere } = useGetAboutUsHowtoGetHere();

  const [filterType, setFilterType] = useState("");

  // const howtoGetHereCategories = useMemo(() => {
  //   const result = aboutUsHowtoGetHere?.map((item) => {
  //     return {
  //       title: he.decode(item?.name),
  //       category_id: item?.tid,
  //     };
  //   });

  //   return result;
  // }, [aboutUsHowtoGetHere]);

  useEffect(() => {
    if (aboutUsHowtoGetHere) {
      setFilterType(aboutUsHowtoGetHere?.[0]?.tid);
    }
  }, [aboutUsHowtoGetHere]);

  const currentData = useMemo(() => {
    return data?.field_how_to_get_here_informatio?.filter(
      (el) => el.field_how_to_get_here_.id === filterType
    )[0];
  }, [data?.field_how_to_get_here_informatio, filterType]);

  return (
    <ListWithTabs
      tabArr={[]}
      filterType={filterType}
      setFilterType={setFilterType}
    >
      <GettingHereHowTitle>
        <FreeShuttleBusServiceText />
      </GettingHereHowTitle>

      <NotificationToggle data={busNotificationData} />

      <GettingHereTable data={currentData?.field_how_to_get_here_?.id} table={data?.field_shuttle_bus_table}/>
    </ListWithTabs>
  );
};

export default GettingHereHow;
