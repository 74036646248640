import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import {
  BookYourInspire,
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { MomentumLayout, PageBottomContents } from "@components/layout";
import { SliderSix } from "@components/sliders";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { ErrorManage } from "@screens/index";
import {
  useGetAmenityDetail,
  useGetAmenityAliasGroup,
} from "@utils/api-requests/StayRequests";
import { CustomMetatag } from "@utils/metatags";

import { AmenityFeatures, AmenityInformation } from ".";

const AmenityDetail = ({ pageInfo }) => {
  const intl = useIntl();

  const alias = useParams().amenityAlias;

  const [currentIndex, setCurrentIndex] = useState(0);

  // fetching list of uuid for previous and next pages
  const { data: amenityAliasGroup } = useGetAmenityAliasGroup();

  const {
    data: amenityDetail,
    isFetching,
    isError,
    error,
  } = useGetAmenityDetail(alias);

  const amenityDetailBC = [
    {
      id: 1,
      name: intl.formatMessage({
        id: "lang-home",
      }),
    },
    {
      id: 2,
      name: intl.formatMessage({
        id: "lang-stay",
      }),
      href: "/stay",
    },
    {
      id: 3,
      name: intl.formatMessage({
        id: "lang-amenities",
      }),
      href: "/stay",
      tab: "amenities",
    },
    { id: 4, current: true, name: amenityDetail?.field_amenity_name },
  ];

  const sectionArray = [
    {
      id: 1,
      disabled:
        amenityDetail?.field_multiple_images === "" ||
        !amenityDetail?.field_multiple_images,
      contents: <SliderSix imageArr={amenityDetail?.field_multiple_images} />,
    },
    {
      id: 2,
      titleDirection: "left",
      noMargin: true,
      disabled:
        amenityDetail?.field_exclusive_customer_service?.length <= 0 ||
        amenityDetail?.field_exclusive_customer_service?.[0]?.title === null,
      contents: (
        <AmenityFeatures
          data={amenityDetail?.field_exclusive_customer_service}
        />
      ),
    },
  ];

  useEffect(() => {
    if (amenityAliasGroup) {
      setCurrentIndex(
        amenityAliasGroup?.findIndex((item) => item === "/" + alias)
      );
    }
  }, [amenityDetail, amenityAliasGroup, alias]);

  return (
    <div>
      <CustomMetatag metaData={amenityDetail} />

      <ErrorManage isLoading={isFetching} isError={isError} error={error}>
        <DetailPageHero data={amenityDetail} />
      </ErrorManage>

      <CommonContainer>
        <>
          <NotificationToggle data={amenityDetail} />

          <MuiBreadcrumbs
            breadcrumbArr={amenityDetailBC}
            pageTitle={amenityDetail?.field_amenity_name}
            value={amenityDetail?.field_amenity_full_description}
            prevUUid={amenityAliasGroup?.[currentIndex - 1]}
            nextUUid={amenityAliasGroup?.[currentIndex + 1]}
            url="/stay/amenities"
          />
        </>

        {amenityDetail?.field_logo !== "" && (
          <AmenityInformation data={amenityDetail} />
        )}

        <DetailPageContainer data={pageInfo} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <BookYourInspire data={amenityDetail} />

        <OfferRecommendation data={amenityDetail} />

        <MomentumLayout data={amenityDetail} />
      </PageBottomContents>
    </div>
  );
};

export default AmenityDetail;
