import React from "react";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { FrameThree } from "@components/frame";
import { DangerouslyHtml } from "@components/item";
import { FrameThreeIn } from "@components/frame/FrameStyling";
import {
  LocationText,
  OperatingHoursText,
} from "@utils/lanugauge-settings/static-texts/TranslatedTexts";

const SplashBayServiceInfomation = ({ data }) => {
  return (
    <FrameThree data={data}>
      <li>
        <FrameThreeIn>
          <header>
            <LocationText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml value={data?.field_service_location} />
          </CommonDescTwo>
        </FrameThreeIn>

        <FrameThreeIn>
          <header>
            <OperatingHoursText />
          </header>

          <CommonDescTwo>
            <DangerouslyHtml value={data?.field_operation_hours_long} />
          </CommonDescTwo>
        </FrameThreeIn>
      </li>
    </FrameThree>
  );
};

export default SplashBayServiceInfomation;
