import React, { useMemo, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { image } from "src/theme";
import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { SwiperArrowButton } from "@components/buttons";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { commonSwiperOptions } from "@components/layout/CommonSwiperSettings";
import {
  BoxListSixDetailLink,
  BoxListSixInformation,
  BoxListSixName,
  BoxListSixTexts,
} from "@components/box-list/BoxListStyling";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { ViewDetailsText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  SliderEightArrowWrapper,
  SliderEightContainer,
  SliderEightItem,
} from "./SlidersStyling";
import { DangerouslyHtml } from "@components/item";

const SliderEight = ({ data }) => {
  const navPrevRef = useRef(null);
  const navNextRef = useRef(null);

  const custom = {
    ...commonSwiperOptions,
    spaceBetween: 30,
    breakpoints: {
      300: {
        grid: {
          rows: 1,
          fill: "row",
        },
        slidesPerView: 1,
      },
      768: {
        grid: {
          rows: 1,
          fill: "row",
        },
        slidesPerView: 2,
      },
      1200: {
        grid: {
          rows: 2,
          fill: "row",
        },
        slidesPerView: 3,
      },
    },
    navigation: {
      prevEl: navPrevRef.current,
      nextEl: navNextRef.current,
    },
    pagination: {
      clickable: true,
    },
    loop: false,
  };

  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    data && (
      <CommonContainer>
        <SliderEightContainer>
          <SliderEightArrowWrapper>
            <SwiperArrowButton ref={navPrevRef} direction="left" />

            <SwiperArrowButton ref={navNextRef} direction="right" />
          </SliderEightArrowWrapper>

          {data && (
            <Swiper
              {...custom}
              onResize={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = navPrevRef.current;
                swiper.params.navigation.nextEl = navNextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
            >
              {data?.map((item, index) => {
                return (
                  <SwiperSlide key={index} style={{ margin: "0 !important" }}>
                    <SliderEightItem>
                      <ImageWrapper>
                        <Image
                          src={item.field_thumbnail[0]?.src}
                          alt={item.field_thumbnail[0]?.alt}
                        />
                      </ImageWrapper>

                      <BoxListSixInformation>
                        <BoxListSixTexts>
                          <BoxListSixName>
                            <DangerouslyHtml value={item?.field_artist_name} />
                          </BoxListSixName>

                          <CommonDescTwo>
                            <DangerouslyHtml
                              value={item?.field_performance_category_name}
                            />{" "}
                            <span>/ </span>
                            {item?.field_date} {item?.field_time}
                          </CommonDescTwo>
                        </BoxListSixTexts>

                        <BoxListSixDetailLink
                          to={`${baseUrl}/entertainment/performance/${item.uuid}`}
                        >
                          <ViewDetailsText />

                          <img
                            src={image.linkArrowPurple.default}
                            alt="right arrow"
                            width={22}
                            loading="lazy"
                          />
                        </BoxListSixDetailLink>
                      </BoxListSixInformation>
                    </SliderEightItem>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </SliderEightContainer>
      </CommonContainer>
    )
  );
};

export default SliderEight;
