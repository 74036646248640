import React, { memo, useMemo } from "react";

import { MomentumLayout, PageBottomContents } from "@components/layout";
import { HighlightsSection, OfferRecommendation } from "@components/item";
import { PageContainer, SubpageHero } from "@components/sub-page";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { CustomMetatag } from "@utils/metatags";
import { useGetEatDrinkOfferRecommendation } from "@utils/api-requests/EatDrinkRequests";

import { Restaurants, SeasonalPick } from ".";

const EatDrink = ({ pageInfo }) => {
  const { data: eatDrinkOfferRecommendation } =
    useGetEatDrinkOfferRecommendation();

  const pageData = useMemo(() => {
    return pageInfo;
  }, [pageInfo]);

  const sectionArray = [
    {
      id: 1,
      titleDirection: "left",
      noMargin: true,
      disabled:
        eatDrinkOfferRecommendation?.field_highlights.length === 0 ||
        !eatDrinkOfferRecommendation,
      contents: <HighlightsSection data={eatDrinkOfferRecommendation} />,
    },
    {
      id: 2,
      contents: <Restaurants />,
    },
    {
      id: 3,
      contents: <SeasonalPick />,
    },
  ];

  return (
    <div>
      <CustomMetatag metaData={pageData} />

      <SubpageHero pageData={pageData} />

      <CommonContainer>
        <PageContainer data={pageData} sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={eatDrinkOfferRecommendation} />

        <MomentumLayout data={pageInfo} />
      </PageBottomContents>
    </div>
  );
};

export default memo(EatDrink);
