import React from "react";

import {
  CommonDescTwo,
  CommonTitleFive,
  CommonTitleOne,
} from "@assets/styles/CommonStyles";
import {
  BoxListThreeContainer,
  BoxListThreeInformation,
  BoxListThreeWrapper,
} from "@components/box-list/BoxListStyling";
import { Image, ImageWrapper } from "@components/item/ImageRelated";
import { DangerouslyHtml } from "@components/item";
import { useGetCasinoResponsibleGaming } from "@utils/api-requests/CasinoRequests";

const CasinoResponsibleGaming = () => {
  const { data: casinoResponsibleGamings } = useGetCasinoResponsibleGaming();

  return casinoResponsibleGamings?.field_thumbnail_full_description && (
    <BoxListThreeContainer>
      <BoxListThreeWrapper>
        <ImageWrapper radius="2.5rem" ratio="3 / 2">
          <Image
            src={casinoResponsibleGamings?.field_thumbnail[0]?.src}
            alt={casinoResponsibleGamings?.field_thumbnail[0]?.alt}
          />
        </ImageWrapper>

        <BoxListThreeInformation>
          <CommonTitleOne>
            <DangerouslyHtml
              value={casinoResponsibleGamings?.field_thumbnail_title}
            />
          </CommonTitleOne>

          <CommonTitleFive>
            <DangerouslyHtml
              value={casinoResponsibleGamings?.field_short_description}
            />
          </CommonTitleFive>

          <CommonDescTwo>
            <DangerouslyHtml
              value={casinoResponsibleGamings?.field_thumbnail_full_description}
            />
          </CommonDescTwo>

          {/* <PrimaryButton linkTo="/casino/responsible-gaming">
            <ViewDetailsText />
          </PrimaryButton> */}
        </BoxListThreeInformation>
      </BoxListThreeWrapper>
    </BoxListThreeContainer>
  );
};

export default CasinoResponsibleGaming;
