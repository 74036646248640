import React from "react";

import { PrimaryButton } from "@components/buttons";
import { CommonContentButtonWrapper } from "@components/layout/ContainerStyle";
import { GalleryOne } from "@components/layout";
import { ViewAllText } from "@utils/lanugauge-settings/static-texts/TranslatedTexts";
import { useGetAboutUsGallery } from "@utils/api-requests/AboutUsRequests";
import { ErrorManage } from "@screens/index";

const AboutUsGallery = () => {
  const { data: aboutUsGallery, isError, error } = useGetAboutUsGallery();

  return (
    <ErrorManage isError={isError} error={error}>
      <CommonContentButtonWrapper>
        <GalleryOne
          noClick
          gallery
          data={aboutUsGallery?.recent_gallary_images?.slice(0, 6)}
        />

        <PrimaryButton linkTo="/about-us/gallery">
          <ViewAllText />
        </PrimaryButton>
      </CommonContentButtonWrapper>
    </ErrorManage>
  );
};

export default AboutUsGallery;
