import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { DetailPageContainer, DetailPageHero } from "@components/sub-page";
import { PageBottomContents } from "@components/layout";
import { CommonContainer } from "@components/layout/ContainerStyle";
import {
  MuiBreadcrumbs,
  NotificationToggle,
  OfferRecommendation,
} from "@components/item";
import { ErrorManage } from "@screens/index";
import { useGetAboutUsSitemap } from "@utils/api-requests/AboutUsRequests";
import { CustomMetatag } from "@utils/metatags";

import { SitemapList } from "./detail/sitemap";
import { aboutUsRouteList } from "./detail/aboutUsRouteList";

const AboutUsSitemap = () => {
  const intl = useIntl();

  const currentUrl = useLocation().pathname.split("/")[3];

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    data: aboutUsSitemap,
    isLoading,
    isError,
    error,
  } = useGetAboutUsSitemap();

  const sitemapDetailBC = useMemo(() => {
    const roomDetails = [
      {
        id: 1,
        name: intl.formatMessage({
          id: "lang-home",
        }),
      },
      {
        id: 2,
        name: intl.formatMessage({
          id: "lang-about-us",
        }),
        href: "/about-us",
      },

      { id: 3, current: true, name: aboutUsSitemap?.field_sitemap_name },
    ];

    return roomDetails;
  }, [aboutUsSitemap?.field_sitemap_name, intl]);

  const sectionArray = [
    {
      id: 1,
      contents: <SitemapList />,
    },
  ];

  useEffect(() => {
    setCurrentIndex(
      aboutUsRouteList.findIndex((item) => item === "/" + currentUrl)
    );
  }, [currentUrl]);

  return (
    <ErrorManage isLoading={isLoading} isError={isError} error={error}>
      <CustomMetatag metaData={aboutUsSitemap} />

      <DetailPageHero data={aboutUsSitemap} small />

      <CommonContainer>
        <>
          <NotificationToggle data={aboutUsSitemap} />

          <MuiBreadcrumbs
            breadcrumbArr={sitemapDetailBC}
            pageTitle={aboutUsSitemap?.field_sitemap_name}
            value={aboutUsSitemap?.field_full_description}
            prevUUid={aboutUsRouteList?.[currentIndex - 1]}
            nextUUid={aboutUsRouteList?.[currentIndex + 1]}
            url="/about-us"
          />
        </>

        <DetailPageContainer sectionArray={sectionArray} />
      </CommonContainer>

      <PageBottomContents>
        <OfferRecommendation data={aboutUsSitemap} />
      </PageBottomContents>
    </ErrorManage>
  );
};

export default AboutUsSitemap;
