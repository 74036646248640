import React, { memo, useMemo } from "react";
import { Card, CardContent, CardMedia } from "@mui/material";

import { CommonDescTwo } from "@assets/styles/CommonStyles";
import { SliderTemplate } from "@components/sliders";
import { CommonContainer } from "@components/layout/ContainerStyle";
import { DangerouslyHtml } from "@components/item";
import { LOCAL_STORAGE_LANGUAGE } from "@utils/api-requests/static-data";

import {
  BoxListOneCardArea,
  BoxListOneItem,
  BoxListOneTitle,
} from "./BoxListStyling";
import { SwiperSlide } from "swiper/react";

const BoxListOne = ({ data, nameKey, descKey, imageKey, linkKey }) => {
  const lang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);

  const baseUrl = useMemo(() => {
    return `/${lang}`;
  }, [lang]);

  return (
    data?.length > 0 && (
      <CommonContainer>
        <SliderTemplate length={data?.length}>
          {data?.map((item) => {
            return (
              <SwiperSlide key={item[nameKey]}>
                <BoxListOneItem
                  to={linkKey ? `${baseUrl}/offers${item[linkKey]}` : ""}
                >
                  <Card sx={{ flex: 1 }}>
                    <BoxListOneCardArea>
                      {imageKey ? (
                        <CardMedia
                          component="img"
                          image={`${process.env.REACT_APP_SERVER_URL}${item[imageKey]?.[0]?.src}`}
                          alt={item[imageKey]?.[0]?.alt}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          image={`${process.env.REACT_APP_SERVER_URL}${item?.image?.src}`}
                          alt={item.image?.alt}
                        />
                      )}

                      <CardContent>
                        <BoxListOneTitle className="ellipsis-2">
                          <DangerouslyHtml value={item[nameKey]} />
                        </BoxListOneTitle>

                        <CommonDescTwo className="ellipsis-2">
                          <DangerouslyHtml value={item[descKey]} />
                        </CommonDescTwo>
                      </CardContent>
                    </BoxListOneCardArea>
                  </Card>
                </BoxListOneItem>
              </SwiperSlide>
            );
          })}
        </SliderTemplate>
      </CommonContainer>
    )
  );
};

export default memo(BoxListOne);
